import React from 'react'
import PageContent from 'components/common/PageContent'
import AppComponent from 'components/common/AppComponent'
import ChatTinyHabits from 'components/chatflows/ChatTinyHabits'

const pathElements = [
    {id: 0, title: "Home", url: "/"},
    {id: 1, title: "Chat Flows", url: "/chatflows"},
    {id: 2, title: "Tiny Habits"}
];

export default class ChatTinyHabitsPage extends AppComponent {

    render() {
        return (
            <PageContent pathElements={pathElements} >
                <ChatTinyHabits />
            </PageContent>
        );
    }
}