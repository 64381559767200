import React from 'react'
import ReactTable from 'react-table'
import Axios from 'axios/index'
import { API } from 'api/APIClient'
import DatatableHelper from 'utils/DatatableHelper'
import TableContainer from 'components/common/TableContainer'
import SearchBar from 'components/common/SearchBar'
import DateHelper from 'utils/DateHelper'

export default class AppOrganisationsTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            searchKey: "",
            page: 0,
            pageSize: props.pageSize || 10,
            sorted: [{id: "id", "desc": true}],
            totalPages: -1,
            isLoading: true,
            filtersAreOpen: false
        };

        this.searchTimer = null;
    }

    searchTextDidChange(newValue) {
        this.setState({
            searchKey: newValue || ""
        }, () => {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.fetchItems();
            }, 1000);
        });
    }

    toggleRow = (id) => {
        this.props.onSelectedRow(id);
    }

    toggleSelectAll = () => {
        this.props.onSelectedAll(!this.props.selectAll, this.state.items.map(item => item.id));
    }

    fetchItems = () => {

        if (this.props.onWillFetch instanceof Function) {
            this.props.onWillFetch();
        }

        Axios
            .post(API.organisation.getAppUserOrganisations, {
                    page: this.state.page,
                    pageSize: this.state.pageSize,
                    sorted: this.state.sorted,
                    filters: {
                        search: this.state.searchKey
                    }
                }
            )
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        items: res.data.data.rows,
                        totalPages: DatatableHelper.getTotalPages(res.data.data),
                        isLoading: false
                    }, () => {
                        if (this.props.onDidFetch instanceof Function) {
                            this.props.onDidFetch();
                        }
                    })
                }
                else {
                    this.setState({
                        isLoading: false
                    })
                }
            })
    }

    render() {

        const columns = [
            {
                id: "checkbox",
                accessor: "",
                Cell: row => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.props.selected.has(row.original.id)}
                            onChange={() => this.toggleRow(row.original.id)}
                        />
                    );
                },
                Header: () => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.props.selectAll}
                            onChange={this.toggleSelectAll}
                        />
                    );
                },
                sortable: false,
                width: 45
            },
            {
                Header: 'ID',
                accessor: 'id',
                width: 60
            },
            {
                Header: 'Name',
                accessor: 'name',
                minWidth: 250
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                width: 180,
                Cell: row => {
                    return DateHelper.convertToLocalDateTime(row.value);
                }
            },
            {
                Header: "Status",
                accessor: 'active',
                width: 100,
                Cell: row => (
                    row.original.active ? 'active' : 'suspended'
                )
            }
        ]

        const filters = (
            <div className="cell small-12 medium-6 large-3">
                <span>Search</span>
                <SearchBar
                    onDidChange={(newValue) => this.searchTextDidChange(newValue)}
                />
            </div>
        )

        const table = (
            <ReactTable
                columns={columns}
                data={this.state.items}
                pages={this.state.totalPages}
                pageSize={this.state.pageSize}
                loading={this.state.isLoading}
                multisort={true}
                manual
                className='-striped'
                onFetchData={(state) => {
                    this.setState({
                        page: state.page,
                        pageSize: state.pageSize,
                        sorted: state.sorted
                    }, () => {
                        this.fetchItems()
                    })
                }}
            />
        )

        return (
            <TableContainer
                title="Organisations table"
                filters={filters}
                table={table}
            />
        );
    }

}