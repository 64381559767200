import React from 'react'
import ReactTable from 'react-table'
import Axios from 'axios/index'
import { API } from 'api/APIClient'
import DatatableHelper from 'utils/DatatableHelper'
import TableContainer from 'components/common/TableContainer'
import SearchBar from 'components/common/SearchBar'
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import AppComponent from 'components/common/AppComponent'
import OrganisationForm from 'components/organisation/organisations/OrganisationForm'
import Modal from "react-responsive-modal"
import { NotificationManager } from 'react-notifications'
import StandardModal from 'components/common/StandardModal'
import DateHelper from 'utils/DateHelper'

export default class OrganisationsTable extends AppComponent {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            searchKey: "",
            page: 0,
            pageSize: props.pageSize || 10,
            sorted: [{id: "id", "desc": true}],
            totalPages: -1,
            isLoading: true,
            filtersAreOpen: false,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            }
        };

        this.searchTimer = null;
    }

    searchTextDidChange(newValue) {
        this.setState({
            searchKey: newValue || ""
        }, () => {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.fetchItems();
            }, 1000);
        });
    }

    fetchItems = () => {

        Axios
            .post(API.organisation.getOrganisationsDT, {
                    page: this.state.page,
                    pageSize: this.state.pageSize,
                    sorted: this.state.sorted,
                    filters: {
                        search: this.state.searchKey
                    }
                }
            )
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        items: res.data.data.rows,
                        totalPages: DatatableHelper.getTotalPages(res.data.data),
                        isLoading: false
                    }, () => {
                        if (this.props.onDidFetch instanceof Function) {
                            this.props.onDidFetch();
                        }
                    })
                }
                else {
                    this.setState({
                        isLoading: false
                    })
                }
            })
    }

    handleAddNewItem = () => {
        this.showItemModal({});
    }


    handleEditItem = (item) => {
        this.showItemModal(item);
    }

    showItemModal = (item) => {
        if (!this.state.modal.isOpen) {
            const content = (
                <OrganisationForm
                    item={item}
                    onSubmitted={() => this.closeModal(this.fetchItems)}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null,
                    isUploading: false
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    showDeleteModal = (item) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Organisation"
                    content={(
                        <React.Fragment>
                            <p>Are you sure to delete <strong>{item.name}</strong> organisation ? This action cannot be reverted.</p>
                            <p>Please note that organisation can only be deleted if there are no app or admin users registered to that organisation.</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteItem(item)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                    error={this.state.modal.error}
                    isUploading={this.state.modal.isUploading}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }

    handleDeleteItem = (item) => {

        if (!this.state.modal.isUploading) {
            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isUploading: true
                })
            }, () => {
                Axios
                    .post(API.organisation.deleteOrganisation, {
                        id: item.id
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.closeModal(() => {
                                this.fetchItems();
                                NotificationManager.success("Organisation deleted");
                            });
                        }
                        else {
                            this.closeModal(() => {
                                NotificationManager.error(res.data.error.desc);
                            });
                        }
                    })
                    .catch(error => {
                        this.closeModal(() => {
                            NotificationManager.error(error.response);
                        });
                    });
            })
        }
    }

    render() {

        const columns = [
            {
                Header: 'ID',
                accessor: 'id',
                width: 60
            },
            {
                Header: 'Name',
                accessor: 'name',
                minWidth: 250
            },
            {
                Header: "Role",
                accessor: "roleName",
                width: 200
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                width: 180,
                Cell: row => {
                    return DateHelper.convertToLocalDateTime(row.value);
                }
            },
            {
                Header: "Status",
                accessor: 'active',
                width: 100,
                Cell: row => (
                    row.original.active ? 'active' : 'suspended'
                )
            },
            {
                Header: 'Actions',
                Cell: row => (
                    <ButtonGroup size={Sizes.TINY}>
                        {this.context.permissions.organisations.edit &&
                        <Button color={Colors.PRIMARY} onClick={() => this.handleEditItem(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                        }
                        {this.context.permissions.organisations.delete &&
                        <Button color={Colors.ALERT} onClick={() => this.showDeleteModal(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                        }
                    </ButtonGroup>
                ),
                maxWidth: 100,
                sortable: false,
                show: this.context.permissions.organisations.delete || this.context.permissions.organisations.edit
            }
        ]

        const buttons = (
            <React.Fragment>
                {this.context.permissions.organisations.create &&
                <button type="button" className="button small" onClick={this.handleAddNewItem}>Add New Organisation</button>
                }
            </React.Fragment>
        );

        const filters = (
            <div className="cell small-12 medium-6 large-3">
                <span>Search</span>
                <SearchBar
                    onDidChange={(newValue) => this.searchTextDidChange(newValue)}
                />
            </div>
        )

        const table = (
            <ReactTable
                columns={columns}
                data={this.state.items}
                pages={this.state.totalPages}
                pageSize={this.state.pageSize}
                loading={this.state.isLoading}
                multisort={true}
                manual
                className='-striped'
                onFetchData={(state) => {
                    this.setState({
                        page: state.page,
                        pageSize: state.pageSize,
                        sorted: state.sorted
                    }, () => {
                        this.fetchItems()
                    })
                }}
            />
        )

        return (
            <React.Fragment>
                <TableContainer
                    title="Organisations table"
                    filters={filters}
                    table={table}
                    buttons={buttons}
                />

                <Modal
                    classNames={{modal:"modal-medium"}}
                    open={this.state.modal.isOpen}
                    modalId="Modal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }

}