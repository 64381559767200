import React from 'react'
import Axios from 'axios'
import ReactSelect from 'components/common/ReactSelect'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import {Button, ButtonGroup, Colors, Sizes, Switch} from 'react-foundation'
import ModalProgress from 'components/common/ModalProgress'
import Image from 'components/common/Image'
import BlockUi from 'react-block-ui'
import ServicesSelector from "components/services/ServicesSelector"
import Modal from "react-responsive-modal"
import { UnitType } from "constants/UnitType";
import ReactTable from "react-table";
import DateHelper from "../../utils/DateHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PodcastsSelector from "../podcasts/PodcastsSelector";
import UUID from "../../utils/UUID";
import StandardModal from "../common/StandardModal";

class EmotionForm extends AppComponent {

    static defaultProps = {
        item: {}
    }

    constructor(props, context) {
        super(props);

        this.state = {
            item: props.item.langID ? Object.assign({}, props.item) : Object.assign({
                langID: null,
                translations: [],
                organisationID: props.item.organisationID || context.organisation.id,
                organisations: [],
                podcasts: []
            }, props.item),
            organisationOptions: [
                props.item.organisationID ? {value: props.item.organisationID, label: props.item.organisationName} : {value: context.organisation.id, label: context.organisation.name}
            ],
            validated: false,
            errors: {},
            touched: {},
            imagePickerURL: null,
            imagePickerFile: null,
            isUploading: false,
            uploadProgress: 0,
            isLoadingVisibility: false,
            isLoadingOrganisations: false,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            },
            isLoadingTranslations: false,
            languageOptions: [],
            selectedLanguage: null
        };
    }

    componentDidMount() {
        this.validate();

        if (this.state.item.langID === null) {
            if (this.context.permissions.emotions.allOrgs) {
                this.getOrganisationOptions();
                if (this.state.item.id) {
                    this.getVisibility(this.state.item.id);
                }
            }

            if (this.state.item.id) {
                this.getTranslations();
            }
            else {
                this.getLanguages();
            }
        }
    }

    getTranslations = () => {

        if (this.state.isLoadingTranslations) { return; }

        this.setState({
            isLoadingTranslations: true
        }, () => {
            Axios
                .get(API.emotion.getTranslations, {
                    params: {
                        id: this.state.item.id
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        this.setState(prevState => ({
                            ...prevState,
                            item: {
                                ...prevState.item,
                                translations: res.data.data.translations
                            }
                        }), () => {
                            this.getLanguages();
                        })
                    }
                })
        })
    }

    getLanguages = () => {
        Axios
            .get(API.organisation.getLanguages)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        isLoadingTranslations: false,
                        languageOptions: res.data.data.languages.filter((item) => { return !this.state.item.translations.find((o) => o.langID.toString() === item.id.toString()) }).map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    getOrganisationOptions = () => {

        this.setState({
            isLoadingOrganisations: true
        });

        Axios
            .get(API.organisation.getOrganisations, {
                props: {
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        isLoadingOrganisations: false,
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    getVisibility = (itemID) => {

        this.setState({
            isLoadingVisibility: true
        });

        Axios
            .get(API.emotion.getVisibility, {
                params: {
                    emotionID: itemID
                }
            })
            .then((res) => {
                if (res.data.success) {
                    let item = this.state.item;
                    item.organisations = res.data.data.organisations;
                    this.setState({
                        isLoadingVisibility: false,
                        item: item
                    })
                }
            });
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        let self = this;

        reader.onloadend = () => {
            self.setState({
                imagePickerFile: file,
                imagePickerURL: reader.result
            }, () => {
                self.validate()
            })
        };

        reader.readAsDataURL(file);
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {


                let formData = new FormData();

                if (this.state.item.id) {
                    formData.append("id", this.state.item.id);
                }

                formData.append("name", this.state.item.name);
                formData.append("organisationID", this.state.item.organisationID);
                formData.append("translations", JSON.stringify(this.state.item.translations));

                if (this.state.item.info && this.state.item.info.length > 0) {
                    formData.append("info", this.state.item.info);
                }

                if (this.state.imagePickerFile) {
                    formData.append("file", this.state.imagePickerFile);
                }


                if (this.context.permissions.emotions.allOrgs) {
                    if (this.state.item.organisations) {
                        this.state.item.organisations.forEach((item) => {
                            formData.append("organisations[]", item)
                        });
                    }
                }
                else {
                    if (this.state.item.visible) {
                        formData.append("organisations[]", this.context.organisation.id);
                    }
                }

                this.state.item.podcasts.forEach((item) => {
                    formData.append("podcasts[]", item.id)
                });

                Axios
                    .post((this.state.item.id ? API.emotion.updateEmotion : API.emotion.createEmotion), formData)
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted(res.data.data.item);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }

                        this.setState({
                            isUploading: false
                        });
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let newState = {};

        if (name === "selectedLanguage") {
            newState[name] = value;
        }
        else {

            let item = Object.assign({}, this.state.item);
            item[name] = value;
            newState.item = item;
        }


        this.setState(newState, () => {
            this.validate()
        });
    }


    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    size: "modal-medium"
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.name) {
            if (touched.name) {
                errors.name = "Emotion name is required";
            }
            validated = false;
        }


        if (!item.id && !this.state.imagePickerFile) {
            if (touched.imageFile) {
                errors.imageFile = "Image is required"
            }
            validated = false;
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    showPodcastModal = (item, key) => {

        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <PodcastsSelector
                isSingleSelection={false}
                selected={this.state.item.podcasts ?? []}
                onSubmitted={(podcasts) => {

                    let item = Object.assign({}, this.state.item);
                    item.podcasts = podcasts;

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })

    }

    deleteEmotionPodcast = (podcast) => {
        let item = Object.assign({}, this.state.item);
        item.podcasts = item.podcasts.filter((p) => p.id !== podcast.id);
        this.setState({
            item: item
        }, () => {
            this.validate();
        })
    }

    handleAddTranslation = (langID) => {

        const language = this.state.languageOptions.find((i) => i.value.toString() === langID.toString());

        let translation = {
            langID: language.value,
            langName: language.label,
            name: "",
            info: "",
            id: UUID.uuidv4(),
            isNew: 1
        };

        if (!this.state.modal.isOpen) {
            const content = (
                <EmotionForm
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState(prevState => ({
                languageOptions: this.state.languageOptions.filter((i) => i.value.toString() !== language.value.toString()),
                selectedLanguage: null,
                item: {
                    ...prevState.item,
                    translations: [...prevState.item.translations, translation]
                },
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    isUploading: false,
                    content: content
                })
            }))
        }

    }

    updateTranslation = (updatedTranslation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.map((o) => {
                    if (o.langID.toString() === updatedTranslation.langID.toString()) {
                        return updatedTranslation;
                    }
                    else {
                        return o;
                    }
                })
            }
        }))
    }

    handleEditTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <EmotionForm
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    content: content
                })
            })
        }
    }

    handleDeleteTranslation = (translation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.filter((i) => i.langID.toString() !== translation.langID.toString() )
            },
            languageOptions: [...this.state.languageOptions, {label: translation.langName, value: translation.langID}].sort((a,b) => a.name < b.name ? 1 : -1 )
        }), () => {
            this.closeModal();
        })
    }

    showDeleteTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Translation"
                    content={(
                        <React.Fragment>
                            <p>Are you sure to delete <strong>{translation.langName}</strong> translation ? This action cannot be reverted.</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteTranslation(translation)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    isUploading: false,
                    content: content
                })
            })
        }
    }

    render() {

        let podcastsTableColumns = [
            {
                Header: 'ID',
                accessor: 'id',
                maxWidth: 60
            },
            {
                Header: "Title",
                accessor: "title"
            },
            {
                Header: "Category Title",
                accessor: "categoryTitle"
            },
            {
                Header: 'Actions',
                Cell: row => (
                    <ButtonGroup size={Sizes.TINY}>
                        {this.context.permissions.emotions.delete &&
                        <Button color={Colors.ALERT} onClick={() => this.deleteEmotionPodcast(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                        }
                    </ButtonGroup>
                ),
                maxWidth: 100,
            }
        ];


        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />
                <div className="modal-head">
                    {this.state.item.langID === null ?
                        <h3>{this.state.item.id ? "Edit Emotion" : "New Emotion"}</h3>
                        :
                        <h3>Emotion ({this.state.item.langName} translation)</h3>
                    }
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        {this.state.item.langID === null && !this.context.permissions.emotions.allOrgs &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="visible" className="text-right middle ">Visible</label>
                                </div>
                                <div className="small-9 cell">
                                    <Switch size={Sizes.TINY} onChange={this.handleChange} input={{name:"visible", checked: this.state.item.visible === 1}} />
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="name" className="text-right middle ">* Name</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.name && "error")}>
                                <input type="text" name="name" onChange={this.handleChange} value={this.state.item.name} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.name}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="info" className="text-right middle">Info</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.info && "error")}>
                                <textarea rows="6" type="text" name="info" onChange={this.handleChange} value={this.state.item.info} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.info}</small>
                            </div>
                        </div>
                        {this.state.item.langID === null &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="imageFile" className="text-right middle">Select Image</label>
                                </div>
                                <div className="large-9 small-9 cell ">
                                    <input name="imageFile" type="file" onChange={this.handleFilePickerChange} onBlur={this.handleBlur} />
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="imageFile" className="text-right middle">* Current Image</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.imageFile && "error")} style={{marginBottom: 20}}>
                                    {this.state.imagePickerURL &&
                                    <Image src={this.state.imagePickerURL} width={200} height={120} border={true} fit={"contain"}/>
                                    }
                                    {!this.state.imagePickerURL && this.state.item.imageURL &&
                                    <Image src={this.state.item.imageURL} width={200} height={120} border={true} fit={"contain"}/>
                                    }
                                    {!this.state.imagePickerURL && !this.state.item.imageURL &&
                                    <p>No image selected</p>
                                    }
                                    <small className="error">{this.state.errors.imageFile}</small>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <fieldset className="fieldset">
                                        <legend>Attached Podcasts</legend>
                                        <React.Fragment>
                                            <div className="grid-x grid-padding-x">
                                                <div className="cell small-12">
                                                    <div className="btn-wrap pull-right">
                                                        <button type="button" className="button small" onClick={this.showPodcastModal} style={{marginTop: "-30px"}}>Add New Podcast</button>
                                                    </div>
                                                </div>
                                                <div className="cell small-12">
                                                    <ReactTable
                                                        columns={podcastsTableColumns}
                                                        data={this.state.item.podcasts}
                                                        minRows={1}
                                                        showPagination={false}
                                                        manual
                                                        noDataText={"No podcasts attached"}
                                                        className='-striped'
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    </fieldset>
                                </div>
                            </div>
                            {this.context.permissions.emotions.allOrgs &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <fieldset className="fieldset">
                                        <legend>Visibility</legend>

                                        <BlockUi tag="div" blocking={this.state.isLoadingVisibility || this.state.isLoadingOrganisations}>
                                            <div className="grid-x grid-padding-x">
                                                <div className="small-3 cell">
                                                    <label htmlFor="organisations" className="text-right middle">Organisations</label>
                                                </div>
                                                <div className="large-9 small-9 cell">
                                                    <ReactSelect
                                                        name="organisations"
                                                        onChange={this.handleChange}
                                                        value={this.state.item.organisations}
                                                        options={this.state.organisationOptions}
                                                        isMulti={true}
                                                        placeholder="None"
                                                        className="react-select"
                                                    />
                                                </div>
                                            </div>
                                        </BlockUi>
                                    </fieldset>
                                </div>
                            </div>
                            }
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <fieldset className="fieldset">
                                        <legend>Translations</legend>
                                        <div className="grid-x grid-padding-x">
                                            <div className="cell small-12">
                                                <span>Add translation for</span>
                                                <ReactSelect
                                                    name="selectedLanguage"
                                                    className="react-select translation-selector"
                                                    onChange={this.handleChange}
                                                    value={this.state.selectedLanguage}
                                                    options={this.state.languageOptions}
                                                    onBlur={this.handleBlur}
                                                />
                                                <Button type="button" className="translation-button" color={Colors.PRIMARY} isDisabled={!this.state.selectedLanguage} onClick={() => this.handleAddTranslation(this.state.selectedLanguage)}>Add</Button>
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="cell small-12">
                                                <ReactTable
                                                    columns={[
                                                        {
                                                            Header: 'Language',
                                                            accessor: 'langName'
                                                        },
                                                        {
                                                            Header: 'Actions',
                                                            Cell: row => (
                                                                <ButtonGroup size={Sizes.TINY}>
                                                                    {this.context.permissions.emotions.edit &&
                                                                    <Button type="button" color={Colors.PRIMARY} onClick={() => this.handleEditTranslation(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                                                                    }
                                                                    {this.context.permissions.emotions.delete &&
                                                                    <Button type="button" color={Colors.ALERT} onClick={() => this.showDeleteTranslation(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                                                                    }
                                                                </ButtonGroup>
                                                            ),
                                                            maxWidth: 100,
                                                            show: this.context.permissions.emotions.delete || this.context.permissions.emotions.edit
                                                        }
                                                    ]}
                                                    data={this.state.item.translations}
                                                    className='-striped'
                                                    showPagination={false}
                                                    noDataText={"No translations"}
                                                    pageSize={this.state.item.translations ? this.state.item.translations.length : 1}
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    {this.state.item.langID === null ?
                                        <React.Fragment>
                                            <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                                Cancel
                                            </button>
                                            <button className="button success" type="submit" disabled={!this.state.validated || this.state.isLoadingTranslations}>
                                                Submit
                                            </button>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <button className="button secondary" type="button" onClick={() => this.props.onCompleted(this.state.item)}>
                                                Update
                                            </button>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="EmotionInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}

export default EmotionForm;
