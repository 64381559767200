import React, {useEffect} from "react";
import ModalProgress from "../../common/ModalProgress";
import {useState} from "react";
import {CommonUtil} from "../../../utils/CommonUtil";
import Axios from "axios";
import {API} from "../../../api/APIClient";
import {SSOUtil} from "../../../utils/SSOUtil";

import "./SSOProviderForm.css";
import {SSOProviderRuleBuilder, SSOProviderRuleBuilderActions} from "./SSOProviderRuleBuilder";
import {NotificationManager} from "react-notifications";

export const SSOProviderForm = (props) => {

    const {organisationID} = props;
    const {ssoTypeID} = props;
    const {onSubmitted} = props;
    const {onCancel} = props;

    const [ssoType, setSSOType] = useState(null);
    const [ssoData, setSSOData] = useState({});
    const [active, setActive] = useState(1);
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [requiredKeys, setRequiredKeys] = useState([]);

    const [campusSelections, setCampusSelections] = useState([]);
    const [campusSelectionConditions, setCampusSelectionConditions] = useState([]);

    const [roleSelections, setRoleSelections] = useState([]);
    const [roleSelectionConditions, setRoleSelectionConditions] = useState([]);

    useEffect(() => {
        fetchOrganisationSSOFromNetwork();

        fetchCampusesFromNetwork();
        fetchUserRolesFromNetwork();
    }, []);

    useEffect(() => {
        validate();
    }, [ssoData]);

    function putSSOData(keyName, value) {
        const newSSOData = CommonUtil.cloneObject(ssoData);
        let resolve = resolveTargetFromKeyName(newSSOData, keyName);
        if (value && value !== "") {
            resolve.targetObject[resolve.keyName] = value;
        } else {
            delete resolve.targetObject[resolve.keyName];
        }
        setSSOData(newSSOData);
    }

    function getSSOData(keyName, defaultValue) {
        if (ssoData) {
            let resolve = resolveTargetFromKeyName(ssoData, keyName);
            if (resolve.targetObject[resolve.keyName] !== undefined) {
                return resolve.targetObject[resolve.keyName];
            }
        }
        return defaultValue;
    }

    function resolveTargetFromKeyName(rootObject, keyName) {
        let targetObject = rootObject;

        let keySplit = keyName.split('.');
        let lastKey = keyName;
        console.log("keySplit", keySplit);
        if (keySplit.length > 1) {
            for (let i = 0; i < keySplit.length - 1; i++) {
                console.log("Looking for: " + keySplit[i]);
                if (!targetObject.hasOwnProperty(keySplit[i])) {
                    console.log("Object does not have a key of " + keySplit[i] + ". Creating...");
                    targetObject[keySplit[i]] = {};
                }
                targetObject = targetObject[keySplit[i]];
                console.log("New target object: ", targetObject);
            }
            lastKey = keySplit.pop();
        }

        return { targetObject, keyName : lastKey };
    }

    function processFormSections(sections) {
        const out = [];
        sections.forEach(section => {
            const sectionFields = [];

            if (section.fields && section.fields.length > 0) {
                section.fields.forEach(field => {
                    let key = field.key;
                    if (section.targetProperty) {
                        key = section.targetProperty + "." + field.key;
                    }

                    if (field.type === "text" || field.type === "number") {
                        let inputType = field.type;
                        let value = getSSOData(key, "");

                        sectionFields.push(
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor={key} className="text-right middle">{field.label}</label>
                                </div>
                                <div className="large-9 small-9 cell">
                                    <input
                                        name={key}
                                        type={inputType}
                                        value={value}
                                        placeholder={CommonUtil.getOrDefault(field, "placeholder", "")}
                                        onChange={(e) => putSSOData(key, e.target.value)}
                                    />
                                </div>
                            </div>
                        )
                    }
                });
            }

            let descriptionElem = [];
            if (section.description) {
                descriptionElem = (
                    <div className="grid-x grid-padding-x" style={{marginBottom : "10px"}}>
                        <div className="small-3 cell" />
                        <div className="large-9 small-9 cell">
                            <small>{section.description}</small>
                        </div>
                    </div>
                )
            }

            out.push(
                <div className="grid-x grid-padding-x">
                    <div className="small-12 cell">
                        <fieldset className="fieldset">
                            <legend>{section.title}</legend>

                            {descriptionElem}

                            {sectionFields}
                        </fieldset>
                    </div>
                </div>
            );
        });
        return out;
    }

    function handleSubmit(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (validate()) {
            submitOrganisationSSOOverNetwork();
        }
    }

    function validate() {
        let validated = true;
        if (requiredKeys) {
            requiredKeys.forEach((key) => {
                const value = getSSOData(key, null);
                if (value === null || value === "") {
                    validated = false;
                }
            })
        }
        setValidated(validated);
        return validated;
    }

    function fetchOrganisationSSOFromNetwork() {
        if (isLoading) return;
        setIsLoading(true);

        const formData = new FormData();
        formData.append("organisationID", organisationID);
        formData.append("ssoTypeID", ssoTypeID);

        Axios.post(API.organisation.getOrganisationSSO, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.organisationSSO) {
                        if (resp.data.organisationSSO.data) {
                            setSSOData(resp.data.organisationSSO.data);
                        }

                        if (resp.data.organisationSSO.campusRules) {
                            setCampusSelectionConditions(resp.data.organisationSSO.campusRules);
                        }

                        if (resp.data.organisationSSO.userRoleRules) {
                            setRoleSelectionConditions(resp.data.organisationSSO.userRoleRules);
                        }

                        if (resp.data.organisationSSO.hasOwnProperty("active")) {
                            setActive(parseInt(resp.data.organisationSSO.active));
                        }
                    }

                    if (resp.data.ssoType) {
                        let ssoType = resp.data.ssoType;
                        if (ssoType.fields) {
                            if (!Array.isArray(ssoType.fields)) {
                                ssoType.fields = JSON.parse(ssoType.fields);
                            }

                            const requiredFields = [...requiredKeys];
                            ssoType.fields.forEach((section) => {
                                if (section.fields && section.fields.length > 0) {
                                    section.fields.forEach((field) => {
                                        if (field.required) {
                                            requiredFields.push(field.key);
                                        }
                                    })
                                }
                            });
                            setRequiredKeys(requiredFields);
                        }
                        setSSOType(ssoType);
                    }
                } else {
                    console.log(API.formatError(r));
                }
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    }

    function submitOrganisationSSOOverNetwork() {
        if (isLoading) return;
        setIsLoading(true);

        const formData = new FormData();
        formData.append("organisationID", organisationID);
        formData.append("ssoTypeID", ssoTypeID);
        formData.append("data", JSON.stringify(ssoData));
        formData.append("active", active);

        if (campusSelectionConditions) {
            formData.append("campusRules", JSON.stringify(campusSelectionConditions));
        }

        if (roleSelectionConditions) {
            formData.append("userRoleRules", JSON.stringify(roleSelectionConditions));
        }

        Axios.post(API.organisation.submitOrganisationSSO, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (onSubmitted) {
                        onSubmitted(resp.data.organisationSSO);
                    }
                } else {
                    console.log(API.formatError(resp));
                }
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    function fetchCampusesFromNetwork() {
        Axios.get(API.campus.getCampuses, {
                params: {
                    organisationID
                }
            })
            .then((res) => {
                if (res.data.success) {
                    const campusSelections = res.data.data.campusOptions.map((campus) => {
                        return {
                            id : campus.id,
                            name : campus.name
                        }
                    }).sort((a, b) => a.name.localeCompare(b.name));

                    setCampusSelections(campusSelections);
                } else {
                    NotificationManager.error(res.data.error.desc);
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error("Failed to load Campuses");
            });
    }

    function fetchUserRolesFromNetwork() {
        Axios.get(API.appUser.getRoleOptions)
            .then((res) => {
                if (res.data.success) {
                    const roleSelections = res.data.data.roleOptions.map((role) => {
                        return {
                            id: role.id,
                            name: role.name
                        }
                    })

                    setRoleSelections(roleSelections);
                }
                else {
                    NotificationManager.error(res.data.error.desc);
                    this.setState({
                        isLoadingRoles: false
                    })
                }
            });
    }

    // RENDER

    let ssoBrandElem = [];
    if (ssoType) {
        let ssoTypeIcon = "";
        if (ssoType.iconUrl) {
            ssoTypeIcon = ssoType.iconUrl;
        }

        ssoBrandElem = (
            <div className={"sso-type-widget"}>
                <div className={"sso-type-widget-icon"} style={{backgroundImage : "url(" + ssoTypeIcon + ")"}} />
                <div className={"sso-type-widget-title"}>{ssoType.name}</div>
            </div>
        )
    }

    let formContent = [];

    if (ssoType) {
        if (ssoType.fields) {
            const parsedSSOFields = processFormSections(ssoType.fields);
            if (parsedSSOFields.length > 0) {
                parsedSSOFields.forEach((fields) => {
                    formContent.push(fields);
                });
            }
        }
    }

    const mappingFields = [
        {
            title : "Data Mapping",
            description : "Please review the following. If your Active Directory uses different field names for the following pieces of data, please provide the appropriate mapping so appropriate details can be imported correctly.",
            targetProperty : "mapping",
            fields : [
                {
                    key : SSOUtil.keys.EMAIL,
                    type : "text",
                    label : "Email Address",
                    placeholder : SSOUtil.defaults.email
                },
                {
                    key : SSOUtil.keys.FIRST_NAME,
                    type : "text",
                    label : "First Name",
                    placeholder : SSOUtil.defaults.firstName
                },
                {
                    key : SSOUtil.keys.LAST_NAME,
                    type : "text",
                    label : "Last Name",
                    placeholder : SSOUtil.defaults.lastName
                },
                {
                    key : SSOUtil.keys.DOB,
                    type : "text",
                    label : "Date of Birth",
                    placeholder : SSOUtil.defaults.dob
                }
            ]
        }
    ]

    const genericMappingElems = processFormSections(mappingFields);
    if (genericMappingElems.length > 0) {
        genericMappingElems.forEach((elem) => {
            formContent.push(elem);
        });
    }

    return (
        <>
            <ModalProgress show={isLoading}/>

            <div className="modal-head">
                <h3>SSO Configuration</h3>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="modal-body">
                    {ssoBrandElem}

                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <fieldset className="fieldset" style={{paddingTop: 0, paddingBottom: 0}}>
                                <legend>Status</legend>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell">

                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="active" className="text-right middle">Active</label>
                                            </div>
                                            <div className={"small-9 cell"}>
                                                <label className="chkbx-container">
                                                    <input
                                                        className="chkbx"
                                                        type="checkbox"
                                                        name="active"
                                                        onChange={(e) => {
                                                            setActive(e.target.checked ? 1 : 0)
                                                        }}
                                                        checked={active === 1}
                                                    />

                                                    <span className="chkmk"/>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>

                    {formContent}

                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <fieldset className="fieldset">
                                <legend>Campus Selection Rules</legend>

                                <div className="grid-x grid-padding-x" style={{marginBottom: "10px"}}>
                                    <div className="small-12 cell">
                                        <div className={"organisation-form-hint-box"}>
                                            <div><small>Enable UniWellBeing to automatically assign Single Sign On users
                                                the correct Campus by using field data in their Identity
                                                Profile.</small></div>
                                            <div><small>Provide rules below to allow the system to pick the correct
                                                Campus. You may provide as many rules for each Campus as
                                                required.</small></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x" style={{marginBottom: "10px"}}>
                                    <div className="small-12 cell">
                                        <SSOProviderRuleBuilder
                                            label={"Campus Name"}
                                            values={campusSelections}
                                            data={campusSelectionConditions}
                                            callback={(action, data) => {
                                                if (action === SSOProviderRuleBuilderActions.CHANGE) {
                                                    setCampusSelectionConditions(data);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                            </fieldset>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <fieldset className="fieldset">
                                <legend>User Role Selection Rules</legend>

                                <div className="grid-x grid-padding-x" style={{marginBottom: "10px"}}>
                                    <div className="small-12 cell">
                                        <div className={"organisation-form-hint-box"}>
                                            <div><small>Enable UniWellBeing to automatically assign Single Sign On users
                                                the correct User Role by using field data in their Identity
                                                Profile.</small></div>
                                            <div><small>Provide rules below to allow the system to pick the correct User
                                                Role. You may provide as many rules for each User Role as
                                                required.</small></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x" style={{marginBottom: "10px"}}>
                                    <div className="small-12 cell">
                                        <SSOProviderRuleBuilder
                                            label={"Role Name"}
                                            values={roleSelections}
                                            data={roleSelectionConditions}
                                            callback={(action, data) => {
                                                if (action === SSOProviderRuleBuilderActions.CHANGE) {
                                                    setRoleSelectionConditions(data);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>

                </div>

                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                <button className="button secondary" type="button" onClick={onCancel}>
                                    Cancel
                                </button>
                                <button className="button success" type="submit" disabled={!validated}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )

}