import React from 'react'
import Axios from 'axios'
import moment from 'moment'
import { API } from 'api/APIClient'
import Image from 'components/common/Image'
import ReactSelect from 'components/common/ReactSelect'
import AppComponent from 'components/common/AppComponent'
import AxiosHelper from 'utils/AxiosHelper'
import ModalProgress from 'components/common/ModalProgress'
import FileInput from 'components/common/FileInput'
import { NotificationManager } from 'react-notifications'
import BlockUI from 'react-block-ui'
import ServicesSelector from "../services/ServicesSelector";
import Modal from "react-responsive-modal";
import DatePicker from 'components/common/DatePicker'
import PostHelp from 'components/posts/PostHelp'
import DateHelper from 'utils/DateHelper'
import TemplateFile from "../../assets/files/podcast_asset_template.psd";
import UUID from "../../utils/UUID";
import StandardModal from "../common/StandardModal";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import ReactTable from "react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { SketchPicker } from 'react-color';
import ButtonColors from 'components/posts/ButtonColors'

const defaultButtonTextColor = "#575757";
const defaultButtonBGColor = "#ffffff";

class PostForm extends AppComponent {

    static defaultProps = {
        item: {},
        onSubmitted: () => void 0
    }

    constructor(props, context) {
        super(props);

        let mediaTypes = [
            {value: "nothing", label: "No attachement"},
            {value: "image", label: "Image"},
            {value: "video", label: "Youtube video"}
        ];


        this.state = {
            item: props.item.langID ? Object.assign({
                showTitleOnNotif: 0,
                sendToAll: 0
            }, props.item) : this.composeItemObject(context, props.item, mediaTypes, props.isDuplicate || false),
            showDuplicates: context.permissions.post.allOrgs ? (props.item.id ? false : true) : false,
            organisationOptions: [
                props.item.organisationID ? {value: props.item.organisationID, label: props.item.organisationName} : {value: context.organisation.id, label: context.organisation.name}
            ],
            organisations: [],
            validated: false,
            errors: {},
            touched: {},
            roleOptions: [],
            campusOptions: [],
            studyYearOptions: [],
            courseOptions: [],
            studentCategoryOptions: [],
            degreeTypesOptions: [],
            departmentOptions: [],
            mediaTypes: mediaTypes,
            imagePickerURL: null,
            imagePickerFile: null,
            iconPickerURL: null,
            iconPickerFile: null,
            isUploading: false,
            uploadProgress: 0,
            isLoading: false,
            isLoadingCampuses: false,
            isLoadingRoles: false,
            isLoadingStudyYears: false,
            isLoadingCourses: false,
            isLoadingStudentCategories: false,
            isLoadingDegreeTypes: false,
            isLoadingDepartments: false,
            iconReverted: false,
            contentCharLeft: 500,
            schuduledToPublish: (props.item.active === 0 && props.item.scheduledAt) ? 1 : 0,
            isLoadingTranslations: false,
            languageOptions: [],
            selectedLanguage: null,
            colorPickerContent: null,
            showButtonTextColorPicker: false,
            showButtonBGColorPicker: false,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            }
        };
    }


    componentDidMount() {
        if (this.state.item.langID === null) {
            this.validate();
            this.getRoleOptions();
            this.getCampusOptions();
            this.getStudyYearOptions();
            this.getCourseOptions();
            this.getStudentCategoryOptions();
            this.getDegreeOptions();
            this.getDepartmentOptions();

            if (this.state.item.id) {
                this.getPost(this.state.item.id);
            }

            if (this.props.isDuplicate) {
                this.getPostVisibility(this.props.item.id);
            }

            if (this.context.permissions.post.allOrgs) {
                this.getOrganisationOptions();
            }

            if (!this.state.item.id) {
                this.getLanguages();
            }
        }
    }

    composeItemObject = (context, item, mediaTypes, isDuplicate) => {

        let mediaType = mediaTypes[0].value;
        if (item.imageURL) {
            mediaType = mediaTypes[1].value;
        }
        else if (item.videoURL) {
            mediaType = mediaTypes[2].value;
        }

        if (isDuplicate) {
            let objCopy =  Object.assign({
                mediaType: mediaType,
                scheduledAt: item.scheduledAt || null,
                sendNotifications: item.active === 1 ? 0 : (item.notifOnPublish || 0),
                showTitleOnNotif: item.showTitleOnNotif || 0,
                sendToAll: item.sendToAll || 0,
                cta: item.cta || 0,
                duplicates: [],
                langID: null,
                imageAltText: "",
                translations: [],
                ctaExpiry: item.ctaExpiry || null
            }, item);

            objCopy.id = null;
            objCopy.createdAt = moment.utc().format("YYYY-MM-DD HH:mm:ss");
            objCopy.organisationID = context.organisation.id;
            objCopy.organisationLogoImageURL = context.organisation.logoImageURL;
            objCopy.active = 1;
            objCopy.campuses = [];
            objCopy.courses = [];
            objCopy.degreeTypes = [];
            objCopy.studentCategories = [];
            objCopy.departments = [];

            return objCopy;
        }
        else {
            return Object.assign({
                createdAt: item.createdAt || moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                organisationID: item.organisationID || context.organisation.id,
                organisationLogoImageURL: item.organisationLogoImageURL || context.organisation.logoImageURL,
                active: item.active || 1,
                mediaType: mediaType,
                scheduledAt: item.scheduledAt || null,
                sendNotifications: item.active === 1 ? 0 : (item.notifOnPublish || 0),
                showTitleOnNotif: item.showTitleOnNotif || 0,
                sendToAll: item.sendToAll || 0,
                cta: item.cta || 0,
                imageAltText: "",
                duplicates: [],
                langID: null,
                translations: [],
                ctaExpiry: item.ctaExpiry || null
            }, item);
        }
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisations: res.data.data.organisations,
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} }),
                    })
                }
            })
    }

    getTranslations = () => {

        if (this.state.isLoadingTranslations) { return; }

        this.setState({
            isLoadingTranslations: true
        }, () => {
            Axios
                .get(API.post.getTranslations, {
                    params: {
                        id: this.state.item.id
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        this.setState(prevState => ({
                            ...prevState,
                            item: {
                                ...prevState.item,
                                translations: res.data.data.translations
                            }
                        }), () => {
                            this.getLanguages();
                        })
                    }
                })
        })
    }

    getLanguages = () => {
        Axios
            .get(API.organisation.getLanguages)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        isLoadingTranslations: false,
                        languageOptions: res.data.data.languages.filter((item) => { return !this.state.item.translations.find((o) => o.langID.toString() === item.id.toString()) }).map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    getPost = () => {
        if (!this.state.isLoading) {
            this.setState({
                isLoading: true
            }, () => {

                Axios
                    .get(API.post.getPost, {
                        params: {
                            postID: this.props.item.id
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            let item = res.data.data.post;
                            let postItem = this.composeItemObject(this.context, item, this.state.mediaTypes);
                            this.setState({
                                isLoading: false,
                                item: postItem,
                                schuduledToPublish: (item.active === 0 && item.scheduledAt) ? 1 : 0,
                                organisationOptions: [
                                    item.organisationID ? {value: item.organisationID, label: item.organisationName} : {value: this.context.organisation.id, label: this.context.organisation.name}
                                ]
                            }, () => {
                                this.getPostVisibility(this.state.item.id);
                                this.getTranslations();
                            })
                        }
                        else {
                            this.setState({
                                isLoading: false
                            })
                        }
                    });

            })
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (!this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {
                let formData = new FormData();

                if (this.state.item.id) {
                    formData.append("id", this.state.item.id);
                }

                formData.append("active", this.state.item.active ? 1 : 0);
                formData.append("desc", this.state.item.desc);
                formData.append("mediaType", this.state.item.mediaType);
                formData.append("organisationID", this.state.item.organisationID);
                formData.append("sendNotifications", this.state.item.sendNotifications ? 1 : 0);
                formData.append("scheduledToPublish", this.state.schuduledToPublish ? 1 : 0);
                formData.append("showTitleOnNotif", this.state.item.showTitleOnNotif ? 1 : 0);
                formData.append("sendToAll", this.state.item.sendToAll ? 1 : 0);
                formData.append("cta", this.state.item.cta);
                formData.append("translations", JSON.stringify(this.state.item.translations));

                if (this.state.schuduledToPublish) {
                    formData.append("scheduledAt", this.state.item.scheduledAt);
                }

                if (this.state.item.ctaExpiry) {
                    formData.append("ctaExpiry", this.state.item.ctaExpiry);
                }

                if (this.state.item.action) {
                    formData.append("serviceID", this.state.item.serviceID);
                    formData.append("actionTitle", this.state.item.actionTitle);
                    if (this.state.item.actionButtonTextColor && this.state.item.actionButtonBGColor) {
                        formData.append("actionButtonTextColor", this.state.item.actionButtonTextColor);
                        formData.append("actionButtonBGColor", this.state.item.actionButtonBGColor);
                    }
                }

                if (this.state.item.title) {
                    formData.append("title", this.state.item.title);
                }

                if (this.state.item.mediaType === "image" && this.state.item.imageAction) {
                    formData.append("imageServiceID", this.state.item.imageServiceID);
                }

                if (this.state.item.mediaType === "image" && this.state.imagePickerFile) {
                    formData.append("file", this.state.imagePickerFile);
                }
                else if (this.state.item.mediaType === "video") {
                    formData.append("videoURL", this.state.item.videoURL);
                }

                if (this.state.item.mediaType === "image") {
                    formData.append("imageAltText", this.state.item.imageAltText);
                }

                if (this.props.isDuplicate) {
                    if (this.state.item.mediaType === "image" && !this.state.imagePickerFile && this.props.item.imageURL) {
                        formData.append("imageCopyFromPostID", this.props.item.id);
                    }
                    if (this.state.item.iconImageURL && !this.state.iconPickerFile) {
                        formData.append("iconImageCopyFromPostID", this.props.item.id);
                    }
                }

                if (this.state.iconPickerFile) {
                    formData.append("iconFile", this.state.iconPickerFile);
                }

                if (!this.state.iconPickerFile && this.state.iconReverted) {
                    formData.append("iconReverted", 1);
                }

                if (this.state.item.roles) {
                    this.state.item.roles.forEach((item) => {
                        formData.append("roles[]", item)
                    });
                }

                if (this.state.item.campuses) {
                    this.state.item.campuses.forEach((item) => {
                        formData.append("campuses[]", item)
                    });
                }

                if (this.state.item.studyYears) {
                    this.state.item.studyYears.forEach((item) => {
                        formData.append("studyYears[]", item)
                    });
                }

                if (this.state.item.courses) {
                    this.state.item.courses.forEach((item) => {
                        formData.append("courses[]", item)
                    });
                }

                if (this.state.item.studentCategories) {
                    this.state.item.studentCategories.forEach((item) => {
                        formData.append("studentCategories[]", item)
                    });
                }

                if (this.state.item.degreeTypes) {
                    this.state.item.degreeTypes.forEach((item) => {
                        formData.append("degreeTypes[]", item)
                    });
                }

                if (this.state.item.departments) {
                    this.state.item.departments.forEach((item) => {
                        formData.append("departments[]", item)
                    });
                }

                if (this.state.item.duplicates) {
                    this.state.item.duplicates.forEach((item) => {
                        formData.append("duplicates[]", item)
                    })
                }

                if (this.context.permissions.post.sponsored) {
                    formData.append("sponsored", this.state.item.sponsored ? 1 : 0);
                }

                Axios
                    .post((this.state.item.id ? API.post.updatePost : API.post.createPost), formData, {
                        headers: {
                            'Content-Type': formData.has('file') || formData.has('iconFile') ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
                        },
                        onUploadProgress: (progressEvent) => {
                            this.setState({
                                uploadProgress: AxiosHelper.getUploadProgress(progressEvent)
                            });
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            NotificationManager.success("Saved successfully");
                            this.props.onSubmitted(res.data.data.post);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors,
                                isUploading: false
                            });
                        }
                    })
            })
        }
        else {
            console.log("is uploading");
        }
    }

    getPostVisibility = (postID) => {
        Axios
            .get(API.post.getPostVisibility, {
                params: {
                    id: postID
                }
            })
            .then((res) => {
                if (res.data.success) {
                    let item = Object.assign({}, this.state.item);
                    item.roles = res.data.data.roles;
                    item.studyYears = res.data.data.studyYears;
                    if (!this.props.isDuplicate) {
                        item.campuses = res.data.data.campuses;
                        item.courses = res.data.data.courses;
                        item.studentCategories = res.data.data.studentCategories;
                        item.degreeTypes = res.data.data.degreeTypes;
                        item.departments = res.data.data.departments;
                    }
                    this.setState({
                        item: item
                    })
                }
            });
    }

    handleFileDelete = (name) => {
        if (name === "iconFile") {
            this.setState({
                iconPickerURL: null,
                iconPickerFile: null
            });
        }
        else if (name === "imageFile") {
            this.setState({
                imagePickerFile: null,
                imagePickerURL: null
            })
        }
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];
        let name = event.target.name;

        let self = this;

        reader.onloadend = () => {

            let newState = {};

            if (name === "iconFile") {
                newState = {
                    iconPickerFile: file ? file : null,
                    iconPickerURL: file ? reader.result : null
                }
            }
            else if (name === "imageFile") {
                newState = {
                    imagePickerFile: file ? file : null,
                    imagePickerURL: file ? reader.result : null
                }
            }

            self.setState(newState, () => {
                self.validate()
            })
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    handleIconRevert = (e) => {

        let item = Object.assign({}, this.state.item);
        item.iconImageURL = null;

        this.setState({
            item: item,
            iconReverted: true
        });

    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        console.log(name);

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let newState = {};

        if (name === "selectedLanguage") {
            newState[name] = value;
        }
        else {
            let item = Object.assign({}, this.state.item);
            item[name] = value;

            if (name === "scheduledAt" && value) {
                item[name] = DateHelper.convertFromLocalToUTCDateTime(value);
            }

            if (name === "ctaExpiry" && value) {
                item[name] = DateHelper.convertFromLocalToUTCDateTime(value);
            }

            if (name === "cta" && value === 0) {
                item.ctaExpiry = null;
            }

            if ((name === "roles" && this.state.roleOptions.length === value.length) ||
                (name === "studyYears" && this.state.studyYearOptions.length === value.length) ||
                (name === "campuses" && this.state.campusOptions.length === value.length) ||
                (name === "courses" && this.state.courseOptions.length === value.length) ||
                (name === "studentCategories" && this.state.studentCategoryOptions.length === value.length) ||
                (name === "degreeTypes" && this.state.degreeTypeOptions.length === value.length) ||
                (name === "departments" && this.state.departmentOptions.length === value.length)) {
                item[name] = [];
            }

            if (name === "mediaType") {

                let touched = this.state.touched;
                delete touched.imageFile;
                delete touched.videoURL;

                item.videoURL = null;

                newState.imagePickerURL = null;
                newState.imagePickerFile = null;
                newState.touched = touched;
            }
            else if (name === "organisationID") {

                let logoImageURL = "";

                for (let o of this.state.organisations) {
                    if (o.id.toString() === value.toString()) {
                        logoImageURL = o.logoImageURL;
                    }
                }

                item.organisationLogoImageURL = logoImageURL;

                item.campuses = [];
                newState.campusOptions = [];
            }
            else if (name === "action") {
                item.serviceID = null;
                item.serviceTitle = "";
                item.serviceTypeName = "";
                item.actionTitle = "";
            }
            else if (name === "imageAction") {
                item.imageServiceID = null;
                item.imageServiceTitle = "";
                item.imageServiceTypeName = "";
            }
            else if (name === "active") {
                newState.schuduledToPublish = 0;
                item.scheduledAt = null;
                if (value === 0) {
                    item.sendNotifications = 0;
                }
            }
            else if (name === "schuduledToPublish") {
                newState.schuduledToPublish = value;
                item.scheduledAt = null;
                if (value === 0) {
                    item.sendNotifications = 0;
                }
            }
            else if (name === "desc") {
                if (value.length > 500) {
                    value = value.substring(0, 499);
                    item[name] = value;
                }
                newState.contentCharLeft = 500 - value.length;
            }


            newState.item = item;
        }



        this.setState(newState, () => {
            this.validate();
            if (name === "organisationID") {
                this.getCampusOptions();
            }
        });
    }

    getRoleOptions = () => {

        if (!this.state.isLoadingRoles) {

            this.setState({
                isLoadingRoles: true
            }, () => {

                Axios
                    .get(API.appUser.getRoleOptions)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingRoles: false,
                                roleOptions: res.data.data.roleOptions.map((role) => { return {value: role.id.toString(), label: role.name} })
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingRoles: false
                            })
                        }
                    });
            })
        }
    }

    getCampusOptions = () => {

        if (!this.state.isLoadingCampuses) {

            this.setState({
                isLoadingCampuses: true
            }, () => {

                Axios
                    .get(API.campus.getCampuses, {
                        params: {
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingCampuses: false,
                                campusOptions: res.data.data.campusOptions.map((campus) => { return {value: campus.id.toString(), label: campus.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingCampuses: false
                            })
                        }
                    });

            })

        }


    }

    getStudyYearOptions = () => {

        if (!this.state.isLoadingStudyYears) {

            this.setState({
                isLoadingStudyYears: true
            }, () => {

                Axios
                    .get(API.appUser.getStudyYearOptions)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingStudyYears: false,
                                studyYearOptions: res.data.data.studyYearOptions.map((studyYear) => { return {value: studyYear.value.toString(), label: studyYear.name} })
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingStudyYears: false
                            })
                        }
                    });
            })
        }
    }


    getCourseOptions = () => {

        if (!this.state.isLoadingCourses) {

            this.setState({
                isLoadingCourses: true
            }, () => {

                Axios
                    .get(API.course.getCourses, {
                        params: {
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingCourses: false,
                                courseOptions: res.data.data.courses.map((item) => { return {value: item.id.toString(), label: item.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingCourses: false
                            })
                        }
                    });

            })

        }

    }


    getDepartmentOptions = () => {

        if (!this.state.isLoadingDepartments) {

            this.setState({
                isLoadingDepartments: true
            }, () => {

                Axios
                    .get(API.department.getDepartments, {
                        params: {
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingDepartments: false,
                                departmentOptions: res.data.data.departments.map((item) => { return {value: item.id.toString(), label: item.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingDepartments: false
                            })
                        }
                    });
            })
        }
    }


    getStudentCategoryOptions = () => {
        if (!this.state.isLoadingStudentCategories) {

            this.setState({
                isLoadingStudentCategories: true
            }, () => {

                Axios
                    .get(API.studentCategory.getCategories, {
                        params: {
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingStudentCategories: false,
                                studentCategoryOptions: res.data.data.studentCategories.map((item) => { return {value: item.id.toString(), label: item.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingStudentCategories: false
                            })
                        }
                    });

            })

        }
    }

    getDegreeOptions = () => {
        if (!this.state.isLoadingDegreeTypes) {

            this.setState({
                isLoadingDegreeTypes: true
            }, () => {

                Axios
                    .get(API.degreeType.getTypes, {
                        params: {
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingDegreeTypes: false,
                                degreeTypeOptions: res.data.data.degreeTypes.map((item) => { return {value: item.id.toString(), label: item.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingDegreeTypes: false
                            })
                        }
                    });

            })

        }
    }


    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.desc) {
            if (touched.desc) {
                errors.desc = "Post content is required";
            }
            validated = false;
        }

        if (!item.id && item.mediaType === "image" && !this.state.imagePickerFile && !this.state.item.imageURL) {
            if (touched.imageFile) {
                errors.imageFile = "Image is required if the image attachment has been selected";
            }
            validated = false;
        }
        else if (!item.id && item.mediaType === "video" && !item.videoURL) {
            if (touched.videoURL) {
                errors.videoURL = "Video URL is required";
            }
            validated = false;
        }

        if (item.action === 1) {
            if (!item.actionTitle) {
                if (touched.actionTitle) {
                    errors.actionTitle = "Button title is required";
                }
                validated = false;
            }
            if (!item.serviceID) {
                if (touched.serviceID) {
                    errors.serviceID = "Service is required";
                }
                validated = false;
            }
        }

        if (item.imageAction === 1) {
            if (!item.imageServiceID) {
                if (touched.imageServiceID) {
                    errors.imageServiceID = "Service is required";
                }
                validated = false;
            }
        }


        if (item.cta === 1) {
            if (!item.ctaExpiry) {
                if (touched.ctaExpiry) {
                    errors.ctaExpiry = "CTA Expiry date is required";
                }
                validated = false;
            }
        }


        if (this.state.schuduledToPublish === 1) {
            console.log("publish date on");
            if (!item.scheduledAt) {
                if (touched.scheduledAt) {
                    errors.scheduledAt = "Publish date is required";
                }
                validated = false;
            }
        }


        this.setState({
            validated: validated,
            errors: errors
        });
    }

    showExamples = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        const content = <PostHelp onCancel={this.closeModal} />

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        })
    }

    showServiceModal = (isImageAction) => {

        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.item.serviceID && !isImageAction) {
            selected.push({id: this.state.item.serviceID})
        }
        else if (this.state.item.imageServiceID && isImageAction) {
            selected.push({id: this.state.item.imageServiceID})
        }

        const content = (
            <ServicesSelector
                organisationID={this.state.item.organisationID}
                isSingleSelection
                selected={selected}
                onSubmitted={(service) => {

                    let item = Object.assign({}, this.state.item);

                    if (isImageAction) {
                        item.imageServiceID = service.id;
                        item.imageServiceTitle = service.title;
                        item.imageServiceTypeName = service.typeName;
                    }
                    else {
                        item.serviceID = service.id;
                        item.serviceTitle = service.title;
                        item.serviceTypeName = service.typeName;
                    }

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    item: undefined
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    handleCloseColorPicker = () => {
        this.setState({
            colorPickerContent: null
        })
    }

    handleButtonTextColorPicker = (item, styles) => {

        const content = (
            <React.Fragment>
                <div style={styles.popover}>
                    <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                    <SketchPicker
                        color={item.color}
                        onChange={(color) => {
                            item.onChange(color);
                        }}
                    />
                </div>
            </React.Fragment>
        );

        this.setState({
            colorPickerContent: content
        })
    }

    handleAddTranslation = (langID) => {

        const language = this.state.languageOptions.find((i) => i.value.toString() === langID.toString());

        let translation = {
            langID: language.value,
            langName: language.label,
            name: "",
            actionTitle: "",
            videoURL: "",
            content: "",
            id: UUID.uuidv4(),
            isNew: 1,
            mediaType: this.state.item.mediaType,
            action: this.state.item.action
        };

        if (!this.state.modal.isOpen) {
            const content = (
                <PostForm
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState(prevState => ({
                languageOptions: this.state.languageOptions.filter((i) => i.value.toString() !== language.value.toString()),
                selectedLanguage: null,
                item: {
                    ...prevState.item,
                    translations: [...prevState.item.translations, translation]
                },
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    isUploading: false,
                    content: content
                })
            }))
        }

    }

    updateTranslation = (updatedTranslation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.map((o) => {
                    if (o.langID.toString() === updatedTranslation.langID.toString()) {
                        return updatedTranslation;
                    }
                    else {
                        return o;
                    }
                })
            }
        }))
    }

    handleEditTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <PostForm
                    item={{
                        ...translation,
                        mediaType: this.state.item.mediaType,
                        action: this.state.item.action
                    }}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    content: content
                })
            })
        }
    }

    handleDeleteTranslation = (translation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.filter((i) => i.langID.toString() !== translation.langID.toString() )
            },
            languageOptions: [...this.state.languageOptions, {label: translation.langName, value: translation.langID}].sort((a,b) => a.name < b.name ? 1 : -1 )
        }), () => {
            this.closeModal();
        })
    }

    showDeleteTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Translation"
                    content={(
                        <React.Fragment>
                            <p>Are you sure to delete <strong>{translation.langName}</strong> translation ? This action cannot be reverted.</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteTranslation(translation)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    isUploading: false,
                    content: content
                })
            })
        }
    }

    showButtonColorsModal = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ButtonColors
                defaultButtonBGColor={defaultButtonBGColor}
                defaultButtonTextColor={defaultButtonTextColor}
                onSubmitted={(colors) => {

                    let item = Object.assign({}, this.state.item);
                    item.actionButtonBGColor = colors.bgColor;
                    item.actionButtonTextColor = colors.textColor;

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })
    }

    render() {

        let organisationName = "";

        if (this.state.item.organisationID) {
            const orgID = this.state.item.organisationID;

            if (this.state.organisationOptions.length > 0) {
                for (let i of this.state.organisationOptions) {
                    if (i.value.toString() === orgID.toString()) {
                        organisationName = i.label;
                        break;
                    }
                }
            }
            else {
                organisationName = this.context.organisation.name;
            }
        }

        const styles = {
            colorContainer: {
                display: "inline-block"
            },
            colorText: {
                lineHeight: "40px",
                marginLeft: "10px",
                marginRight: "18px"
            },
            buttonAccessibleColorPresets: {

            },
            buttonPreset: {
                minWidth: "150px",
                backgroundColor: defaultButtonBGColor,
                color: defaultButtonTextColor,
                height: "40px",
                textAlign: "center",
                lineHeight: "40px",
                fontWeight: "900",
                borderRadius: "25px",
                display: "inline-block",
                paddingLeft: "20px",
                paddingRight: "20px",
                border: "1px solid " + defaultButtonTextColor,
                cursor: "pointer"
            },
            buttonPreview: {
                minWidth: "150px",
                backgroundColor: this.state.item.actionButtonBGColor || defaultButtonBGColor,
                color: this.state.item.actionButtonTextColor || defaultButtonTextColor,
                height: "40px",
                textAlign: "center",
                lineHeight: "40px",
                fontWeight: "900",
                borderRadius: "25px",
                display: "inline-block",
                paddingLeft: "20px",
                paddingRight: "20px",
                border: !this.state.item.actionButtonBGColor || this.state.item.actionButtonBGColor === defaultButtonBGColor ? "1px solid " + defaultButtonTextColor : "none"
            },
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            }
        }

        return (
            <React.Fragment>
                    <ModalProgress show={this.state.isUploading} showProgress={this.state.imagePickerFile !== null || this.state.iconPickerFile !== null} progress={this.state.uploadProgress} />
                    <ModalProgress show={this.state.isLoading} showProgress={false} title="Loading"/>
                    <div className="modal-head">
                        {this.state.item.langID === null ?
                            <h3>{this.state.item.id ? "Edit Post" : (this.props.isDuplicate ? "Duplicate Post" : "New Post")}</h3>
                            :
                            <h3>Post ({this.state.item.langName} translation)</h3>
                        }
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="modal-body">
                            {this.state.item.langID === null &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell text-center">
                                        <p>Please check our examples to see what you can do with posts</p>
                                        <button type="button" className="button small secondary" onClick={this.showExamples}>Show examples</button>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="active" className="text-right middle">{ this.props.item.active === 1 ? "Published" : "Publish Now" }</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <label className="chkbx-container">
                                            <input className="chkbx" type="checkbox" name="active" onChange={this.handleChange} checked={this.state.item.active || 0 === 1} />
                                            <span className="chkmk"></span>
                                        </label>
                                    </div>
                                </div>
                                {this.state.item.id && this.props.item.active === 1 && this.state.item.active === 1 &&
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="publishedAt" className="text-right middle">Published At</label>
                                    </div>
                                    <div className="large-9 small-9 cell">
                                        <input name="publishedAt" type="text" value={moment(DateHelper.convertToLocalDateTime(this.state.item.publishedAt), "YYYY-MM-DD HH:mm:ss").format("LLLL")} disabled={true} />
                                    </div>
                                </div>
                                }
                                {!this.state.item.active &&
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="schuduledToPublish" className="text-right middle">Schedule to publish</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <label className="chkbx-container">
                                            <input className="chkbx" type="checkbox" name="schuduledToPublish" onChange={this.handleChange} checked={this.state.schuduledToPublish || 0 === 1} />
                                            <span className="chkmk"></span>
                                        </label>
                                    </div>
                                </div>
                                }
                                {!this.state.item.active && this.state.schuduledToPublish === 1 &&
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="scheduledAt" className="text-right middle">* Publish Date</label>
                                    </div>
                                    <div className={"small-9 cell " + (this.state.errors.scheduledAt && "error")}>
                                        <DatePicker
                                            name="scheduledAt"
                                            data-enable-time
                                            value={DateHelper.convertToLocalDateTime(this.state.item.scheduledAt)}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            options={{
                                                dateFormat: "Y-m-d H:i:S",
                                                altFormat: "l, F j, Y h:i K",
                                                altInput: true,
                                                static: true,
                                                minuteIncrement: 1
                                            }}
                                        />
                                        <small className="error">{this.state.errors.scheduledAt}</small>
                                    </div>
                                </div>
                                }
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="cta" className="text-right middle">Show as CTA</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <label className="chkbx-container">
                                            <input className="chkbx" type="checkbox" name="cta" onChange={this.handleChange} checked={(this.state.item.cta || 0) === 1} />
                                            <span className="chkmk"></span>
                                        </label>
                                    </div>
                                </div>
                                {this.state.item.cta === 1 &&
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="ctaExpiry" className="text-right middle">* CTA Expiry Date</label>
                                    </div>
                                    <div className={"small-9 cell " + (this.state.errors.ctaExpiry && "error")}>
                                        <DatePicker
                                            name="ctaExpiry"
                                            data-enable-time
                                            value={DateHelper.convertToLocalDateTime(this.state.item.ctaExpiry)}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            options={{
                                                dateFormat: "Y-m-d H:i:S",
                                                altFormat: "l, F j, Y h:i K",
                                                altInput: true,
                                                static: true,
                                                minuteIncrement: 1
                                            }}
                                        />
                                        <small className="error">{this.state.errors.ctaExpiry}</small>
                                    </div>
                                </div>
                                }
                                {this.context.permissions.post.notifications &&
                                <React.Fragment>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="sendNotifications" className="text-right middle">{this.state.schuduledToPublish ? "Send notifications on publish" : "Send notifications"}</label>
                                        </div>
                                        <div className="large-4 small-9 cell">
                                            <label className="chkbx-container">
                                                <input className="chkbx" type="checkbox" name="sendNotifications" onChange={this.handleChange} checked={(this.state.item.sendNotifications || 0) === 1} disabled={this.state.item.active !== 1 && this.state.schuduledToPublish !== 1}/>
                                                <span className="chkmk"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {this.state.item.sendNotifications === 1 &&
                                        <React.Fragment>
                                            <div className="grid-x grid-padding-x">
                                                <div className="small-3 cell">
                                                    <label htmlFor="showTitleOnNotif" className="text-right middle">Show title in notification</label>
                                                </div>
                                                <div className="large-4 small-9 cell">
                                                    <label className="chkbx-container">
                                                        <input className="chkbx" type="checkbox" name="showTitleOnNotif" onChange={this.handleChange} checked={(this.state.item.showTitleOnNotif || 0) === 1} disabled={this.state.item.active !== 1 && this.state.schuduledToPublish !== 1}/>
                                                        <span className="chkmk"></span>
                                                    </label>
                                                    <small className="field-info">If selected, post's title will be shown in the notification</small>
                                                </div>
                                            </div><div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="sendToAll" className="text-right middle">Send to All</label>
                                            </div>
                                            <div className="large-4 small-9 cell">
                                                <label className="chkbx-container">
                                                    <input className="chkbx" type="checkbox" name="sendToAll" onChange={this.handleChange} checked={(this.state.item.sendToAll || 0) === 1} disabled={this.state.item.active !== 1 && this.state.schuduledToPublish !== 1}/>
                                                    <span className="chkmk"></span>
                                                </label>
                                                <small className="field-info">If selected, notifications for the post will be sent to all users, regardless of whether they have opted out of receiving post notifications in their personal settings.</small>
                                            </div>
                                        </div>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                                }
                                {this.context.permissions.post.sponsored &&
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="sponsored" className="text-right middle">Pinned Post</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <label className="chkbx-container">
                                            <input className="chkbx" type="checkbox" name="sponsored" onChange={this.handleChange} checked={(this.state.item.sponsored || 0) === 1} />
                                            <span className="chkmk"></span>
                                        </label>
                                    </div>
                                </div>
                                }
                                {this.context.permissions.post.allOrgs &&
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="organisationID" className="text-right middle">* Posted by</label>
                                    </div>
                                    <div className={"small-9 cell " + (this.state.errors.organisationID && "error")}>
                                        <ReactSelect
                                            name="organisationID"
                                            className="react-select"
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            value={this.state.item.organisationID}
                                            options={this.state.organisationOptions}
                                            isDisabled={!!this.state.item.id}
                                        />
                                        <small className="error">{this.state.errors.organisationID}</small>
                                    </div>
                                </div>
                                }
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="createdAt" className="text-right middle">Created At</label>
                                    </div>
                                    <div className="large-9 small-9 cell">
                                        <input name="createdAt" type="text" value={moment(DateHelper.convertToLocalDateTime(this.state.item.createdAt), "YYYY-MM-DD HH:mm:ss").format("LLLL")} disabled={true} />
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="title" className="text-right middle ">Title</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.title && "error")}>
                                    <input type="text" name="title" onChange={this.handleChange} placeholder={organisationName} value={this.state.item.title} onBlur={this.handleBlur} />
                                    <small className="error">{this.state.errors.title}</small>
                                    {this.state.item.langID === null &&
                                    <small className="field-info">If left blank then organisation's name will be used for the title</small>
                                    }
                                </div>
                            </div>
                            {this.state.item.langID === null &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="iconFile" className="text-right middle">Select Icon</label>
                                    </div>
                                    <div className="large-9 small-9 cell ">
                                        <FileInput
                                            name="iconFile"
                                            onChange={this.handleFilePickerChange}
                                            onBlur={this.handleBlur}
                                            onDelete={this.handleFileDelete}
                                            placeholder="Not selected. Ideal size W:126px H:126px (jpg or png)"
                                            accept="image/jpeg,image/jpg,image/png"
                                        />
                                        <small className="field-info">If left blank then organisation's logo will be used for the icon</small>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="iconFile" className="text-right middle">Current Icon</label>
                                    </div>
                                    <div className={"large-9 small-9 cell " + (this.state.errors.imageFile && "error")} style={{marginBottom: 20}}>
                                        {this.state.iconPickerURL &&
                                        <Image src={this.state.iconPickerURL} width={42} height={42} border={true} fit="contain" />
                                        }
                                        {!this.state.iconPickerURL && this.state.item.iconImageURL &&
                                        <React.Fragment>
                                            <Image src={this.state.item.iconImageURL} width={42} height={42} border={true} fit="contain" />
                                            <button type="button" className="button alert" style={{marginBottom: "0px", marginLeft: "20px"}} onClick={this.handleIconRevert}>Change to organisation's logo</button>
                                        </React.Fragment>
                                        }
                                        {!this.state.iconPickerURL && !this.state.item.iconImageURL && this.state.item.organisationLogoImageURL &&
                                        <Image src={this.state.item.organisationLogoImageURL} width={42} height={42} border={true} fit="contain" />
                                        }
                                        {!this.state.iconPickerURL && !this.state.item.iconImageURL && !this.state.item.organisationLogoImageURL &&
                                        <p>No image selected</p>
                                        }
                                        <small className="error">{this.state.errors.imageFile}</small>
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="desc" className="text-right middle">* Content</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.desc && "error")}>
                                    <textarea rows="6" type="text" name="desc" onChange={this.handleChange} value={this.state.item.desc} onBlur={this.handleBlur} />
                                    <small className="field-info">{this.state.contentCharLeft} characters left</small>
                                    <small className="error">{this.state.errors.desc}</small>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <fieldset className="fieldset">
                                        <legend>Action Button</legend>
                                        {this.state.item.langID === null &&
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="action" className="text-right middle">Add Action</label>
                                            </div>
                                            <div className="large-4 small-9 cell">
                                                <label className="chkbx-container">
                                                    <input className="chkbx" type="checkbox" name="action" onChange={this.handleChange}
                                                           checked={this.state.item.action || 0 === 1}/>
                                                    <span className="chkmk"></span>
                                                </label>
                                            </div>
                                        </div>
                                        }
                                        {this.state.item.action === 1 &&
                                        <React.Fragment>
                                            { this.state.item.langID === null &&
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="buttonColor" className="text-right middle ">Button preview</label>
                                                    </div>
                                                    <div className="small-9 cell">
                                                        <div style={styles.buttonPreview}>
                                                            {this.state.item.actionTitle || "Button"}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="grid-x grid-padding-x">
                                                <div className="small-3 cell">
                                                    <label htmlFor="actionTitle" className="text-right middle ">* Button title</label>
                                                </div>
                                                <div className={"small-9 cell " + (this.state.errors.actionTitle && "error")}>
                                                    <input type="text" name="actionTitle" onChange={this.handleChange} value={this.state.item.actionTitle} onBlur={this.handleBlur} />
                                                    <small className="error">{this.state.errors.actionTitle}</small>
                                                </div>
                                            </div>
                                            {this.state.item.langID === null &&
                                                <React.Fragment>
                                                    <div className="grid-x grid-padding-x">
                                                        <div className="small-3 cell">
                                                            <label htmlFor="buttonColor" className="text-right middle ">Button colour</label>
                                                        </div>
                                                        <div className="small-9 cell">
                                                            <div style={styles.colorContainer}>
                                                                <div
                                                                    style={ styles.swatch }
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            showButtonTextColorPicker: true
                                                                        })
                                                                    }}
                                                                >
                                                                    <div style={Object.assign({backgroundColor: this.state.item.actionButtonTextColor || defaultButtonTextColor}, styles.color)} />
                                                                </div>
                                                                {this.state.showButtonTextColorPicker &&
                                                                    <div style={styles.popover}>
                                                                        <div style={styles.cover} onClick={() => {
                                                                            this.setState({
                                                                                showButtonTextColorPicker: false
                                                                            })
                                                                        }} />
                                                                        <SketchPicker
                                                                            color={this.state.item.actionButtonTextColor || defaultButtonTextColor}
                                                                            onChange={(color) => {
                                                                                let item = Object.assign({}, this.state.item);
                                                                                item.actionButtonTextColor = color.hex;
                                                                                this.setState({
                                                                                    item: item
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                <span style={styles.colorText}>Text</span>
                                                            </div>
                                                            <div style={styles.colorContainer}>
                                                                <div
                                                                    style={ styles.swatch }
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            showButtonBGColorPicker: true
                                                                        })
                                                                    }}
                                                                >
                                                                    <div style={Object.assign({backgroundColor: this.state.item.actionButtonBGColor || defaultButtonBGColor}, styles.color)} />
                                                                </div>
                                                                {this.state.showButtonBGColorPicker &&
                                                                    <div style={styles.popover}>
                                                                        <div style={styles.cover} onClick={() => {
                                                                            this.setState({
                                                                                showButtonBGColorPicker: false
                                                                            })
                                                                        }} />
                                                                        <SketchPicker
                                                                            color={this.state.item.actionButtonBGColor || defaultButtonBGColor}
                                                                            onChange={(color) => {
                                                                                let item = Object.assign({}, this.state.item);
                                                                                item.actionButtonBGColor = color.hex;
                                                                                this.setState({
                                                                                    item: item
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                <span style={styles.colorText}>Background</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid-x grid-padding-x">
                                                        <div className="small-3 cell">
                                                            <label htmlFor="serviceID" className="text-right middle">Button Presets</label>
                                                        </div>
                                                        <div className="small-9 cell">
                                                            <button type="button" className="button primary" onClick={this.showButtonColorsModal}>Show Colour Presets</button>
                                                        </div>
                                                    </div>
                                                    <div className="grid-x grid-padding-x">
                                                        <div className="small-3 cell">
                                                            <label htmlFor="serviceID" className="text-right middle">* Service</label>
                                                        </div>
                                                        <div className={"small-9 cell " + (this.state.errors.serviceID && "error")}>
                                                            <div className="input-group">
                                                                <span className="input-group-label" style={{fontSize: 10}}>{this.state.item.serviceTypeName}</span>
                                                                <input className="input-group-field" name="serviceID" type="text" value={this.state.item.serviceTitle || "Not selected" } disabled/>
                                                                <div className="input-group-button">
                                                                    <button type="button" className="button" onClick={() => this.showServiceModal(false)}>Browse</button>
                                                                </div>
                                                            </div>
                                                            <small className="error">{this.state.errors.serviceID}</small>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                        }
                                    </fieldset>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <fieldset className="fieldset">
                                        <legend>Attachement</legend>
                                        {this.state.item.langID === null &&
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="mediaType" className="text-right middle">Type</label>
                                            </div>
                                            <div className="large-9 small-9 cell">
                                                <ReactSelect
                                                    name="mediaType"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.mediaType || null}
                                                    options={this.state.mediaTypes}
                                                    className="react-select"
                                                />
                                            </div>
                                        </div>
                                        }
                                        {this.state.item.mediaType === "video" &&
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="videoURL" className="text-right middle">* Youtube video URL</label>
                                            </div>
                                            <div className={"large-9 small-9 cell " + (this.state.errors.videoURL && "error")}>
                                                <input name="videoURL" type="text" value={this.state.item.videoURL} onBlur={this.handleBlur} onChange={this.handleChange} />
                                                <small className="error">{this.state.errors.videoURL}</small>
                                            </div>
                                        </div>
                                        }
                                        {this.state.item.langID === null &&
                                        <React.Fragment>
                                            {this.state.item.mediaType === "image" &&
                                            <React.Fragment>
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="imageFile" className="text-right middle">Select Image</label>
                                                    </div>
                                                    <div className="large-9 small-9 cell ">
                                                        <FileInput
                                                            name="imageFile"
                                                            onChange={this.handleFilePickerChange}
                                                            onBlur={this.handleBlur}
                                                            onDelete={this.handleFileDelete}
                                                            placeholder="Not selected. Ideal size W:1160px H:654px (jpg or png)"
                                                            accept="image/jpeg,image/jpg,image/png"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="imageFile" className="text-right middle">* Current Image</label>
                                                    </div>
                                                    <div className={"large-9 small-9 cell " + (this.state.errors.imageFile && "error")} style={{marginBottom: 20}}>
                                                        {this.state.imagePickerURL &&
                                                        <Image src={this.state.imagePickerURL} width={200} height={120} border={true} />
                                                        }
                                                        {!this.state.imagePickerURL && this.state.item.imageURL &&
                                                        <Image src={this.state.item.imageURL} width={200} height={120} border={true} />
                                                        }
                                                        {!this.state.imagePickerURL && !this.state.item.imageURL &&
                                                        <p>No image selected</p>
                                                        }
                                                        <small className="error">{this.state.errors.imageFile}</small>
                                                    </div>
                                                </div>
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="imageAltText" className="text-right middle">Alt text</label>
                                                    </div>
                                                    <div className={"large-9 small-9 cell " + (this.state.errors.imageAltText && "error")}>
                                                        <textarea rows={5} name="imageAltText" value={this.state.item.imageAltText} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                                        <small className="field-info">Image's description used by Voice Over reader in the app</small>
                                                        <small className="error">{this.state.errors.imageAltText}</small>
                                                    </div>
                                                </div>
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="imageAction" className="text-right middle">Add Action</label>
                                                    </div>
                                                    <div className="large-4 small-9 cell">
                                                        <label className="chkbx-container">
                                                            <input className="chkbx" type="checkbox" name="imageAction" onChange={this.handleChange}
                                                                   checked={this.state.item.imageAction || 0 === 1}/>
                                                            <span className="chkmk"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {this.state.item.imageAction === 1 &&
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="imageServiceID" className="text-right middle">* Service</label>
                                                    </div>
                                                    <div className={"small-9 cell " + (this.state.errors.imageServiceID && "error")}>
                                                        <div className="input-group">
                                                            <span className="input-group-label" style={{fontSize: 10}}>{this.state.item.imageServiceTypeName}</span>
                                                            <input className="input-group-field" name="imageServiceID" type="text" value={this.state.item.imageServiceTitle || "Not selected" } disabled/>
                                                            <div className="input-group-button">
                                                                <button type="button" className="button" onClick={() => this.showServiceModal(true)}>Browse</button>
                                                            </div>
                                                        </div>
                                                        <small className="error">{this.state.errors.imageServiceID}</small>
                                                    </div>
                                                </div>
                                                }
                                            </React.Fragment>
                                            }
                                        </React.Fragment>
                                        }
                                    </fieldset>
                                </div>
                            </div>
                            {this.state.item.langID === null &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell">
                                        <fieldset className="fieldset">
                                            <legend>Visibility</legend>
                                            <BlockUI tag="div" blocking={this.state.isLoadingCampuses || this.state.isLoadingRoles || this.state.isLoadingStudyYears || this.state.isLoadingCourses || this.state.isLoadingStudentCategories || this.state.isLoadingDegreeTypes}>
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="roles" className="text-right middle">User role</label>
                                                    </div>
                                                    <div className="large-9 small-9 cell">
                                                        <ReactSelect
                                                            name="roles"
                                                            onChange={this.handleChange}
                                                            value={this.state.item.roles}
                                                            options={this.state.roleOptions}
                                                            isMulti
                                                            placeholder="All"
                                                            className="react-select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="campuses" className="text-right middle">Campus</label>
                                                    </div>
                                                    <div className="large-9 small-9 cell">
                                                        <ReactSelect
                                                            name="campuses"
                                                            onChange={this.handleChange}
                                                            value={this.state.item.campuses}
                                                            options={this.state.campusOptions}
                                                            isMulti={true}
                                                            placeholder="All"
                                                            className="react-select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="studyYears" className="text-right middle">Study Year</label>
                                                    </div>
                                                    <div className="large-9 small-9 cell">
                                                        <ReactSelect
                                                            name="studyYears"
                                                            onChange={this.handleChange}
                                                            value={this.state.item.studyYears}
                                                            options={this.state.studyYearOptions}
                                                            isMulti={true}
                                                            placeholder="All"
                                                            className="react-select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="courses" className="text-right middle">School/Department</label>
                                                    </div>
                                                    <div className="large-9 small-9 cell">
                                                        <ReactSelect
                                                            name="courses"
                                                            onChange={this.handleChange}
                                                            value={this.state.item.courses}
                                                            options={this.state.courseOptions}
                                                            isMulti={true}
                                                            placeholder="All"
                                                            className="react-select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="departments" className="text-right middle">Staff Department</label>
                                                    </div>
                                                    <div className="large-9 small-9 cell">
                                                        <ReactSelect
                                                            name="departments"
                                                            onChange={this.handleChange}
                                                            value={this.state.item.departments}
                                                            options={this.state.departmentOptions}
                                                            isMulti={true}
                                                            placeholder="All"
                                                            className="react-select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="studentCategories" className="text-right middle">Student Category</label>
                                                    </div>
                                                    <div className="large-9 small-9 cell">
                                                        <ReactSelect
                                                            name="studentCategories"
                                                            onChange={this.handleChange}
                                                            value={this.state.item.studentCategories}
                                                            options={this.state.studentCategoryOptions}
                                                            isMulti={true}
                                                            placeholder="All"
                                                            className="react-select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="degreeTypes" className="text-right middle">Degree Type</label>
                                                    </div>
                                                    <div className="large-9 small-9 cell">
                                                        <ReactSelect
                                                            name="degreeTypes"
                                                            onChange={this.handleChange}
                                                            value={this.state.item.degreeTypes}
                                                            options={this.state.degreeTypeOptions}
                                                            isMulti={true}
                                                            placeholder="All"
                                                            className="react-select"
                                                        />
                                                    </div>
                                                </div>
                                            </BlockUI>
                                        </fieldset>
                                    </div>
                                </div>
                                {this.state.showDuplicates &&
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell">
                                        <fieldset className="fieldset">
                                            <legend>Duplicate post for other organisations </legend>
                                            <div className="fieldset-info">
                                                <h5>Duplication rules</h5>
                                                <p><small>For the visibility, only <strong>User Roles</strong> and <strong>Study Year</strong> options will be copied as these are the only values shared across all organisations</small></p>
                                                <p><small>If organisation's logo is selected for the post's icon (icon image not set) then duplicated posts will use a logo of their own organisation</small></p>
                                                <p><small>Only system services are allowed for button or image actions if duplication is requested.</small></p>
                                            </div>
                                            <div className="grid-x grid-padding-x">
                                                <div className="small-3 cell">
                                                    <label htmlFor="duplicates" className="text-right middle">Duplicate for</label>
                                                </div>
                                                <div className={"small-9 cell " + (this.state.errors.duplicates && "error")}>
                                                    <ReactSelect
                                                        name="duplicates"
                                                        className="react-select"
                                                        onChange={this.handleChange}
                                                        value={this.state.item.duplicates}
                                                        options={this.state.organisationOptions}
                                                        isMulti
                                                        placeholder={"None"}
                                                    />
                                                    <small className="error">{this.state.errors.duplicates}</small>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                }
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell">
                                        <fieldset className="fieldset">
                                            <legend>Translations</legend>
                                            <div className="grid-x grid-padding-x">
                                                <div className="cell small-12">
                                                    <span>Add translation for</span>
                                                    <ReactSelect
                                                        name="selectedLanguage"
                                                        className="react-select translation-selector"
                                                        onChange={this.handleChange}
                                                        value={this.state.selectedLanguage}
                                                        options={this.state.languageOptions}
                                                        onBlur={this.handleBlur}
                                                    />
                                                    <Button type="button" className="translation-button" color={Colors.PRIMARY} isDisabled={!this.state.selectedLanguage} onClick={() => this.handleAddTranslation(this.state.selectedLanguage)}>Add</Button>
                                                </div>
                                            </div>
                                            <div className="grid-x grid-padding-x">
                                                <div className="cell small-12">
                                                    <ReactTable
                                                        columns={[
                                                            {
                                                                Header: 'Language',
                                                                accessor: 'langName'
                                                            },
                                                            {
                                                                Header: 'Actions',
                                                                Cell: row => (
                                                                    <ButtonGroup size={Sizes.TINY}>
                                                                        {this.context.permissions.campus.edit &&
                                                                        <Button type="button" color={Colors.PRIMARY} onClick={() => this.handleEditTranslation(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                                                                        }
                                                                        {this.context.permissions.campus.delete &&
                                                                        <Button type="button" color={Colors.ALERT} onClick={() => this.showDeleteTranslation(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                                                                        }
                                                                    </ButtonGroup>
                                                                ),
                                                                maxWidth: 100,
                                                                show: this.context.permissions.campus.delete || this.context.permissions.campus.edit
                                                            }
                                                        ]}
                                                        data={this.state.item.translations}
                                                        className='-striped'
                                                        showPagination={false}
                                                        noDataText={"No translations"}
                                                        pageSize={this.state.item.translations ? this.state.item.translations.length : 1}
                                                    />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </React.Fragment>
                            }

                            <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                                <div className="cell small-12">
                                    <span>{this.state.errors.genericError}</span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <div className="btn-wrap">
                                        {this.state.item.langID === null ?
                                            <React.Fragment>
                                                <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                                    Cancel
                                                </button>
                                                <button className="button success" type="submit" disabled={!this.state.validated || this.state.isLoadingTranslations}>
                                                    Submit
                                                </button>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <button className="button secondary" type="button" onClick={() => this.props.onCompleted(this.state.item)}>
                                                    Update
                                                </button>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <Modal
                        classNames={{modal: this.state.modal.size}}
                        open={this.state.modal.isOpen}
                        modalId="PostInnerModal"
                        onClose={this.closeModal}
                        center
                    >
                        {this.state.modal.content}
                    </Modal>
            </React.Fragment>
        );
    }
}

export default PostForm;
