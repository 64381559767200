import React from 'react'
import AppComponent from 'components/common/AppComponent'
import Utils from "../../utils/Utils";
import Axios from "axios";
import {API, BASE_URL} from "../../api/APIClient";
import {NotificationManager} from "react-notifications";
import AxiosHelper from "../../utils/AxiosHelper";
import BlockUi from "react-block-ui";
import PageContent from "../common/PageContent";
import ReactSelect from "../common/ReactSelect";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import ContentEditor from 'components/contentEditor/ContentEditor'
import { Redirect } from 'react-router-dom'
import DatePicker from "../common/DatePicker";
import FileInput from "../common/FileInput";
import Image from "../common/Image";
import { ChallengeType } from "constants/ChallengeType"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DateHelper from 'utils/DateHelper'
import UUID from "../../utils/UUID";
import StandardModal from "../common/StandardModal";
import ReactTable from "react-table";
import Modal from "react-responsive-modal";

const pathElements = [
    {id: 0, title: "Home", url: "/"},
    {id: 1, title: "Challenges", url: "/challenges"},
    {id: 2, title: "Challenge Edit"}
];

export default class ChallengeEdit extends AppComponent {

    constructor(props, context) {
        super(props);

        let item = this.props.item ? this.props.item : Utils.getNested(props, "location", "state", "item");

        let parsedVisibility = item && !item.langID ? this.parseVisibility(item.visibility) : undefined;

        this.state = {
            habitUnitOptions: item ? [{value: item.unitID, label: item.unitSystemName}] : [],
            challengeTypeOptions: item ? [{value: item.typeID, label: item.typeName}] : [],
            redirect: false,
            errors: [],
            touched: [],
            elements: null,
            defaults: null,
            item: item && item.langID ? Object.assign({}, item) : Object.assign({
                langID: null,
                translations: [],
                organisationID: context.organisation.id
            }, item),
            isUploading: false,
            validated: false,
            isFetching: false,
            habitOptions: item && item.typeID === ChallengeType.SINGLE_HABIT && item.habitID ? [{value: item.habitID, label: item.habitName}] : [],
            fetchingHabitOptions: false,
            habitGroupOptions: item && item.typeID === ChallengeType.HABIT_GROUP && item.habitGroupID ? [{value: item.habitGroupID, label: item.habitGroupTitle}] : [],
            fetchingHabitGroupOptions: false,
            categoryOptions: item && item.typeID === ChallengeType.WELLBEING_CATEGORY && item.categoryID ? [{value: item.categoryID, label: item.categoryName}] : [],
            fetchingCategoryOptions: false,
            visibility: parsedVisibility ? parsedVisibility.visibility : (context.permissions.challenges.allOrgs && !item ? [{organisation: null, campuses: []}] : [{organisation: context.organisation.id, campuses: []}]),
            campusOptions: parsedVisibility ? parsedVisibility.campuses : [],
            organisationOptions: parsedVisibility ? parsedVisibility.organisations : (context.permissions.challenges.allOrgs && !item ? [] : [{value: context.organisation.id, label: context.organisation.name}]),
            imagePickerURL: null,
            imagePickerFile: null,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            },
            isLoadingTranslations: false,
            languageOptions: [],
            selectedLanguage: null
        }
    }


    parseVisibility = (items) => {

        let visibility = {};
        let campuses = {};
        let organisations = [];

        items.forEach((visItem) => {

            if (visItem.organisationID in visibility) {
                visibility[visItem.organisationID].campuses.push(visItem.campusID);
                campuses[visItem.organisationID].push({value: visItem.campusID, label: visItem.campusName});
            }
            else {
                visibility[visItem.organisationID] = {
                    organisation: visItem.organisationID,
                    campuses: [visItem.campusID]
                }
                organisations.push({value: visItem.organisationID, label: visItem.organisationName});
                campuses[visItem.organisationID] = [{value: visItem.campusID, label: visItem.campusName}];
            }

        });

        let allValues = Object.values(visibility);

        return {
            visibility: allValues,
            campuses: campuses,
            organisations: organisations
        };
    }

    componentDidMount() {

        if (this.state.item.langID === null) {
            if (!this.state.item.id && this.context.permissions.challenges.allOrgs) {
                this.getOrganisationOptions();
            }
            if (!this.state.item.id) {
                this.getHabitUnitOptions();
                this.getChallengeTypes();
            }
            this.getVisibilityOptions();
            this.validate();

            if (this.state.item.id) {
                this.getTranslations();
            } else {
                this.getLanguages();
            }
        }
    }

    getTranslations = () => {

        if (this.state.isLoadingTranslations) { return; }

        this.setState({
            isLoadingTranslations: true
        }, () => {
            Axios
                .get(API.challenge.getTranslations, {
                    params: {
                        id: this.state.item.id
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        this.setState(prevState => ({
                            ...prevState,
                            item: {
                                ...prevState.item,
                                translations: res.data.data.translations
                            }
                        }), () => {
                            this.getLanguages();
                        })
                    }
                })
        })
    }

    getLanguages = () => {
        Axios
            .get(API.organisation.getLanguages)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        isLoadingTranslations: false,
                        languageOptions: res.data.data.languages.filter((item) => { return !this.state.item.translations.find((o) => o.langID.toString() === item.id.toString()) }).map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }


    getVisibilityOptions = () => {
        Axios
            .get(API.challenge.getVisibilityOptions)
            .then((res) => {
                if (res.data.success) {
                    const options = this.parseVisibilityOptions(res.data.data.options);

                    let newState = {};
                    newState.organisationOptions = options.organisationOptions;
                    newState.campusOptions = options.campusOptions;

                    this.setState(newState, () => {
                        this.validate();
                    });
                }
            })
    }

    parseVisibilityOptions = (res) => {
        let organisationOptions = [];
        let campusOptions = {};

        res.forEach(o => {
            organisationOptions.push({
                value: o.organisation.id,
                label: o.organisation.name
            });

            campusOptions[o.organisation.id] = o.campuses.map((r) => { return {
                value: r.id,
                label: r.name
            }});
        });

        return {
            organisationOptions: organisationOptions,
            campusOptions: campusOptions
        }
    }

    getCategoryOptions = () => {
        if (!this.state.fetchingCategoryOptions) {
            this.setState({
                fetchingCategoryOptions: true
            }, () => {
                Axios
                    .get(API.challenge.getCategoryOptions, {
                        params: {
                            unitID: this.state.item.unitID,
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            let categoryOptions = res.data.data.options.map((item) => { return {value: item.id, label: item.name}});

                            this.setState({
                                categoryOptions: categoryOptions,
                                fetchingCategoryOptions: false
                            })
                        }
                        else {
                            this.setState({
                                fetchingCategoryOptions: false
                            })
                        }
                    })
            })
        }
    }

    getHabitOptions = () => {
        if (!this.state.fetchingHabitOptions) {
            this.setState({
                fetchingHabitOptions: true
            }, () => {
                Axios
                    .get(API.challenge.getHabitOptions, {
                        params: {
                            unitID: this.state.item.unitID,
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            let habitOptions = res.data.data.options.map((item) => { return {value: item.id, label: item.name}});

                            this.setState({
                                habitOptions: habitOptions,
                                fetchingHabitOptions: false
                            })
                        }
                        else {
                            this.setState({
                                fetchingHabitOptions: false
                            })
                        }
                    })
            })
        }
    }

    getHabitGroupOptions = () => {
        if (!this.state.fetchingHabitGroupOptions) {
            this.setState({
                fetchingHabitGroupOptions: true
            }, () => {
                Axios
                    .get(API.challenge.getHabitGroupOptions, {
                        params: {
                            unitID: this.state.item.unitID,
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            let habitGroupOptions = res.data.data.options.map((item) => { return {value: item.id, label: item.title}});

                            this.setState({
                                habitGroupOptions: habitGroupOptions,
                                fetchingHabitGroupOptions: false
                            })
                        }
                        else {
                            this.setState({
                                fetchingHabitGroupOptions: false
                            })
                        }
                    })
            })
        }
    }

    getHabitUnitOptions = () => {
        Axios
            .get(API.habit.getHabitUnits)
            .then((res) => {
                if (res.data.success) {
                    let habitUnitOptions = res.data.data.units.map((item) => { return {value: item.id, label: item.systemName}});

                    this.setState({
                        habitUnitOptions: habitUnitOptions
                    })
                }
            })
    }

    getChallengeTypes = () => {
        Axios
            .get(API.challenge.getChallengeTypes)
            .then((res) => {
                if (res.data.success) {
                    let challengeTypeOptions = res.data.data.types.map((item) => { return {value: item.id, label: item.name}});

                    this.setState({
                        challengeTypeOptions: challengeTypeOptions
                    })
                }
            })
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations, {
                params: {
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    let organisationOptions = res.data.data.organisations.map((item) => { return {value: item.id, label: item.name}});

                    this.setState({
                        organisationOptions: organisationOptions
                    })
                }
            })
    }

    handleSubmit = () => {
        if (!this.state.isUploading) {

            this.setState({
                isUploading: true
            }, () => {

                if (this.state.item.langID === null) {

                    let formData = new FormData();

                    if (this.state.item.id) {
                        formData.append("id", this.state.item.id);
                    }

                    formData.append("title", this.state.item.title);
                    formData.append("startDate", this.state.item.startDate);
                    formData.append("endDate", this.state.item.endDate);
                    formData.append("unitID", this.state.item.unitID);
                    formData.append("typeID", this.state.item.typeID);

                    if (this.state.item.target) {
                        formData.append("target", this.state.item.target);
                    }

                    if (this.state.item.habitID) {
                        formData.append("habitID", this.state.item.habitID);
                    }

                    if (this.state.item.habitGroupID) {
                        formData.append("habitGroupID", this.state.item.habitGroupID);
                    }

                    if (this.state.item.categoryID) {
                        formData.append("categoryID", this.state.item.categoryID);
                    }


                    this.state.visibility.forEach(item => {
                        item.campuses.forEach(campusID => {
                            formData.append("campuses[]", campusID);
                        })
                    });

                    // Serialize description elements
                    let hasImage = false;

                    let elements = Array.from(this.state.elements);

                    elements.forEach((element, index, array) => {
                        if (element.type === "image" && element.imagePickerFile) {
                            hasImage = true;
                            formData.append(element.id, element.imagePickerFile);

                            elements[index] = {
                                type: element.type,
                                id: element.id,
                                data: {
                                    aspectRatio: element.data.aspectRatio,
                                    contentMode: element.data.contentMode
                                }
                            }
                        }
                    });

                    let itemData = {};
                    itemData.defaults = {};
                    itemData.defaults.style = Object.assign({}, this.state.defaults);
                    itemData.elements = elements;
                    const json = JSON.stringify(itemData);
                    formData.append("desc", json);

                    // Check if the main image is set
                    if (this.state.imagePickerFile) {
                        formData.append("mainImage", this.state.imagePickerFile);
                        hasImage = true;
                    }

                    Axios
                        .post((this.state.item.id ? API.challenge.updateChallenge : API.challenge.createChallenge), formData, {
                            headers: {
                                'Content-Type': hasImage ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
                            },
                            onUploadProgress: (progressEvent) => {
                                this.setState({
                                    uploadProgress: AxiosHelper.getUploadProgress(progressEvent)
                                });
                            }
                        })
                        .then((res) => {

                            if (res.data.success) {
                                NotificationManager.success("Saved successfully");
                            }
                            else if (res.data.error) {
                                NotificationManager.error(res.data.error.desc);
                            }

                            this.setState({
                                isUploading: false,
                                redirect: res.data.success === true
                            });
                        })

                }
                else {

                    let formData = new FormData();

                    formData.append("id", this.props.parentID);
                    formData.append("title", this.state.item.title);
                    formData.append("langID", this.state.item.langID);

                    // Serialize description elements
                    let hasImage = false;

                    let elements = Array.from(this.state.elements);

                    elements.forEach((element, index, array) => {
                        if (element.type === "image" && element.imagePickerFile) {
                            hasImage = true;
                            formData.append(element.id, element.imagePickerFile);

                            elements[index] = {
                                type: element.type,
                                id: element.id,
                                data: {
                                    aspectRatio: element.data.aspectRatio,
                                    contentMode: element.data.contentMode
                                }
                            }
                        }
                    });

                    let itemData = {};
                    itemData.defaults = {};
                    itemData.defaults.style = Object.assign({}, this.state.defaults);
                    itemData.elements = elements;
                    const json = JSON.stringify(itemData);
                    formData.append("desc", json);

                    Axios
                        .post(API.challenge.updateTranslation, formData, {
                            headers: {
                                'Content-Type': hasImage ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
                            },
                            onUploadProgress: (progressEvent) => {
                                this.setState({
                                    uploadProgress: AxiosHelper.getUploadProgress(progressEvent)
                                });
                            }
                        })
                        .then((res) => {

                            if (res.data.success) {
                                NotificationManager.success("Saved successfully");
                            }
                            else if (res.data.error) {
                                NotificationManager.error(res.data.error.desc);
                            }

                            this.setState({
                                isUploading: false
                            }, () => {
                                this.props.onCompleted(this.state.item);
                            });
                        })

                }

            })
        }
    }

    validate = () => {

        let validated = true;

        const item = this.state.item;
        const touched = this.state.touched;
        const errors = [];

        if (!item.title) {
            if (touched.title) {
                errors.name = "Challenge title is required";
            }
            validated = false;
        }

        if (this.state.elements === null || this.state.elements.length === 0) {
            validated = false;
        }

        if (!item.startDate) {
            if (touched.startDate) {
                errors.startDate = "Start date is required"
            }
            validated = false;
        }

        if (!item.endDate) {
            if (touched.endDate) {
                errors.endDate = "End date is required"
            }
            validated = false;
        }

        if (item.target && item.target.length === 0) {
            if (touched.target) {
                errors.target = "Target is required"
            }
            validated = false;
        }

        if (!item.unitID) {
            if (touched.unitID) {
                errors.unitID = "Unit type is required"
            }
            validated = false;
        }

        if (!item.typeID) {
            if (touched.typeID) {
                errors.typeID = "Challenge type is required"
            }
            validated = false;
        }

        if (item.typeID === ChallengeType.SINGLE_HABIT && !item.habitID) {
            if (touched.habitID) {
                errors.habitID = "Habit is required"
            }
            validated = false;
        }

        if (item.typeID === ChallengeType.HABIT_GROUP && !item.habitGroupID) {
            if (touched.habitGroupID) {
                errors.habitGroupID = "Habit group is required"
            }
            validated = false;
        }

        if (item.typeID === ChallengeType.WELLBEING_CATEGORY && !item.categoryID) {
            if (touched.categoryID) {
                errors.categoryID = "Category is required"
            }
            validated = false;
        }

        if (!this.state.visibility || this.state.visibility.length === 0) {
            if (touched.visibility) {
                errors.visibility = "At least one campus is required"
            }
            validated = false;
        }


        this.setState({
            validated: validated,
            errors: errors
        });
    }

    handleChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        if (name === "selectedLanguage") {
            let newState = {};
            newState[name] = value;

            this.setState(newState, () => {
                this.validate();
            })
        }
        else {
            if ((name === "startDate" || name === "endDate") && value) {
                value = DateHelper.convertFromLocalToUTCDateTime(value);
            }


            if (name === "organisationID") {
                let item = Object.assign({}, this.state.item);
                item.organisationID = value;
                item.habitID = null;
                item.habitGroupID = null;
                item.categoryID = null;
                this.setState({
                    item: item
                }, () => {
                    this.validate();
                })
            }
            else if (name === "hasTarget") {
                let target = value === 1 ? "" : null;
                let item = Object.assign({}, this.state.item);
                item.target = target;
                this.setState({
                    item: item
                }, () => {
                    this.validate();
                })
            }
            else if (name === "unitID") {
                let item = Object.assign({}, this.state.item);
                item.unitID = value;
                item.habitID = null;
                item.habitGroupID = null;
                item.categoryID = null;
                item.typeID = null;
                this.setState({
                    item: item
                }, () => {
                    this.getHabitOptions();
                    this.getHabitGroupOptions();
                    this.getCategoryOptions();
                })
            }
            else if (name === "typeID") {
                let item = Object.assign({}, this.state.item);
                item.typeID = value;
                item.habitID = null;
                item.habitGroupID = null;
                item.categoryID = null;
                this.setState({
                    item: item
                })
            }
            else {
                let item = Object.assign({}, this.state.item);
                item[name] = value;

                this.setState({
                    item: item
                }, () => {
                    this.validate();
                    console.log(this.state.item);
                })
            }
        }
    }

    handleVisibilityChange = (event, item) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let itemIndex = this.state.visibility.findIndex(i => i === item);

        if (itemIndex > -1) {

            let visibility = this.state.visibility;
            let newItem = Object.assign({}, item);

            if (name === "organisations") {

                newItem.organisation = value;
                newItem.campuses = [];
                visibility[itemIndex] = newItem;

                this.setState({
                    visibility: visibility
                }, () => {
                    this.validate();
                });
            }
            else if (name === "campuses") {

                newItem.campuses = value;
                visibility[itemIndex] = newItem;

                this.setState({
                    visibility: visibility
                }, () => {
                    this.validate();
                });

            }
        }
    }

    addVisibilityVariation = () => {

        let visibility = this.state.visibility;
        visibility.push({
            organisation: null,
            campuses: []
        });

        this.setState({
            visibility: visibility
        });
    }

    removeVisibilityVariation = (item) => {

        let visibility = this.state.visibility;

        const index = visibility.findIndex((i) => i === item);
        if (index > -1) {
            visibility.splice(index, 1);
        }

        this.setState({
            visibility: visibility
        }, () => {
            this.validate();
        });
    }

    handleFileDelete = (name) => {
        this.setState({
            imagePickerFile: null,
            imagePickerURL: null
        })
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        let self = this;

        reader.onloadend = () => {

            let newState = {
                imagePickerFile: file ? file : null,
                imagePickerURL: file ? reader.result : null
            }

            self.setState(newState, () => {
                self.validate()
            })
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    handleAddTranslation = (langID) => {

        const language = this.state.languageOptions.find((i) => i.value.toString() === langID.toString());

        let translation = {
            langID: language.value,
            langName: language.label,
            title: "",
            desc: "",
            id: UUID.uuidv4(),
            isNew: 1
        };

        if (!this.state.modal.isOpen) {
            const content = (
                <ChallengeEdit
                    parentID={this.state.item.id}
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.setState(prevState => ({
                            languageOptions: this.state.languageOptions.filter((i) => i.value.toString() !== language.value.toString()),
                            item: {
                                ...prevState.item,
                                translations: [...prevState.item.translations, updatedTranslation]
                            }
                        }), () => {
                            this.closeModal();
                        });
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState(prevState => ({
                selectedLanguage: null,
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-large",
                    isUploading: false,
                    content: content
                })
            }))
        }

    }

    updateTranslation = (updatedTranslation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.map((o) => {
                    if (o.langID.toString() === updatedTranslation.langID.toString()) {
                        return updatedTranslation;
                    }
                    else {
                        return o;
                    }
                })
            }
        }))
    }

    handleEditTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <ChallengeEdit
                    parentID={this.state.item.id}
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-large",
                    content: content
                })
            })
        }
    }

    handleDeleteTranslation = (translation) => {

        this.deleteTranslation(translation);

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.filter((i) => i.langID.toString() !== translation.langID.toString() )
            },
            languageOptions: [...this.state.languageOptions, {label: translation.langName, value: translation.langID}].sort((a,b) => a.name < b.name ? 1 : -1 )
        }), () => {
            this.closeModal();
        })

    }

    deleteTranslation = (translation) => {
        Axios
            .post(API.challenge.deleteTranslation, {
                id: this.state.item.id,
                langID: translation.langID
            })
            .then((res) => {

                if (res.data.success) {
                    NotificationManager.success("Deleted successfully");
                }
                else if (res.data.error) {
                    NotificationManager.error(res.data.error.desc);
                }
            })
    }

    showDeleteTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Translation"
                    content={(
                        <React.Fragment>
                            <p>Are you sure to delete <strong>{translation.langName}</strong> translation ? This action cannot be reverted.</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteTranslation(translation)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    isUploading: false,
                    content: content
                })
            })
        }
    }


    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    render() {

        if (this.state.redirect) return <Redirect to="/challenges" />;

        let content = (
            <React.Fragment>
                <div className="grid-x grid-padding-x">
                    <div className="cell medium-12 large-6" >
                        <fieldset className="fieldset edit-field">
                            <legend>Details</legend>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    {this.state.item.langID === null &&
                                    <div className="grid-x grid-padding-x">
                                        <div className={"medium-12 large-6 cell " + (this.state.errors.startDate && "error")}>
                                            <span>* Start Date</span>
                                            <DatePicker
                                                name="startDate"
                                                data-enable-time
                                                value={DateHelper.convertToLocalDateTime(this.state.item.startDate)}
                                                onChange={this.handleChange}
                                                options={{
                                                    dateFormat: "Y-m-d H:i:S",
                                                    altFormat: "l, F j, Y h:i K",
                                                    altInput: true,
                                                    static: true,
                                                    minuteIncrement: 1
                                                }}
                                                disabled={this.state.item.id !== undefined}
                                            />
                                            <span className="error">{this.state.errors.startDate}</span>
                                        </div>
                                        <div className={"medium-12 large-6 cell " + (this.state.errors.endDate && "error")}>
                                            <span>* End Date</span>
                                            <DatePicker
                                                name="endDate"
                                                data-enable-time
                                                value={DateHelper.convertToLocalDateTime(this.state.item.endDate)}
                                                onChange={this.handleChange}
                                                options={{
                                                    dateFormat: "Y-m-d H:i:S",
                                                    altFormat: "l, F j, Y h:i K",
                                                    altInput: true,
                                                    static: true,
                                                    minuteIncrement: 1
                                                }}
                                                disabled={this.state.item.id !== undefined}
                                            />
                                            <span className="error">{this.state.errors.endDate}</span>
                                        </div>
                                    </div>
                                    }
                                    <div className="grid-x grid-padding-x">
                                        <div className={"small-12 cell " + (this.state.errors.title && "error")}>
                                            <span>{this.state.item.langID === null ? "*" : ""} Title</span>
                                            <input name="title" type="text" value={this.state.item.title} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                            <span className="error">{this.state.errors.title}</span>
                                        </div>
                                    </div>
                                    {this.state.item.langID === null &&
                                    <React.Fragment>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-12 cell">
                                                <span>Main image</span>
                                                <FileInput
                                                    name="iconFile"
                                                    onChange={this.handleFilePickerChange}
                                                    onBlur={this.handleBlur}
                                                    onDelete={this.handleFileDelete}
                                                    placeholder="Not selected. Ideal size W:750px H:422px (jpg or png)"
                                                    accept="image/jpeg,image/jpg,image/png"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="iconFile" className="text-right middle">Current Image</label>
                                            </div>
                                            <div className={"large-9 small-9 cell " + (this.state.errors.imageFile && "error")} style={{marginBottom: 20}}>
                                                {!this.state.imagePickerURL && this.state.item.imageURL &&
                                                <Image src={this.state.item.imageURL} width={375} height={211} border={true} fit="cover" />
                                                }
                                                {!this.state.imagePickerURL && !this.state.item.imageURL &&
                                                <p style={{marginTop: 7}}>No image selected</p>
                                                }
                                                {this.state.imagePickerURL &&
                                                <Image src={this.state.imagePickerURL} width={375} height={211} border={true} fit="cover" />
                                                }
                                                <small className="error">{this.state.errors.imageFile}</small>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    }
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    {this.state.item.langID === null &&
                    <div className="cell medium-12 large-6" >
                        <fieldset className="fieldset edit-field">
                            <legend>Settings</legend>
                            <div className="grid-x grid-padding-x">
                                <div className="small-6 medium-3 cell">
                                    <span>Has target?</span>
                                    <label className="chkbx-container">
                                        <input
                                            className="chkbx"
                                            type="checkbox"
                                            name="hasTarget"
                                            onChange={this.handleChange}
                                            checked={this.state.item.target !== null}
                                            disabled={this.state.item.id !== undefined}
                                        />
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                                <div className="small-6 medium-9 cell">
                                    {this.state.item.target !== null &&
                                    <React.Fragment>
                                        <span>* Target</span>
                                        <input
                                            name="target"
                                            type="number"
                                            value={this.state.item.target}
                                            onBlur={this.handleBlur}
                                            onChange={this.handleChange}
                                            disabled={this.state.item.id !== undefined}
                                        />
                                        <span className="error">{this.state.errors.target}</span>
                                    </React.Fragment>
                                    }
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell ">
                                    <span>Unit type</span>
                                    <ReactSelect
                                        name="unitID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.item.unitID}
                                        options={this.state.habitUnitOptions}
                                        isDisabled={this.state.item.id !== undefined}
                                    />
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell ">
                                    <span>Challenge type</span>
                                    <ReactSelect
                                        name="typeID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.item.typeID}
                                        options={this.state.challengeTypeOptions}
                                        isDisabled={this.state.item.id !== undefined}
                                    />
                                </div>
                            </div>
                            {this.state.item.typeID === ChallengeType.SINGLE_HABIT &&
                            <BlockUi tag="div" blocking={this.state.fetchingHabitOptions} message="Loading" keepInView>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell ">
                                        <span>Habit</span>
                                        <ReactSelect
                                            name="habitID"
                                            className="react-select"
                                            onChange={this.handleChange}
                                            value={this.state.item.habitID}
                                            options={this.state.habitOptions}
                                            isDisabled={this.state.item.id !== undefined}
                                        />
                                    </div>
                                </div>
                            </BlockUi>
                            }
                            {this.state.item.typeID === ChallengeType.HABIT_GROUP &&
                            <BlockUi tag="div" blocking={this.state.fetchingHabitGroupOptions} message="Loading" keepInView>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell ">
                                        <span>Habit Group</span>
                                        <ReactSelect
                                            name="habitGroupID"
                                            className="react-select"
                                            onChange={this.handleChange}
                                            value={this.state.item.habitGroupID}
                                            options={this.state.habitGroupOptions}
                                            isDisabled={this.state.item.id !== undefined}
                                        />
                                    </div>
                                </div>
                            </BlockUi>
                            }
                            {this.state.item.typeID === ChallengeType.WELLBEING_CATEGORY &&
                            <BlockUi tag="div" blocking={this.state.fetchingCategoryOptions} message="Loading" keepInView>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell ">
                                        <span>Category</span>
                                        <ReactSelect
                                            name="categoryID"
                                            className="react-select"
                                            onChange={this.handleChange}
                                            value={this.state.item.categoryID}
                                            options={this.state.categoryOptions}
                                            isDisabled={this.state.item.id !== undefined}
                                        />
                                    </div>
                                </div>
                            </BlockUi>
                            }
                        </fieldset>
                    </div>
                    }
                </div>
                {this.state.item.langID === null &&
                    <React.Fragment>
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset edit-field">
                                    <legend>Visibility</legend>
                                    {!this.state.item.id && this.context.permissions.challenges.allOrgs &&
                                    <div className="grid-x grid-padding-x">
                                        <div className="cell small-12">
                                            <Button size={Sizes.SMALL} onClick={this.addVisibilityVariation} disabled={this.state.visibility.length >= this.state.organisationOptions.length}>
                                                Add&nbsp;
                                                <FontAwesomeIcon icon="plus"/>
                                            </Button>
                                        </div>
                                    </div>
                                    }
                                    {this.state.visibility.map(item =>
                                        <div className="grid-x grid-padding-x"  >
                                            <div className="cell small-12 medium-6 large-3">
                                                <div className="input-button-group">
                                                    {!this.state.item.id && this.context.permissions.challenges.allOrgs &&
                                                    <button className="button clear alert push-left" onClick={() => this.removeVisibilityVariation(item)}>
                                                        <FontAwesomeIcon icon="times-circle"/>
                                                    </button>
                                                    }
                                                    <div className="input-element">
                                                        <span>Organisation</span>
                                                        <ReactSelect
                                                            style={{width: "100%"}}
                                                            name="organisations"
                                                            onChange={(e) => this.handleVisibilityChange(e, item)}
                                                            value={item.organisation}
                                                            options={this.state.organisationOptions}
                                                            filterOption={(option) => !this.state.visibility.find(v => v.organisation && v.organisation.toString() === option.value.toString())}
                                                            placeholder="None"
                                                            className="react-select"
                                                            isDisabled={this.state.item.id !== undefined}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cell small-12 medium-6 large-9">
                                                <span>Campuses</span>
                                                <ReactSelect
                                                    name="campuses"
                                                    onChange={(e) => this.handleVisibilityChange(e, item)}
                                                    value={item.campuses}
                                                    options={item.organisation ? this.state.campusOptions[item.organisation] : []}
                                                    isMulti
                                                    className="react-select"
                                                    isDisabled={this.state.item.id !== undefined || !item.organisation}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </fieldset>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset edit-field">
                                    <legend>Translations</legend>
                                    {this.state.item.id ?
                                        <React.Fragment>
                                            <div className="grid-x grid-padding-x">
                                                <div className="cell small-12">
                                                    <span>Add translation for</span>
                                                    <ReactSelect
                                                        name="selectedLanguage"
                                                        className="react-select translation-selector"
                                                        onChange={this.handleChange}
                                                        value={this.state.selectedLanguage}
                                                        options={this.state.languageOptions}
                                                        onBlur={this.handleBlur}
                                                    />
                                                    <Button type="button" className="translation-button" color={Colors.PRIMARY} isDisabled={!this.state.selectedLanguage} onClick={() => this.handleAddTranslation(this.state.selectedLanguage)}>Add</Button>
                                                </div>
                                            </div>
                                            <div className="grid-x grid-padding-x">
                                                <div className="cell small-12">
                                                    <ReactTable
                                                        columns={[
                                                            {
                                                                Header: 'Language',
                                                                accessor: 'langName'
                                                            },
                                                            {
                                                                Header: 'Actions',
                                                                Cell: row => (
                                                                    <ButtonGroup size={Sizes.TINY}>
                                                                        {this.context.permissions.challenges.edit &&
                                                                        <Button type="button" color={Colors.PRIMARY} onClick={() => this.handleEditTranslation(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                                                                        }
                                                                        {this.context.permissions.challenges.delete &&
                                                                        <Button type="button" color={Colors.ALERT} onClick={() => this.showDeleteTranslation(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                                                                        }
                                                                    </ButtonGroup>
                                                                ),
                                                                maxWidth: 100,
                                                                show: this.context.permissions.challenges.delete || this.context.permissions.challenges.edit
                                                            }
                                                        ]}
                                                        data={this.state.item.translations}
                                                        className='-striped'
                                                        showPagination={false}
                                                        noDataText={"No translations"}
                                                        pageSize={this.state.item.translations ? this.state.item.translations.length : 1}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <p>Translations for Challenges can only be added once the Challenge is created. Please submit the Challenge first and then use editing functionality to add additional language variations.</p>
                                        </React.Fragment>
                                    }
                                </fieldset>
                            </div>
                        </div>
                    </React.Fragment>
                }
                <div className="grid-x grid-padding-x">
                    <div className="cell small-12" style={{margin: "20px 0"}}>
                        <fieldset className="fieldset edit-field">
                            <legend>Description</legend>
                            <ContentEditor
                                organisationID={this.state.item.organisationID}
                                content={this.state.item.desc}
                                onChange={(s) => {
                                    this.setState({
                                        elements: s.elements,
                                        defaults: s.defaults
                                    }, () => {
                                        this.validate();
                                    })
                                }}
                            />
                        </fieldset>
                    </div>
                </div>
                {this.state.item.langID === null &&
                <div className="fix-save">
                    <ButtonGroup>
                        <Button className="edit-button" onClick={() => this.setState({redirect: true})}>Cancel</Button>
                        <Button className="edit-button" color={Colors.SUCCESS} disabled={!this.state.validated} onClick={this.handleSubmit} >Save</Button>
                    </ButtonGroup>
                </div>
                }
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <BlockUi tag="div" blocking={this.state.isUploading || this.state.isFetching} message="Please wait" keepInView>
                    {this.state.item.langID === null ?
                        <PageContent pathElements={pathElements} >
                            {content}
                        </PageContent>
                        :
                        <React.Fragment>
                            <div className="modal-head">
                                <h3>Challenge ({this.state.item.langName} translation)</h3>
                            </div>
                            <div className="modal-body">
                                {content}
                            </div>
                            <div className="modal-footer">
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell">
                                        <div className="btn-wrap">
                                            <ButtonGroup>
                                                <Button className="edit-button" onClick={this.props.onCancel}>Cancel</Button>
                                                <Button className="edit-button" color={Colors.SUCCESS} onClick={this.handleSubmit} >Save</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </BlockUi>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="TranslationsModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        )
    }


}