import React from 'react'
import Axios from "axios";
import {NotificationManager} from "react-notifications";
import ReactTable from "react-table";
import Utils from 'utils/Utils'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import moment from "moment";

export default class PodcastTotalsTable extends AppComponent {


    constructor(props, context) {
        super(props);

        this.state = {
            data: [],
            selectedPodcast: null,
            isLoadingData: false
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startDate !== this.props.startDate ||
            prevProps.endDate !== this.props.endDate ||
            prevProps.filters !== this.props.filters) {
            this.fetchData();
        }
    }

    exportPDF = () => {

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = this.props.title;
        const headers = [["DATE", "UNITS"]];

        const data = this.state.data.tableData.map(i=> [i.label, i.value]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Report.pdf")
    }

    fetchData = () => {

        if (!this.state.isLoadingData) {

            this.setState({
                isLoadingData: true
            }, () => {

                Axios
                    .get(API.report.getPodcastTotals, {
                        params: {
                            filters: this.props.filters,
                            startDate: this.props.startDate.format("YYYY-MM-DD"),
                            endDate: this.props.endDate.format("YYYY-MM-DD"),
                            tz: moment().local().format("Z")
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            let data = res.data.data;

                            this.setState({
                                isLoadingData: false,
                                data: data.podcasts
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingData: false
                            })
                        }
                    });

            })

        }

    }

    handlePodcastClick = (podcast) => {
        let value = this.state.selectedPodcast ? (this.state.selectedPodcast.id === podcast.id ? null : podcast) : podcast;
        this.setState({
            selectedPodcast: value
        }, () => {
            if (typeof this.props.onPodcastSelected === 'function') {
                this.props.onPodcastSelected(value)
            }
        })
    }

    render() {

        const columns = [
            {
                Header: "Podcast",
                columns: [
                    {
                        Header: 'ID',
                        accessor: 'id',
                        width: 50
                    },
                    {
                        Header: 'Title',
                        accessor: 'title',
                        minWidth: 150
                    }
                ]
            },
            {
                Header: "Selected Period",
                columns: [
                    {
                        Header: 'Views',
                        accessor: 'periodViews',
                        width: 80,
                        className: "text-center highlighted-cell"
                    },
                    {
                        Header: 'Rating',
                        accessor: 'periodRating',
                        className: "text-center highlighted-cell",
                        Cell: row => {
                            if (row.value) {
                                return parseFloat(row.value).toFixed(1) + " (" + row.original.periodRatingCount + ")"
                            }
                            else {
                                return "No ratings"
                            }
                        }
                    }
                ]
            },
            {
                Header: "Today",
                columns: [
                    {
                        Header: 'Views',
                        accessor: 'todayViews',
                        width: 80,
                        className: "text-center"
                    },
                    {
                        Header: 'Rating',
                        accessor: 'todayRating',
                        className: "text-center",
                        Cell: row => {
                            if (row.value) {
                                return parseFloat(row.value).toFixed(1) + " (" + row.original.todayRatingCount + ")"
                            }
                            else {
                                return "No ratings"
                            }
                        }
                    }
                ]
            },
            {
                Header: "Total",
                columns: [
                    {
                        Header: 'Views',
                        accessor: 'views',
                        width: 80,
                        className: "text-center"
                    },
                    {
                        Header: 'Rating',
                        accessor: 'rating',
                        className: "text-center",
                        Cell: row => {
                            if (row.value) {
                                return parseFloat(row.value).toFixed(1) + " (" + row.original.ratingCount + ")"
                            }
                            else {
                                return "No ratings"
                            }
                        }
                    }
                ]
            },
            {
                Header: "Overall (All organisations)",
                show: this.context.permissions.reports.allOrgs,
                columns: [
                    {
                        Header: 'Views',
                        accessor: 'overallViews',
                        width: 80,
                        className: "text-center"
                    },
                    {
                        Header: 'Rating',
                        accessor: 'overallRating',
                        className: "text-center",
                        Cell: row => {
                            if (row.value) {
                                return parseFloat(row.value).toFixed(1) + " (" + row.original.overallRatingCount + ")"
                            }
                            else {
                                return "No ratings"
                            }
                        }
                    }
                ]
            }
        ];

        const selectedID = this.state.selectedPodcast ? this.state.selectedPodcast.id : null;

        return (
            <React.Fragment>
                <div className="grid-x grid-padding-x">
                    <div className="small-12 cell">
                        <ReactTable
                            columns={columns}
                            data={this.state.data}
                            defaultPageSize={10}
                            loading={this.state.isLoadingData}
                            multisort={true}
                            className='-striped'
                            getTrProps={(table, row) => ({
                                style: {cursor: "pointer"},
                                className: selectedID === Utils.getNested(row, "original", "id") ? "selectedRow" : null,
                                onClick: () => {
                                    this.handlePodcastClick(row.original);
                                }
                            })}
                        />
                    </div>
                </div>
            </React.Fragment>

        )
    }
}