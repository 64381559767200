import React from 'react'
import {PromptTemplateTable} from "./PromptTemplateTable";

export const PromptTemplateSelector = (props) => {

    const {onSelected} = props;
    const {onCancel} = props;

    const {showCopyButton} = props;
    const {showSelectButton} = props;
    const {showDeleteButton} = props;

    function handleSelected(item) {
        if (onSelected instanceof Function) {
            onSelected(item);
        }
    }

    // RENDER
    return (
        <React.Fragment>
            <div className="modal-head">
                <h3>Prompt Templates</h3>
            </div>
            <div className="modal-body">
                <PromptTemplateTable
                    showCopyButton={showCopyButton}
                    showSelectButton={showSelectButton}
                    showDeleteButton={showDeleteButton}
                    onSelected={(item) => handleSelected(item)}
                />
            </div>
            <div className="modal-footer">
                <div className="grid-x grid-padding-x">
                    <div className="small-12 cell">
                        <div className="btn-wrap">
                            <button className="button secondary" type="button" onClick={onCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}