import React from 'react'
import { ChatItemType } from 'constants/ChatItemType'
import Modal from "react-responsive-modal";
import ServicesSelector from "../services/ServicesSelector";
import Utils from 'utils/Utils'
import ChatFlowsSelector from 'components/chatflows/ChatFlowsSelector'
import UUID from "../../utils/UUID";

export default class ChatServiceForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: Object.assign({
                chatFlow: null,
                nodeText: null,
                typeText: "Linked Chat Flow",
                typeID: ChatItemType.CHATFLOW,
                uuid: UUID.uuidv4()
            }, props.node),
            validated: false,
            errors: {},
            touched: {},
            modal: {
                isOpen: false,
                content: null
            }
        };
    }

    componentDidMount() {
        this.validate();
    }


    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        let item = Object.assign({}, this.state.item);
        item[name] = value;

        this.setState({
            item: item
        }, () => {
            this.validate();
        });
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.onSubmitted instanceof Function) {
            this.props.onSubmitted(this.state.item)
        }
    }


    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.chatFlow) {
            validated = false;
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    showChatFlowModal = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.item.service) {
            selected.push({id: this.state.item.service.id})
        }

        const content = (
            <ChatFlowsSelector
                isSingleSelection
                selected={selected}
                onSubmitted={(chatFlow) => {

                    let item = Object.assign({}, this.state.item);
                    item.chatFlow = {
                        id: chatFlow.id,
                        title: chatFlow.title
                    };
                    item.nodeText = chatFlow.title;

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Link Chat Flow</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="chatFlow" className="text-right middle">* Chat Flow</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.serviceID && "error")}>
                                <div className="input-group">
                                    <input className="input-group-field" name="chatFlow" type="text" value={Utils.value(Utils.getNested(this.state.item, "chatFlow", "title"), "Not Selected")} disabled/>
                                    <div className="input-group-button">
                                        <button type="button" className="button" onClick={() => this.showChatFlowModal(false)}>Browse</button>
                                    </div>
                                </div>
                                <small className="error">{this.state.errors.service}</small>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}
