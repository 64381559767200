import React, {useEffect, useState} from 'react';
import {CommonUtil} from "../../utils/CommonUtil";

export const ChatMessageFormTranslations = (props) => {

    const {languageCode} = props;
    const {item} = props;
    const {onSubmit} = props;
    const {onCancel} = props;

    const [translations, setTranslations] = useState({});

    useEffect(() => {
        if (item) {
            if (item.translations) {
                setTranslations(item.translations[languageCode]);
            }
        }
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        if (!item.translations) {
            item.translations = {};
        }

        item.translations[languageCode] = translations;

        console.log("TRANSLATIONS: ", item.translations);

        if (onSubmit) {
            onSubmit();
        }
    }

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.value;

        const newTranslations = CommonUtil.cloneObject(translations);

        if (!newTranslations.hasOwnProperty("texts")) {
            newTranslations.texts = [];
        } else if (!Array.isArray(newTranslations.texts)) {
            newTranslations.texts = [];
        }

        let existingIndex = -1;
        for (let i = 0; i < newTranslations.texts.length; i++) {
            if (newTranslations.texts[i].id === name) {
                existingIndex = i;
                break;
            }
        }

        if (value.trim() !== "") {
            if (existingIndex >= 0) {
                newTranslations.texts[existingIndex].text = value;
            } else {
                newTranslations.texts.push({
                    id : name,
                    text : value
                });
            }
        } else {
            newTranslations.texts.splice(existingIndex, 1);
        }
        setTranslations(newTranslations);
    }

    function getTranslation(id) {
        if (translations) {
            if (translations.hasOwnProperty("texts")) {
                for (let i = 0; i < translations.texts.length; i++) {
                    if (translations.texts[i].id === id) {
                        return translations.texts[i].text;
                    }
                }
            }
        }
        return "";
    }

    // RENDER

    const inputFields = [];
    if (item && item.texts) {
        item.texts.forEach((tItem, index) => {
            let label = "Text";
            if (index > 0) {
                label = "Alternate text"
            }

            inputFields.push(
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="aiReturnResponse" className="text-right middle">
                            {label}
                        </label>
                    </div>
                    <div className="small-9 cell">
                        <textarea
                            rows={5}
                            name={tItem.id}
                            value={getTranslation(tItem.id)}
                            placeholder={tItem.text}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            );
        })
    }

    return (
        <>
            <div className="modal-head">
                <h3>Translations</h3>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="modal-body translation-editor">
                    <React.Fragment>
                        {inputFields}
                    </React.Fragment>
                </div>

                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                <button
                                    className="button secondary"
                                    type="button"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </button>

                                <button
                                    className="button success"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}