import React from 'react'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'

class StandardModal extends AppComponent {

    static defaultProps = {
        title: "",
        content: null,
        type: "",
        buttons: null,
        error: null,
        isUploading: false
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.props.isUploading} />
                <div className="modal-head">
                    <h3>{this.props.title}</h3>
                </div>
                <div className="modal-body">
                    {this.props.content}
                    <div className="form-error-msg grid-x grid-padding-x" style={{display: this.props.error ? "block" : "none"}}>
                        <div className="cell small-12">
                            <span>{this.props.error}</span>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                {this.props.buttons}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default StandardModal;
