import React from 'react';
import ReactTable from 'react-table'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchBar from 'components/common/SearchBar'
import DatatableHelper from 'utils/DatatableHelper'
import Modal from 'react-responsive-modal'
import PostForm from 'components/posts/PostForm'
import { Colors, Sizes, ButtonGroup, Button} from 'react-foundation'
import { API } from 'api/APIClient'
import TableContainer from 'components/common/TableContainer'
import AppComponent from 'components/common/AppComponent'
import ReactSelect from 'components/common/ReactSelect'
import { NotificationManager } from "react-notifications"
import StandardModal from 'components/common/StandardModal'
import DateHelper from 'utils/DateHelper'

class PostsTable extends AppComponent {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            searchKey: "",
            organisations: [],
            organisationOptions: [],
            page: 0,
            pageSize: 10,
            sorted: [{id: "id", "desc": true}],
            totalPages: -1,
            isLoading: true,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            },
            filtersAreOpen: false
        };

        this.searchTimer = null;
    }


    componentDidMount() {
        if (this.context.permissions.post.allOrgs) {
            this.getOrganisationOptions();
        }
    }


    searchTextDidChange(newValue) {
        this.setState({
            searchKey: newValue || ""
        }, () => {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.fetchItems();
            }, 1000);
        });
    }


    handleChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "organisations") {

            if (value.length === this.state.organisationOptions.length) {
                value = [];
            }

            this.setState({
                organisations: value
            }, () => {
                this.fetchItems();
            });
        }
    }


    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }


    fetchItems = () => {
        this.setState({
            loading: true
        });

        Axios
            .post(API.post.getPostsDT, {
                    page: this.state.page,
                    pageSize: this.state.pageSize,
                    sorted: this.state.sorted,
                    filters: {
                        search: this.state.searchKey,
                        organisations: this.state.organisations
                    }
                }
            )
            .then((res) => {
                this.setState({
                    items: res.data.data.rows,
                    totalPages: DatatableHelper.getTotalPages(res.data.data),
                    isLoading: false
                })
            })
    }

    handleAddNewItem = () => {
        this.showItemModal({}, false);
    }


    handleEditItem = (item, isDuplicate) => {
        this.showItemModal(item, isDuplicate);
    }

    showItemModal = (item, isDuplicate) => {
        if (!this.state.modal.isOpen) {
            const content = (
                <PostForm
                    item={item}
                    isDuplicate={isDuplicate}
                    onSubmitted={() => this.closeModal(this.fetchItems)}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null,
                    isUploading: false
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    showDeleteModal = (item) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Post"
                    content={(
                        <div>Are you sure to delete <strong>{item.title}</strong> post ? This action cannot be reverted.</div>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteItem(item)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                    isUploading={this.state.modal.isUploading}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }

    handleDeleteItem = (item) => {

        if (!this.state.modal.isUploading) {
            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isUploading: true
                })
            }, () => {

                Axios
                    .post(API.post.deletePost, {
                        id: item.id
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.closeModal(() => {
                                this.fetchItems();
                                NotificationManager.success("Post deleted");
                            });
                        }
                        else {
                            this.closeModal(() => {
                                NotificationManager.error(res.data.error.desc);
                            });
                        }
                    })
                    .catch(error => {
                        this.closeModal(() => {
                            NotificationManager.error(error.response);
                        });
                    });
            })
        }
    }

    render() {

        const columns = [
            {
                Header: 'ID',
                accessor: 'id',
                maxWidth: 60
            },
            {
                Header: "Title",
                accessor: "title",
                width: 200
            },
            {
                Header: 'Content',
                accessor: 'desc'
            },
            {
                Header: 'Organisation',
                accessor: 'organisationName',
                maxWidth: 160
            },
            {
                Header: 'Publish date',
                accessor: 'publishedAt',
                maxWidth: 180,
                Cell: row => {
                    let date = row.original.publishedAt;
                    return date.length > 0 ? DateHelper.convertToLocalDateTime(date) : date;
                }
            },
            {
                Header: "Status",
                accessor: 'active',
                maxWidth: 100,
                Cell: row => (
                    row.original.active ? 'published' : (row.original.scheduledAt ? "scheduled" : 'draft')
                )
            },
            {
                Header: "Pinned",
                accessor: 'sponsored',
                maxWidth: 100,
                Cell: row => (
                    row.original.sponsored ? 'Yes' : 'No'
                ),
                className: "text-center"
            },
            {
                Header: "CTA",
                accessor: 'cta',
                maxWidth: 100,
                Cell: row => (
                    row.original.cta === 1 ? 'Yes' : 'No'
                ),
                className: "text-center"
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                maxWidth: 180,
                Cell: row => {
                    return DateHelper.convertToLocalDateTime(row.value);
                }
            },
            {
                Header: 'Actions',
                Cell: row => (
                    <ButtonGroup size={Sizes.TINY}>
                        {this.context.permissions.post.allOrgs &&
                        <Button color={Colors.PRIMARY} onClick={() => this.handleEditItem(row.original, true)}><FontAwesomeIcon icon="clone" /></Button>
                        }
                        <Button color={Colors.PRIMARY} onClick={() => this.handleEditItem(row.original, false)}><FontAwesomeIcon icon="edit" /></Button>
                        <Button color={Colors.ALERT} onClick={() => this.showDeleteModal(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                    </ButtonGroup>
                ),
                maxWidth: 120,
                sortable: false,
                className: "center",
                show: this.context.permissions.post.edit || this.context.permissions.post.delete
            }
        ]

        const table = (
            <ReactTable
                columns={columns}
                data={this.state.items}
                pages={this.state.totalPages}
                pageSize={this.state.pageSize}
                loading={this.state.isLoading}
                multisort={true}
                manual
                className='-striped'
                onFetchData={(state) => {
                    this.setState({
                        page: state.page,
                        pageSize: state.pageSize,
                        sorted: state.sorted
                    }, () => {
                        this.fetchItems()
                    })
                }}
            />
        );

        const filters = (
            <React.Fragment>
                <div className="cell small-12 medium-6 large-3">
                    <span>Search</span>
                    <SearchBar
                        onDidChange={(newValue) => this.searchTextDidChange(newValue)}
                    />
                </div>
                {this.context.permissions.post.allOrgs &&
                <div className="cell small-12 medium-6 large-3">
                    <span>Organisations</span>
                    <ReactSelect
                        name="organisations"
                        onChange={this.handleChange}
                        value={this.state.organisations}
                        options={this.state.organisationOptions}
                        isMulti={true}
                        placeholder="All"
                        className="react-select"
                    />
                </div>
                }
            </React.Fragment>
        )

        const buttons = (
            <React.Fragment>
                {this.context.permissions.post.create &&
                <button type="button" className="button small" onClick={this.handleAddNewItem}>Add New Post</button>
                }
            </React.Fragment>
        )

        return (
            <React.Fragment>
                <TableContainer
                    title="Posts table"
                    buttons={buttons}
                    filters={filters}
                    table={table}
                />
                <Modal
                    classNames={{modal:"modal-medium"}}
                    open={this.state.modal.isOpen}
                    modalId="postsModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}

export default PostsTable;