import React from 'react'
import PageContent from 'components/common/PageContent'
import AppComponent from 'components/common/AppComponent'
import WellbeingPlan from 'components/onboarding/WellbeingPlan'

const pathElements = [
    {id: 0, title: "Home", url: "/"},
    {id: 1, title: "Organisation", url: "/organisation"},
    {id: 2, title: "Onboarding Setup"}
];

export default class Onboarding extends AppComponent {

    render() {
        return (
            <PageContent pathElements={pathElements} >
                <WellbeingPlan/>
            </PageContent>
        );
    }
}