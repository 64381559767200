import React from 'react'
import ReactTable from 'react-table'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchBar from 'components/common/SearchBar'
import DatatableHelper from 'utils/DatatableHelper'
import { NotificationManager } from 'react-notifications'
import Modal from 'react-responsive-modal'
import TableContainer from 'components/common/TableContainer'
import { Colors, Sizes, ButtonGroup, Button} from 'react-foundation'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ReactSelect from 'components/common/ReactSelect'
import DepartmentForm from 'components/organisation/departments/DepartmentForm'
import StandardModal from 'components/common/StandardModal'
import DateHelper from 'utils/DateHelper'


export default class DepartmentsTable extends AppComponent {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            searchKey: "",
            organisations: [],
            organisationOptions: [],
            page: 0,
            pageSize: 10,
            sorted: [{id: "id", "desc": true}],
            totalPages: -1,
            isLoading: true,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            },
            filtersAreOpen: false
        };

        this.searchTimer = null;
    }

    componentDidMount() {
        if (this.context.permissions.department.allOrgs) {
            this.getOrganisationOptions();
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "organisations") {

            if (value.length === this.state.organisationOptions.length) {
                value = [];
            }

            this.setState({
                organisations: value
            }, () => {
                this.fetchItems();
            });
        }
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations, {
                props: {
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }


    searchTextDidChange(newValue) {
        this.setState({
            searchKey: newValue || ""
        }, () => {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.fetchItems();
            }, 1000);
        });
    }


    fetchItems = () => {
        this.setState({
            loading: true
        });

        Axios
            .post(API.department.getDepartmentsDT, {
                    page: this.state.page,
                    pageSize: this.state.pageSize,
                    sorted: this.state.sorted,
                    filters: {
                        search: this.state.searchKey,
                        organisations: this.state.organisations
                    }
                }
            )
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        items: res.data.data.rows,
                        totalPages: DatatableHelper.getTotalPages(res.data.data),
                        isLoading: false
                    })
                }
            })
    }

    handleAddNewItem = () => {
        this.showItemModal({});
    }


    handleEditItem = (item) => {
        this.showItemModal(item);
    }

    showItemModal = (item) => {
        if (!this.state.modal.isOpen) {
            const content = (
                <DepartmentForm
                    item={item}
                    onSubmitted={() => this.closeModal(this.fetchItems)}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null,
                    isUploading: false
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    showDeleteModal = (item) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Department"
                    content={(
                        <React.Fragment>
                            <p>Are you sure to delete <strong>{item.name}</strong> department ? This action cannot be reverted.</p>
                            <p>Please note that department can only be deleted if there are no app users registered to that department.</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteItem(item)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                    error={this.state.modal.error}
                    isUploading={this.state.modal.isUploading}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }

    handleDeleteItem = (item) => {

        if (!this.state.modal.isUploading) {
            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isUploading: true
                })
            }, () => {

                Axios
                    .post(API.department.deleteDepartment, {
                        id: item.id
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.closeModal(() => {
                                this.fetchItems();
                                NotificationManager.success("Department deleted");
                            });
                        }
                        else {
                            this.closeModal(() => {
                                NotificationManager.error(res.data.error.desc);
                            });
                        }
                    })
                    .catch(error => {
                        this.closeModal(() => {
                            NotificationManager.error(error.response);
                        });
                    });
            })
        }
    }

    render() {

        const columns = [
            {
                Header: 'ID',
                accessor: 'id',
                width: 60
            },
            {
                Header: 'Name',
                accessor: 'name',
                minWidth: 200
            },
            {
                Header: 'Organisation',
                accessor: 'organisationName',
                minWidth: 120,
                show: this.context.permissions.department.allOrgs
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                width: 180,
                Cell: row => {
                    return DateHelper.convertToLocalDateTime(row.value);
                }
            },
            {
                Header: "Status",
                accessor: 'active',
                width: 120,
                Cell: row => (
                    row.original.active ?
                        <span className="label success">active</span> :
                        <span className="label alert">hidden</span>
                )
            },
            {
                Header: 'Actions',
                Cell: row => (
                    <ButtonGroup size={Sizes.TINY}>
                        {this.context.permissions.department.edit &&
                        <Button color={Colors.PRIMARY} onClick={() => this.handleEditItem(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                        }
                        {this.context.permissions.department.delete &&
                        <Button color={Colors.ALERT} onClick={() => this.showDeleteModal(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                        }
                    </ButtonGroup>
                ),
                maxWidth: 100,
                sortable: false,
                show: this.context.permissions.department.delete || this.context.permissions.department.edit
            }
        ];

        const table = (
            <ReactTable
                columns={columns}
                data={this.state.items}
                pages={this.state.totalPages}
                pageSize={this.state.pageSize}
                loading={this.state.isLoading}
                multisort={true}
                manual
                className='-striped'
                onFetchData={(state) => {
                    this.setState({
                        page: state.page,
                        pageSize: state.pageSize,
                        sorted: state.sorted
                    }, () => {
                        this.fetchItems()
                    })
                }}
            />
        );

        const filters = (
            <React.Fragment>
                <div className="cell small-12 medium-6 large-3">
                    <span>Search</span>
                    <SearchBar
                        onDidChange={(newValue) => this.searchTextDidChange(newValue)}
                    />
                </div>
                {this.context.permissions.department.allOrgs &&
                <div className="cell small-12 medium-6 large-3">
                    <span>Organisations</span>
                    <ReactSelect
                        name="organisations"
                        onChange={this.handleChange}
                        value={this.state.organisations}
                        options={this.state.organisationOptions}
                        isMulti={true}
                        placeholder="All"
                        className="react-select"
                    />
                </div>
                }
            </React.Fragment>
        )

        const buttons = (
            <React.Fragment>
                {this.context.permissions.department.create &&
                <button type="button" className="button small" onClick={this.handleAddNewItem}>Add New Department</button>
                }
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <TableContainer
                    title="Departments table"
                    filters={filters}
                    table={table}
                    buttons={buttons}
                />
                <Modal
                    classNames={{modal:"modal-medium"}}
                    open={this.state.modal.isOpen}
                    modalId="Modal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}