import React from 'react'
import { NavLink } from "react-router-dom"
import AppComponent from 'components/common/AppComponent'

class NavBar extends AppComponent {

    constructor(props) {
        super(props);

        this.state = {
            menuClassName: "dropdown menu show"
        }
    }

    handleMenuClick = (e) => {

        if (e.target.classList.contains("active")) {
            e.preventDefault();
            return;
        }

        this.setState({
            menuClassName: "dropdown menu"
        }, () => {
            setTimeout(function() {
                this.setState({menuClassName: "dropdown menu show"})
            }.bind(this), 500)
        });
    }

    handleLogOut = (event) => {
        event.preventDefault();
        this.context.logout();
    }

    render() {
        return (
            
                <ul className={this.state.menuClassName}>
                    <li>
                        {this.props.sections
                            .filter((section) => section.navBar)
                            .map((section) => {
                                if (section.id != 10) {
                                    return (
                                        <NavLink exact={section.id === 1} key={section.id} to={section.link}
                                                 className={section.iconClass} activeClassName="active"
                                                 onClick={this.handleMenuClick}>
                                            {section.title}
                                        </NavLink>
                                    );
                                }
                                return [];
                            })}
                        <ul className="menu dropmen">
                            {this.props.sections
                                .filter((section) => section.navBar)
                                .map((section) => {
                                    if (section.id != 10) {
                                        return (
                                            <li key={section.id}>
                                                <NavLink exact={section.id === 1} to={section.link} className={section.iconClass} activeClassName="active" onClick={this.handleMenuClick}>
                                                    {section.title}
                                                </NavLink>
                                            </li>
                                        );
                                    }
                                    return [];
                            })}
                            <li>
                                <a href="#" onClick={this.handleLogOut}>
                                    Log Out
                                </a>
                            </li>
                        </ul>
                    </li>
                    <span>University Admin Portal</span>
                    
                </ul>
               
        );
    }
}

export default NavBar;