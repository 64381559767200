import React from 'react'
import AppComponent from 'components/common/AppComponent'
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation"
import ReactTable from "react-table";
import ChatImportMappings from 'components/chatflows/ChatImportMappings'
import Modal from "react-responsive-modal";

export default class ChatImportRelationships extends AppComponent {

    constructor(props, context) {
        super(props);

        this.state = {
            chatFlow: Object.assign({}, props.chatFlow),
            modal: {
                isOpen: false,
                content: null,
                size: "modal-medium"
            }
        };
    }

    componentDidMount() {

    }


    getTRPropsType(state, rowInfo, column) {

        if (!rowInfo) { return {}; }

        const { original } = rowInfo;
        const { rel } = original;

        let errorsCount = 0;
        let fixedCount = 0;
        rel.forEach((o) => { if (o.status === "error") {
            errorsCount++;
            if (o.overwritten === true) { fixedCount++; }
        } });

        if (errorsCount > 0) {
            return {
                style: {
                    background: fixedCount !== errorsCount ? "#f59790" : "#92d689"
                }
            };
        }

        return {};
    }


    showItemModal = (row) => {
        if (!this.state.modal.isOpen) {
            const content = (
                <ChatImportMappings
                    mappings={this.state.chatFlow.relationships[row.index].rel}
                    onSubmitted={(rel) => {
                        let chatFlow = Object.assign({}, this.state.chatFlow);
                        chatFlow.relationships[row.index].rel = rel;
                        this.setState({
                            chatFlow: chatFlow
                        }, () => {
                            this.closeModal();
                        })
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: {
                    isOpen: true,
                    content: content,
                    size: "modal-large"
                }
            })
        }
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null,
                    isUploading: false
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    handleSubmit = () => {
        if (this.props.onSubmitted instanceof Function) {
            this.props.onSubmitted(this.state.chatFlow);
        }
    }


    render() {

        let chatFlowsTableColumns = [
            {
                Header: "Type",
                accessor: "name"
            },
            {
                Header: "UUID",
                accessor: "uuid"
            },
            {
                Header: "Relationships",
                accessor: "name",
                Cell: row => {
                    return row.original.rel.length;
                },
            },
            {
                Header: "Mapped",
                accessor: "name",
                Cell: row => {
                    let successCount = 0;
                    row.original.rel.forEach((o) => { if (o.status === "success") { successCount++; } });
                    return successCount;
                },
            },
            {
                Header: "Errors",
                accessor: "name",
                Cell: row => {
                    let errorsCount = 0;
                    row.original.rel.forEach((o) => { if (o.status === "error") { errorsCount++; } });
                    return errorsCount;
                },
            },
            {
                Header: "Fixed",
                accessor: "name",
                Cell: row => {
                    let fixedCount = 0;
                    row.original.rel.forEach((o) => {
                        if (o.overwritten === true) { fixedCount++; }
                    });
                    return fixedCount;
                },
            },
            {
                Header: 'Actions',
                Cell: row => {
                    if (row.original.rel.length > 0) {
                        return (
                            <ButtonGroup size={Sizes.TINY}>
                                <Button color={Colors.PRIMARY} onClick={() => this.showItemModal(row)}>Details</Button>
                            </ButtonGroup>
                        );
                    }
                    else {
                        return "";
                    }
                },
                maxWidth: 100,
            }
        ];

        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Chat action</h3>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <ReactTable
                            columns={chatFlowsTableColumns}
                            data={this.state.chatFlow.relationships}
                            minRows={1}
                            showPagination={false}
                            manual
                            noDataText={"No relationships detected"}
                            className='-striped'
                            getTrProps={this.getTRPropsType}
                        />
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatMapModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        )
    }

}


