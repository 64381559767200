import React from 'react'
import PageContent from 'components/common/PageContent'
import DomainsTable from 'components/organisation/domains/DomainsTable'

export default function Departments(props) {

    const pathElements = [
        {id: 0, title: "Home", url: "/"},
        {id: 1, title: "Organisation", url: "/organisation"},
        {id: 2, title: "Email Domains"}
    ];

    return (
        <PageContent pathElements={pathElements}>
            <DomainsTable/>
        </PageContent>
    )
}