import React from 'react'
import AppComponent from 'components/common/AppComponent'
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation"
import ReactTable from "react-table";
import HabitsSelector from "../habits/HabitsSelector";
import Modal from "react-responsive-modal";
import ServicesSelector from "../services/ServicesSelector";
import PodcastsSelector from "../podcasts/PodcastsSelector";

export default class ChatImportMappings extends AppComponent {

    constructor(props, context) {
        super(props);

        this.state = {
            mappings: props.mappings ?? [],
            modal: {
                isOpen: false,
                content: null,
                size: "modal-large"
            }
        };
    }

    componentDidMount() {

    }

    getTRPropsType(state, rowInfo, column) {

        if (!rowInfo) { return {}; }
        
        const { original } = rowInfo;
        const { status, overwritten } = original;

        if (overwritten === true) {
            return {
                style: {
                    background: "#92d689"
                }
            };
        }

        if (status === "error") {
            return {
                style: {
                    background: "#f59790"
                }
            };
        }

        return {};
    }


    selectHabit = (row) => {
        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.mappings[row.index].destID) {
            selected.push({id: this.state.mappings[row.index].destID})
        }

        const content = (
            <HabitsSelector
                isSingleSelection
                selected={selected}
                onSubmitted={(habit) => {

                    let item = this.state.mappings[row.index];
                    item.destID = habit ? habit.id : null;
                    item.overwritten = !!habit;

                    let mappings = this.state.mappings.slice();
                    mappings[row.index] = item;

                    this.setState({
                        mappings: mappings,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.closeModal();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {

        })
    }

    selectService = (row) => {

        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.mappings[row.index].destID) {
            selected.push({id: this.state.mappings[row.index].destID})
        }

        const content = (
            <ServicesSelector
                isSingleSelection
                selected={selected}
                onSubmitted={(service) => {

                    let item = this.state.mappings[row.index];
                    item.destID = service ? service.id : null;
                    item.overwritten = !!service;

                    let mappings = this.state.mappings.slice();
                    mappings[row.index] = item;

                    this.setState({
                        mappings: mappings,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.closeModal();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        })
    }



    selectPodcast = (row) => {

        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.mappings[row.index].destID) {
            selected.push({id: this.state.mappings[row.index].destID})
        }

        const content = (
            <PodcastsSelector
                organisationID={this.state.item.organisationID}
                isSingleSelection
                selected={selected}
                onSubmitted={(podcast) => {

                    let item = this.state.mappings[row.index];
                    item.destID = podcast ? podcast.id : null;
                    item.overwritten = !!podcast;

                    let mappings = this.state.mappings.slice();
                    mappings[row.index] = item;

                    this.setState({
                        mappings: mappings,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.closeModal();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {
            this.validate();
        })

    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null,
                    isUploading: false
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    handleSubmit = () => {
        if (this.props.onSubmitted instanceof Function) {
            this.props.onSubmitted(this.state.mappings);
        }
    }


    render() {

        let chatFlowsTableColumns = [
            {
                Header: "Type",
                accessor: "type",
                maxWidth: 200
            },
            {
                Header: "Source ID",
                accessor: "sourceID",
                maxWidth: 100
            },
            {
                Header: "Source Name",
                accessor: "sourceName"
            },
            {
                Header: "Message",
                accessor: "message",
                Cell: row => {
                    if (row.original.overwritten === true) {
                        return "Destination object selected manually";
                    }
                    return row.original.message;
                }
            },
            {
                Header: "Dest ID",
                accessor: "destID",
                maxWidth: 100
            },
            {
                Header: 'Actions',
                Cell: row => {
                    if (row.original.status === "error") {
                        if (row.original.type === "habit") {
                            return (
                                <ButtonGroup size={Sizes.TINY}>
                                    <Button color={Colors.PRIMARY} onClick={() => this.selectHabit(row)}>Select habit</Button>
                                </ButtonGroup>
                            );
                        }
                        else if (row.original.type === "service") {
                            return (
                                <ButtonGroup size={Sizes.TINY}>
                                    <Button color={Colors.PRIMARY} onClick={() => this.selectService(row)}>Select service</Button>
                                </ButtonGroup>
                            );
                        }
                        else if (row.original.type === "podcast") {
                            return (
                                <ButtonGroup size={Sizes.TINY}>
                                    <Button color={Colors.PRIMARY} onClick={() => this.selectPodcast(row)}>Select podcast</Button>
                                </ButtonGroup>
                            );
                        }
                    }
                    return "";
                },
                maxWidth: 100,
                show: false
            }
        ];

        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Chat action</h3>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <ReactTable
                            columns={chatFlowsTableColumns}
                            data={this.state.mappings}
                            minRows={1}
                            showPagination={false}
                            manual
                            noDataText={"No relationships detected"}
                            className='-striped'
                            getTrProps={this.getTRPropsType}
                        />
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatOverwriteModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        )
    }

}


