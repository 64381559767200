import React from 'react'
import EditorHelper from 'components/contentEditor/EditorHelper'
import ImageView from 'components/common/Image'
import Utils from 'utils/Utils'
import placeholder from 'assets/img/placeholder.png'

export default class Image extends React.Component {

    render() {

        const {item, defaults, style, innerRef, scale, ...props} = this.props;

        const leftPadding = EditorHelper.getMargin(item, defaults, "left") * scale;
        const rightPadding = EditorHelper.getMargin(item, defaults, "right") * scale;

        const width = (375 * this.props.scale);
        const height = width * this.props.item.data.aspectRatio;

        return (
            <span
                ref={innerRef}
                style={Object.assign(style,{
                    paddingLeft: leftPadding,
                    paddingRight: rightPadding,
                    paddingBottom: EditorHelper.getMargin(item, defaults, "bottom") * this.props.scale,
                    paddingTop: EditorHelper.getMargin(item, defaults, "top") * this.props.scale,
                    display: "block"
                })}
                {...props}
            >
                <ImageView
                    width={width}
                    height={height}
                    fit={Utils.getNested(this.props.item, "data", "contentMode")}
                    // innerRef={innerRef}
                    src={Utils.getNested(this.props.item, "data", "url")}
                    placeholder={placeholder}
                />
            </span>
        )
    }

}