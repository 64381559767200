import React from 'react'
import { Progress } from 'react-foundation'

export default function ModalProgress(props) {
    return (
        <div className={"modblock " + (props.show && "show")}>
            <h2>{props.title || 'Saving'}</h2>
            {props.showProgress ?
                <div className="modal-progress">
                    <Progress meter={{ text: (props.progress + " %") }} tabIndex="0" min={0} max={100} value={props.progress} valueText={props.progress + " percent"} />
                </div>
                :
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            }
        </div>
    )
}