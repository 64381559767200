import React, {useEffect, useRef, useState} from "react";
import Axios from "axios";
import {API} from "../../api/APIClient";
import ModalProgress from "../common/ModalProgress";
import {CommonUtil} from "../../utils/CommonUtil";
import Modal from "react-responsive-modal";
import ChatFlowsSelector from "../chatflows/ChatFlowsSelector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const PluginChatFlowEditor = (props) => {

    const MODAL_MODE_CHAT_FLOW = 1;

    const {id} = props;
    const {callback} = props;

    const [chatFlowList, setChatFlowList] = useState(null);
    // const [organisationOptions, setOrganisationOptions] = useState([]);

    const [selectedChatFlows, setSelectedChatFlows] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({});
    const [touched, setTouched] = useState({});
    const [errors, setErrors] = useState({});

    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState(0);
    const modalMetadata = useRef({});

    useEffect(() => {
        if (id) {
            fetchPluginChatFlowFromNetwork();
        }
    }, []);

    useEffect(() => {
        if (chatFlowList) {
            const newFormData = {
                id : chatFlowList.id,
                name : chatFlowList.name,
                description : chatFlowList.description,
                ordering : chatFlowList.ordering
            };
            setFormData(newFormData);
            if (chatFlowList.data) {
                setSelectedChatFlows(JSON.parse(chatFlowList.data));
            }
        }
    }, [chatFlowList]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function handleSubmit(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (validateForm()) {
            submitPluginChatFlowOverNetwork();
        }
    }

    function validateForm() {
        let out = true;
        const errors = {};

        const requiredFields = [
            { key : "name", label : "Name" }
        ];

        for (let i = 0; i < requiredFields.length; i++) {
            let problem = false;

            const field = requiredFields[i];
            if (formData.hasOwnProperty(field.key)) {
                if (formData[field.key] === undefined || formData[field.key] === null) {
                    problem = true;
                }
            } else {
                problem = true;
            }

            if (problem) {
                out = false;
                errors[field.key] = "Please provide a value for '" + field.label + "'";
            }
        }

        return out;
    }

    function handleChange(event) {
        const value = event.target.type === "checkbox" ? (event.target.checked ? 1 : 0) : event.target.value;

        let outData = {};
        outData[event.target.name] = value;
        putFormData(outData);
    }

    function putFormData(data) {
        if (!CommonUtil.isObject(data)) {
            return;
        }

        const newFormData = CommonUtil.cloneObject(formData);
        const keys = Object.keys(data);

        keys.forEach((key) => {
            newFormData[key] = data[key];
        });

        setFormData(newFormData);
    }

    function handleBlur(event) {
        const name = event.target.name;
        const newTouched = CommonUtil.cloneObject(touched);
        newTouched[name] = true;
        setTouched(touched);
    }

    function summonChatFlowSelector(e) {
        e.preventDefault();
        e.stopPropagation();

        setModalMode(MODAL_MODE_CHAT_FLOW);
        setModalOpen(true);
    }

    function pluginModalDidDismiss() {
        setModalOpen(false);
    }

    function addChatFlow(chatFlow) {
        const chatFlows = [...selectedChatFlows];
        if (!Array.isArray(chatFlow)) {
            chatFlow = [chatFlow];
        }
        chatFlow.forEach((flow) => {
            chatFlows.push(flow);
        });
        setSelectedChatFlows(chatFlows);
    }

    function removeChatFlow(chatFlow) {
        const chatFlows = [...selectedChatFlows];
        for (let i = selectedChatFlows.length - 1; i >= 0; i--) {
            if (chatFlows[i] === chatFlow) {
                chatFlows.splice(i, 1);
            }
        }
        setSelectedChatFlows(chatFlows);
    }

    function clearChatFlows(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setSelectedChatFlows([]);
    }

    function fetchPluginChatFlowFromNetwork() {
        if (isLoading) return;
        setIsLoading(true);

        const data = {
            id
        };

        Axios.post(API.plugin.getPluginChatFlow, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setChatFlowList(resp.data.chatFlowList);
                } else {
                    console.log(API.formatError(resp));
                }
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    }

    function submitPluginChatFlowOverNetwork() {
        if (isLoading) return;
        setIsLoading(true);

        const data = formData;
        data.data = JSON.stringify(selectedChatFlows);

        Axios.post(API.plugin.submitPluginChatFlowList, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    handleCallback("submit");
                } else {
                    console.log(API.formatError(resp));
                }
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    }

    // RENDER

    let modalTitle = "Add Chat Flow List";
    if (id) {
        modalTitle = "Edit Chat Flow List";
    }

    let organisationPicker = [];
    // if (PermissionUtil.allOrgs("plugin")) {
    //     organisationPicker = <div className="grid-x grid-padding-x">
    //         <div className="small-3 cell">
    //             <label htmlFor="organisationID" className="text-right middle">* Created By</label>
    //         </div>
    //         <div className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "organisationID", null) && "error")}>
    //             <ReactSelect
    //                 name="organisationID"
    //                 className="react-select"
    //                 onChange={handleChange}
    //                 value={CommonUtil.getOrDefault(formData, "organisationID", null)}
    //                 options={organisationOptions}
    //                 onBlur={handleBlur}
    //             />
    //             <small className="error">{CommonUtil.getOrDefault(errors, "organisationID", "")}</small>
    //         </div>
    //     </div>
    // }

    let chatFlowItemElems = (<div className={"empty-message"}>No Chat Flows Selected</div>);
    if (selectedChatFlows.length > 0) {
        chatFlowItemElems = selectedChatFlows.map((chatFlow) => {
            let subtitleElem = [];
            if (parseInt(chatFlow.pluginCompatible) === 1) {
                subtitleElem = (
                    <div className={"list-builder-item-content-subtitle"}>
                        <em>Plugin Compatible</em>
                    </div>
                );
            }

            return (
                <div className={"list-builder-item clickable"}>
                    <div className={"list-builder-item-content"}>
                        <div className={"list-builder-item-content-title"}>
                            {chatFlow.title}
                        </div>
                        {subtitleElem}
                    </div>

                    <div className={"list-builder-item-icon clickable danger"}>
                        <FontAwesomeIcon icon={"trash"} onClick={() => removeChatFlow(chatFlow)} />
                    </div>
                </div>
            )
        })
    }

    let modalContent = [];
    if (modalMode === MODAL_MODE_CHAT_FLOW) {
        modalContent = (
            <ChatFlowsSelector
                onCancel={() => setModalOpen(false)}
                pluginCompatible={1}
                // selected={modalMetadata.current}
                onSubmitted={(chatFlow) => {
                    if (chatFlow) {
                        addChatFlow(chatFlow);
                    }
                    setModalOpen(false);
                }}
                onClose={() => {
                    setModalOpen(false);
                }}/>
        );
    }

    return (
        <>
            <ModalProgress show={isLoading} />
            <div className={"modal-head"}>
                <div className={"modal-head"}>
                    <h3>{modalTitle}</h3>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className={"modal-body"}>
                    {organisationPicker}

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="name" className="text-right middle ">* Name</label>
                        </div>
                        <div className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "name", null) && "error")}>
                            <input
                                type="text"
                                name="name"
                                onChange={handleChange}
                                value={CommonUtil.getOrDefault(formData, "name", "")}
                                onBlur={handleBlur}
                            />

                            <small className="error">{CommonUtil.getOrDefault(errors, "name", "")}</small>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="name" className="text-right middle ">Description</label>
                        </div>
                        <div
                            className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "description", null) && "error")}>
                            <textarea
                                name="description"
                                onChange={handleChange}
                                value={CommonUtil.getOrDefault(formData, "description", "")}
                                onBlur={handleBlur}
                            />

                            <small className="error">{CommonUtil.getOrDefault(errors, "description", "")}</small>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="name" className="text-right middle ">Order Position</label>
                        </div>
                        <div
                            className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "ordering", null) && "error")}>
                            <input
                                type="number"
                                name="ordering"
                                onChange={handleChange}
                                value={CommonUtil.getOrDefault(formData, "ordering", "")}
                                onBlur={handleBlur}
                            />

                            <small className="error">{CommonUtil.getOrDefault(errors, "ordering", "")}</small>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <fieldset className="fieldset">
                                <legend>Chat Flows</legend>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-9 cell">
                                        <div className={"list-builder"}>
                                            {chatFlowItemElems}
                                        </div>
                                    </div>

                                    <div className={"small-3 cell"}>
                                        <div>
                                            <button className={"button"} onClick={summonChatFlowSelector}>
                                                Add Chat Flow
                                            </button>
                                        </div>

                                        <div>
                                            <button className={"button alert"} onClick={clearChatFlows}>
                                                Remove all
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>

                </div>

                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                {
                                    <React.Fragment>
                                        <button
                                            className="button secondary"
                                            type="button"
                                            onClick={() => handleCallback("submit")}
                                        >
                                            Cancel
                                        </button>

                                        <button
                                            className="button success"
                                            type="submit"
                                            disabled={!validateForm()}
                                        >
                                            Submit
                                        </button>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <Modal
                classNames={{modal: "modal-medium"}}
                open={modalOpen}
                modalId="PluginModal"
                onClose={pluginModalDidDismiss}
                center
            >
                {modalContent}
            </Modal>
        </>
    )

}