export const ContextUtil = {
    _data : {},
    _getItem : (key) => {
        if (ContextUtil._data.hasOwnProperty(key)) {
            return ContextUtil._data[key];
        }
        return null;
    },
    getUser : () => {
        return ContextUtil._getItem("user");
    },
    setUser : (user) => {
        ContextUtil._data.user = user
    },
    getOrganisation : () => {
        return ContextUtil._getItem("organisation");
    },
    setOrganisation : (organisation) => {
        ContextUtil._data.organisation = organisation;
    },
    getPermissions : () => {
        return ContextUtil._getItem("permissions");
    },
    setPermissions : (permissions) => {
        ContextUtil._data.permissions = permissions;
    },
    getAuthToken : () => {
        return ContextUtil._getItem("authToken");
    },
    setAuthToken : (authToken) => {
        ContextUtil._data.authToken = authToken;
    },
    getMenu : () => {
        return ContextUtil._getItem("menu");
    },
    setMenu : (menu) => {
        ContextUtil._data.menu = menu;
    },
    init : (data) => {
        if (data.user) ContextUtil.setUser(data.user);
        if (data.organisation) ContextUtil.setOrganisation(data.organisation);
        if (data.permissions) ContextUtil.setPermissions(data.permissions);
        if (data.authToken) ContextUtil.setAuthToken(data.authToken);
        if (data.menu) ContextUtil.setMenu(data.menu);
    }
}
