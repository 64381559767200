import React from 'react'
import PageContent from 'components/common/PageContent'
import EmotionsTable from 'components/emotions/EmotionsTable'

export default function Emotions(props) {

    const pathElements = [
        {id: 0, title: "Home", url: "/"},
        {id: 1, title: "Emotions"}
    ];

    return (
        <PageContent pathElements={pathElements} >
            <EmotionsTable/>
        </PageContent>
    );
}