import React from 'react'
import AppComponent from 'components/common/AppComponent'
import ChatCustomVariablesTable from 'components/chatflows/ChatCustomVariablesTable'
import ChatVariablesTable from "./ChatVariablesTable";

export default class ChatCustomVariablesSelector extends AppComponent {

    constructor(props) {
        super(props);
    }


    handleSelected = (item) => {
        if (this.props.onSelected instanceof Function) {
            this.props.onSelected(item);
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Select custom property</h3>
                </div>
                <div className="modal-body">
                    <ChatCustomVariablesTable
                        showCopyButton={this.props.showCopyButton}
                        showSelectButton={this.props.showSelectButton}
                        onSelected={(item) => this.handleSelected(item)}
                    />
                </div>
                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}