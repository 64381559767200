import React from 'react'
import SubSection from 'components/common/SubSection'
import AppComponent from 'components/common/AppComponent'
import { MenuItem } from 'constants/Menu'

const pathElements = [
    {id: 0, title: "Home", url: "/"},
    {id: 1, title: "Money"}
];

export default class Money extends AppComponent {

    render() {

        const menuItem = this.context.menu.find((section) => section.id === MenuItem.MONEY);

        return (
            <SubSection pathElements={pathElements} item={menuItem} />
        );
    }
}