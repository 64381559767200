import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AppContext } from 'contexts/AppContext'

export default function PrivateRoute({ component: Component, ...rest }) {
    return (
        <AppContext.Consumer>
            {context =>
                <Route
                    {...rest}
                    render={props =>
                        context.user !== null ? (
                                <Component {...props} />
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: "/login",
                                        state: { from: props.location }
                                    }}
                                />
                            )
                    }
                />
            }
        </AppContext.Consumer>
    );
}