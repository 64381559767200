import React from 'react'
import Axios from 'axios'
import ReactSelect from 'components/common/ReactSelect'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'
import moment from 'moment'
import DateHelper from 'utils/DateHelper'

export default class APIKeyForm extends AppComponent {

    static defaultProps = {
        item: {}
    }

    constructor(props, context) {
        super(props);

        this.state = {
            item: Object.assign({
                createdAt: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                organisationID: context.organisation.id,
                active: 1,
                refreshRateLimit: 0,
                key: "API Key will be generated upon submission",
                secret: "API Secret will be generated upon submission"
            }, props.item),
            organisationOptions: [
                props.item.organisationID ? {value: props.item.organisationID, label: props.item.organisationName} : {value: context.organisation.id, label: context.organisation.name}
            ],
            clientOptions: props.item.clientID ? [{value: props.item.clientID, label: props.item.clientName}] : [],
            validated: false,
            editable: props.editable || false,
            errors: {},
            touched: {},
            roleOptions: [],
            isUploading: false
        };
    }

    componentDidMount() {
        this.validate();
        if (!this.props.item.id) {
            if (this.context.permissions.apiKeys.allOrgs) {
                this.getOrganisationOptions();
            }
            this.getClientOptions()
        }
    }

    getClientOptions = () => {
        Axios
            .get(API.apiKey.getClients)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        clientOptions: res.data.data.clients.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations, {
                props: {
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {
                Axios
                    .post((this.state.item.id ? API.apiKey.updateKey : API.apiKey.createKey), this.state.item)
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted(res.data.data.item);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }

                        this.setState({
                            isUploading: false
                        });
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = this.state.item;
        item[name] = value;

        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;


        this.setState({
            validated: validated,
            errors: errors
        });
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />
                <div className="modal-head">
                    <h3>{this.state.editable ?  (this.state.item.id ? "Edit API Key" : "New API Key") : "API Key"}</h3>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="active" className="text-right middle">Active</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.active && "error")}>
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="active" onChange={this.handleChange} checked={this.state.item.active === 1} onBlur={this.handleBlur} disabled={!this.state.editable}/>
                                    <span className="chkmk"></span>
                                    <small className="error">{this.state.errors.active}</small>
                                </label>
                            </div>
                        </div>

                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="createdAt" className="text-right middle">Created At</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <input name="createdAt" type="text" value={moment(DateHelper.convertToLocalDateTime(this.state.item.createdAt), "YYYY-MM-DD HH:mm:ss").format("LLLL")} disabled={true} />
                            </div>
                        </div>
                        {this.context.permissions.apiKeys.allOrgs && !this.props.item.id && this.state.editable ?
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="organisationID" className="text-right middle">* Organisation</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.organisationID && "error")}>
                                    <ReactSelect
                                        name="organisationID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.item.organisationID}
                                        options={this.state.organisationOptions}
                                        onBlur={this.handleBlur}
                                    />
                                    <small className="error">{this.state.errors.organisationID}</small>
                                </div>
                            </div>
                            :
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="organisationName" className="text-right middle ">Organisation</label>
                                </div>
                                <div className="small-9 cell">
                                    <input type="text" name="organisationName" value={this.state.item.organisationName} disabled/>
                                </div>
                            </div>
                        }
                        {!this.props.item.id && this.state.editable ?
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="clientID" className="text-right middle">* Client</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.clientID && "error")}>
                                    <ReactSelect
                                        name="clientID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.item.clientID}
                                        options={this.state.clientOptions}
                                        onBlur={this.handleBlur}
                                    />
                                    <small className="error">{this.state.errors.clientID}</small>
                                </div>
                            </div>
                            :
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="clientName" className="text-right middle ">Client</label>
                                </div>
                                <div className="small-9 cell">
                                    <input type="text" name="clientName" value={this.state.item.clientName} disabled/>
                                </div>
                            </div>
                        }
                        {this.props.item &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="key" className="text-right middle ">API Key</label>
                                </div>
                                <div className="small-9 cell">
                                    <input type="text" name="key" onChange={this.handleChange} value={this.state.item.key} onBlur={this.handleBlur} disabled/>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="secret" className="text-right middle ">API Secret</label>
                                </div>
                                <div className="small-9 cell">
                                    <input type="text" name="secret" onChange={this.handleChange} value={this.state.item.secret} onBlur={this.handleBlur} disabled/>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="refreshRateLimit" className="text-right middle ">Refresh Rate Limit</label>
                            </div>
                            <div className="small-9 cell">
                                <input type="number" placeholder="Type 0 for no limit" min={0} name="refreshRateLimit" value={this.state.item.refreshRateLimit} onBlur={this.handleBlur} onChange={this.handleChange} disabled={!this.state.editable}/>
                                <small className="field-info">Refresh Rate Limit controls frequency of requests from a single user. Limit is only enforced on clients apps. Value is specified in seconds. 0 represents no limit, values higher than 0 means that data on a client app is not refreshed earlier than after x seconds.</small>
                            </div>
                        </div>
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    {this.state.editable &&
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </form>
            </React.Fragment>
        );
    }
}
