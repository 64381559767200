import React from 'react'
import DetailedReport from 'components/reports/DetailedReport'
import MoodTotalsTable from 'components/reports/MoodTotalsTable'
import Utils from 'utils/Utils'
import {GraphType} from "../../constants/GraphType";


export default class PodcastReport extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            selectedEmotion: null
        }
    }

    handleItemSelected = (item) => {
        this.setState({
            selectedItem: item
        })
    }

    render() {

        let { filters, title, endpoint, graphType, ...props } = this.props;

        let f = Object.assign({}, filters);
        f.emotionID = this.state.selectedItem ? this.state.selectedItem.id : null;

        title = Utils.getNested(this.state.selectedItem, "name") || "Mood Cloud";

        if (this.state.selectedItem && this.props.tableEndpoint) {
            endpoint = this.props.tableEndpoint;
            graphType = GraphType.BAR
        }

        return (
            <React.Fragment>
                <div className="grid-x grid-padding-x">
                    <div className="small-12 cell">
                        <div className="panel-box">
                            <MoodTotalsTable {...this.props} onItemSelected={this.handleItemSelected} />
                        </div>
                    </div>
                </div>
                <DetailedReport filters={f} title={title} endpoint={endpoint} graphType={graphType} {...props} />
            </React.Fragment>

        )
    }
}