import React from 'react'
import PageContent from 'components/common/PageContent'
import AppComponent from 'components/common/AppComponent'
import WellbeingScoreSetup from "./WellbeingScoreSetup";

const pathElements = [
    {id: 0, title: "Home", url: "/"},
    {id: 1, title: "Organisation", url: "/organisation"},
    {id: 2, title: "Wellbeing Score"}
];

export default class WellbeingScore extends AppComponent {

    render() {
        return (
            <PageContent pathElements={pathElements} >
                <WellbeingScoreSetup />
            </PageContent>
        );
    }
}