import React from 'react';
import {CommonUtil} from "../../utils/CommonUtil";
import {PluginUtil} from "../../utils/PluginUtil";

import "./PluginFormNavigationPreview.css";

import sosIcon from "../../assets/img/plugin/ic_sos.svg";
import charleyIcon from "../../assets/img/plugin/ic_charley_filled.svg";
import overflowIcon from "../../assets/img/plugin/ic_options.svg";
import qrCode from "../../assets/img/plugin/qrcode.png";

export const PluginFormNavigationPreview = (props) => {

    const {data} = props;

    const showNavigation = CommonUtil.getOrDefault(data, PluginUtil.configKeys.CHAT_SHOW_NAVIGATION, true);
    const showSos = CommonUtil.getOrDefault(data, PluginUtil.configKeys.CHAT_SHOW_SOS, true);
    const showCharley = CommonUtil.getOrDefault(data, PluginUtil.configKeys.CHAT_SHOW_CHARLEY, true);
    const showQRLink = CommonUtil.getOrDefault(data, PluginUtil.configKeys.CHAT_SHOW_QR_LINK, true);
    const showOverflow = CommonUtil.getOrDefault(data, PluginUtil.configKeys.CHAT_SHOW_OVERFLOW, true);

    if (!showNavigation) return [];

    let actionSos = [];
    if (showSos) {
        actionSos = (
            <div
                className={"navigation-action"}
                style={{backgroundImage: "url(" + sosIcon + ")"}}
            />
        );
    }

    let actionCharley = [];
    if (showCharley) {
        actionCharley = (
            <div
                className={"navigation-action"}
                style={{
                    backgroundImage: "url(" + charleyIcon + ")",
                    marginLeft: "10px"
                }}
            />
        )
    }

    let qrLink = [];
    if (showQRLink) {
        qrLink = (
            <div className={"download-prompt-container"}>
                <span className={"qr-display"} style={{backgroundImage : "url(" + qrCode + ")"}} />

                <span className={"download-prompt"}>
                    Scan QR Code or <span className={"underlined"}>Click to download app and access all features.</span>
                </span>
            </div>
        );
    }

    let actionOverflow = [];
    if (showOverflow) {
        actionOverflow = (
            <div
                className={"overflow"}
                style={{backgroundImage: "url(" + overflowIcon + ")"}}
            />
        );
    }

    return (
        <div className={"chat-navigation-wrapper"}>
            <div className={"chat-navigation"}>
                {actionSos}

                {actionCharley}

                <div className={"spacer"}/>

                {qrLink}

                {actionOverflow}
            </div>
        </div>
    );

}