import React from 'react'
import EditorHelper from 'components/contentEditor/EditorHelper'
import Utils from 'utils/Utils'

export default class Header extends React.Component {

    render() {

        const {item, defaults, style, innerRef, ...props} = this.props;

        const leftText = Utils.value(Utils.getNested(this.props.item, "data", "leftText"), "");
        const mainText = Utils.value(Utils.getNested(this.props.item, "data", "mainText"), "");

        const displayLeftText = leftText.length > 0;

        return (
            <span
                ref={innerRef}
                style={Object.assign(style,{
                    paddingLeft: EditorHelper.getMargin(item, defaults, "left") * this.props.scale,
                    paddingRight: EditorHelper.getMargin(item, defaults, "right") * this.props.scale,
                    paddingBottom: EditorHelper.getMargin(item, defaults, "bottom") * this.props.scale,
                    paddingTop: EditorHelper.getMargin(item, defaults, "top") * this.props.scale,
                    display: "block"
                })}

                {...props}
            >
                <div
                    style={{
                        backgroundColor: "#575757",
                        minHeight: 50 * this.props.scale,
                        paddingTop: 5 * this.props.scale,
                        paddingBottom: 5 * this.props.scale,
                        paddingLeft: 15 * this.props.scale,
                        paddingRight: 15 * this.props.scale,
                        border: "1px solid #575757",
                        borderRadius: 25 * this.props.scale,
                        display: "block"
                    }}
                >
                    {displayLeftText &&
                    <div
                        style={{
                            fontFamily: "Barlow Condensed, sans-serif",
                            fontSize: 25 * this.props.scale,
                            fontWeight: "600",
                            color: "#b7b7b7",
                            textAlign: "left",
                            lineHeight: "normal",
                            display: "inline-block",
                            marginRight: 5,
                            float: "left",
                            width: "10%",
                            overflow: "hidden"
                        }}
                    >
                        {leftText}
                    </div>
                    }
                    <div
                        style={{
                            fontFamily: "Barlow Condensed, sans-serif",
                            fontSize: 20 * this.props.scale,
                            fontWeight: "600",
                            color: "white",
                            textAlign: "left",
                            lineHeight: "normal",
                            display: "inline-block",
                            width: "80%",
                            verticalAlign: "top"
                        }}
                        >
                        {mainText}
                    </div>
                </div>
            </span>
        )
    }

}