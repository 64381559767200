import React from 'react'
import Axios from 'axios'
import moment from 'moment'
import { API } from 'api/APIClient'
import ReactSelect from 'components/common/ReactSelect'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'
import { NotificationManager } from 'react-notifications'
import BlockUI from 'react-block-ui'
import ServicesSelector from "../services/ServicesSelector";
import Modal from "react-responsive-modal";
import DatePicker from 'components/common/DatePicker'
import DateHelper from 'utils/DateHelper'
import FileInput from "../common/FileInput";
import Image from "../common/Image";


const TITLE_MAX_CHAR = 50;
const SUBTITLE_MAX_CHAR = 512;

class ActionForm extends AppComponent {

    static defaultProps = {
        item: {},
        onSubmitted: () => void 0
    }

    constructor(props, context) {
        super(props);

        this.state = {
            item: Object.assign({
                createdAt: props.item.createdAt || moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                organisationID: props.item.organisationID || context.organisation.id,
                active: props.item.active || 1,
                nonRegistered: props.item.nonce || 0,
            }, props.item),
            organisationOptions: [
                props.item.organisationID ? {value: props.item.organisationID, label: props.item.organisationName} : {value: context.organisation.id, label: context.organisation.name}
            ],
            organisations: [],
            validated: false,
            errors: {},
            touched: {},
            roleOptions: [],
            campusOptions: [],
            studyYearOptions: [],
            courseOptions: [],
            studentCategoryOptions: [],
            degreeTypesOptions: [],
            departmentOptions: [],
            imagePickerURL: null,
            imagePickerFile: null,
            isUploading: false,
            isLoading: false,
            isLoadingCampuses: false,
            isLoadingRoles: false,
            isLoadingStudyYears: false,
            isLoadingCourses: false,
            isLoadingStudentCategories: false,
            isLoadingDegreeTypes: false,
            isLoadingDepartments: false,
            iconReverted: false,
            useDefaultImage: props.item.imageURL === null,
            titleCharLeft: TITLE_MAX_CHAR,
            subtitleCharLeft: SUBTITLE_MAX_CHAR,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            }
        };

        console.log(props.item.imageURL);
    }


    componentDidMount() {
        this.validate();
        this.getFilterOptions();

        if (this.state.item.id) {
            this.getAction(this.state.item.id);
        }

        if (this.context.permissions.post.allOrgs) {
            this.getOrganisationOptions();
        }
    }

    getFilterOptions = () => {
        this.getRoleOptions();
        this.getCampusOptions();
        this.getStudyYearOptions();
        this.getCourseOptions();
        this.getStudentCategoryOptions();
        this.getDegreeOptions();
        this.getDepartmentOptions();
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisations: res.data.data.organisations,
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} }),
                    })
                }
            })
    }

    getAction = () => {
        if (!this.state.isLoading) {
            this.setState({
                isLoading: true
            }, () => {

                Axios
                    .get(API.cta.getAction, {
                        params: {
                            actionID: this.props.item.id
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            let item = res.data.data.action;
                            this.setState({
                                isLoading: false,
                                item: item,
                                organisationOptions: [
                                    item.organisationID ? {value: item.organisationID, label: item.organisationName} : {value: this.context.organisation.id, label: this.context.organisation.name}
                                ]
                            }, () => {
                                this.getActionVisibility(this.state.item.id);
                            })
                        }
                        else {
                            console.log(res.data.error.desc);
                            this.setState({
                                isLoading: false
                            })
                        }
                    });

            })
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (!this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {
                let formData = new FormData();

                if (this.state.item.id) {
                    formData.append("id", this.state.item.id);
                }

                formData.append("active", this.state.item.active ? 1 : 0);
                formData.append("title", this.state.item.title);
                formData.append("subtitle", this.state.item.subtitle);
                formData.append("organisationID", this.state.item.organisationID);
                formData.append("startDate", this.state.item.startDate);
                formData.append("endDate", this.state.item.endDate);
                formData.append("nonRegistered", this.state.item.nonRegistered);
                formData.append("serviceID", this.state.item.serviceID);


                if (this.state.item.roles) {
                    this.state.item.roles.forEach((item) => {
                        formData.append("roles[]", item)
                    });
                }

                if (this.state.item.campuses) {
                    this.state.item.campuses.forEach((item) => {
                        formData.append("campuses[]", item)
                    });
                }

                if (this.state.item.studyYears) {
                    this.state.item.studyYears.forEach((item) => {
                        formData.append("studyYears[]", item)
                    });
                }

                if (this.state.item.courses) {
                    this.state.item.courses.forEach((item) => {
                        formData.append("courses[]", item)
                    });
                }

                if (this.state.item.studentCategories) {
                    this.state.item.studentCategories.forEach((item) => {
                        formData.append("studentCategories[]", item)
                    });
                }

                if (this.state.item.degreeTypes) {
                    this.state.item.degreeTypes.forEach((item) => {
                        formData.append("degreeTypes[]", item)
                    });
                }

                if (this.state.item.departments) {
                    this.state.item.departments.forEach((item) => {
                        formData.append("departments[]", item)
                    });
                }

                if (!this.state.useDefaultImage) {
                    if (this.state.imagePickerFile) {
                        formData.append("useDefaultImage", 0);
                        formData.append("file", this.state.imagePickerFile);
                    }
                    else {
                        formData.append("useDefaultImage", this.state.item.imageURL ? 0 : 1);
                    }
                }
                else {
                    formData.append("useDefaultImage", 1);
                }

                Axios
                    .post((this.state.item.id ? API.cta.updateAction : API.cta.createAction), formData, {
                        headers: {
                            'Content-Type': formData.has('file') ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
                        }})
                    .then((res) => {
                        if (res.data.success) {
                            NotificationManager.success("Saved successfully");
                            this.props.onSubmitted(res.data.data.action);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors,
                                isUploading: false
                            });
                        }
                    })
            })
        }
        else {
            console.log("is uploading");
        }
    }

    getActionVisibility = (actionID) => {
        Axios
            .get(API.cta.getActionVisibility, {
                params: {
                    id: actionID
                }
            })
            .then((res) => {
                if (res.data.success) {
                    let item = Object.assign({}, this.state.item);
                    item.roles = res.data.data.roles;
                    item.campuses = res.data.data.campuses;
                    item.studyYears = res.data.data.studyYears;
                    item.courses = res.data.data.courses;
                    item.studentCategories = res.data.data.studentCategories;
                    item.degreeTypes = res.data.data.degreeTypes;
                    item.departments = res.data.data.departments;
                    this.setState({
                        item: item
                    })
                }
            });
    }


    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let newState = {};

        let item = Object.assign({}, this.state.item);
        item[name] = value;

        if ((name === "startDate" || name === "endDate") && value) {
            value = DateHelper.convertFromLocalToUTCDateTime(value);
        }


        if ((name === "roles" && this.state.roleOptions.length === value.length) ||
            (name === "studyYears" && this.state.studyYearOptions.length === value.length) ||
            (name === "campuses" && this.state.campusOptions.length === value.length) ||
            (name === "courses" && this.state.courseOptions.length === value.length) ||
            (name === "studentCategories" && this.state.studentCategoryOptions.length === value.length) ||
            (name === "degreeTypes" && this.state.degreeTypeOptions.length === value.length) ||
            (name === "departments" && this.state.departmentOptions.length === value.length)) {
            item[name] = [];
        }

        if (name === "organisationID") {

            let logoImageURL = "";


            item.studyYears = [];
            item.campuses = [];
            item.courses = [];
            item.studentCategories = [];
            item.degreeTypes = [];
            item.departments = [];
            newState.studyYearOptions = [];
            newState.campusOptions = [];
            newState.courseOptions = [];
            newState.studentCategoryOptions = [];
            newState.degreeTypeOptions = [];
            newState.departmentOptions = [];
        }
        else if (name === "title") {
            if (value.length > TITLE_MAX_CHAR) {
                value = value.substring(0, TITLE_MAX_CHAR - 1);
                item[name] = value;
            }
            newState.titleCharLeft = TITLE_MAX_CHAR - value.length;
        }
        else if (name === "subtitle") {
            if (value.length > SUBTITLE_MAX_CHAR) {
                value = value.substring(0, SUBTITLE_MAX_CHAR - 1);
                item[name] = value;
            }
            newState.subtitleCharLeft = SUBTITLE_MAX_CHAR - value.length;
        }
        else if (name === "useDefaultImage") {
            newState.useDefaultImage = value;
        }

        newState.item = item;

        this.setState(newState, () => {
            this.validate();
            if (name === "organisationID") {
                this.getFilterOptions();
            }
        });
    }

    getRoleOptions = () => {

        if (!this.state.isLoadingRoles) {

            this.setState({
                isLoadingRoles: true
            }, () => {

                Axios
                    .get(API.appUser.getRoleOptions)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingRoles: false,
                                roleOptions: res.data.data.roleOptions.map((role) => { return {value: role.id.toString(), label: role.name} })
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingRoles: false
                            })
                        }
                    });

            })

        }


    }

    getCampusOptions = () => {

        if (!this.state.isLoadingCampuses) {

            this.setState({
                isLoadingCampuses: true
            }, () => {

                Axios
                    .get(API.campus.getCampuses, {
                        params: {
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingCampuses: false,
                                campusOptions: res.data.data.campusOptions.map((campus) => { return {value: campus.id.toString(), label: campus.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingCampuses: false
                            })
                        }
                    });

            })

        }


    }

    getStudyYearOptions = () => {

        if (!this.state.isLoadingStudyYears) {

            this.setState({
                isLoadingStudyYears: true
            }, () => {

                Axios
                    .get(API.appUser.getStudyYearOptions)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingStudyYears: false,
                                studyYearOptions: res.data.data.studyYearOptions.map((studyYear) => { return {value: studyYear.value.toString(), label: studyYear.name} })
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingStudyYears: false
                            })
                        }
                    });

            })

        }


    }


    getCourseOptions = () => {

        if (!this.state.isLoadingCourses) {

            this.setState({
                isLoadingCourses: true
            }, () => {

                Axios
                    .get(API.course.getCourses, {
                        params: {
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingCourses: false,
                                courseOptions: res.data.data.courses.map((item) => { return {value: item.id.toString(), label: item.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingCourses: false
                            })
                        }
                    });

            })

        }

    }


    getDepartmentOptions = () => {

        if (!this.state.isLoadingDepartments) {

            this.setState({
                isLoadingDepartments: true
            }, () => {

                Axios
                    .get(API.department.getDepartments, {
                        params: {
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingDepartments: false,
                                departmentOptions: res.data.data.departments.map((item) => { return {value: item.id.toString(), label: item.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingDepartments: false
                            })
                        }
                    });
            })
        }
    }


    getStudentCategoryOptions = () => {
        if (!this.state.isLoadingStudentCategories) {

            this.setState({
                isLoadingStudentCategories: true
            }, () => {

                Axios
                    .get(API.studentCategory.getCategories, {
                        params: {
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingStudentCategories: false,
                                studentCategoryOptions: res.data.data.studentCategories.map((item) => { return {value: item.id.toString(), label: item.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingStudentCategories: false
                            })
                        }
                    });

            })

        }
    }

    getDegreeOptions = () => {
        if (!this.state.isLoadingDegreeTypes) {

            this.setState({
                isLoadingDegreeTypes: true
            }, () => {

                Axios
                    .get(API.degreeType.getTypes, {
                        params: {
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingDegreeTypes: false,
                                degreeTypeOptions: res.data.data.degreeTypes.map((item) => { return {value: item.id.toString(), label: item.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingDegreeTypes: false
                            })
                        }
                    });

            })

        }
    }


    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.title) {
            if (touched.title) {
                errors.title = "Title is required";
            }
            validated = false;
        }

        if (!item.subtitle) {
            if (touched.subtitle) {
                errors.subtitle = "Subtitle is required";
            }
            validated = false;
        }

        if (!item.startDate) {
            if (touched.startDate) {
                errors.startDate = "Start date is required"
            }
            validated = false;
        }

        if (!item.endDate) {
            if (touched.endDate) {
                errors.endDate = "End date is required"
            }
            validated = false;
        }

        if (!item.serviceID) {
            if (touched.serviceID) {
                errors.serviceID = "Service is required"
            }
            validated = false;
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }


    showServiceModal = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.item.serviceID) {
            selected.push({id: this.state.item.serviceID})
        }


        const content = (
            <ServicesSelector
                organisationID={this.state.item.organisationID}
                isSingleSelection
                selected={selected}
                onSubmitted={(service) => {

                    let item = Object.assign({}, this.state.item);

                    item.serviceID = service.id;
                    item.serviceTitle = service.title;
                    item.serviceTypeName = service.typeName;
                    item.defaultImageURL = service.defaultCTAImageURL;

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })
    }

    handleFileDelete = (name) => {
        if (name === "imageFile") {
            this.setState({
                imagePickerFile: null,
                imagePickerURL: null
            })
        }
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];
        let name = event.target.name;

        let self = this;

        reader.onloadend = () => {

            let newState = {};

            if (name === "imageFile") {
                newState = {
                    imagePickerFile: file ? file : null,
                    imagePickerURL: file ? reader.result : null
                }
            }

            self.setState(newState, () => {
                self.validate()
            })
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    item: undefined
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    render() {

        let organisationName = "";

        if (this.state.item.organisationID) {
            const orgID = this.state.item.organisationID;

            if (this.state.organisationOptions.length > 0) {
                for (let i of this.state.organisationOptions) {
                    if (i.value.toString() === orgID.toString()) {
                        organisationName = i.label;
                        break;
                    }
                }
            }
            else {
                organisationName = this.context.organisation.name;
            }
        }

        console.log(this.state.item);


        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} showProgress={false} title="Updating" />
                <ModalProgress show={this.state.isLoading} showProgress={false} title="Loading"/>
                <div className="modal-head">
                    <h3>{this.state.item.id ? "Edit Call-to-Action" : "New Call-to-Action"}</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="createdAt" className="text-right middle">Created At</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <input name="createdAt" type="text" value={moment(DateHelper.convertToLocalDateTime(this.state.item.createdAt), "YYYY-MM-DD HH:mm:ss").format("LLLL")} disabled={true} />
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="active" className="text-right middle">Published</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="active" onChange={this.handleChange} checked={this.state.item.active || 0 === 1} />
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.context.permissions.post.allOrgs &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="organisationID" className="text-right middle">* Organisation</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.organisationID && "error")}>
                                <ReactSelect
                                    name="organisationID"
                                    className="react-select"
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                    value={this.state.item.organisationID}
                                    options={this.state.organisationOptions}
                                    isDisabled={!!this.state.item.id}
                                />
                                <small className="error">{this.state.errors.organisationID}</small>
                            </div>
                        </div>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="startDate" className="text-right middle">* Start Date</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.startDate && "error")}>
                                <DatePicker
                                    name="startDate"
                                    data-enable-time
                                    value={DateHelper.convertToLocalDateTime(this.state.item.startDate)}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                    options={{
                                        dateFormat: "Y-m-d H:i:S",
                                        altFormat: "l, F j, Y h:i K",
                                        altInput: true,
                                        static: true,
                                        minuteIncrement: 1
                                    }}
                                />
                                <small className="error">{this.state.errors.startDate}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="endDate" className="text-right middle">* End Date</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.endDate && "error")}>
                                <DatePicker
                                    name="endDate"
                                    data-enable-time
                                    value={DateHelper.convertToLocalDateTime(this.state.item.endDate)}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                    options={{
                                        dateFormat: "Y-m-d H:i:S",
                                        altFormat: "l, F j, Y h:i K",
                                        altInput: true,
                                        static: true,
                                        minuteIncrement: 1
                                    }}
                                />
                                <small className="error">{this.state.errors.endDate}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="title" className="text-right middle ">* Title</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.title && "error")}>
                                <input type="text" name="title" onChange={this.handleChange} value={this.state.item.title} onBlur={this.handleBlur} />
                                <small className="field-info">{this.state.titleCharLeft} characters left</small>
                                <small className="error">{this.state.errors.title}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="subtitle" className="text-right middle">* Subtitle</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.subtitle && "error")}>
                                <textarea rows="6" type="text" name="subtitle" onChange={this.handleChange} value={this.state.item.subtitle} onBlur={this.handleBlur} />
                                <small className="field-info">{this.state.subtitleCharLeft} characters left</small>
                                <small className="error">{this.state.errors.subtitle}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="nonRegistered" className="text-right middle">Non-registered users only</label>
                            </div>
                            <div className="large-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="nonRegistered" onChange={this.handleChange} checked={this.state.item.nonRegistered || 0 === 1} />
                                    <small className="field-info" style={{marginTop: 0}}>If selected, Call-to-action will only be shown to users who don't have matching UniWellBeing account</small>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="serviceID" className="text-right middle">* Service</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.serviceID && "error")}>
                                <div className="input-group">
                                    <span className="input-group-label" style={{fontSize: 10}}>{this.state.item.serviceTypeName}</span>
                                    <input className="input-group-field" name="serviceID" type="text" value={this.state.item.serviceTitle || "Not selected" } disabled/>
                                    <div className="input-group-button">
                                        <button type="button" className="button" onClick={() => this.showServiceModal(false)}>Browse</button>
                                    </div>
                                </div>
                                <small className="error">{this.state.errors.serviceID}</small>
                            </div>
                        </div>
                        {this.state.item.serviceID &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="useDefaultImage" className="text-right middle">Use default image</label>
                                </div>
                                <div className="large-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="useDefaultImage" onChange={this.handleChange} checked={this.state.useDefaultImage} />
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            {this.state.useDefaultImage ?
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="imageFile" className="text-right middle">Default Image</label>
                                    </div>
                                    <div className="large-9 small-9 cell" style={{marginBottom: 20}}>
                                        <Image src={this.state.item.defaultImageURL} width={200} height={120} border={true} />
                                    </div>
                                </div>
                                :
                                <React.Fragment>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="imageFile" className="text-right middle">Select Image</label>
                                        </div>
                                        <div className="large-9 small-9 cell ">
                                            <FileInput
                                                name="imageFile"
                                                onChange={this.handleFilePickerChange}
                                                onBlur={this.handleBlur}
                                                onDelete={this.handleFileDelete}
                                                placeholder="Not selected."
                                                accept="image/jpeg,image/jpg,image/png"
                                            />
                                        </div>
                                    </div>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="imageFile" className="text-right middle">* Current Image</label>
                                        </div>
                                        <div className={"large-9 small-9 cell " + (this.state.errors.imageFile && "error")} style={{marginBottom: 20}}>
                                            {this.state.imagePickerURL &&
                                            <Image src={this.state.imagePickerURL} width={200} height={120} border={true}/>
                                            }
                                            {!this.state.imagePickerURL && this.state.item.imageURL &&
                                            <Image src={this.state.item.imageURL} width={200} height={120} border={true}/>
                                            }
                                            {!this.state.imagePickerURL && !this.state.item.imageURL &&
                                            <p>No image selected</p>
                                            }
                                            <small className="error">{this.state.errors.imageFile}</small>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset">
                                    <legend>Visibility</legend>
                                    <BlockUI tag="div" blocking={this.state.isLoadingCampuses || this.state.isLoadingRoles || this.state.isLoadingStudyYears || this.state.isLoadingCourses || this.state.isLoadingStudentCategories || this.state.isLoadingDegreeTypes}>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="roles" className="text-right middle">User role</label>
                                            </div>
                                            <div className="large-9 small-9 cell">
                                                <ReactSelect
                                                    name="roles"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.roles}
                                                    options={this.state.roleOptions}
                                                    isMulti
                                                    placeholder="All"
                                                    className="react-select"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="campuses" className="text-right middle">Campus</label>
                                            </div>
                                            <div className="large-9 small-9 cell">
                                                <ReactSelect
                                                    name="campuses"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.campuses}
                                                    options={this.state.campusOptions}
                                                    isMulti={true}
                                                    placeholder="All"
                                                    className="react-select"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="studyYears" className="text-right middle">Study Year</label>
                                            </div>
                                            <div className="large-9 small-9 cell">
                                                <ReactSelect
                                                    name="studyYears"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.studyYears}
                                                    options={this.state.studyYearOptions}
                                                    isMulti={true}
                                                    placeholder="All"
                                                    className="react-select"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="courses" className="text-right middle">School/Department</label>
                                            </div>
                                            <div className="large-9 small-9 cell">
                                                <ReactSelect
                                                    name="courses"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.courses}
                                                    options={this.state.courseOptions}
                                                    isMulti={true}
                                                    placeholder="All"
                                                    className="react-select"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="departments" className="text-right middle">Staff Department</label>
                                            </div>
                                            <div className="large-9 small-9 cell">
                                                <ReactSelect
                                                    name="departments"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.departments}
                                                    options={this.state.departmentOptions}
                                                    isMulti={true}
                                                    placeholder="All"
                                                    className="react-select"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="studentCategories" className="text-right middle">Student Category</label>
                                            </div>
                                            <div className="large-9 small-9 cell">
                                                <ReactSelect
                                                    name="studentCategories"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.studentCategories}
                                                    options={this.state.studentCategoryOptions}
                                                    isMulti={true}
                                                    placeholder="All"
                                                    className="react-select"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="degreeTypes" className="text-right middle">Degree Type</label>
                                            </div>
                                            <div className="large-9 small-9 cell">
                                                <ReactSelect
                                                    name="degreeTypes"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.degreeTypes}
                                                    options={this.state.degreeTypeOptions}
                                                    isMulti={true}
                                                    placeholder="All"
                                                    className="react-select"
                                                />
                                            </div>
                                        </div>
                                    </BlockUI>
                                </fieldset>
                            </div>
                        </div>
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="CTAInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}

export default ActionForm;
