import parseInt from 'parse-int'

const DatatableHelper = {
    prepareRequestParams: function(defaultParams, tableData = null) {
        if (tableData !== null) {
            defaultParams.sorted = tableData.sorted;
            defaultParams.page = tableData.page;
            defaultParams.pageSize = tableData.pageSize;
        }
        return defaultParams;
    },
    getTotalPages: function(res) {
        let recordsTotal = parseInt(res.recordsTotal || 0);
        let pageSize = parseInt(res.tableOptions.pageSize || 1);
        if (recordsTotal && pageSize) {
            return Math.ceil(recordsTotal / pageSize);
        }
        else {
            return 0
        }
    }
}

export default DatatableHelper;