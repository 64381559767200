import React from 'react';

class SearchBar extends React.Component {

    constructor(props) {
        super(props);
        this.searchTextDidChange = this.searchTextDidChange.bind(this);
    }

    searchTextDidChange(event) {
        this.props.onDidChange(event.target.value);
    }

    render() {
        return (
            <input className="search-bar" type="text" onChange={this.searchTextDidChange} />
        );
    }
}

export default SearchBar;