import React from 'react'
import PageContent from 'components/common/PageContent'
import DegreeTypesTable from 'components/organisation/degreeTypes/DegreeTypesTable'

export default function DegreeTypes(props) {

    const pathElements = [
        {id: 0, title: "Home", url: "/"},
        {id: 1, title: "Organisation", url: "/organisation"},
        {id: 2, title: "Degree Types"}
    ];

    return (
        <PageContent pathElements={pathElements}>
            <DegreeTypesTable/>
        </PageContent>
    )
}