import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import SearchBar from "../../common/SearchBar";
import ReactTable from "react-table";
import TableContainer from "../../common/TableContainer";
import Axios from "axios";
import {API} from "../../../api/APIClient";

export const SSOTypeSelector = (props) => {

    const {showSelectButton} = props;
    const {onSelected} = props;
    const {onCancel} = props;

    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        fetchSSOTypesFromNetwork();
    }, []);

    useEffect(() => {
        fetchSSOTypesFromNetwork();
    }, [keyword]);

    function handleSelected(row) {
        if (onSelected) {
            onSelected(row);
        }
    }

    function searchTextDidChange(text) {
        setKeyword(text);
    }

    function fetchSSOTypesFromNetwork() {
        if (isLoading) return;
        setIsLoading(true);

        const formData = new FormData();
        formData.append("page", page);
        formData.append("pageSize", pageSize);
        formData.append("keyword", keyword);

        Axios.post(API.auth.getSSOTypes, keyword)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.ssoTypes) {
                        setItems(resp.data.ssoTypes);
                    }
                } else {
                    // TODO Error
                    console.log(API.formatError(r));
                }
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    }

    // RENDER

    const columns = [
        {
            Header: 'Icon',
            accessor: 'iconUrl',
            width: 80,
            maxWidth: 80,
            style : {textAlign: 'center'},
            Cell : (row) => {
                return (
                    <img
                        src={row.original.iconUrl}
                        style={{width: "40px", height: "40px"}}
                        alt={row.original.name}
                    />
                );
            }
        },
        {
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Actions',
            Cell: row => (
                <ButtonGroup size={Sizes.TINY}>

                    {showSelectButton === true &&
                        <Button color={Colors.PRIMARY} onClick={() => handleSelected(row.original)}>Select</Button>}

                </ButtonGroup>
            ),
            maxWidth: 130,
            show: showSelectButton
        }
    ]

    const filters = (
        <React.Fragment>
            <div className="cell small-12 medium-6 large-3">
                <span>Search</span>
                <SearchBar
                    onDidChange={(newValue) => searchTextDidChange(newValue)}
                />
            </div>
        </React.Fragment>

    )

    const table = (
        <ReactTable
            columns={columns}
            data={items}
            pageSize={pageSize}
            loading={isLoading}
            multisort={true}
            className='-striped'
        />
    )

    return (
        <React.Fragment>
            <div className="modal-head">
                <h3>Single Sign On</h3>
            </div>
            <div className="modal-body">
                <React.Fragment>
                    <TableContainer
                        title="Available SSO Integrations"
                        filters={filters}
                        table={table}
                    />
                </React.Fragment>
            </div>
            <div className="modal-footer">
                <div className="grid-x grid-padding-x">
                    <div className="small-12 cell">
                        <div className="btn-wrap">
                            <button className="button secondary" type="button" onClick={onCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}