import React from 'react'
import Utils from 'utils/Utils'
import ReactSelect from 'components/common/ReactSelect'
import BlockUI from 'react-block-ui'
import ServicesSelector from 'components/services/ServicesSelector'
import Modal from "react-responsive-modal";


export default class TextFieldEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            actionTypeOptions: [
                {value: "service", label: "Service"},
                {value: "website", label: "Website"},
                {value: "email", label: "Email"},
                {value: "phone", label: "Phone Call"}
            ],
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            }
        }
    }

    handleChange = (event) => {

        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = Object.assign({}, this.props.item);

        if (name === "margin-left" || name === "margin-right" || name === "margin-top" || name === "margin-bottom") {
            let type = name.replace("margin-", "");
            let style = Object.assign({}, this.props.item.style);
            let margins = Object.assign({}, style.margins);

            if (value !== null && value.toString().length > 0) {
                margins[type] = parseInt(value);
            }
            else {
                delete margins[type];
            }

            if (Object.keys(margins).length > 0) {
                style.margins = margins;
            }
            else {
                delete style.margins;
            }

            if (Object.keys(style).length > 0) {
                item.style = style;
            }
            else {
                delete item.style;
            }
        }

        if (this.props.onChange instanceof Function) {
            this.props.onChange(item);
        }
    }

    handleDelete = () => {
        if (this.props.onDelete instanceof Function) {
            this.props.onDelete(this.props.item);
        }
    }


    render() {

        let leftMargin = Utils.getNested(this.props.item, "style", "margins", "left");
        let rightMargin = Utils.getNested(this.props.item, "style", "margins", "right");
        let topMargin = Utils.getNested(this.props.item, "style", "margins", "top");
        let bottomMargin = Utils.getNested(this.props.item, "style", "margins", "bottom");


        return (
            <div className="grid-x grid-padding-x">
                <div className="small-9 cell">
                    <h3>Text Field</h3>
                </div>
                <div className="cell auto text-right">
                    <button className="button alert small" onClick={this.handleDelete}>Delete</button>
                </div>
                <div className="cell small-12 medium-12 large-8">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <fieldset className="fieldset">
                                <legend>Properties</legend>
                                No additional properties
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div className="cell small-12 medium-12 large-4">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell small-setts-box">
                            <fieldset className="fieldset">
                                <legend>Margins</legend>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-6 cell">
                                        <span>Left</span>
                                        <input name="margin-left" type="number" placeholder="inherited" min={0} value={leftMargin} onChange={this.handleChange} />
                                    </div>
                                    <div className="small-6 cell">
                                        <span>Top</span>
                                        <input name="margin-top" type="number" placeholder="inherited" min={0} value={topMargin} onChange={this.handleChange} />
                                    </div>
                                    <div className="small-6 cell">
                                        <span>Right</span>
                                        <input name="margin-right" type="number" placeholder="inherited" min={0} value={rightMargin} onChange={this.handleChange} />
                                    </div>
                                    <div className="small-6 cell">
                                        <span>Bottom</span>
                                        <input name="margin-bottom" type="number" placeholder="inherited" min={0} value={bottomMargin} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}