import React from "react"
import { DateRangePicker} from 'react-dates'
import moment from 'moment'
import {Button, Sizes, ButtonGroup, Colors} from "react-foundation";


export default class ReportDateRange extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            startDate: props.startDate || moment().local(),
            endDate: props.endDate || moment().local(),
            focusedInput: false
        }
    }


    onDatesDidChange = (start, end) => {
        this.setState({
            startDate: start,
            endDate: end,
            focusedInput: false
        }, () => {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(start, end)
            }
        })
    }

    renderDatePresets = () => {
        return (
            <ButtonGroup style={{padding: 20}}>
                {this.props.datePresets.map(({text, start, end}) =>
                    {
                        const isSelected = this.state.startDate.isSame(start) && this.state.endDate.isSame(end);
                        return (
                            <Button size={Sizes.SMALL} color={Colors.PRIMARY} isHollow={!isSelected} onClick={() => this.onDatesDidChange(start, end)}>
                                {text}
                            </Button>
                        )
                    }
                )}
            </ButtonGroup>
        )
    }

    render() {



        return (
            <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId={"picker-startDate"}
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId={"picker-endDate"}
                onDatesChange={({ startDate, endDate }) => this.onDatesDidChange(startDate, endDate)} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                initialVisibleMonth={() => moment().add(-1, "M")} // PropTypes.func or null,
                renderCalendarInfo={this.renderDatePresets}
                displayFormat={() => "YYYY/MM/DD"}
                isOutsideRange={() => false}
            />
        );
    }
}