import React from 'react';


class TableContainer extends React.Component {

    static defaultProps = {
        table: null,
        filters: null,
        buttons: null,
        title: null
    }

    constructor(props) {
        super(props);

        this.state = {
            filtersAreOpen: false,
            filtersVisible: props.filters !== null
        }
    }

    render() {

        return (

        <React.Fragment>
            <div className="grid-x grid-padding-x main-table-head">
                <div className="cell small-12 medium-6">
                    <h3>{this.props.title}</h3>
                </div>

                <div className="cell small-12 medium-6">
                    <div className="btn-wrap">
                        {this.props.buttons}
                        {this.state.filtersVisible &&
                        <button type="button" className={"button small filters-btn " + (this.state.filtersAreOpen ? "open" : "")} onClick={() => this.setState({filtersAreOpen: !this.state.filtersAreOpen}) }>Filters</button> }
                    </div>
                </div>
            </div>
            <div className="grid-x grid-padding-x">
                <div className="cell small-12 section-wrapper table-wrap">
                    {this.state.filtersVisible &&
                    <div className={"grid-x grid-margin-x filter-box " + (this.state.filtersAreOpen ? "show" : "")}>
                        <div className="cell small-12">
                            <h3>Filters</h3>
                            <div className="grid-x grid-padding-x">
                                {this.props.filters}
                            </div>
                        </div>
                    </div>}
                    {this.props.table}
                </div>
            </div>
        </React.Fragment>

        );
    }

}

export default TableContainer;