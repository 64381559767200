import {ContextUtil} from "./ContextUtil";

/**
 * Convenience class for checking permissions on the current session
 * @type {{view: (function(*): *|boolean), edit: (function(*): *|boolean), create: (function(*): *|boolean), check: ((function(*, *): (*|boolean))|*), delete: (function(*): *|boolean), allOrgs: (function(*): *|boolean)}}
 */
export const PermissionUtil = {
    PermissionCategories : {
        apiKeys : "apiKeys",
        knowledgeDocuments : "knowledgeDocuments",
        conversationTypes : "conversationTypes",
        promptTemplates : "promptTemplates",
        aiAccessKeys : "aiAccessKeys"
    },
    /**
     * Checks the current state of a given permission and type
     * @param category      The category of permission to check (i.e. habits)
     * @param type          The type of the permission to check (i.e. view, allOrgs, etc)
     * @returns {*|boolean} The state of the permission. If a permission is not defined, `false` will be returned.
     */
    check : (category, type) => {
        const permissions = ContextUtil.getPermissions();
        if (permissions) {
            if (permissions.hasOwnProperty(category)) {
                if (permissions[category].hasOwnProperty(type)) {
                    return permissions[category][type];
                }
            }
        }
        return false;
    },
    /** Checks 'view' permission for provided category **/
    view : (category) => {
        return PermissionUtil.check(category, "view");
    },
    /** Checks 'allOrgs' permission for provided category **/
    allOrgs : (category) => {
        return PermissionUtil.check(category, "allOrgs");
    },
    /** Checks 'create' permission for provided category **/
    create : (category) => {
        return PermissionUtil.check(category, "create");
    },
    /** Checks 'edit' permission for provided category **/
    edit : (category) => {
        return PermissionUtil.check(category, "edit");
    },
    /** Checks 'delete' permission for provided category **/
    delete : (category) => {
        return PermissionUtil.check(category, "delete");
    },
    get : (category) => {
        const permissions = ContextUtil.getPermissions();
        if (permissions) {
            if (permissions.hasOwnProperty(category)) {
                return permissions[category];
            }
        }
        return null;
    }
}