import React from 'react'
import Axios from 'axios'
import ReactSelect from 'components/common/ReactSelect'
import { API } from 'api/APIClient'
import validator from 'validator'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'

class AdminUserForm extends AppComponent {

    static defaultProps = {
        item: {}
    }

    constructor(props, context) {
        super(props);

        this.state = {
            item: Object.assign({
                organisationID: props.item.organisationID || context.organisation.id,
                active: props.item.active || 1
            }, props.item),
            organisationOptions: [
                props.item.organisationID ? {value: props.item.organisationID, label: props.item.organisationName} : {value: context.organisation.id, label: context.organisation.name}
            ],
            validated: false,
            errors: {},
            touched: {},
            roleOptions: [],
            isUploading: false
        };
    }

    componentDidMount() {
        this.validate();
        this.getRoleOptions();
        if (this.context.permissions.adminUser.allOrgs) {
            this.getOrganisationOptions();
        }
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {
                Axios
                    .post((this.state.item.id ? API.adminUser.updateUser : API.adminUser.createUser), this.state.item)
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted(res.data.data.item);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }

                        this.setState({
                            isUploading: false
                        });
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = this.state.item;
        item[name] = value;

        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    getRoleOptions = () => {
        Axios
            .get(API.adminUser.getRoleOptions)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        roleOptions: res.data.data.roleOptions.map((role) => { return {value: role.id.toString(), label: role.name} })
                    })
                }
            });
    }

    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.firstName) {
            if (touched.firstName) {
                errors.firstName = "First name is required";
            }
            validated = false;
        }

        if (!item.lastName) {
            if (touched.lastName) {
                errors.lastName = "Last name is required";
            }
            validated = false;
        }

        if (!item.email) {
            if (touched.email) {
                errors.email = "Email is required";
            }
            validated = false;
        }
        else {
            if (!validator.isEmail(item.email)) {
                if (touched.email) {
                    errors.email = "Invalid email address"
                }
                validated = false;
            }
        }


        if (item.roleID < 0) {
            if (touched.roleID) {
                errors.roleID = "Role type is required";
            }
            validated = false;
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />
                <div className="modal-head">
                    <h3>{this.state.item.id ? "Edit Admin User" : "New Admin User"}</h3>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="active" className="text-right middle">Active</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.active && "error")}>
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="active" onChange={this.handleChange} checked={this.state.item.active === 1} onBlur={this.handleBlur} />
                                    <span className="chkmk"></span>
                                    <small className="error">{this.state.errors.active}</small>
                                </label>
                            </div>
                        </div>
                        {this.context.permissions.adminUser.allOrgs &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="organisationID" className="text-right middle">* Organisation</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.organisationID && "error")}>
                                <ReactSelect
                                    name="organisationID"
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.item.organisationID}
                                    options={this.state.organisationOptions}
                                    onBlur={this.handleBlur}
                                />
                                <small className="error">{this.state.errors.organisationID}</small>
                            </div>
                        </div>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="firstName" className="text-right middle ">* First name</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.firstName && "error")}>
                                <input type="text" name="firstName" onChange={this.handleChange} value={this.state.item.firstName} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.firstName}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="lastName" className="text-right middle">* Last name</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.lastName && "error")}>
                                <input type="text" name="lastName" onChange={this.handleChange} value={this.state.item.lastName} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.lastName}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="email" className="text-right middle">* Email</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.email && "error")}>
                                <input type="email" name="email" onChange={this.handleChange} value={this.state.item.email} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.email}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="roleID" className="text-right middle">* Role</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.roleID && "error")}>
                                <ReactSelect
                                    name="roleID"
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.item.roleID}
                                    options={this.state.roleOptions}
                                    onBlur={this.handleBlur}
                                />
                                <small className="error">{this.state.errors.roleID}</small>
                            </div>
                        </div>
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default AdminUserForm;
