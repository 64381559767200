import React from 'react'
import { ChatItemType } from 'constants/ChatItemType'
import Modal from "react-responsive-modal";
import ServicesSelector from "../services/ServicesSelector";
import Utils from 'utils/Utils'
import UUID from "../../utils/UUID";
import ChatVariablesSelector from "./ChatVariablesSelector";
import ChatCustomVariablesSelector from "./ChatCustomVariablesSelector";

export default class ChatServiceForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: Object.assign({
                service: null,
                dynamicServiceTitle: '',
                nodeText: null,
                typeText: "Service Link",
                useCustomDisplayTime: null,
                displayTime: 0,
                useCustomTypingTime: null,
                typingTime: 0,
                typeID: ChatItemType.SERVICE,
                dynamicService: 0,
                uuid: UUID.uuidv4()
            }, props.node),
            validated: false,
            errors: {},
            touched: {},
            modal: {
                isOpen: false,
                content: null
            }
        };
    }

    componentDidMount() {
        this.validate();
    }


    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        let item = Object.assign({}, this.state.item);
        item[name] = value;

        if (name === "useCustomDisplayTime") {
            item.displayTime = 0;
        }

        if (name === "useCustomTypingTime") {
            item.typingTime = 0;
        }

        if (name === "dynamicServiceTitle") {
            item.nodeText = "Find service by title: \"" + value + "\"";
        }

        this.setState({
            item: item
        }, () => {
            this.validate();
        });
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.onSubmitted instanceof Function) {
            this.props.onSubmitted(this.state.item)
        }
    }


    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (item.dynamicService === 1) {
            if (item.dynamicServiceTitle.length === 0) {
                validated = false;
            }
        }
        else {
            if (!item.service) {
                validated = false;
            }
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    showServiceModal = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.item.service) {
            selected.push({id: this.state.item.service.id})
        }

        const content = (
            <ServicesSelector
                isSingleSelection
                selected={selected}
                serviceTypes={this.props.serviceTypes}
                onSubmitted={(service) => {

                    let item = Object.assign({}, this.state.item);
                    item.service = service;
                    item.nodeText = service.typeName + " - " + service.title;

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })
    }

    showVariablesSelector = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatVariablesSelector
                showCopyButton={true}
                showSelectButton={false}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={() => this.closeModal()}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    showCustomVariablesSelectorForText = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatCustomVariablesSelector
                showCopyButton={true}
                showSelectButton={false}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={() => this.closeModal()}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Chat service link</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="dynamicService" className="text-right middle">Select service by title</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="dynamicService" onChange={this.handleChange}
                                           checked={this.state.item.useCustomDisplayTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.dynamicService === 1 &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="dynamicServiceTitle" className="text-right middle">Service Title</label>
                                    </div>
                                    <div className={"large-9 small-9 cell " + (this.state.errors.dynamicServiceTitle && "error")}>
                                        <input name="dynamicServiceTitle" type="text" value={this.state.item.dynamicServiceTitle} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                        <span className="error">{this.state.errors.dynamicServiceTitle}</span>
                                        <small className="field-info">The system will try to match the service by the provided title. System and custom properties can be used. User's organisation services are searched first, if not found, system services are queried. If Service cannot be matched, this element will be ignored and chat flow moves to another element from the list.</small>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="text" className="text-right middle">System properties</label>
                                    </div>
                                    <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                        <button className="button primary" type="button"
                                                onClick={this.showVariablesSelector}>Show properties
                                        </button>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="text" className="text-right middle">Custom properties</label>
                                    </div>
                                    <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                        <button className="button primary" type="button"
                                                onClick={this.showCustomVariablesSelectorForText}>Show properties
                                        </button>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        {this.state.item.dynamicService === 0 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="serviceID" className="text-right middle">* Service</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.serviceID && "error")}>
                                    <div className="input-group">
                                        <span className="input-group-label" style={{fontSize: 10}}>{Utils.value(Utils.getNested(this.state.item, "service", "typeName"))}</span>
                                        <input className="input-group-field" name="service" type="text" value={Utils.value(Utils.getNested(this.state.item, "service", "title"), "Not Selected")} disabled/>
                                        <div className="input-group-button">
                                            <button type="button" className="button" onClick={() => this.showServiceModal(false)}>Browse</button>
                                        </div>
                                    </div>
                                    <small className="error">{this.state.errors.service}</small>
                                </div>
                            </div>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="useCustomDisplayTime" className="text-right middle">Custom display time</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="useCustomDisplayTime" onChange={this.handleChange}
                                           checked={this.state.item.useCustomDisplayTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.useCustomDisplayTime === 1 &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="displayTime" className="text-right middle">* Display Time</label>
                                    </div>
                                    <div className={"large-9 small-9 cell " + (this.state.errors.displayTime && "error")}>
                                        <input name="displayTime" type="number" value={this.state.item.displayTime} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                        <span className="error">{this.state.errors.displayTime}</span>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="useCustomTypingTime" className="text-right middle">Custom typing time</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="useCustomTypingTime" onChange={this.handleChange}
                                           checked={this.state.item.useCustomTypingTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.useCustomTypingTime === 1 &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="typing" className="text-right middle">* Typing Time</label>
                                    </div>
                                    <div className={"large-9 small-9 cell " + (this.state.errors.typingTime && "error")}>
                                        <input name="typingTime" type="number" value={this.state.item.typingTime} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                        <span className="error">{this.state.errors.typingTime}</span>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}
