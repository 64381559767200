import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "react-responsive-modal";
import ChatFlowsSelector from "../chatflows/ChatFlowsSelector";
import {PluginChatFlowListSelector} from "./PluginChatFlowListSelector";

export const PluginChatFlowListBuilder = (props) => {

    const MODAL_MODE_CHAT_FLOW = 1;
    const MODAL_MODE_CHAT_FLOW_LIST = 2;

    const {data} = props;
    const {callback} = props;

    const [selectedChatFlows, setSelectedChatFlows] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState(-1);

    useEffect(() => {
        if (data) {
            let useData = data;
            if (!Array.isArray(data)) {
                useData = JSON.parse(data);
            }
            setSelectedChatFlows(useData);
        } else {
            setSelectedChatFlows([]);
        }
    }, [data]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function summonChatFlowSelector(e) {
        e.preventDefault();
        e.stopPropagation();

        setModalMode(MODAL_MODE_CHAT_FLOW);
        setModalOpen(true);
    }

    function summonChatFlowListSelector(e) {
        e.preventDefault();
        e.stopPropagation();

        setModalMode(MODAL_MODE_CHAT_FLOW_LIST);
        setModalOpen(true);
    }

    function pluginModalDidDismiss() {
        setModalOpen(false);
    }

    function addChatFlow(chatFlow) {
        console.log(chatFlow);
        const chatFlows = [...selectedChatFlows];
        if (!Array.isArray(chatFlow)) {
            chatFlow = [chatFlow];
        }
        chatFlow.forEach((flow) => {
            chatFlows.push(flow);
        });
        setSelectedChatFlows(chatFlows);
    }

    function removeChatFlow(chatFlow) {
        const chatFlows = [...selectedChatFlows];
        for (let i = selectedChatFlows.length - 1; i >= 0; i--) {
            if (chatFlows[i] === chatFlow) {
                chatFlows.splice(i, 1);
            }
        }
        setSelectedChatFlows(chatFlows);
    }

    function clearChatFlows(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setSelectedChatFlows([]);
    }

    // RENDER

    let modalContent = [];
    if (modalMode === MODAL_MODE_CHAT_FLOW) {
        modalContent = (
            <ChatFlowsSelector
                onCancel={() => setModalOpen(false)}
                pluginCompatible={1}
                // selected={modalMetadata.current}
                onSubmitted={(chatFlow) => {
                    if (chatFlow) {
                        addChatFlow(chatFlow);
                    }
                    setModalOpen(false);
                }}
                onClose={() => {
                    setModalOpen(false);
                }}/>
        )
    } else if (modalMode === MODAL_MODE_CHAT_FLOW_LIST) {
        modalContent = (
            <PluginChatFlowListSelector
                callback={(action, data) => {
                    if (action === "select") {
                        if (data.hasOwnProperty("data")) {
                            const dataParse = JSON.parse(data.data);
                            if (dataParse) {
                                addChatFlow(dataParse);
                            } else {
                                console.log("PARSE ERROR!")
                            }
                        } else {
                            console.log("ROW LACKS DATA");
                        }

                        pluginModalDidDismiss();
                    }
                }} />
        )
    }

    let chatFlowItemElems = [];
    if (selectedChatFlows.length > 0) {
        chatFlowItemElems = selectedChatFlows.map((chatFlow) => {
            let subtitleElem = [];
            if (parseInt(chatFlow.pluginCompatible) === 1) {
                subtitleElem = (
                    <div className={"list-builder-item-content-subtitle"}>
                        <em>Plugin Compatible</em>
                    </div>
                );
            }

            return (
                <div className={"list-builder-item clickable"}>
                    <div className={"list-builder-item-content"}>
                        <div className={"list-builder-item-content-title"}>
                            {chatFlow.title}
                        </div>
                        {subtitleElem}
                    </div>

                    <div className={"list-builder-item-icon clickable danger"}>
                        <FontAwesomeIcon icon={"trash"} onClick={() => removeChatFlow(chatFlow)} />
                    </div>
                </div>
            )
        })
    } else {
        chatFlowItemElems.push(
            <div className={"empty-message"}>No Chat Flows Assigned</div>
        )
    }

    return (
        <>
            <div className={"modal-head"}>
                <div className={"modal-head"}>
                    <h3>Chat Flows</h3>
                </div>
            </div>

            <div className={"modal-body"}>
                <div className="grid-x grid-padding-x">
                    <div className="small-12 cell">
                        <fieldset className="fieldset">
                            <legend>Chat Flows</legend>

                            <div className="grid-x grid-padding-x">
                                <div className="small-9 cell">
                                    <div className={"list-builder"}>
                                        {chatFlowItemElems}
                                    </div>
                                </div>

                                <div className={"small-3 cell"}>
                                    <div>
                                        <button className={"button"} onClick={summonChatFlowListSelector}>
                                            Add From List
                                        </button>
                                    </div>

                                    <div>
                                        <button className={"button"} onClick={summonChatFlowSelector}>
                                            Add Chat Flow
                                        </button>
                                    </div>

                                    <div>
                                        <button className={"button alert"} onClick={clearChatFlows}>
                                            Remove all
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>

            <div className="modal-footer">
                <div className="grid-x grid-padding-x">
                <div className="small-12 cell">
                        <div className="btn-wrap">
                            {
                                <React.Fragment>
                                    <button
                                        className="button secondary"
                                        type="button"
                                        onClick={() => handleCallback("cancel")}
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        className="button success"
                                        type="submit"
                                        onClick={() => handleCallback("submit", selectedChatFlows)}
                                    >
                                        Submit
                                    </button>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                classNames={{modal: "modal-medium"}}
                open={modalOpen}
                modalId="PluginModal"
                onClose={pluginModalDidDismiss}
                center
            >
                {modalContent}
            </Modal>
        </>
    )

}