import React from 'react'
import logoImage from 'assets/img/uniwellbeing-logo.png'
import Image from 'components/common/Image'
import Axios from 'axios'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import {Link, Redirect} from 'react-router-dom'
import BlockUi from "react-block-ui"
import queryString from 'querystring'

export default class ResetPassword extends AppComponent {

    constructor(props) {
        super(props);

        this.state = {
            item: {
                password: "",
                passwordRepeat: ""
            },
            validated: false,
            errors: {},
            touched: {},
            isUploading: false,
            submitted: false,
            policy: null
        };
    }

    componentDidMount() {
        this.getPasswordPolicy();
        this.validate();
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {

                let params = queryString.parse(this.props.location.search.substr(1));

                Axios
                    .post((API.auth.resetPassword), {
                        email: params.email || "",
                        token: params.token || "",
                        password: this.state.item.password
                    })
                    .then((res) => {
                        if (res.data.success === true) {
                            this.setState({
                                submitted: true,
                                isUploading: false
                            })
                        } else {
                            let errors = Object.assign(this.state.errors, {});
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors,
                                isUploading: false
                            });
                        }
                    })
            })
        }
    }

    getPasswordPolicy = () => {
        Axios
            .get(API.auth.getPasswordPolicy)
            .then((res) => {
                if (res.data.success === true) {
                    this.setState({
                        policy: res.data.data.policy
                    })
                }
            })
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = this.state.item;
        item[name] = value;

        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.password) {
            if (touched.password) {
                errors.email = "Please enter new password"
            }
            validated = false;
        }

        if (!item.passwordRepeat) {
            if (touched.passwordRepeat) {
                errors.passwordRepeat = "Please repeat your new password"
            }
            validated = false;
        }

        if (validated && item.password !== item.passwordRepeat) {
            errors.passwordRepeat = "Passwords do not match";
            validated = false;
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    render() {

        if (this.context.user !== null) return <Redirect to="/"/>;

        return (
            <BlockUi tag="div" blocking={this.state.isUploading}>
                {this.state.submitted ?
                    <div className="login-wrapper">
                        <div className="login-box">
                            <div className="grid-container">
                                <div className="grid-x grid-padding-x">
                                    <div
                                        className="cell large-2 large-offset-5 medium-6 medium-offset-3 small-10 small-offset-1">
                                        <Image src={logoImage} alt="Uniwellbeing Logo" fit="contain"/>
                                    </div>
                                    <div className="cell large-4 large-offset-4 medium-8 medium-offset-2 small-12">
                                        <h1>Password reset</h1>
                                        <p>Your password has been reset successfully.<br/>You can continue to the login screen now.</p>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="cell large-4 large-offset-4 medium-8 medium-offset-2 small-12">
                                        <Link to="/login" className="button">Back to login screen</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="login-wrapper">
                        <div className="login-box">
                            <div className="grid-container">
                                <div className="grid-x grid-padding-x">
                                    <div className="cell large-2 large-offset-5 medium-6 medium-offset-3 small-10 small-offset-1">
                                        <Image src={logoImage} alt="Uniwellbeing Logo" fit="contain"/>
                                    </div>
                                    <div className="cell large-4 large-offset-4 medium-8 medium-offset-2 small-12">
                                        <h1>Password reset</h1>
                                        {this.state.policy &&
                                            <ul style={{fontSize: "0.8em"}}>
                                                {this.state.policy.map((item) => {
                                                    return <li>{item}</li>
                                                })}
                                            </ul>
                                        }
                                    </div>
                                </div>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="grid-x grid-padding-x">
                                        <div className={"cell large-4 large-offset-4 medium-8 medium-offset-2 small-12 " + (this.state.errors.password && "error")}>
                                            <label htmlFor="password">New Password
                                                <input type="password" name="password" placeholder="Password" onChange={this.handleChange} onBlur={this.handleBlur} />
                                                <small className="error">{this.state.errors.password}</small>
                                            </label>
                                        </div>
                                        <div className={"cell large-4 large-offset-4 medium-8 medium-offset-2 small-12 " + (this.state.errors.passwordRepeat && "error")}>
                                            <label htmlFor="passwordRepeat">New Password Repeat
                                                <input type="password" name="passwordRepeat" placeholder="Password Repeat" onChange={this.handleChange} onBlur={this.handleBlur} />
                                                <small className="error">{this.state.errors.passwordRepeat}</small>
                                            </label>
                                        </div>
                                        <div className="cell small-12">
                                            <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                                                <div className="cell small-12">
                                                    <span>{this.state.errors.genericError}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cell large-4 large-offset-4 medium-8 medium-offset-2 small-12">
                                            <button className="button" type="submit" value="Submit" disabled={this.state.validated !== true}> Reset Password</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                }
            </BlockUi>
        );
    }

}