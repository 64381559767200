import React from 'react'
import PageContent from 'components/common/PageContent'
import CoursesTable from 'components/organisation/courses/CoursesTable'

export default function Courses(props) {

    const pathElements = [
        {id: 0, title: "Home", url: "/"},
        {id: 1, title: "Organisation", url: "/organisation"},
        {id: 2, title: "Courses"}
    ];

    return (
        <PageContent pathElements={pathElements}>
            <CoursesTable/>
        </PageContent>
    )
}