import React from 'react'
import EditorHelper from 'components/contentEditor/EditorHelper'
import Utils from 'utils/Utils'

export default class Label extends React.Component {

    render() {

        const {item, defaults, style, innerRef, ...props} = this.props;

        const text = Utils.value(Utils.getNested(this.props.item, "data", "text"), "");

        return (
            <span
                ref={innerRef}
                style={Object.assign(style,{
                    fontFamily: EditorHelper.getFontFamily(item, defaults),
                    fontSize: EditorHelper.getFontSize(item, defaults) * this.props.scale,
                    color: EditorHelper.getFontColor(item, defaults),
                    paddingLeft: EditorHelper.getMargin(item, defaults, "left") * this.props.scale,
                    paddingRight: EditorHelper.getMargin(item, defaults, "right") * this.props.scale,
                    paddingBottom: EditorHelper.getMargin(item, defaults, "bottom") * this.props.scale,
                    paddingTop: EditorHelper.getMargin(item, defaults, "top") * this.props.scale,
                    display: "block",
                    lineHeight: "normal",
                    wordBreak: "break-word"
                })}

                {...props}
            >
                {text.split('\n').map((item, key) => {
                    return <React.Fragment key={key}>{item}<br/></React.Fragment>
                })}
            </span>
        )
    }

}