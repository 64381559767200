import React from 'react'
import AppComponent from 'components/common/AppComponent'
import Utils from "../../utils/Utils";
import Axios from "axios";
import {API, BASE_URL} from "../../api/APIClient";
import {NotificationManager} from "react-notifications";
import {ServiceType} from "../../constants/ServiceType";
import AxiosHelper from "../../utils/AxiosHelper";
import BlockUi from "react-block-ui";
import PageContent from "../common/PageContent";
import ReactSelect from "../common/ReactSelect";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import ContentEditor from 'components/contentEditor/ContentEditor'
import UUID from "../../utils/UUID";
import { Redirect } from 'react-router-dom'
import FileInput from "../common/FileInput";
import Image from "../common/Image";
import Placeholder from "../../assets/img/placeholder.png";
import moment from "./ServiceForm";
import StandardModal from "../common/StandardModal";
import Modal from "react-responsive-modal";
import ReactTable from "react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const pathElements = [
    {id: 0, title: "Home", url: "/"},
    {id: 1, title: "Services", url: "/services"},
    {id: 2, title: "Page Editor"}
];

export default class ServicePageEditor extends AppComponent {

    constructor(props, context) {
        super(props);

        let item = this.props.item ? this.props.item : Utils.getNested(props, "location", "state", "item");

        this.state = {
            organisationID: item ? item.organisationID : context.organisation.id,
            organisationOptions: item ? [{value: item.organisationID, label: item.organisationName}] : [{value: context.organisation.id, label: context.organisation.name}],
            redirect: false,
            errors: [],
            touched: [],
            itemData: null,
            elements: null,
            defaults: null,
            imagePickerURL: null,
            imagePickerFile: null,
            item: item && item.langID ? Object.assign({}, item) : Object.assign({
                langID: null,
                translations: []
            }, item),
            isUploading: false,
            validated: false,
            isFetching: false,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            },
            isLoadingTranslations: false,
            languageOptions: [],
            selectedLanguage: null
        }
    }


    componentDidMount() {

        if (this.state.item.langID === null) {
            if (!this.state.item.id && this.props.location.state.permissions.allOrgs) {
                this.getOrganisationOptions();
            }
            if (this.state.item.id) {
                this.getService();
            }

            if (this.state.item.id) {
                this.getTranslations();
            }
            else {
                this.getLanguages();
            }
        }
        else {
            this.getTranslation();
        }
        this.validate();
    }

    getTranslations = () => {

        if (this.state.isLoadingTranslations) { return; }

        this.setState({
            isLoadingTranslations: true
        }, () => {
            Axios
                .get(API.service.getTranslations, {
                    params: {
                        id: this.state.item.id
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        this.setState(prevState => ({
                            ...prevState,
                            item: {
                                ...prevState.item,
                                translations: res.data.data.translations
                            }
                        }), () => {
                            this.getLanguages();
                        })
                    }
                })
        })
    }

    getLanguages = () => {
        Axios
            .get(API.organisation.getLanguages)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        isLoadingTranslations: false,
                        languageOptions: res.data.data.languages.filter((item) => { return !this.state.item.translations.find((o) => o.langID.toString() === item.id.toString()) }).map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    handleFileDelete = (name) => {
        this.setState({
            imagePickerFile: null,
            imagePickerURL: null
        }, () => {
            this.validate()
        })
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        let self = this;

        reader.onloadend = () => {
            self.setState({
                imagePickerFile: file,
                imagePickerURL: reader.result
            }, () => {
                self.validate()
            })
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    getTranslation = () => {

        if (!this.state.isFetching && this.state.item) {
            this.setState({
                isFetching: true
            }, () => {

                Axios
                    .get(API.service.getTranslation, {
                        params: {
                            id: this.props.parentID,
                            langID: this.state.item.langID
                        }
                    })
                    .then((res) => {

                        console.log("getService responded");

                        if (res.data.success) {
                            const itemData = res.data.data.translation.data;


                            this.setState({
                                isFetching: false,
                                itemData: itemData
                            }, () => {
                                this.validate();
                                console.log(this.state.itemData);
                            });
                        }
                        else {
                            this.setState({
                                isFetching: false
                            })
                        }
                    })

            })
        }

    }

    getService = () => {
        if (!this.state.isFetching && this.state.item) {
            this.setState({
                isFetching: true
            }, () => {

                Axios
                    .get(API.service.getService, {
                        params: {
                            serviceID: this.state.item.id
                        }
                    })
                    .then((res) => {

                        console.log("getService responded");

                        if (res.data.success) {
                            const itemData = res.data.data.service.data;


                            this.setState({
                                isFetching: false,
                                itemData: itemData
                            }, () => {
                                this.validate();
                                console.log(this.state.itemData);
                            });
                        }
                        else {
                            this.setState({
                                isFetching: false
                            }, () => {
                                this.validate();
                                NotificationManager.error(res.data.error);
                            })
                        }
                    })

            })
        }
    }


    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations, {
                props: {
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    let organisationOptions = res.data.data.organisations.map((item) => { return {value: item.id, label: item.name}});
                    organisationOptions.splice(0, 0, {value: "-1", label: "System Service"});

                    this.setState({
                        organisationOptions: organisationOptions
                    })
                }
            })
    }


    handleSubmit = () => {
        if (!this.state.isUploading) {

            this.setState({
                isUploading: true
            }, () => {

                if (this.state.item.langID === null) {
                    let formData = new FormData();

                    if (this.state.item.id) {
                        formData.append("id", this.state.item.id);
                    }

                    formData.append("title", this.state.item.title);
                    formData.append("subtitle", this.state.item.subtitle || "");
                    formData.append("typeID", ServiceType.PAGE.toString());
                    formData.append("organisationID", this.state.organisationID);

                    let hasImage = false;

                    let elements = Array.from(this.state.elements);

                    elements.forEach((element, index, array) => {
                        if (element.type === "image" && element.imagePickerFile) {
                            hasImage = true;
                            formData.append(element.id, element.imagePickerFile);

                            let newElement = {
                                type: element.type,
                                id: element.id,
                                data: {
                                    aspectRatio: element.data.aspectRatio,
                                    contentMode: element.data.contentMode
                                }
                            }

                            if (element.style) {
                                newElement.style = element.style
                            }

                            elements[index] = newElement;
                        }
                    });

                    if (this.state.imagePickerFile) {
                        formData.append("file", this.state.imagePickerFile);
                        hasImage = true;
                    }

                    let itemData = {};
                    itemData.defaults = {};
                    itemData.defaults.style = Object.assign({}, this.state.defaults);
                    itemData.elements = elements;
                    const json = JSON.stringify(itemData);
                    formData.append("data", json);

                    Axios
                        .post((this.state.item.id ? API.service.updateService : API.service.createService), formData, {
                            headers: {
                                'Content-Type': hasImage ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
                            },
                            onUploadProgress: (progressEvent) => {
                                this.setState({
                                    uploadProgress: AxiosHelper.getUploadProgress(progressEvent)
                                });
                            }
                        })
                        .then((res) => {

                            if (res.data.success) {
                                NotificationManager.success("Saved successfully");
                            }
                            else if (res.data.error) {
                                NotificationManager.error(res.data.error.desc);
                            }

                            this.setState({
                                isUploading: false,
                                redirect: res.data.success === true
                            });
                        })
                }
                else {

                    let formData = new FormData();

                    if (!this.props.parentID) { return }

                    formData.append("id", this.props.parentID);
                    formData.append("title", this.state.item.title);
                    formData.append("subtitle", this.state.item.subtitle || "");
                    formData.append("langID", this.state.item.langID)
                    

                    let hasImage = false;

                    let elements = Array.from(this.state.elements);

                    elements.forEach((element, index, array) => {
                        if (element.type === "image" && element.imagePickerFile) {
                            hasImage = true;
                            formData.append(element.id, element.imagePickerFile);

                            let newElement = {
                                type: element.type,
                                id: element.id,
                                data: {
                                    aspectRatio: element.data.aspectRatio,
                                    contentMode: element.data.contentMode
                                }
                            }

                            if (element.style) {
                                newElement.style = element.style
                            }

                            elements[index] = newElement;
                        }
                    });

                    let itemData = {};
                    itemData.defaults = {};
                    itemData.defaults.style = Object.assign({}, this.state.defaults);
                    itemData.elements = elements;
                    const json = JSON.stringify(itemData);
                    formData.append("data", json);

                    Axios
                        .post(API.service.updatePageServiceTranslation, formData, {
                            headers: {
                                'Content-Type': hasImage ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
                            },
                            onUploadProgress: (progressEvent) => {
                                this.setState({
                                    uploadProgress: AxiosHelper.getUploadProgress(progressEvent)
                                });
                            }
                        })
                        .then((res) => {

                            if (res.data.success) {
                                NotificationManager.success("Saved successfully");
                            }
                            else if (res.data.error) {
                                NotificationManager.error(res.data.error.desc);
                            }

                            this.setState({
                                isUploading: false
                            }, () => {
                                this.props.onCompleted(this.state.item);
                            });
                        });
                    
                }
                
            })
        }
    }

    validate = () => {

        let validated = true;

        const item = this.state.item;
        const touched = this.state.touched;
        const errors = [];

        if (!item.title) {
            if (touched.title) {
                errors.name = "Page title is required";
            }
            validated = false;
        }

        if (this.state.elements === null || this.state.elements.length === 0) {
            validated = false;
        }


        this.setState({
            validated: validated,
            errors: errors
        });
    }

    handleChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let newState = {};

        if (name === "selectedLanguage") {
            newState[name] = value;
        }

        else if (name === "organisationID") {
            newState.organisationID = value;
        }
        else {
            let item = Object.assign({}, this.state.item);
            item[name] = value;
            newState.item = item;
        }

        this.setState(newState, () => {
            this.validate();
        })
    }


    handleAddTranslation = (langID) => {

        const language = this.state.languageOptions.find((i) => i.value.toString() === langID.toString());

        let translation = {
            langID: language.value,
            langName: language.label,
            title: "",
            subtitle: "",
            typeTitle: "",
            id: UUID.uuidv4(),
            isNew: 1
        };

        if (!this.state.modal.isOpen) {
            const content = (
                <ServicePageEditor
                    parentID={this.state.item.id}
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.setState(prevState => ({
                            languageOptions: this.state.languageOptions.filter((i) => i.value.toString() !== language.value.toString()),
                            item: {
                                ...prevState.item,
                                translations: [...prevState.item.translations, updatedTranslation]
                            }
                        }), () => {
                            this.closeModal();
                        });
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState(prevState => ({
                selectedLanguage: null,
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-large",
                    isUploading: false,
                    content: content
                })
            }))
        }

    }

    updateTranslation = (updatedTranslation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.map((o) => {
                    if (o.langID.toString() === updatedTranslation.langID.toString()) {
                        return updatedTranslation;
                    }
                    else {
                        return o;
                    }
                })
            }
        }))
    }

    handleEditTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <ServicePageEditor
                    parentID={this.state.item.id}
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-large",
                    content: content
                })
            })
        }
    }

    handleDeleteTranslation = (translation) => {

        this.deleteTranslation(translation);

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.filter((i) => i.langID.toString() !== translation.langID.toString() )
            },
            languageOptions: [...this.state.languageOptions, {label: translation.langName, value: translation.langID}].sort((a,b) => a.name < b.name ? 1 : -1 )
        }), () => {
            this.closeModal();
        })

    }

    deleteTranslation = (translation) => {
        Axios
            .post(API.service.deletePageServiceTranslation, {
                id: this.state.item.id,
                langID: translation.langID
            })
            .then((res) => {

                if (res.data.success) {
                    NotificationManager.success("Deleted successfully");
                }
                else if (res.data.error) {
                    NotificationManager.error(res.data.error.desc);
                }
            })
    }

    showDeleteTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Translation"
                    content={(
                        <React.Fragment>
                            <p>Are you sure to delete <strong>{translation.langName}</strong> translation ? This action cannot be reverted.</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteTranslation(translation)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    isUploading: false,
                    content: content
                })
            })
        }
    }


    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }


    render() {

        if (this.state.redirect) return <Redirect to="/services" />;

        let content = (
            <React.Fragment>
                <div className="grid-x grid-padding-x">
                    <div className="cell small-12" style={{margin: "20px 0"}}>
                        <fieldset className="fieldset edit-field">
                            <legend>Details</legend>
                            <div className="grid-x grid-padding-x">
                                {this.state.item.langID === null && !this.state.item.id && this.props.location.state.permissions.allOrgs &&
                                <div className="small-12 medium-6 large-3 cell ">
                                    <span>Organisation</span>
                                    <ReactSelect
                                        name="organisationID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.organisationID}
                                        options={this.state.organisationOptions}
                                    />
                                </div>
                                }
                                <div className={"small-12 medium-6 large-3 cell " + (this.state.errors.title && "error")}>
                                    <span>{this.state.item.langID === null ? "*" : ""} Page title</span>
                                    <input name="title" type="text" value={this.state.item.title} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.title}</span>
                                </div>
                                <div className={"small-12 medium-6 large-3 cell " + (this.state.errors.subtitle && "error")}>
                                    <span>Page subtitle</span>
                                    <input name="subtitle" type="text" value={this.state.item.subtitle} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.subtitle}</span>
                                </div>

                            </div>
                        </fieldset>
                        {this.state.item.langID === null &&
                        <fieldset className="fieldset edit-field">
                            <legend>Cover image</legend>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 medium-6">
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="coverImage" className="text-right middle">Select Image</label>
                                        </div>
                                        <div className="large-9 small-9 cell ">
                                            <FileInput
                                                name="coverImage"
                                                onChange={this.handleFilePickerChange}
                                                onBlur={this.handleBlur}
                                                onDelete={this.handleFileDelete}
                                                placeholder="Not selected."
                                                accept="image/jpeg,image/jpg,image/png"
                                            />
                                        </div>
                                    </div>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="coverImage" className="text-right middle">Current Image</label>
                                        </div>
                                        <div className={"large-9 small-9 cell " + (this.state.errors.imageFile && "error")} style={{marginBottom: 20}}>
                                            {this.state.imagePickerURL &&
                                            <React.Fragment>
                                                <Image src={this.state.imagePickerURL} width={200} height={200} border={true} style={{marginRight: 10}}/>
                                                <Image src={this.state.imagePickerURL} width={200} height={100} border={true} />
                                            </React.Fragment>
                                            }
                                            {!this.state.imagePickerURL &&
                                            <React.Fragment>
                                                <Image src={this.state.item.imageURL} width={200} height={200} border={true} placeholder={Placeholder} style={{marginRight: 10}}/>
                                                <Image src={this.state.item.imageURL} width={200} height={100} border={true} placeholder={Placeholder}/>
                                            </React.Fragment>
                                            }
                                            <small className="error">{this.state.errors.imageFile}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        }
                        {this.state.item.langID === null &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset edit-field">
                                    <legend>Translations</legend>
                                    {this.state.item.id ?
                                    <React.Fragment>
                                        <div className="grid-x grid-padding-x">
                                            <div className="cell small-12">
                                                <span>Add translation for</span>
                                                <ReactSelect
                                                    name="selectedLanguage"
                                                    className="react-select translation-selector"
                                                    onChange={this.handleChange}
                                                    value={this.state.selectedLanguage}
                                                    options={this.state.languageOptions}
                                                    onBlur={this.handleBlur}
                                                />
                                                <Button type="button" className="translation-button" color={Colors.PRIMARY} isDisabled={!this.state.selectedLanguage} onClick={() => this.handleAddTranslation(this.state.selectedLanguage)}>Add</Button>
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="cell small-12">
                                                <ReactTable
                                                    columns={[
                                                        {
                                                            Header: 'Language',
                                                            accessor: 'langName'
                                                        },
                                                        {
                                                            Header: 'Actions',
                                                            Cell: row => (
                                                                <ButtonGroup size={Sizes.TINY}>
                                                                    {this.context.permissions.campus.edit &&
                                                                    <Button type="button" color={Colors.PRIMARY} onClick={() => this.handleEditTranslation(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                                                                    }
                                                                    {this.context.permissions.campus.delete &&
                                                                    <Button type="button" color={Colors.ALERT} onClick={() => this.showDeleteTranslation(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                                                                    }
                                                                </ButtonGroup>
                                                            ),
                                                            maxWidth: 100,
                                                            show: this.context.permissions.campus.delete || this.context.permissions.campus.edit
                                                        }
                                                    ]}
                                                    data={this.state.item.translations}
                                                    className='-striped'
                                                    showPagination={false}
                                                    noDataText={"No translations"}
                                                    pageSize={this.state.item.translations ? this.state.item.translations.length : 1}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <p>Translations for page services can only be added once the default page is created. Please submit the default page first and then use editing functionality to add additional language variations.</p>
                                        </React.Fragment>
                                    }
                                </fieldset>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <ContentEditor
                    organisationID={this.state.organisationID}
                    content={this.state.itemData}
                    showFormElements={true}
                    onChange={(s) => {
                        this.setState({
                            elements: s.elements,
                            defaults: s.defaults
                        }, () => {
                            this.validate();
                        })
                    }}
                />
                {this.state.item.langID === null &&
                    <div className="fix-save">
                        <ButtonGroup>
                            <Button className="edit-button" onClick={() => this.setState({redirect: true})}>Cancel</Button>
                            <Button className="edit-button" color={Colors.SUCCESS} disabled={!this.state.validated} onClick={this.handleSubmit} >Save</Button>
                        </ButtonGroup>
                    </div>
                }
            </React.Fragment>
        );

        return (

            <React.Fragment>
                <BlockUi tag="div" blocking={this.state.isUploading || this.state.isFetching} message="Please wait" keepInView>
                    {this.state.item.langID === null ?
                        <PageContent pathElements={pathElements} >
                            {content}
                        </PageContent>
                        :
                        <React.Fragment>
                            <div className="modal-head">
                                <h3>Page Service ({this.state.item.langName} translation)</h3>
                            </div>
                            <div className="modal-body">
                                {content}
                            </div>
                            <div className="modal-footer">
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell">
                                        <div className="btn-wrap">
                                            <ButtonGroup>
                                                <Button className="edit-button" onClick={this.props.onCancel}>Cancel</Button>
                                                <Button className="edit-button" color={Colors.SUCCESS} disabled={!this.state.validated} onClick={this.handleSubmit} >Save</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </BlockUi>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="TranslationsModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        )
    }


}