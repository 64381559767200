import React from 'react'
import Axios from "axios";
import {NotificationManager} from "react-notifications";
import moment from 'moment'
import SummaryChart from "./SummaryChart";


export default class DashboardGraph extends React.Component {

    requestSource = undefined;

    constructor(props) {
        super(props);

        this.state = {
            data: {
                total: 0,
                period: 0,
                today: 0,
                graphData: []
            },
            dp: false,
            isLoadingData: false
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startDate !== this.props.startDate ||
            prevProps.endDate !== this.props.endDate ||
            prevProps.filters !== this.props.filters) {
            this.fetchData();
        }
    }

    onShowMoreClicked = () => {
        if (typeof this.props.onShowMore === 'function') {
            this.props.onShowMore(this.state.data);
        }
    }

    fetchData = () => {

        if (this.requestSource) {
            console.log("cancelling previous request");
            this.requestSource.cancel(this.props.endpoint);
        }

        this.setState({
            isLoadingData: true
        }, () => {

            const cancelToken = Axios.CancelToken;
            this.requestSource = cancelToken.source();

            Axios
                .get(this.props.endpoint, {
                    params: {
                        filters: this.props.filters,
                        startDate: this.props.startDate.format("YYYY-MM-DD"),
                        endDate: this.props.endDate.format("YYYY-MM-DD"),
                        tz: moment().local().format("Z")
                    },
                    cancelToken: this.requestSource.token
                })
                .catch(function(thrown) {
                    if (Axios.isCancel(thrown)) {
                        console.log('Request canceled', thrown.message);
                    }
                })
                .then((res) => {
                    if (!res) { return; } // For some reason if request is cancelled it still returns a promise but with an empty result
                    this.requestSource = undefined;
                    if (res.data.success) {
                        const data = res.data.data;
                        const dp = data.dp || false;

                        if (dp) {
                            this.setState({
                                data: {
                                    total: 0,
                                    period: 0,
                                    today: 0,
                                    graphData: []
                                },
                                dp: true,
                                isLoadingData: false
                            });
                            return;
                        }

                        const graphData = data.items.map((item) => {
                            return Object.assign({label: moment(item.date, "YYYY-MM-DD").format("YYYY/MM/DD")}, item)
                        });
                        this.setState({
                            isLoadingData: false,
                            dp: false,
                            data: {
                                total: data.total,
                                period: data.period,
                                today: data.today,
                                graphData: graphData
                            }
                        })
                    }
                    else {
                        NotificationManager.error(res.data.error.desc);
                        this.setState({
                            isLoadingData: false
                        })
                    }
                });

        })

    }


    render() {

        return (
            <SummaryChart
                color={this.props.color}
                title={this.props.title}
                data={this.state.data.graphData}
                graphType={this.props.graphType}
                periodTotal={this.state.data.period}
                todayTotal={this.state.data.today}
                total={this.state.data.total}
                dp={this.state.dp}
                addShowMore={this.props.addShowMore}
                onShowMore={this.onShowMoreClicked}
                footer={this.props.footer}
                isLoadingData={this.state.isLoadingData}
            />
        )
    }

}