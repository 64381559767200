import React from 'react'
import Breadcrumb from 'components/common/Breadcrumb'

export default function PageContent(props) {
    return (
        <div className="grid-container xlarge-grid-container">
            <div className="grid-x grid-padding-x">
                <Breadcrumb links={props.pathElements} />
            </div>
            {props.children}
        </div>
    );
}