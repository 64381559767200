export const AIIntegration = {
    TYPES : {
        CHATGPT : 1,
        AWS_BEDROCK : 2
    },
    getAIIntegrations : () => {
        return [
            { id : AIIntegration.TYPES.CHATGPT, label : "ChatGPT", value : AIIntegration.TYPES.CHATGPT },
            { id : AIIntegration.TYPES.AWS_BEDROCK, label : "AWS Bedrock", value : AIIntegration.TYPES.AWS_BEDROCK }
        ]
    },
    getAIIntegrationById : (typeId) => {
        typeId = parseInt(typeId);
        const types = AIIntegration.getAIIntegrations();
        for (let i = 0; i < types.length; i++) {
            if (types[i].id === typeId) {
                return types[i];
            }
        }
        return undefined;
    }
}