import React, {useEffect, useRef, useState} from 'react'
import ReactTable from 'react-table'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchBar from 'components/common/SearchBar'
import DatatableHelper from 'utils/DatatableHelper'
import { NotificationManager } from 'react-notifications'
import Modal from 'react-responsive-modal'
import TableContainer from 'components/common/TableContainer'
import { Colors, Sizes, ButtonGroup, Button} from 'react-foundation'
import { API } from 'api/APIClient'
import ReactSelect from 'components/common/ReactSelect'
import APIKeyForm from 'components/organisation/apiKeys/APIKeyForm'
import StandardModal from 'components/common/StandardModal'
import DateHelper from 'utils/DateHelper'
import {PermissionUtil} from "../../../utils/PermissionUtil";
import {AccessKeysForm} from "./AccessKeysForm";


export const AccessKeysTable = (props) => {

    const [items, setItems] = useState([]);

    const [searchKey, setSearchKey] = useState("");
    const [filtersIsOpen, setFiltersIsOpen] = useState(false);

    const [organisations, setOrganisations] = useState([]);
    const [organisationOptions, setOrganisationOptions] = useState([]);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(-1);

    const [isLoading, setIsLoading] = useState(true);

    const [sorted, setSorted] = useState([{id: "id", "desc": true}]);

    const [modal, setModal] = useState({ isOpen : false, content : null, isUploading : false, error : null });

    const searchTimer = useRef();

    useEffect(() => {
        if (PermissionUtil.allOrgs(PermissionUtil.PermissionCategories.aiAccessKeys)) {
            getOrganisationOptions();
        }
    }, []);

    useEffect(() => {
        fetchItems();
    }, [organisations, searchKey, page, pageSize, sorted]);

    function handleChange(event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "organisations") {
            if (value.length === organisationOptions.length) {
                value = [];
            }
            setOrganisations(value);
        }
    }

    function getOrganisationOptions() {
        Axios.get(API.organisation.getOrganisations, {
            props: {
                appUsersOnly: 1
            }
        })
        .then((res) => {
            if (res.data.success) {
                setOrganisationOptions(res.data.data.organisations.map((item) => {
                    return {value: item.id.toString(), label: item.name}
                }));
            }
        })
    }

    function searchTextDidChange(newValue) {
        clearTimeout(searchTimer.current);
        searchTimer.current = setTimeout(() => {
            setSearchKey(newValue);
        }, 1000);
    }


    function fetchItems() {
        setIsLoading(true);

        Axios.post(API.aiAccessKey.getAiAccessKeysDT, {
                page,
                pageSize,
                sorted,
                filters: {
                    search: searchKey,
                    organisations
                }
            }
        )
        .then((res) => {
            if (res.data.success) {
                setTotalPages(DatatableHelper.getTotalPages(res.data.data));
                setItems(res.data.data.rows);
            }
            setIsLoading(false);
        })
    }

    function handleAddNewItem() {
        showItemModal({}, true);
    }

    function handleShowItem(item) {
        showItemModal(item, false);
    }

    function handleEditItem(item) {
        showItemModal(item, true);
    }

    function showItemModal(item, editable) {
        if (!modal.isOpen) {
            const content = (
                <AccessKeysForm
                    itemId={item.id}
                    organisationId={item.organisationID}
                    editable={editable}
                    onSubmitted={() => closeModal(fetchItems)}
                    onCancel={closeModal}
                />
            );

            setModal(Object.assign({}, modal, {
                isOpen: true,
                content: content
            }))
        }
    }

    function closeModal(completion) {
        setModal({
            isOpen: false,
            content: null,
            error: null,
            isUploading: false
        });

        if (completion && typeof completion === "function") {
            completion();
        }
    }

    function showDeleteModal(item) {

        if (!modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Access Key"
                    content={(
                        <React.Fragment>
                            <p>Are you sure to delete this Access Key ? This action cannot be reverted.</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => handleDeleteItem(item)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                    error={modal.error}
                    isUploading={modal.isUploading}
                />
            );

            setModal(Object.assign({}, modal, {
                isOpen: true,
                content: content
            }))
        }
    }

    function handleDeleteItem(item) {
        if (!modal.isUploading) {
            setModal(Object.assign({}, modal, {
                isUploading: true
            }))

            Axios.post(API.aiAccessKey.deleteAiAccessKey, {id: item.id})
                .then((res) => {
                    if (res.data.success) {
                        closeModal(() => {
                            fetchItems();
                            NotificationManager.success("Access Key deleted");
                        });
                    }
                    else {
                        closeModal(() => {
                            NotificationManager.error(res.data.error.desc);
                        });
                    }
                })
                .catch(error => {
                    closeModal(() => {
                        NotificationManager.error(error.response);
                    });
                });

        }
    }


    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
            width: 60
        },
        {
            Header: 'Type',
            accessor: 'typeName',
            minWidth: 150
        },
        {
            Header: 'Organisation',
            accessor: 'organisationName',
            minWidth: 120,
            show: PermissionUtil.allOrgs(PermissionUtil.PermissionCategories.aiAccessKeys)
        },
        {
            Header: 'Created At',
            accessor: 'createdAt',
            width: 180,
            Cell: row => {
                return DateHelper.convertToLocalDateTime(row.value);
            }
        },
        {
            Header: 'Actions',
            Cell: row => (
                <ButtonGroup size={Sizes.TINY}>
                    <Button color={Colors.PRIMARY} onClick={() => handleShowItem(row.original)}><FontAwesomeIcon icon="eye"/></Button>
                    {PermissionUtil.edit(PermissionUtil.PermissionCategories.aiAccessKeys) &&
                        <Button color={Colors.PRIMARY} onClick={() => handleEditItem(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                    }
                    {PermissionUtil.delete(PermissionUtil.PermissionCategories.aiAccessKeys) &&
                        <Button color={Colors.ALERT} onClick={() => showDeleteModal(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                    }
                </ButtonGroup>
            ),
            sortable: false,
            maxWidth: 140
        }
    ];

    const table = (
        <ReactTable
            columns={columns}
            data={items}
            pages={totalPages}
            pageSize={pageSize}
            loading={isLoading}
            multisort={true}
            manual
            className='-striped'
            onFetchData={(state) => {
                setPage(state.page);
                setPageSize(state.pageSize);
                setSorted(state.sorted);
            }}
        />
    );

    const filters = (
        <React.Fragment>
            <div className="cell small-12 medium-6 large-3">
                <span>Search</span>
                <SearchBar onDidChange={searchTextDidChange} />
            </div>
            {PermissionUtil.allOrgs(PermissionUtil.PermissionCategories.aiAccessKeys) &&
                <div className="cell small-12 medium-6 large-3">
                    <span>Organisations</span>
                    <ReactSelect
                        name="organisations"
                        onChange={handleChange}
                        value={organisations}
                        options={organisationOptions}
                        isMulti={true}
                        placeholder="All"
                        className="react-select"
                    />
                </div>
            }
        </React.Fragment>
    )

    const buttons = (
        <React.Fragment>
            {PermissionUtil.create(PermissionUtil.PermissionCategories.aiAccessKeys) &&
                <button type="button" className="button small" onClick={handleAddNewItem}>Add Access Key</button>
            }
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <TableContainer
                title="Access Keys table"
                filters={filters}
                table={table}
                buttons={buttons}
            />
            <Modal
                classNames={{modal:"modal-medium"}}
                open={modal.isOpen}
                modalId="Modal"
                onClose={closeModal}
                center
            >
                {modal.content}
            </Modal>
        </React.Fragment>
    );

}