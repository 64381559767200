import React from 'react';
import ReactTable from 'react-table'
import Axios from 'axios'
import SearchBar from 'components/common/SearchBar'
import { Colors, Sizes, ButtonGroup, Button} from 'react-foundation'
import TableContainer from 'components/common/TableContainer'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import {CopyToClipboard} from 'react-copy-to-clipboard';

class ChatVariablesTable extends AppComponent {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            searchKey: "",
            pageSize: 10,
            isLoading: true,
            filtersAreOpen: false
        };

        this.searchTimer = null;
    }

    componentDidMount() {
        this.fetchItems();
    }


    searchTextDidChange(newValue) {
        // this.setState({
        //     searchKey: newValue || ""
        // }, () => {
        //     clearTimeout(this.searchTimer);
        //     this.searchTimer = setTimeout(() => {
        //         this.fetchItems();
        //     }, 1000);
        // });
    }

    handleSelected = (item) => {
        if (this.props.onSelected instanceof Function) {
            this.props.onSelected(item);
        }
    }


    fetchItems = () => {
        this.setState({
            loading: true
        });

        let type = null;
        if (!this.props.showCondVariables && this.props.showTextVariables) {
            type = "text"
        }
        else if (!this.props.showTextVariables && this.props.showCondVariables) {
            type = "cond"
        }


        Axios
            .post(API.chat.getProperties, {
                    type: type
                }
            )
            .then((res) => {
                if (res.data.success) {

                    this.setState({
                        items: Object.values(res.data.data.variables),
                        isLoading: false
                    })
                }
                else if (res.data.error) {
                    console.log(res.data.error)
                }

            })
    }



    render() {

        const columns = [
            {
                Header: 'Key',
                accessor: 'key',
                minWidth: 100
            },
            {
                Header: 'Type',
                accessor: 'typeText',
                minWidth: 40
            },
            {
                Header: 'Description',
                accessor: 'label'
            },
            {
                Header: 'Actions',
                Cell: row => (
                    <ButtonGroup size={Sizes.TINY}>

                        {this.props.showSelectButton === true &&
                        <Button color={Colors.PRIMARY} onClick={() => this.handleSelected(row.original)}>Select</Button>}

                        {this.props.showCopyButton === true &&
                            <CopyToClipboard
                                text={"{{"+row.original.key+"}}"}
                                onCopy={() => this.handleSelected(row.original)}
                            >
                                <Button color={Colors.PRIMARY}>Copy to clipboard</Button>
                            </CopyToClipboard>
                        }
                    </ButtonGroup>
                ),
                maxWidth: 130,
                show: this.state.showCopyButton || this.state.showSelectButton
            }
        ]

        const filters = (
            <React.Fragment>
                <div className="cell small-12 medium-6 large-3">
                    <span>Search</span>
                    <SearchBar
                        onDidChange={(newValue) => this.searchTextDidChange(newValue)}
                    />
                </div>
            </React.Fragment>

        )

        const table = (
            <ReactTable
                columns={columns}
                data={this.state.items}
                pageSize={this.state.pageSize}
                loading={this.state.isLoading}
                multisort={true}
                className='-striped'
            />
        )

        return (
            <React.Fragment>
                <TableContainer
                    title="System properties table"
                    filters={filters}
                    table={table}
                />
            </React.Fragment>
        );
    }
}

export default ChatVariablesTable;
