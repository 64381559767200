import React from 'react';
import ReactTable from 'react-table'
import Axios from 'axios'
import SearchBar from 'components/common/SearchBar'
import { Colors, Sizes, ButtonGroup, Button} from 'react-foundation'
import TableContainer from 'components/common/TableContainer'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Modal from "react-responsive-modal";
import {PermissionUtil} from "../../utils/PermissionUtil";
import {KnowledgeDocumentEditor} from "./KnowledgeDocumentEditor";
import StandardModal from "../common/StandardModal";
import {CommonUtil} from "../../utils/CommonUtil";
import {Toast} from "../../utils/ToastUtil";
import ReactSelect from "../common/ReactSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NotificationManager} from "react-notifications";

export default class KnowledgeDocumentTable extends AppComponent {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            searchKey: "",
            page : 0,
            pageSize: 10,
            isLoading: true,
            filtersAreOpen: false,
            organisationOptions : [],
            modal : {
                isOpen : false,
                content : []
            }
        };

        this.searchTimer = null;
    }

    componentDidMount() {
        this.fetchItems();

        if (PermissionUtil.allOrgs(PermissionUtil.PermissionCategories.knowledgeDocuments)) {
            this.fetchOrganisations();
        }
    }

    searchTextDidChange(newValue) {
        this.setState({
            searchKey: newValue || ""
        }, () => {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.fetchItems();
            }, 1000);
        });
    }

    organisationDidChange = (newValue) => {
        this.setState({
            searchOrganisationID : newValue
        }, () => {
            this.fetchItems();
        });
    }

    handleSelected = (item) => {
        if (this.props.onSelected instanceof Function) {
            this.props.onSelected(item);
        }
    }

    handleAddNewItem = () => {
        this.openModal(
            <KnowledgeDocumentEditor callback={this.modalDidCallback} />
        );
    }

    handleEditItem = (item) => {
        this.openModal(
            <KnowledgeDocumentEditor
                knowledgeDocument={item}
                callback={this.modalDidCallback} />
        )
    }

    promptDeleteItem = (item) => {
        this.openModal(
            <StandardModal
                title="Delete Knowledge Document"
                content={(
                    <div>Are you sure to delete the &quot;<strong>{item.name}</strong>&quot; Knowledge Document? This action cannot be reverted.</div>
                )}
                buttons={(
                    <React.Fragment>
                        <button className="button secondary" type="button" onClick={this.closeModal}>
                            Cancel
                        </button>
                        <button className="button alert" type="button" onClick={() => this.handleDeleteItem(item)}>
                            Delete
                        </button>
                    </React.Fragment>
                )}
                isUploading={this.state.modal.isUploading}
            />
        )
    }

    handleDeleteItem = (item) => {
        if (this.state.modal.isUploading) return;
        const modal = CommonUtil.cloneObject(this.state.modal);
        modal.isUploading = true;
        this.setState({ modal });

        const data = {
            id : item.id
        };

        Axios.post(API.knowledgeDocument.deleteKnowledgeDocument, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    NotificationManager.success("Deleted item: " + item.name);
                } else {
                    NotificationManager.error(API.formatError(resp));
                }

                this.closeModal();
            })
            .catch((e) => {
                NotificationManager.error("Could not delete item. An unknown error has occurred.");

                this.closeModal();
            });
    }

    openModal = (content) => {
        this.setState({
            modal : {
                isOpen : true,
                content
            }
        });
    }

    closeModal = () => {
        this.setState({
            modal : {
                isOpen : false,
                content : []
            }
        });

        this.fetchItems();
    }

    modalDidCallback = (action, data) => {
        if (action === "submit") {
            this.closeModal();
        }
    }

    fetchItems = () => {
        this.setState({
            loading: true
        });

        let organisations = undefined;
        if (this.state.searchOrganisationID) {
            organisations = [this.state.searchOrganisationID];
        }

        Axios
            .post(API.knowledgeDocument.getKnowledgeDocumentsDT, {
                page : this.state.page,
                pageSize : this.state.pageSize,
                sorted : [{id: "id", "desc": true}],
                filters : {
                    search: this.state.searchKey,
                    organisations
                }
            })
            .then((res) => {
                if (res.data.success) {

                    this.setState({
                        items: Object.values(res.data.data.rows),
                        isLoading: false
                    })
                }
                else if (res.data.error) {
                    console.log(res.data.error)
                }
            })
    }

    fetchOrganisations = () => {
        Axios.get(API.organisation.getOrganisations, {
            props : {
                appUsersOnly : 1
            }
        })
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    const organisations = resp.data.organisations;
                    if (organisations) {
                        const orgOptions = organisations.map((org) => {
                            return {
                                value : org.id.toString(),
                                label : org.name
                            }
                        });

                        orgOptions.unshift({
                            value : null,
                            label : "All Organisations"
                        });

                        this.setState({
                            organisationOptions : orgOptions
                        });
                    }
                } else {
                    console.log(API.formatError(resp));
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    render() {

        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Key',
                accessor: 'key',
            },
            {
                Header: 'Type',
                accessor: 'typeName'
            },
            {
                Header: 'Organisation',
                accessor: 'organisationName',
            },
            {
                Header: 'Actions',
                Cell: row => (
                    <ButtonGroup size={Sizes.TINY}>

                        {this.props.showCopyButton === true &&
                            <CopyToClipboard
                                text={"{{ST.knowledgeBase.[" + row.original.key + "] " + row.original.name + "}}"}
                                onCopy={() => this.handleSelected(row.original)}
                            >
                                <Button color={Colors.PRIMARY}>Copy to clipboard</Button>
                            </CopyToClipboard>
                        }

                        {this.props.showSelectButton === true &&
                            <Button
                                color={Colors.PRIMARY}
                                onClick={() => this.handleEditItem(row.original)}
                            >
                                <FontAwesomeIcon icon="edit"/>
                            </Button>
                        }

                        {this.props.showDeleteButton === true &&
                            <Button
                                color={Colors.ALERT}
                                onClick={() => this.promptDeleteItem(row.original)}
                            >
                                <FontAwesomeIcon icon="trash"/>
                            </Button>
                        }
                    </ButtonGroup>
                ),
                show: this.props.showCopyButton || this.props.showSelectButton || this.props.showDeleteButton
            }
        ]

        const filters = (
            <React.Fragment>
                <div className="cell small-12 medium-6 large-3">
                    <span>Search</span>
                    <SearchBar
                        onDidChange={(newValue) => this.searchTextDidChange(newValue)}
                    />
                </div>

                <div className="cell small-12 medium-6 large-3">
                    <span>Organisation</span>
                    <ReactSelect
                        name="organisationID"
                        className="react-select"
                        onChange={(e) => this.organisationDidChange(e.target.value)}
                        value={this.state.searchOrganisationID}
                        options={this.state.organisationOptions}
                    />
                </div>
            </React.Fragment>

        )

        const table = (
            <ReactTable
                columns={columns}
                data={this.state.items}
                pageSize={this.state.pageSize}
                loading={this.state.isLoading}
                multisort={true}
                className='-striped'
            />
        )

        const buttons = [];

        if (PermissionUtil.create(PermissionUtil.PermissionCategories.knowledgeDocuments)) {
            buttons.push(
                <button
                    type="button"
                    className="button small"
                    onClick={this.handleAddNewItem}
                >
                    New Document
                </button>
            )
        }

        return (
            <React.Fragment>
                <TableContainer
                    title=""
                    filters={filters}
                    buttons={buttons}
                    table={table}
                />

                <Modal
                    classNames={{modal:"modal-medium"}}
                    open={this.state.modal.isOpen}
                    modalId="Modal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>

            </React.Fragment>
        );
    }
}
