import {PermissionUtil} from "../../utils/PermissionUtil";
import {SectionType} from "../../constants/SectionType";
import ConfigsTable from "../sectionConfig/ConfigsTable";
import React from "react";

export const PluginSupportSelector = (props) => {

    const {sectionType} = props;
    const {callback} = props;

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    // RENDER

    let permissions = PermissionUtil.get("plugin");
    switch (sectionType) {
        case SectionType.I_NEED_HELP_NOW:
            permissions = PermissionUtil.get("support"); break;
        default:
            console.log("Unsupported type: " + sectionType);
    }

    return (
        <>
            <div className={"modal-head"}>
                <div className={"modal-head"}>
                    <h3>Support</h3>
                </div>
            </div>

            <div className={"modal-body"}>
                <div className="grid-x grid-padding-x">
                    <div className="small-12 cell">

                        <ConfigsTable
                            permissions={permissions}
                            sectionType={sectionType}
                            isSelector={true}
                            callback={handleCallback}
                        />

                    </div>
                </div>
            </div>

        </>
    )

}