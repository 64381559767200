import React from 'react'
import EditorHelper from 'components/contentEditor/EditorHelper'
import Utils from 'utils/Utils'

export default class SubmitButton extends React.Component {

    render() {

        const {item, defaults, style, innerRef, ...props} = this.props;

        const title = Utils.value(Utils.getNested(this.props.item, "data", "title"), "");

        return (
            <span
                ref={innerRef}
                style={Object.assign(style,{
                    paddingLeft: EditorHelper.getMargin(item, defaults, "left") * this.props.scale,
                    paddingRight: EditorHelper.getMargin(item, defaults, "right") * this.props.scale,
                    paddingBottom: EditorHelper.getMargin(item, defaults, "bottom") * this.props.scale,
                    paddingTop: EditorHelper.getMargin(item, defaults, "top") * this.props.scale,
                    display: "block"
                })}

                {...props}
            >
                <span
                    style={{
                        fontFamily: "Barlow Condensed, sans-serif",
                        fontSize: 18 * this.props.scale,
                        textTransform: "uppercase",
                        fontWeight: "600",
                        color: "#575757",
                        minHeight: 50 * this.props.scale,
                        paddingTop: 12 * this.props.scale,
                        textAlign: "center",
                        border: "1px solid #575757",
                        borderRadius: 25 * this.props.scale,
                        lineHeight: "normal",
                        display: "block"
                    }}
                >
                    {title}
                </span>
            </span>
        )
    }

}