// Mapped to the DB

export const AwardType = {
    WELLBEING_CATEGORY_AWARD: 1,
    CHALLENGE_AWARD: 2,
    LIMITED_EDITION_CATEGORY_AWARD: 3,
    LIMITED_EDITION_HABIT_AWARD: 4,
    LIMITED_EDITION_ANY_HABIT_AWARD: 5
};

Object.freeze(AwardType);