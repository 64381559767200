import React from 'react'

export default class Device extends React.Component {

    state = {
        scale: 1.0
    };

    originalWidth = 375.0;

    componentDidMount() {
        window.addEventListener("resize", this.updateScale);
        this.updateScale();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateScale);
    }

    updateScale = () => {
        const width = this.screenInstance.clientWidth;
        const newScale = width / this.originalWidth;
        if (this.props.onScaleUpdated instanceof Function) {
            this.props.onScaleUpdated(newScale);
        }
    }

    render() {
        return (
            <div className="device-wrapper" style={{width: 300}}>
                <div className="device" data-device="iPhoneX" data-orientation="portrait" data-color="black">
                    <div
                        ref={ (instance) => this.screenInstance = instance}
                        className="screen"
                        style={{
                            paddingTop: "8%",
                            borderRadius: "26px",
                            zIndex: "0"
                        }}
                    >
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }

}