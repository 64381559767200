import React from 'react'
import Axios from 'axios'
import ReactSelect from 'components/common/ReactSelect'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'
import Image from 'components/common/Image'
import Modal from "react-responsive-modal"
import { AwardType } from "constants/AwardType"
import DatePicker from "../common/DatePicker";
import FileInput from "../common/FileInput";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UUID from "../../utils/UUID";
import StandardModal from "../common/StandardModal";
import ReactTable from "react-table";

class AwardEdit extends AppComponent {

    static defaultProps = {
        item: {}
    }

    constructor(props, context) {
        super(props);

        const parsedVisibility = props.item ? this.parseVisibility(props.item.visibility || []) : undefined;

        this.state = {
            item: props.item.langID ? Object.assign({}, props.item) : Object.assign({
                langID: null,
                translations: []
            }, props.item),
            validated: false,
            errors: {},
            touched: {},
            typeOptions: props.item.typeID ? [{value: props.item.typeID, label: props.item.typeName}] : [],
            habitOptions: props.item.habitID ? [{value: props.item.habitID, label: props.item.habitName}] : [],
            categoryOptions: props.item.categoryID ? [{value: props.item.categoryID, label: props.item.categoryName}] : [],
            visibility: parsedVisibility ? parsedVisibility.visibility : [{organisation: null, campuses: []}],
            campusOptions: parsedVisibility ? parsedVisibility.campuses : [],
            organisationOptions: parsedVisibility ? parsedVisibility.organisations : [],
            imagePickerURL: null,
            imagePickerFile: null,
            isUploading: false,
            uploadProgress: 0,
            isLoadingHabits: false,
            isLoadingCategories: false,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            },
            isLoadingTranslations: false,
            languageOptions: [],
            selectedLanguage: null
        };
    }

    componentDidMount() {

        if (this.state.item.langID === null) {
            this.getHabitOptions();
            this.getCategoryOptions();
            this.getAwardTypeOptions();
            this.getVisibilityOptions();

            if (this.state.item.id) {
                this.getTranslations();
            }
            else {
                this.getLanguages();
            }
        }
        
        this.validate();
    }

    getTranslations = () => {

        if (this.state.isLoadingTranslations) { return; }

        this.setState({
            isLoadingTranslations: true
        }, () => {
            Axios
                .get(API.award.getTranslations, {
                    params: {
                        id: this.state.item.id
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        this.setState(prevState => ({
                            ...prevState,
                            item: {
                                ...prevState.item,
                                translations: res.data.data.translations
                            }
                        }), () => {
                            this.getLanguages();
                        })
                    }
                })
        })
    }

    getLanguages = () => {
        Axios
            .get(API.organisation.getLanguages)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        isLoadingTranslations: false,
                        languageOptions: res.data.data.languages.filter((item) => { return !this.state.item.translations.find((o) => o.langID.toString() === item.id.toString()) }).map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    parseVisibility = (items) => {

        let visibility = {};
        let campuses = {};
        let organisations = [];

        items.forEach((visItem) => {

            if (visItem.organisationID in visibility) {
                visibility[visItem.organisationID].campuses.push(visItem.campusID);
                campuses[visItem.organisationID].push({value: visItem.campusID, label: visItem.campusName});
            }
            else {
                visibility[visItem.organisationID] = {
                    organisation: visItem.organisationID,
                    campuses: [visItem.campusID]
                }
                organisations.push({value: visItem.organisationID, label: visItem.organisationName});
                campuses[visItem.organisationID] = [{value: visItem.campusID, label: visItem.campusName}];
            }

        });

        let allValues = Object.values(visibility);

        return {
            visibility: allValues,
            campuses: campuses,
            organisations: organisations
        };
    }

    parseVisibilityOptions = (res) => {
        let organisationOptions = [];
        let campusOptions = {};

        res.forEach(o => {
            organisationOptions.push({
                value: o.organisation.id,
                label: o.organisation.name
            });

            campusOptions[o.organisation.id] = o.campuses.map((r) => { return {
                value: r.id,
                label: r.name
            }});
        });

        return {
            organisationOptions: organisationOptions,
            campusOptions: campusOptions
        }
    }

    handleVisibilityChange = (event, item) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let itemIndex = this.state.visibility.findIndex(i => i === item);

        if (itemIndex > -1) {

            let visibility = this.state.visibility;
            let newItem = Object.assign({}, item);

            if (name === "organisations") {

                newItem.organisation = value;
                newItem.campuses = [];
                visibility[itemIndex] = newItem;

                this.setState({
                    visibility: visibility
                }, () => {
                    this.validate();
                });
            }
            else if (name === "campuses") {

                newItem.campuses = value;
                visibility[itemIndex] = newItem;

                this.setState({
                    visibility: visibility
                }, () => {
                    this.validate();
                });

            }
        }
    }

    addVisibilityVariation = () => {

        let visibility = this.state.visibility;
        visibility.push({
            organisation: null,
            campuses: []
        });

        this.setState({
            visibility: visibility
        });
    }

    removeVisibilityVariation = (item) => {

        let visibility = this.state.visibility;

        const index = visibility.findIndex((i) => i === item);
        if (index > -1) {
            visibility.splice(index, 1);
        }

        this.setState({
            visibility: visibility
        }, () => {
            this.validate();
        });
    }

    getVisibilityOptions = () => {
        Axios
            .get(API.challenge.getVisibilityOptions)
            .then((res) => {
                if (res.data.success) {
                    const options = this.parseVisibilityOptions(res.data.data.options);

                    this.setState({
                        organisationOptions: options.organisationOptions,
                        campusOptions: options.campusOptions
                    }, () => {
                        this.validate();
                    });
                }
            })
    }

    getHabitOptions = () => {
        Axios
            .get(API.award.getHabitOptions)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        habitOptions: res.data.data.options.map((item) => { return {value: item.id, label: item.categoryName + " - " + item.name} })
                    })
                }
            })
    }

    getCategoryOptions = () => {
        Axios
            .get(API.award.getCategoryOptions)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        categoryOptions: res.data.data.options.map((item) => { return {value: item.id, label: item.name} })
                    })
                }
            })
    }

    getAwardTypeOptions = () => {
        Axios
            .get(API.award.getAwardTypeOptions)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        typeOptions: res.data.data.options.map((item) => { return {value: item.id, label: item.name} })
                    })
                }
            })
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        let self = this;

        reader.onloadend = () => {
            self.setState({
                imagePickerFile: file,
                imagePickerURL: reader.result
            }, () => {
                self.validate()
            })
        };

        reader.readAsDataURL(file);
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {


                let formData = new FormData();

                if (this.state.item.id) {
                    formData.append("id", this.state.item.id);
                }

                formData.append("name", this.state.item.name);
                formData.append("date", this.state.item.date);
                formData.append("typeID", this.state.item.typeID);
                formData.append("translations", JSON.stringify(this.state.item.translations));

                if (this.state.item.typeID === AwardType.LIMITED_EDITION_HABIT_AWARD) {
                    formData.append("habitID", this.state.item.habitID);
                }
                else if (this.state.item.typeID === AwardType.LIMITED_EDITION_CATEGORY_AWARD) {
                    formData.append("categoryID", this.state.item.categoryID);
                }

                if (this.state.imagePickerFile) {
                    formData.append("image", this.state.imagePickerFile);
                }

                this.state.visibility.forEach(item => {
                    item.campuses.forEach(campusID => {
                        formData.append("campuses[]", campusID);
                    })
                });


                Axios
                    .post((this.state.item.id ? API.award.updateAward : API.award.createAward), formData)
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted(res.data.data.item);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }

                        this.setState({
                            isUploading: false
                        });
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleFileDelete = (name) => {
        this.setState({
            imagePickerFile: null,
            imagePickerURL: null
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;


        let newState = {};

        if (name === "selectedLanguage") {
            newState[name] = value;
        }
        else {

            let item = Object.assign({}, this.state.item);

            let touched = Object.assign({}, this.state.touched);

            if (name === "typeID") {

                item.habitID = null;
                item.categoryID = null;

                delete touched.habitID;
                delete touched.categoryID;
                newState.touched = touched;

            }

            item[name] = value;
            newState.item = item;
        }

        this.setState(newState, () => {
            this.validate()
        });
    }

    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.name) {
            if (touched.name) {
                errors.name = "Award name is required";
            }
            validated = false;
        }

        if (!item.id && !this.state.imagePickerFile) {
            if (touched.imageFile) {
                errors.imageFile = "Image is required"
            }
            validated = false;
        }

        if (!item.typeID) {
            if (touched.typeID) {
                errors.typeID = "Award type is required"
            }
            validated = false;
        }

        if (!item.date) {
            if (touched.date) {
                errors.date = "Date is required"
            }
            validated = false;
        }

        if (item.typeID === AwardType.LIMITED_EDITION_HABIT_AWARD && !item.habitID) {
            if (touched.habitID) {
                errors.typeID = "Habit is required"
            }
            validated = false;
        }

        if (item.typeID === AwardType.LIMITED_EDITION_CATEGORY_AWARD && !item.categoryID) {
            if (touched.categoryID) {
                errors.categoryID = "Wellbeing Category is required"
            }
            validated = false;
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    handleAddTranslation = (langID) => {

        const language = this.state.languageOptions.find((i) => i.value.toString() === langID.toString());

        let translation = {
            langID: language.value,
            langName: language.label,
            name: "",
            id: UUID.uuidv4(),
            isNew: 1
        };

        if (!this.state.modal.isOpen) {
            const content = (
                <AwardEdit
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState(prevState => ({
                languageOptions: this.state.languageOptions.filter((i) => i.value.toString() !== language.value.toString()),
                selectedLanguage: null,
                item: {
                    ...prevState.item,
                    translations: [...prevState.item.translations, translation]
                },
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    isUploading: false,
                    content: content
                })
            }))
        }

    }

    updateTranslation = (updatedTranslation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.map((o) => {
                    if (o.langID.toString() === updatedTranslation.langID.toString()) {
                        return updatedTranslation;
                    }
                    else {
                        return o;
                    }
                })
            }
        }))
    }

    handleEditTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <AwardEdit
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    content: content
                })
            })
        }
    }

    handleDeleteTranslation = (translation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.filter((i) => i.langID.toString() !== translation.langID.toString() )
            },
            languageOptions: [...this.state.languageOptions, {label: translation.langName, value: translation.langID}].sort((a,b) => a.name < b.name ? 1 : -1 )
        }), () => {
            this.closeModal();
        })
    }

    showDeleteTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Translation"
                    content={(
                        <React.Fragment>
                            <p>Are you sure to delete <strong>{translation.langName}</strong> translation ? This action cannot be reverted.</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteTranslation(translation)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    isUploading: false,
                    content: content
                })
            })
        }
    }


    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />
                <div className="modal-head">
                    {this.state.item.langID === null ?
                        <h3>{this.state.item.id ? "Edit Award" : "New Award"}</h3>
                        :
                        <h3>Award ({this.state.item.langName} translation)</h3>
                    }
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="name" className="text-right middle ">{this.state.item.langID === null ? "*" : ""} Name</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.name && "error")}>
                                <input type="text" name="name" onChange={this.handleChange} value={this.state.item.name} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.name}</small>
                            </div>
                        </div>
                        {this.state.item.langID === null &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="date" className="text-right middle">* Date</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.date && "error")}>
                                    <DatePicker
                                        name="date"
                                        value={this.state.item.date}
                                        onChange={this.handleChange}
                                        options={{
                                            dateFormat: "Y-m-d",
                                            altFormat: "l, F j, Y",
                                            altInput: true,
                                            static: true
                                        }}
                                        disabled={this.state.item.id !== undefined || this.state.item.langID !== null}
                                    />
                                    <small className="error">{this.state.errors.date}</small>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="typeID" className="text-right middle">* Type</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.typeID && "error")}>
                                    <ReactSelect
                                        name="typeID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.item.typeID}
                                        options={this.state.typeOptions}
                                        onBlur={this.handleBlur}
                                        isDisabled={this.state.item.id !== undefined || this.state.item.langID !== null}
                                    />
                                    <small className="error">{this.state.errors.typeID}</small>
                                </div>
                            </div>
                            {this.state.item.typeID === AwardType.LIMITED_EDITION_HABIT_AWARD &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="habitID" className="text-right middle">* Habit</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.habitID && "error")}>
                                    <ReactSelect
                                        name="habitID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.item.habitID}
                                        options={this.state.habitOptions}
                                        onBlur={this.handleBlur}
                                        isDisabled={this.state.item.id !== undefined || this.state.item.langID !== null}
                                    />
                                    <small className="error">{this.state.errors.habitID}</small>
                                </div>
                            </div>
                            }
                        </React.Fragment>
                        }
                        {this.state.item.typeID === AwardType.LIMITED_EDITION_CATEGORY_AWARD &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="categoryID" className="text-right middle">* Category</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.categoryID && "error")}>
                                <ReactSelect
                                    name="categoryID"
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.item.categoryID}
                                    options={this.state.categoryOptions}
                                    onBlur={this.handleBlur}
                                    isDisabled={this.state.item.id !== undefined || this.state.item.langID !== null}
                                />
                                <small className="error">{this.state.errors.categoryID}</small>
                            </div>
                        </div>
                        }
                        {this.state.item.langID === null &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="imageFile" className="text-right middle">Select Image</label>
                                </div>
                                <div className="large-9 small-9 cell ">
                                    <FileInput
                                        name="imageFile"
                                        onChange={this.handleFilePickerChange}
                                        onBlur={this.handleBlur}
                                        onDelete={this.handleFileDelete}
                                        placeholder="Not selected. Ideal size W:200px H:225px PNG with transparent background"
                                        accept="image/png"
                                    />
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="imageFile" className="text-right middle">* Current Image</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.imageFile && "error")} style={{marginBottom: 20}}>
                                    {this.state.imagePickerURL &&
                                    <Image src={this.state.imagePickerURL} width={80} height={90} border={true} fit="contain"/>
                                    }
                                    {!this.state.imagePickerURL && this.state.item.imageURL &&
                                    <Image src={this.state.item.imageURL} width={80} height={90} border={true} fit="contain"/>
                                    }
                                    {!this.state.imagePickerURL && !this.state.item.imageURL &&
                                    <p>No image selected</p>
                                    }
                                    <small className="error">{this.state.errors.imageFile}</small>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <fieldset className="fieldset">
                                        <legend>Visibility</legend>
                                        <div className="grid-x grid-padding-x">
                                            <div className="cell small-12">
                                                <Button type="button" size={Sizes.SMALL} onClick={this.addVisibilityVariation} disabled={this.state.visibility.length >= this.state.organisationOptions.length}>
                                                    Add&nbsp;
                                                    <FontAwesomeIcon icon="plus"/>
                                                </Button>
                                            </div>
                                        </div>
                                        {this.state.visibility.map(item =>
                                            <div className="grid-x grid-padding-x"  >
                                                <div className="cell small-12 medium-6 large-3">
                                                    <div className="input-button-group">
                                                        <button className="button clear alert push-left" type="button" onClick={() => this.removeVisibilityVariation(item)}>
                                                            <FontAwesomeIcon icon="times-circle"/>
                                                        </button>
                                                        <div className="input-element">
                                                            <span>Organisation</span>
                                                            <ReactSelect
                                                                style={{width: "100%"}}
                                                                name="organisations"
                                                                onChange={(e) => this.handleVisibilityChange(e, item)}
                                                                value={item.organisation}
                                                                options={this.state.organisationOptions}
                                                                filterOption={(option) => !this.state.visibility.find(v => v.organisation && v.organisation.toString() === option.value.toString())}
                                                                placeholder="None"
                                                                className="react-select"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cell small-12 medium-6 large-9">
                                                    <span>Campuses</span>
                                                    <ReactSelect
                                                        name="campuses"
                                                        onChange={(e) => this.handleVisibilityChange(e, item)}
                                                        value={item.campuses}
                                                        options={item.organisation ? this.state.campusOptions[item.organisation] : []}
                                                        isMulti
                                                        className="react-select"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <fieldset className="fieldset">
                                        <legend>Translations</legend>
                                        <div className="grid-x grid-padding-x">
                                            <div className="cell small-12">
                                                <span>Add translation for</span>
                                                <ReactSelect
                                                    name="selectedLanguage"
                                                    className="react-select translation-selector"
                                                    onChange={this.handleChange}
                                                    value={this.state.selectedLanguage}
                                                    options={this.state.languageOptions}
                                                    onBlur={this.handleBlur}
                                                />
                                                <Button type="button" className="translation-button" color={Colors.PRIMARY} isDisabled={!this.state.selectedLanguage} onClick={() => this.handleAddTranslation(this.state.selectedLanguage)}>Add</Button>
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="cell small-12">
                                                <ReactTable
                                                    columns={[
                                                        {
                                                            Header: 'Language',
                                                            accessor: 'langName'
                                                        },
                                                        {
                                                            Header: 'Actions',
                                                            Cell: row => (
                                                                <ButtonGroup size={Sizes.TINY}>
                                                                    {this.context.permissions.campus.edit &&
                                                                    <Button type="button" color={Colors.PRIMARY} onClick={() => this.handleEditTranslation(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                                                                    }
                                                                    {this.context.permissions.campus.delete &&
                                                                    <Button type="button" color={Colors.ALERT} onClick={() => this.showDeleteTranslation(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                                                                    }
                                                                </ButtonGroup>
                                                            ),
                                                            maxWidth: 100,
                                                            show: this.context.permissions.campus.delete || this.context.permissions.campus.edit
                                                        }
                                                    ]}
                                                    data={this.state.item.translations}
                                                    className='-striped'
                                                    showPagination={false}
                                                    noDataText={"No translations"}
                                                    pageSize={this.state.item.translations ? this.state.item.translations.length : 1}
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    {this.state.item.langID === null ?
                                        <React.Fragment>
                                            <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                                Cancel
                                            </button>
                                            <button className="button success" type="submit" disabled={!this.state.validated || this.state.isLoadingTranslations}>
                                                Submit
                                            </button>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <button className="button secondary" type="button" onClick={() => this.props.onCompleted(this.state.item)}>
                                                Update
                                            </button>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="HabitInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}

export default AwardEdit;
