import Utils from 'utils/Utils'

const EditorHelper = {
    getFontFamily: (item, defaults) => {
        const fontType = Utils.value(Utils.getNested(item, "style", "font", "type"), defaults.font.type);
        switch (fontType) {
            case "secondary":
                return "Helvetica Neue, Helvetica, Arial, sans-serif";
            default:
                return "Barlow Condensed";
        }
    },
    getFontSize: (item, defaults) => {
        return Utils.value(Utils.getNested(item, "style", "font", "size"), defaults.font.size);
    },
    getFontColor: (item, defaults) => {
        return Utils.value(Utils.getNested(item, "style", "font", "color"), defaults.font.color);
    },
    getMargin: (item, defaults, type) => {
        return Utils.value(Utils.getNested(item, "style", "margins", type), defaults.margins[type]);
    }
}

export default EditorHelper;