import React from 'react'
import PageContent from 'components/common/PageContent'
import AppComponent from 'components/common/AppComponent'
import ChatFlowsTable from 'components/chatflows/ChatFlowsTable'

const pathElements = [
    {id: 0, title: "Home", url: "/"},
    {id: 1, title: "Chat Flows"}
];

export default class ChatFlows extends AppComponent {

    render() {
        return (
            <PageContent pathElements={pathElements} >
                <ChatFlowsTable />
            </PageContent>
        );
    }
}