import React from 'react'
import AppComponent from 'components/common/AppComponent'
import {Button, ButtonGroup, Colors} from "react-foundation"
import ReactSelect from "../common/ReactSelect"
import Axios from "axios";
import {API} from "../../api/APIClient"
import BlockUI from 'react-block-ui'
import { NotificationManager } from "react-notifications"
import HabitsSelector from "../habits/HabitsSelector";
import {ChatActionType} from "../../constants/ChatActionType";
import Modal from "react-responsive-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class ChatTinyHabits extends AppComponent {

    constructor(props, context) {
        super(props);

        this.state = {
            chatFlowOptions: [],
            tinyHabits: [],
            trigger: null,
            isLoading: false,
            isLoadingChatFlowOptions: false,
            isSubmitting: false,
            validated: false,
            modal: {
                isOpen: false,
                content: null
            }
        };
    }

    componentDidMount() {
        this.getChatFlowOptions();
        this.getTinyHabits();
        this.validate();
    }

    getChatFlowOptions = () => {
        if (!this.isLoadingChatFlowOptions) {

            this.setState({
                isLoadingChatFlowOptions: true
            }, () => {

                Axios
                    .get(API.chat.getChatFlowOptions)
                    .then((res) => {
                        if (res.data.success) {

                            let chatFlows = res.data.data.chatFlows;
                            chatFlows.unshift({value: null, label: "Not Selected"});

                            this.setState({
                                isLoadingChatFlowOptions: false,
                                chatFlowOptions: res.data.data.chatFlows
                            })
                        }
                        else {
                            this.setState({
                                isLoadingChatFlowOptions: false
                            })
                        }
                    })

            })

        }
    }

    getTinyHabits = () => {
        if (!this.isLoading) {

            this.setState({
                isLoading: true
            }, () => {

                Axios
                    .get(API.chat.getTinyHabits)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoading: false,
                                tinyHabits: res.data.data.tinyHabits,
                                trigger: res.data.data.trigger
                            }, () => {
                                this.validate();
                            })
                        }
                        else {
                            this.setState({
                                isLoadingPlan: false
                            }, () => {
                                this.validate();
                            })
                        }
                    })
            })

        }
    }



    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let newState = {};

        if (name === "trigger") {
            let trigger = Object.assign({}, this.state.trigger);
            trigger.chatFlowID = value;
            newState.trigger = trigger;
        }
        else {
            let tinyHabits = this.state.tinyHabits;

            let index = parseInt(name.replace("tinyHabit_", ""));
            let tinyHabit = Object.assign({}, tinyHabits[index]);
            tinyHabit.chatFlowID = value;
            tinyHabits[index] = tinyHabit;
            newState.tinyHabits = tinyHabits;
        }

        this.setState(newState, () => {
            this.validate();
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isSubmitting) {
            this.setState({
                isSubmitting: true
            }, () => {
                Axios
                    .post((API.chat.updateTinyHabits), {
                        tinyHabits: this.state.tinyHabits,
                        triggerChatFlowID: this.state.trigger.chatFlowID
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isSubmitting: false,
                                tinyHabits: res.data.data.tinyHabits,
                                trigger: res.data.data.trigger
                            }, () => {
                                NotificationManager.success("Saved successfully");
                            })
                        }
                        else {
                            this.setState({
                                isUploading: false
                            }, () => {
                                NotificationManager.error(res.data.error.desc);
                            })
                        }
                    })
            })
        }
    }

    showHabitModal = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <HabitsSelector
                isSingleSelection
                onSubmitted={(habit) => {

                    let tinyHabits = this.state.tinyHabits;

                    const found = tinyHabits.find(element => element.habitID === habit.id);
                    
                    if (found) {
                        NotificationManager.error("Selected habit is already on the list of specialised habits");
                        return;
                    }
        
                    tinyHabits.push({
                        habitID: habit.id,
                        habitName: habit.name,
                        chatFlowID: null
                    });
                    
                    this.setState({
                        tinyHabits: tinyHabits,
                        modal: {
                            isOpen: false,
                            content: null
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {
            this.validate();
        })
    }

    closeModal = () => {
        this.setState({
            modal: {
                isOpen: false,
                content: null
            }
        }, () => {
            this.validate();
        })
    }

    removeTinyHabit = (index) => {

        let tinyHabits = this.state.tinyHabits;

        tinyHabits.splice(index, 1);

        this.setState({
            tinyHabits: tinyHabits
        }, () => {
            this.validate();
        })

    }

    validate = () => {

        let validated = true;

        const tinyHabits = this.state.tinyHabits;

        tinyHabits.forEach((item) => {
            if (!item.chatFlowID) {
                validated = false;
            }
        })

        this.setState({
            validated: validated
        })
    }


    render() {

        return (
            <BlockUI tag="div" blocking={this.isSubmitting}>
                <div className="gird-x grid-padding-x" style={{marginBottom: 100}}>
                    <div className="cell small-12" style={{margin: "20px 0"}}>
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset edit-field">
                                    <legend>Generic trigger</legend>
                                    <BlockUI tag="div" blocking={this.state.isLoading}>
                                        <React.Fragment>
                                            {this.state.trigger &&
                                            <div className="grid-x grid-padding-x">
                                                <div className="small-3 cell">
                                                    <label htmlFor="trigger" className="text-right middle">{this.state.trigger.name}</label>
                                                </div>
                                                <div className="small-9 cell">
                                                    <ReactSelect
                                                        name="trigger"
                                                        className="react-select"
                                                        onChange={this.handleChange}
                                                        value={this.state.trigger.chatFlowID}
                                                        options={this.state.chatFlowOptions}
                                                    />
                                                    <small className="field-info">{this.state.trigger.desc}</small>
                                                </div>
                                            </div>
                                            }
                                        </React.Fragment>
                                    </BlockUI>
                                </fieldset>
                            </div>
                            <div className="small-12 cell">
                                <fieldset className="fieldset edit-field">
                                    <legend>Specialised triggers</legend>
                                    {!this.state.isLoading &&
                                    <div className="grid-x grid-padding-x">
                                        <div className="cell small-12">
                                            <ButtonGroup className="float-right">
                                                <Button color={Colors.PRIMARY} onClick={this.showHabitModal} >Add new</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                    }
                                    <BlockUI tag="div" blocking={this.state.isLoading}>
                                        {this.state.tinyHabits && this.state.tinyHabits.length > 0 ?
                                        <React.Fragment>

                                            {this.state.tinyHabits.map((tinyHabit, index) => {

                                                return (

                                                    <div className="grid-x grid-padding-x" key={index}>
                                                        <div className="small-3 cell">
                                                            <label htmlFor={"tinyHabit_" + index} className="text-right middle">{tinyHabit.habitName}</label>
                                                        </div>
                                                        <div className="small-9 cell" >
                                                            <div style={{display: "flex"}}>
                                                                <div style={{flexGrow: 100}}>
                                                                    <ReactSelect
                                                                        name={"tinyHabit_" + index}
                                                                        className="react-select"
                                                                        onChange={this.handleChange}
                                                                        value={this.state.tinyHabits[index].chatFlowID}
                                                                        options={this.state.chatFlowOptions}
                                                                        style={{flexGrow: 100}}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <button style={{height:5}} className="button clear alert" type="button" onClick={() => this.removeTinyHabit(index)}>
                                                                        <FontAwesomeIcon icon="times-circle"/>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )


                                            })}
                                        </React.Fragment> :
                                        <React.Fragment>
                                            {!this.state.isLoading &&
                                            <div className="grid-x grid-padding-x">
                                                <div className="cell small-12">
                                                    <p className="text-center">No specialised triggers</p>
                                                </div>
                                            </div>
                                            }
                                        </React.Fragment>
                                        }
                                    </BlockUI>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fix-save">
                    <ButtonGroup>
                        <Button className="edit-button" onClick={() => this.setState({redirect: true})}>Cancel</Button>
                        <Button className="edit-button" color={Colors.SUCCESS} onClick={this.handleSubmit} isDisabled={this.state.validated ? 0 : 1}>Save</Button>
                    </ButtonGroup>
                </div>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </BlockUI>
        )
    }

}


