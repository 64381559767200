import React from 'react'
import Flatpickr from 'react-flatpickr'

class DatePicker extends React.Component {

    handleChange = (selectedDates, dateString, instance) => {
        if (this.props.onChange instanceof Function) {
            this.props.onChange({
                target: {
                    type: "date-picker",
                    name: instance.input.name,
                    value: dateString
                }
            });
        }
    }

    render() {
        let { onChange, options, ...props } = this.props;


        if (typeof options !== 'object') {
            options = {}
        }

        options.time_24hr = true;

        return (
            <Flatpickr
                onChange={this.handleChange}
                options={options}
                {...props}
            >
                {this.props.children}
            </Flatpickr>
        );
    }
}

export default DatePicker;