import React from 'react'
import logoImage from 'assets/img/uniwellbeing-logo.png'
import Image from 'components/common/Image'

class UnsupportedBrowser extends React.Component {

    render() {
        return (
            <div className="login-wrapper">
                <div className="login-box">
                    <div className="grid-container">
                        <div className="grid-x grid-padding-x">
                            <div className="cell large-2 large-offset-5 medium-6 medium-offset-3 small-10 small-offset-1">
                                <Image src={logoImage} alt="Uniwellbeing Logo" fit="contain"/>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="cell small-12 text-center">
                                <h3>Internet Explorer is not supported</h3>
                            </div>
                            <div className="cell small-12 text-center text-info" style={{marginTop: 40}}>
                                <p><strong>Please use one of the modern browsers:</strong></p>
                                <p style={{textDecoration:"underline"}}><a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a></p>
                                <p style={{textDecoration:"underline"}}><a href="https://www.mozilla.org/en-GB/firefox/features/fast/">Mozilla Firefox</a></p>
                                <p style={{textDecoration:"underline"}}><a href="https://www.microsoft.com/en-gb/windows/microsoft-edge">Microsoft Edge (Windows Only)</a></p>
                                <p>Safari (MacOS only)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default UnsupportedBrowser;