import AppComponent from "../../common/AppComponent";
import Axios from "axios";
import {API} from "../../../api/APIClient";
import React from "react";
import ModalProgress from "../../common/ModalProgress";
import {NotificationManager} from "react-notifications";

export default class AnonymiseUsers extends AppComponent {


    constructor(props, context) {
        super(props);

        this.state = {
            organisationID: props.organisationID,
            organisationName: props.organisationName,
            validated: false,
            isUploading: false,
            checkWord: ""
        };
    }

    componentDidMount() {
        this.validate();
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {
                Axios
                    .post(API.appUser.deleteAllUsers, {
                        organisationID: this.state.organisationID
                    })
                    .then((res) => {
                        if (res.data.success) {
                            NotificationManager.success("Deleted " + res.data.data.deletedAccounts + " accounts");
                            this.props.onSubmitted(res.data.data.item);
                        }
                        else if (res.data.error) {
                            NotificationManager.error(res.data.error);
                        }

                        this.setState({
                            isUploading: false
                        });
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        this.setState({
            checkWord: event.target.value
        }, () => {
            this.validate()
        });
    }

    validate = () => {
        this.setState({
            validated: this.state.checkWord === "Delete"
        });
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} title={"Deleting accounts. Please wait"} />
                <div className="modal-head" style={{backgroundColor: "#CC4B37"}}>
                    <h3 style={{color: "white"}}>Delete all mobile user accounts</h3>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <p><strong>Warning!</strong> This action will <strong><span style={{color: "#CC4B37"}}>delete</span></strong> and anonymise personal data belonging to all mobile app users associated with <strong>{this.state.organisationName}</strong>. This action cannot be reversed, and the data will be permanently removed. Please confirm your action by typing "Delete" below.</p>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <input name="checkWord" type="text" value={this.state.checkWord} placeholder="Delete" onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button alert" type="submit" disabled={!this.state.validated}>
                                        Delete accounts
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}