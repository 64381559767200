import React from 'react'
import Axios from 'axios'
import { API } from 'api/APIClient'
import { UserRole } from 'constants/UserRole'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import Image from 'components/common/Image'
import profileImgPlaceholder from 'assets/img/profile_no_photo.png'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'

class AppUserForm extends AppComponent {

    static defaultProps = {
        item: {},
        onCancel: () => void 0
    }

    constructor(props) {
        super(props);

        this.state = {
            item: Object.assign({}, props.item),
            validated: false,
            errors: {},
            touched: {},
            isUploading: false
        };
    }

    componentDidMount() {
        this.validate();
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.item.id && !this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {
                Axios
                    .post((API.appUser.updateUser), this.state.item)
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted(res.data.data.user);
                        }
                        else {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }
                        this.setState({
                            isUploading: false
                        })
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1:0) : target.value;
        const name = target.name;

        let item = this.state.item;
        item[name] = value;

        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    validate = () => {

        let validated = true;
        let errors = {};

        // Nothing to validate on that form

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />
                <div className="modal-head">
                    <h3>{this.state.item.id ? "Edit App User" : "New App User"}</h3>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 text-center">
                                <Image
                                    src={this.state.item.profileImageURL}
                                    width={150}
                                    height={150}
                                    circular={true}
                                    placeholder={profileImgPlaceholder}
                                    border={true}
                                />
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="active" className="text-right middle">Active</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.active && "error")}>
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="active" onChange={this.handleChange} checked={this.state.item.active === 1} />
                                    <span className="chkmk"></span>
                                    <small className="error">{this.state.errors.active}</small>
                                </label>
                            </div>
                        </div>
                        {this.context.permissions.appUser.allOrgs &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="organisationName" className="text-right middle">* Organisation</label>
                            </div>
                            <div className="small-9 cell">
                                <input type="text" name="organisationName" value={this.state.item.organisationName} disabled/>
                            </div>
                        </div>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="firstName" className="text-right middle">* First name</label>
                            </div>
                            <div className="small-9 cell">
                                <input type="text" name="firstName" value={this.state.item.firstName} disabled/>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="lastName" className="text-right middle">* Last name</label>
                            </div>
                            <div className="small-9 cell">
                                <input type="text" name="lastName" value={this.state.item.lastName} disabled/>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="email" className="text-right middle">* Personal Email</label>
                            </div>
                            <div className="small-9 cell">
                                <input type="email" name="email" value={this.state.item.secondaryEmail} disabled/>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="genderName" className="text-right middle">* Gender</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <input type="text" name="genderName" value={this.state.item.genderName} disabled/>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="dob" className="text-right middle">DOB</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.dob && "error")}>
                                <SingleDatePicker
                                    date={moment(this.state.item.dob, "YYYY-MM-DD")}
                                    disabled
                                    focused={false}
                                    id="dob"
                                    showDefaultInputIcon
                                    onFocusChange={()=>void 0}
                                    onDateChange={()=>void 0}
                                />
                                <small className="error">{this.state.errors.dob}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="onboardingCompleted" className="text-right middle">On-boarding Completed</label>
                            </div>
                            <div className="small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="onboardingCompleted" checked={this.state.item.onboardingCompleted || 0 === 1} disabled/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="contactPermission" className="text-right middle">Contact permitted</label>
                            </div>
                            <div className="small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="contactPermission" checked={this.state.item.contactPermitted || 0 === 1} disabled/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        <fieldset className="fieldset">
                            <legend>University details</legend>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="email" className="text-right middle">* Email</label>
                                </div>
                                <div className="small-9 cell">
                                    <input type="email" name="email" value={this.state.item.email} disabled/>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="roleName" className="text-right middle">* Role</label>
                                </div>
                                <div className="large-9 small-9 cell">
                                    <input type="text" name="roleName" value={this.state.item.roleName} disabled/>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="campusName" className="text-right middle">* Campus</label>
                                </div>
                                <div className="large-9 small-9 cell">
                                    <input type="text" name="campusName" value={this.state.item.campusName} disabled/>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="departmentName" className="text-right middle">* Department</label>
                                </div>
                                <div className="large-9 small-9 cell">
                                    <input type="text" name="departmentName" value={this.state.item.departmentName} disabled/>
                                </div>
                            </div>
                            {(this.state.item.roleID === UserRole.STUDENT || this.state.item.roleID === UserRole.STUDENT_AND_STAFF) &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="courseName" className="text-right middle">* Course</label>
                                    </div>
                                    <div className="large-9 small-9 cell">
                                        <input type="text" name="courseName" value={this.state.item.courseName} disabled/>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="courseLength" className="text-right middle">* Course Length</label>
                                    </div>
                                    <div className="large-9 small-9 cell">
                                        <input type="text" name="courseLength" value={this.state.item.courseLength + " " + (this.state.item.courseLength === 1 ? "year" : "years")} disabled/>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="courseStartYear" className="text-right middle">* Course Start Year</label>
                                    </div>
                                    <div className="large-9 small-9 cell">
                                        <input type="text" name="courseStartYear" value={this.state.item.courseStartYear} disabled/>
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                        </fieldset>
                        <fieldset className="fieldset">
                            <legend>Question & Answers</legend>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="qaDisabled" className="text-right middle">Blocked</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.qaDisabled && "error")}>
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="qaDisabled" onBlur={this.handleBlur} onChange={this.handleChange} checked={this.state.item.qaDisabled || 0 === 1} />
                                        <span className="chkmk"></span>
                                        <small className="error">{this.state.errors.qaDisabled}</small>
                                    </label>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="qaAdvisor" className="text-right middle">Is Advisor</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.qaAdvisor && "error")}>
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="qaAdvisor" onBlur={this.handleBlur} onChange={this.handleChange} checked={this.state.item.qaAdvisor || 0 === 1} />
                                        <span className="chkmk"></span>
                                        <small className="error">{this.state.errors.qaAdvisor}</small>
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="fieldset">
                            <legend>Privacy Settings</legend>
                            <small>User agreed to share its data with the following partners:</small>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="shareRecruiters" className="text-right middle">Recruiters</label>
                                </div>
                                <div className="small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="shareRecruiters" checked={this.state.item.shareRecruiters || 0 === 1} disabled/>
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="shareAccommodation" className="text-right middle">Accomodation</label>
                                </div>
                                <div className="small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="shareAccommodation" checked={this.state.item.shareAccommodation || 0 === 1} disabled/>
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="shareLifestyle" className="text-right middle">Lifestyle</label>
                                </div>
                                <div className="small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="shareLifestyle" onChange={this.handleChange} checked={this.state.item.shareLifestyle || 0 === 1} disabled/>
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="shareHealth" className="text-right middle">Health</label>
                                </div>
                                <div className="small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="shareHealth" onChange={this.handleChange} checked={this.state.item.shareHealth || 0 === 1} disabled/>
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default AppUserForm;
