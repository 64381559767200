import React from 'react'
import Axios from 'axios'
import ReactSelect from 'components/common/ReactSelect'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'
import moment from 'moment'
import { NotificationManager } from "react-notifications"
import DateHelper from 'utils/DateHelper'
import UUID from "../../../utils/UUID";
import StandardModal from "../../common/StandardModal";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import ReactTable from "react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "react-responsive-modal";

export default class CampusForm extends AppComponent {

    static defaultProps = {
        item: {}
    }

    constructor(props, context) {
        super(props);

        this.state = {
            item: Object.assign({
                createdAt: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                organisationID: context.organisation.id,
                active: 1,
                langID: null,
                roleConfigs: [],
                translations: []
            }, props.item),
            organisationOptions: [
                props.item.organisationID ? {value: props.item.organisationID, label: props.item.organisationName} : {value: context.organisation.id, label: context.organisation.name}
            ],
            validated: false,
            errors: {},
            touched: {},
            roleOptions: [],
            configOptions: [],
            isUploading: false,
            isLoadingConfigs: true,
            isLoadingCampusConfigs: !!props.item.id,
            isLoadingTranslations: false,
            modal: {
                isOpen: false,
                content: null,
                error: null
            },
            languageOptions: [],
            selectedLanguage: null
        };
    }



    componentDidMount() {
        this.validate();
        if (this.context.permissions.campus.allOrgs) {
            this.getOrganisationOptions();
        }

        if (this.state.item.id && this.state.item.langID === null) {
            this.getTranslations();
        }
        else {
            this.getLanguages();
        }

        if (this.state.item.id) {
            this.getCampusConfigs();
        }

        this.getRoleOptions();
        this.getAppConfigs();
    }

    getTranslations = () => {

        if (this.state.isLoadingTranslations) { return; }

        this.setState({
            isLoadingTranslations: true
        }, () => {
            Axios
                .get(API.campus.getTranslations, {
                    params: {
                        id: this.state.item.id
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        this.setState(prevState => ({
                            ...prevState,
                            item: {
                                ...prevState.item,
                                translations: res.data.data.translations
                            }
                        }), () => {
                            this.getLanguages();
                        })
                    }
                })
        })
    }

    getLanguages = () => {
        Axios
            .get(API.organisation.getLanguages)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        isLoadingTranslations: false,
                        languageOptions: res.data.data.languages.filter((item) => { return !this.state.item.translations.find((o) => o.langID.toString() === item.id.toString()) }).map((item) => { return {value: item.id.toString(), label: item.name} })
                    }, () => {
                        console.log("completed");
                        console.log(this.state);
                    })
                }
            })
    }

    getCampusConfigs = () => {
        this.setState({
            isLoadingCampusConfigs: true
        }, () => {

            Axios
                .get(API.appConfig.getCampusConfigs, {
                    params: {
                        campusID: this.state.item.id
                    }
                })
                .then((res) => {
                    if (res.data.success) {

                        let item = Object.assign({}, this.state.item);

                        let roleConfigs = {};
                        res.data.data.configs.forEach(i => {
                            roleConfigs[i.userRoleID] = i.appConfigID;
                        });

                        item.roleConfigs = roleConfigs;

                        this.setState({
                            isLoadingCampusConfigs: false,
                            item: item
                        })
                    }
                    else {
                        this.setState({
                            isLoadingCampusConfigs: false
                        });
                        NotificationManager.error(res.data.error.desc);
                    }
                })

        })
    }

    getAppConfigs = () => {
        this.setState({
            isLoadingConfigs: true
        }, () => {

            Axios
                .get(API.appConfig.getAppConfigs, {
                    params: {
                        organisationID: this.state.item.organisationID
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        this.setState({
                            isLoadingConfigs: false,
                            configOptions: res.data.data.configs.map(i => { return {value: i.id, label: i.name}})
                        })
                    }
                    else {
                        this.setState({
                            isLoadingConfigs: false
                        });
                        NotificationManager.error(res.data.error.desc);
                    }
                })
        })
    }

    getRoleOptions = () => {
        Axios
            .get(API.appUser.getRoleOptions)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        roleOptions: res.data.data.roleOptions.map((i) => { return {value: i.id, label: i.name}})
                    })
                }
            })
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {
                Axios
                    .post((this.state.item.id ? API.campus.updateCampus : API.campus.createCampus), this.state.item)
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted(res.data.data.item);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }

                        this.setState({
                            isUploading: false
                        });
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;


        let newState = {};

        if (name === "selectedLanguage") {
            newState[name] = value;
        }
        else {
            let item = this.state.item;
            item[name] = value;
            newState.item = item;

            if (name === "organisationID") {
                item.roleConfigs = {};
                newState.configOptions = [];
                newState.sLoadingConfigs = true;
            }
        }


        this.setState(newState, () => {
            if (name === "organisationID") {
                this.getAppConfigs();
            }
            else {
                this.validate()
            }

        });
    }

    handleConfigChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = this.state.item;
        let roleConfigs = Object.assign({}, this.state.item.roleConfigs);
        roleConfigs[name] = value;

        item.roleConfigs = roleConfigs;

        this.setState({
            item: item
        }, () => {
            this.validate();
        })

    }

    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.name) {
            if (touched.name) {
                errors.name = "Campus name is required";
            }
            validated = false;
        }

        if (this.state.roleOptions.length === 0) {
            validated = false;
        }

        if (this.state.configOptions.length === 0) {
            validated = false;
        }

        this.state.roleOptions.forEach(i => {
            if (!item.roleConfigs.hasOwnProperty(i.value) || item.roleConfigs[i.value] === null) {
                validated = false;
            }
        });

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    handleAddTranslation = (langID) => {

        const language = this.state.languageOptions.find((i) => i.value.toString() === langID.toString());

        let translation = Object.assign({}, this.state.item, {
            langID: language.value,
            langName: language.label,
            name: "",
            id: UUID.uuidv4(),
            isNew: 1
        });

        if (!this.state.modal.isOpen) {
            const content = (
                <CampusForm
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState(prevState => ({
                languageOptions: this.state.languageOptions.filter((i) => i.value.toString() !== language.value.toString()),
                selectedLanguage: null,
                item: {
                    ...prevState.item,
                    translations: [...prevState.item.translations, translation]
                },
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            }))
        }

    }

    updateTranslation = (updatedTranslation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.map((o) => {
                    if (o.langID.toString() === updatedTranslation.langID.toString()) {
                        return updatedTranslation;
                    }
                    else {
                        return o;
                    }
                })
            }
        }))
    }

    handleEditTranslation = (translation) => {

        translation.organisationID = this.state.item.organisationID;
        translation.organisationName = this.state.item.organisationName;
        translation.active = this.state.item.active;

        if (!this.state.modal.isOpen) {
            const content = (
                <CampusForm
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }

    handleDeleteTranslation = (translation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.filter((i) => i.langID.toString() !== translation.langID.toString() )
            },
            languageOptions: [...this.state.languageOptions, {label: translation.langName, value: translation.langID}].sort((a,b) => a.name < b.name ? 1 : -1 )
        }), () => {
            this.closeModal();
        })
    }

    showDeleteTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Department"
                    content={(
                        <React.Fragment>
                            <p>Are you sure to delete <strong>{translation.langName}</strong> translation ? This action cannot be reverted.</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteTranslation(translation)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }


    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />
                <div className="modal-head">
                    {this.state.item.langID === null ?
                        <h3>{this.state.item.id ? "Edit Campus" : "New Campus"}</h3>
                        :
                        <h3>Campus ({this.state.item.langName} translation)</h3>
                    }
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        {this.state.item.langID === null &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="active" className="text-right middle">Active</label>
                                    </div>
                                    <div className={"small-9 cell " + (this.state.errors.active && "error")}>
                                        <label className="chkbx-container">
                                            <input className="chkbx" type="checkbox" name="active" onChange={this.handleChange} checked={this.state.item.active === 1} onBlur={this.handleBlur} disabled={this.state.item.langID !== null}/>
                                            <span className="chkmk"></span>
                                            <small className="error">{this.state.errors.active}</small>
                                        </label>
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="createdAt" className="text-right middle">Created At</label>
                                    </div>
                                    <div className="large-9 small-9 cell">
                                        <input name="createdAt" type="text" value={moment(DateHelper.convertToLocalDateTime(this.state.item.createdAt), "YYYY-MM-DD HH:mm:ss").format("LLLL")} disabled={true} />
                                    </div>
                                </div>
                                {this.context.permissions.campus.allOrgs && !this.props.item.id ?
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="organisationID" className="text-right middle">* Organisation</label>
                                        </div>
                                        <div className={"small-9 cell " + (this.state.errors.organisationID && "error")}>
                                            <ReactSelect
                                                name="organisationID"
                                                className="react-select"
                                                onChange={this.handleChange}
                                                value={this.state.item.organisationID}
                                                options={this.state.organisationOptions}
                                                onBlur={this.handleBlur}
                                                isDisabled={this.state.item.langID !== null}
                                            />
                                            <small className="error">{this.state.errors.organisationID}</small>
                                        </div>
                                    </div>
                                    :
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="organisationName" className="text-right middle ">* Organisation</label>
                                        </div>
                                        <div className="small-9 cell">
                                            <input type="text" name="organisationName" value={this.state.item.organisationName} disabled/>
                                        </div>
                                    </div>
                                }
                            </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="name" className="text-right middle ">{this.state.item.langID === null ? "*" : ""} Campus Name</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.name && "error")}>
                                <input type="text" name="name" onChange={this.handleChange} value={this.state.item.name} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.name}</small>
                            </div>
                        </div>
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                        {this.state.item.langID === null &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell">
                                        <fieldset className="fieldset">
                                            <legend>App configurations:</legend>
                                            {this.state.configOptions.length === 0 ?
                                                this.state.isLoadingConfigs || this.state.isLoadingCampusConfigs ?
                                                    <p style={{height: "150px", textAlign: "center"}}>Loading configurations
                                                        ...</p>
                                                    :
                                                    <p style={{height: "150px", textAlign: "center", color: "red"}}>No
                                                        configurations have been found. Please create at least one app
                                                        configuration before adding new campuses.</p>
                                                :
                                                this.state.roleOptions.map(i =>
                                                    <div className="grid-x grid-padding-x" key={i.value}>
                                                        <div className="small-3 cell">
                                                            <label htmlFor="organisationID"
                                                                   className="text-right middle">* {i.label}</label>
                                                        </div>
                                                        <div className="small-9 cell">
                                                            <ReactSelect
                                                                name={i.value}
                                                                className="react-select"
                                                                onChange={this.handleConfigChange}
                                                                value={this.state.item.roleConfigs[i.value] || null}
                                                                options={this.state.configOptions}
                                                                onBlur={this.handleBlur}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell">
                                        <fieldset className="fieldset">
                                            <legend>Translations</legend>
                                            <div className="grid-x grid-padding-x">
                                                <div className="cell small-12">
                                                    <span>Add translation for</span>
                                                    <ReactSelect
                                                        name="selectedLanguage"
                                                        className="react-select translation-selector"
                                                        onChange={this.handleChange}
                                                        value={this.state.selectedLanguage}
                                                        options={this.state.languageOptions}
                                                        onBlur={this.handleBlur}
                                                    />
                                                    <Button type="button" className="translation-button" color={Colors.PRIMARY} isDisabled={!this.state.selectedLanguage} onClick={() => this.handleAddTranslation(this.state.selectedLanguage)}>Add</Button>
                                                </div>
                                            </div>
                                            <div className="grid-x grid-padding-x">
                                                <div className="cell small-12">
                                                    <ReactTable
                                                        columns={[
                                                            {
                                                                Header: 'Language',
                                                                accessor: 'langName'
                                                            },
                                                            {
                                                                Header: 'Actions',
                                                                Cell: row => (
                                                                    <ButtonGroup size={Sizes.TINY}>
                                                                        {this.context.permissions.campus.edit &&
                                                                        <Button type="button" color={Colors.PRIMARY} onClick={() => this.handleEditTranslation(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                                                                        }
                                                                        {this.context.permissions.campus.delete &&
                                                                        <Button type="button" color={Colors.ALERT} onClick={() => this.showDeleteTranslation(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                                                                        }
                                                                    </ButtonGroup>
                                                                ),
                                                                maxWidth: 100,
                                                                show: this.context.permissions.campus.delete || this.context.permissions.campus.edit
                                                            }
                                                        ]}
                                                        data={this.state.item.translations}
                                                        className='-striped'
                                                        showPagination={false}
                                                        noDataText={"No translations"}
                                                        pageSize={this.state.item.translations ? this.state.item.translations.length : 1}
                                                    />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    {this.state.item.langID === null ?
                                        <React.Fragment>
                                            <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                                Cancel
                                            </button>
                                            <button className="button success" type="submit" disabled={!this.state.validated || this.state.isLoadingTranslations}>
                                                Submit
                                            </button>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <button className="button secondary" type="button" onClick={() => this.props.onCompleted(this.state.item)}>
                                                Update
                                            </button>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    classNames={{modal:"modal-medium"}}
                    open={this.state.modal.isOpen}
                    modalId="Translation"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}
