import React from 'react'
import ReactTable from 'react-table'
import Axios from 'axios'
import SearchBar from 'components/common/SearchBar'
import DatatableHelper from 'utils/DatatableHelper'
import Modal from 'react-responsive-modal'
import TableContainer from 'components/common/TableContainer'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import DateHelper from 'utils/DateHelper'


export default class HabitsTable extends AppComponent {

    constructor(props, context) {
        super(props);

        this.state = {
            items: [],
            searchKey: "",
            createdBy: [],
            categories: [],
            categoryOptions: [],
            groups: [],
            groupOptions: [],
            page: 0,
            pageSize: 10,
            sorted: [{id: "id", "desc": true}],
            totalPages: -1,
            isLoading: true,
            filtersAreOpen: false
        };

        this.searchTimer = null;
    }

    searchTextDidChange(newValue) {
        this.setState({
            searchKey: newValue || ""
        }, () => {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.fetchItems();
            }, 1000);
        });
    }
    

    fetchItems = () => {

        this.setState({
            loading: true
        });

        Axios
            .post(API.assessment.getAssessmentsDT, {
                    page: this.state.page,
                    pageSize: this.state.pageSize,
                    sorted: this.state.sorted,
                    filters: {
                        search: this.state.searchKey
                    }
                }
            )
            .then((res) => {
                this.setState({
                    items: res.data.data.rows,
                    totalPages: DatatableHelper.getTotalPages(res.data.data),
                    isLoading: false
                })
            })
    }
    

    toggleRow = (item) => {
        if (this.props.onSelectedRow instanceof Function) {
            this.props.onSelectedRow(item);
        }
    }

    toggleSelectAll = () => {
        if (this.props.onSelectedAll instanceof Function) {
            this.props.onSelectedAll(!this.props.selectAll, this.state.items);
        }
    }

    render() {

        let columns = [
            {
                id: "checkbox",
                accessor: "",
                Cell: row => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.props.selected.find((o) => o.id === row.original.id) ? true : false}
                            onChange={() => this.toggleRow(row.original)}
                        />
                    );
                },
                Header: () => {
                    if (this.props.isSingleSelection) {
                        return null;
                    }
                    else {
                        return (
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={this.props.selectAll}
                                onChange={this.toggleSelectAll}
                            />
                        );
                    }
                },
                sortable: false,
                width: 45,
                show: this.props.isSelector || false
            },
            {
                Header: 'ID',
                accessor: 'id',
                width: 60
            },
            {
                Header: 'Name',
                accessor: 'name',
                minWidth: 200
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                width: 180,
                Cell: row => {
                    return DateHelper.convertToLocalDateTime(row.value);
                }
            }
        ];

        const filters = (
            <React.Fragment>
                <div className="cell small-12 medium-6 large-3">
                    <span>Search</span>
                    <SearchBar
                        onDidChange={(newValue) => this.searchTextDidChange(newValue)}
                    />
                </div>
            </React.Fragment>
        );

        const table = (
            <ReactTable
                columns={columns}
                data={this.state.items}
                page={this.state.page}
                pages={this.state.totalPages}
                pageSize={this.state.pageSize}
                loading={this.state.isLoading}
                multisort={true}
                manual
                className='-striped'
                onFetchData={(state) => {
                    this.setState({
                        page: state.page,
                        pageSize: state.pageSize,
                        sorted: state.sorted
                    }, () => {
                        this.fetchItems()
                    })
                }}
            />
        )

        return (

            <React.Fragment>
                <TableContainer
                    title="Assessments table"
                    filters={filters}
                    table={table}
                />
            </React.Fragment>
        );
    }

}