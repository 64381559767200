import React from 'react'
import SectionButton from 'components/common/SectionButton'
import AppComponent from 'components/common/AppComponent'


export default class HomeContainer extends AppComponent {

    render() {

        let sectionItems = this.context.menu.filter((section) => section.homePage).map((section) => {
                if (section.id != 10) { // CADE 19/08/2020 - Bodge to remove App Users section from CMS
                    return (<SectionButton key={section.id} title={section.title} link={section.link} iconClass={section.iconClass}/>);
                }
            }
        );

        return (
            <div className="grid-container">
                <div className="grid-x grid-padding-x">
                    {sectionItems}
                </div>
            </div>
        );
    }
}