import React, {useEffect, useState} from 'react';
import {CommonUtil} from "../../utils/CommonUtil";
import FileInput from "../common/FileInput";
import Image from "../common/Image";
import Axios from "axios";
import {API} from "../../api/APIClient";
import {NotificationManager} from "react-notifications";

export const ChatImageFormTranslations = (props) => {

    const {languageCode} = props;
    const {item} = props;
    const {onSubmit} = props;
    const {onCancel} = props;

    const [translations, setTranslations] = useState({});
    const [imageFile, setImageFile] = useState(null);
    const [imagePickerURL, setImagePickerURL] = useState(null);

    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        if (item) {
            let imageUrl = null;

            if (item.translations) {
                const trans = item.translations[languageCode];

                if (trans.hasOwnProperty("imageURL")) {
                    imageUrl = trans.imageURL;
                }

                setTranslations(trans);
            }

            setImagePickerURL(imageUrl);
        }
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        if (imageFile) {
            uploadImageOverNetwork();
        } else {
            onSubmissionComplete();
        }
    }

    function onSubmissionComplete(imageID, imageURL) {
        if (!item.translations) {
            item.translations = {};
        }

        item.translations[languageCode] = translations;

        if (imageID) {
            item.translations[languageCode].imageID = imageID;
        }

        if (imageURL) {
            item.translations[languageCode].imageURL = imageURL;
        }

        if (onSubmit) {
            onSubmit();
        }
    }

    function uploadImageOverNetwork() {
        if (!isUploading) {
            setIsUploading(true);

            let formData = new FormData();

            formData.append("typeID", 23); // Chat flow image
            formData.append("altText", getTranslation("altText"));

            if (imageFile) {
                formData.append("file", imageFile);
            }

            Axios.post(API.image.uploadImage, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        onSubmissionComplete(
                            res.data.data.imageID,
                            res.data.data.imageURL
                        );
                    } else if (res.data.error) {
                        NotificationManager.error(res.data.error.desc);
                    }

                    setIsUploading(false);
                })
                .catch((e) => {
                    console.log(e);
                    NotificationManager.error(e.message);
                });
        }
    }

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.value;

        const newTranslations = CommonUtil.cloneObject(translations);
        if (value.trim() !== "") {
            newTranslations[name] = value;
        } else {
            delete newTranslations[name];
        }
        setTranslations(newTranslations);
    }

    function getTranslation(id) {
        if (translations) {
            if (translations.hasOwnProperty(id)) {
                return translations[id];
            }
        }
        return "";
    }

    function handleFilePickerChange(event) {
        event.preventDefault();

        const reader = new FileReader();
        const file = event.target.files[0];

        setImageFile(file);
        if (file) {
            reader.onloadend = () => {
                setImagePickerURL(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    function handleFileDelete(name) {
        setImageFile(null);
        setImagePickerURL(null);
    }

    // RENDER

    return (
        <>


            <div className="modal-head">
                <h3>Translations</h3>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="modal-body translation-editor">
                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="imageFile" className="text-right middle">Change Image</label>
                        </div>
                        <div className="large-9 small-9 cell ">
                            <FileInput
                                name="file"
                                onChange={handleFilePickerChange}
                                onDelete={handleFileDelete}
                                placeholder="Not selected."
                                accept="image/jpeg,image/jpg,image/png"
                            />
                        </div>
                    </div>
                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="imageFile" className="text-right middle">Current Image</label>
                        </div>
                        <div className={"large-9 small-9 cell"}
                             style={{marginBottom: 20}}>
                            {(imagePickerURL || getTranslation("imageURL")) &&
                                <React.Fragment>
                                    <Image
                                        src={imagePickerURL ? imagePickerURL : getTranslation("imageURL")}
                                        width={200}
                                        height={200}
                                        border={true}
                                        style={{marginRight: 10}}
                                    />

                                    <Image
                                        src={imagePickerURL ? imagePickerURL : getTranslation("imageURL")}
                                        width={200}
                                        height={100}
                                        border={true}
                                    />
                                </React.Fragment>
                            }
                            {!imagePickerURL && !item.imageURL &&
                                <p>No image selected</p>
                            }
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="aiReturnResponse" className="text-right middle">
                                Alt text
                            </label>
                        </div>
                        <div className="small-9 cell">
                            <textarea
                                rows={5}
                                name={"altText"}
                                value={getTranslation("altText")}
                                placeholder={item.altText}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                <button
                                    className="button secondary"
                                    type="button"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </button>

                                <button
                                    className="button success"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}