import React from 'react'
import Axios from 'axios'
import ReactSelect from 'components/common/ReactSelect'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'
import moment from 'moment'
import DateHelper from 'utils/DateHelper'

export default class DomainForm extends AppComponent {

    static defaultProps = {
        item: {}
    }

    constructor(props, context) {
        super(props);

        this.state = {
            item: Object.assign({
                createdAt: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                organisationID: context.organisation.id
            }, props.item),
            organisationOptions: [
                props.item.organisationID ? {value: props.item.organisationID, label: props.item.organisationName} : {value: context.organisation.id, label: context.organisation.name}
            ],
            validated: false,
            errors: {},
            touched: {},
            roleOptions: [],
            isUploading: false
        };
    }

    componentDidMount() {
        this.validate();
        if (this.context.permissions.emailDomain.allOrgs) {
            this.getOrganisationOptions();
        }
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations, {
                props: {
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {
                Axios
                    .post((this.state.item.id ? API.organisation.updateDomain : API.organisation.createDomain), this.state.item)
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted(res.data.data.item);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }

                        this.setState({
                            isUploading: false
                        });
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = this.state.item;
        item[name] = value;

        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.domain) {
            if (touched.name) {
                errors.name = "Domain is required";
            }
            validated = false;
        }


        this.setState({
            validated: validated,
            errors: errors
        });
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />
                <div className="modal-head">
                    <h3>{this.state.item.id ? "Edit Email Domain" : "New Email Domain"}</h3>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="createdAt" className="text-right middle">Created At</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <input name="createdAt" type="text" value={moment(DateHelper.convertToLocalDateTime(this.state.item.createdAt), "YYYY-MM-DD HH:mm:ss").format("LLLL")} disabled={true} />
                            </div>
                        </div>
                        {this.context.permissions.department.allOrgs && !this.props.item.id ?
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="organisationID" className="text-right middle">* Organisation</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.organisationID && "error")}>
                                    <ReactSelect
                                        name="organisationID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.item.organisationID}
                                        options={this.state.organisationOptions}
                                        onBlur={this.handleBlur}
                                    />
                                    <small className="error">{this.state.errors.organisationID}</small>
                                </div>
                            </div>
                            :
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="organisationName" className="text-right middle ">* Organisation</label>
                                </div>
                                <div className="small-9 cell">
                                    <input type="text" name="organisationName" value={this.state.item.organisationName} disabled/>
                                </div>
                            </div>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="domain" className="text-right middle ">* Domain</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.domain && "error")}>
                                <input type="text" name="domain" onChange={this.handleChange} value={this.state.item.domain} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.domain}</small>
                            </div>
                        </div>
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}
