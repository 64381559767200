import React from 'react'
import PageContent from 'components/common/PageContent'
import AdminUsersTable from 'components/adminUsers/AdminUsersTable'

export default function AdminUsers(props) {

    const pathElements = [
        {id: 0, title: "Home", url: "/"},
        {id: 1, title: "Admin Users"}
    ];

    return (
        <PageContent pathElements={pathElements} >
            <AdminUsersTable/>
        </PageContent>
    );
}