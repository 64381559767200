import React from 'react'
import Axios from 'axios'
import ReactSelect from 'components/common/ReactSelect'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'
import Papa from 'papaparse'
import CourseImportTable from 'components/organisation/courses/CourseImportTable'

export default class CourseImport extends AppComponent {

    static defaultProps = {
        item: {}
    }

    constructor(props, context) {
        super(props);

        this.state = {
            item: Object.assign({
                organisationID: context.organisation.id,
                departmentID: null,
                firstRowHeaders: 1,
                selectedColumn: null
            }, props.item),
            organisationOptions: [
                props.item.organisationID ? {value: props.item.organisationID, label: props.item.organisationName} : {value: context.organisation.id, label: context.organisation.name}
            ],
            departmentOptions: [],
            validated: false,
            errors: {},
            touched: {},
            isUploading: false,
            csvPickerURL: null,
            csvPickerFile: null,
            headers: [],
            firstRow: [],
            items: []
        };
    }

    componentDidMount() {
        this.validate();
        this.getDepartmentOptions();
        if (this.context.permissions.course.allOrgs) {
            this.getOrganisationOptions();
        }
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations, {
                props: {
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    getDepartmentOptions = () => {
        Axios
            .get(API.department.getDepartments, {
                params: {
                    organisationID: this.state.item.organisationID
                }
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        departmentOptions: res.data.data.departments.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {

            this.setState({
                isUploading: true
            }, () => {
                Axios
                    .post(API.course.createCourseBatch, {
                        departmentID: this.state.item.departmentID,
                        names: this.state.items.map(i => i[this.state.item.selectedColumn])
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted();
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }

                        this.setState({
                            isUploading: false
                        });
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = this.state.item;
        item[name] = value;

        let change = {
            item: item
        };

        if (name === "firstRowHeaders") {
            if (this.state.items.length > 0 && this.state.firstRow.length > 0) {
                let items = Array.from(this.state.items);
                let headers = [];

                if (value) {
                    items.shift();
                    headers = this.state.firstRow;
                }
                else {
                    items.splice(0, 1, this.state.firstRow);
                    headers = this.generateHeaders(this.state.firstRow.length);
                }
                change.headers = headers;
                change.items = items;
                item.selectedColumn = null;
            }
        }

        if (name === "organisationID") {
            item.departmentID = null;
            change.departmentOptions = [];
        }

        this.setState(change, () => {
            this.validate();
            if (name === "organisationID") {
                this.getDepartmentOptions();
            }
        });
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        let self = this;

        reader.onloadend = () => {

            let item = Object.assign({}, self.state.item);
            item.selectedColumn = null;
            item.firstRowHeaders = 1;

            let touched = Object.assign({}, self.state.touched);
            delete touched.selectedColumn;

            self.setState({
                touched: touched,
                item: item,
                headers: [],
                firstRow: [],
                items: [],
                csvPickerFile: file,
                csvPickerURL: reader.result
            }, () => {
                self.validate();
                self.handleCSVFile();
            })
        };

        reader.readAsDataURL(file);
    }

    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.organisationID) {
            if (touched.organisationID) {
                errors.name = "Organisation is required";
            }
            validated = false;
        }

        if (!item.departmentID) {
            if (touched.departmentID) {
                errors.name = "Department is required"
            }
            validated = false;
        }

        if (item.selectedColumn === null || item.selectedColumn === undefined) {
            if (touched.selectedColumn) {
                errors.name = "Course column is required"
            }
            validated = false;
        }

        if (this.state.items.length < 1) {
            validated = false;
        }


        this.setState({
            validated: validated,
            errors: errors
        });
    }

    handleCSVFile = () => {

        if (this.state.csvPickerFile) {

            let self = this;

            Papa.parse(this.state.csvPickerFile, {
                complete: function(res) {
                    if (res.errors.length === 0 && res.data.length > 1) {

                        let data = Array.from(res.data);
                        const firstRow = data[0];

                        let headers = [];

                        if (self.state.item.firstRowHeaders) {
                            headers = Array.from(firstRow);
                            data.shift();
                        }
                        else {
                            headers = self.generateHeaders(firstRow.length);
                        }

                        self.setState({
                            firstRow: firstRow,
                            headers: headers,
                            items: data
                        })
                    }
                }
            })
        }
    }

    generateHeaders(count) {
        const headers = [];
        for (var i=1; i<count+1; i++) {
            headers.push("Column " + i);
        }
        return headers;
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />
                <div className="modal-head">
                    <h3>Import Courses</h3>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        {this.context.permissions.course.allOrgs && !this.props.item.id ?
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="organisationID" className="text-right middle">* Organisation</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.organisationID && "error")}>
                                    <ReactSelect
                                        name="organisationID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.item.organisationID}
                                        options={this.state.organisationOptions}
                                        onBlur={this.handleBlur}
                                    />
                                    <small className="error">{this.state.errors.organisationID}</small>
                                </div>
                            </div>
                            :
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="organisationName" className="text-right middle ">* Organisation</label>
                                </div>
                                <div className="small-9 cell">
                                    <input type="text" name="organisationName" value={this.state.item.organisationName} disabled/>
                                </div>
                            </div>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="departmentID" className="text-right middle">* Department</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.departmentID && "error")}>
                                <ReactSelect
                                    name="departmentID"
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.item.departmentID}
                                    options={this.state.departmentOptions}
                                    onBlur={this.handleBlur}
                                    disabled={!this.state.item.organisationID}
                                />
                                <small className="error">{this.state.errors.departmentID}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="file" className="text-right middle">Select CSV file</label>
                            </div>
                            <div className="large-9 small-9 cell ">
                                <input name="file" type="file" onChange={this.handleFilePickerChange} onBlur={this.handleBlur} />
                            </div>
                        </div>
                        {this.state.csvPickerFile &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="firstRowHeaders" className="text-right middle">First row as headers</label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <label className="chkbx-container">
                                            <input className="chkbx" type="checkbox" name="firstRowHeaders" onChange={this.handleChange} checked={this.state.item.firstRowHeaders} />
                                            <span className="chkmk"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="mediaType" className="text-right middle">* Course name column</label>
                                    </div>
                                    <div className="large-9 small-9 cell">
                                        <ReactSelect
                                            name="selectedColumn"
                                            onChange={this.handleChange}
                                            value={this.state.item.selectedColumn}
                                            options={this.state.headers.map((o, index) => { return {value: index, label: o} } )}
                                            className="react-select"
                                        />
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell">
                                        <CourseImportTable
                                            headers={this.state.headers}
                                            items={this.state.items}
                                            selectedColumn={this.state.item.selectedColumn}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}
