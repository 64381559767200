import React from 'react'
import EditorHelper from 'components/contentEditor/EditorHelper'
import Utils from 'utils/Utils'

export default class Checkbox extends React.Component {

    render() {

        const {item, defaults, style, innerRef, ...props} = this.props;

        const text = Utils.value(Utils.getNested(this.props.item, "data", "text"), "");
        const groupID = Utils.value(Utils.getNested(this.props.item, "data", "groupID"), "");

        return (
            <div
                ref={innerRef}
                style={Object.assign(style,{
                    paddingLeft: EditorHelper.getMargin(item, defaults, "left") * this.props.scale,
                    paddingRight: EditorHelper.getMargin(item, defaults, "right") * this.props.scale,
                    paddingBottom: EditorHelper.getMargin(item, defaults, "bottom") * this.props.scale,
                    paddingTop: EditorHelper.getMargin(item, defaults, "top") * this.props.scale,
                    display: "block"
                })}

                {...props}
            >
                <div
                    style={{
                        height: 30 * this.props.scale,
                        width: 30 * this.props.scale,
                        border: "1px solid #575757",
                        borderRadius: 15 * this.props.scale,
                        display: "inline-block",
                        marginRight: 20,
                        verticalAlign: "top",
                        marginTop: 10 * this.props.scale
                    }}
                >
                </div>
                <div
                    style={{
                        fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                        fontSize: 20 * this.props.scale,
                        color: "#575757",
                        width: "80%",
                        minHeight: 50 * this.props.scale,
                        paddingTop: 12 * this.props.scale,
                        textAlign: "left",
                        borderBottom: "1px solid #575757",
                        lineHeight: "normal",
                        display: "inline-block"
                    }}
                >
                    {text}
                </div>
            </div>
        )
    }

}