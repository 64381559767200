import React from 'react';
import { Link } from 'react-router-dom'

function SectionButton(props) {
    return (
        <div className={"cell large-3 medium-4 small-6 home-menu-item " + props.iconClass}>
            <Link to={props.link}>
                <div className="m-icon">
                </div>
                <span>{props.title}</span>
            </Link>
        </div>
    );
}

export default SectionButton;