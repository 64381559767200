import React from 'react'
import AppOrganisationsTable from 'components/money/offers/AppOrganisationsTable'
import Axios from 'axios'
import { API } from 'api/APIClient'
import ModalProgress from 'components/common/ModalProgress'


export default class MoneyOfferAccess extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            orgSelected: new Set(),
            selected: new Set(),
            selectAll: false,
            haveChanges: false,
            isUploading: false,
            errors: {}
        }
    }

    componentDidMount() {
        this.getOrganisations(this.props.item.id);
    }

    getOrganisations = (offerID) => {
        Axios
            .get(API.money.getOfferOrganisations, {
                params: {
                    id: offerID
                }
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        orgSelected: new Set(res.data.data.organisations),
                        selected: new Set(res.data.data.organisations)
                    }, () => {
                        this.haveChanges();
                    });
                }
            });
    }

    handleSelectedRow = (id) => {
        const newSelected = new Set(this.state.selected);

        if (newSelected.has(id)) {
            newSelected.delete(id);
        }
        else {
            newSelected.add(id);
        }

        this.setState({
            selected: newSelected,
            selectAll: false
        }, () => {
            this.haveChanges();
        });
    }

    handleSubmit = () => {
        if (!this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {

                let formData = new FormData();

                formData.append("id", this.props.item.id);

                this.state.selected.forEach(orgID => {
                    formData.append("organisations[]", orgID);
                });


                Axios
                    .post(API.money.updateOfferOrganisations, formData)
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted(res.data.data.organisations);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }
                        this.setState({
                            isUploading: false
                        })
                    })
            })
        }
    }

    handleSelectAll = (all, items) => {
        if (all) {
            let selected = new Set(this.state.selected);
            items.forEach(item => selected.add(item));
            this.setState({
                selected: selected,
                selectAll: all
            }, () => {
                this.haveChanges();
            });
        }
        else {
            let selected = new Set(this.state.selected);
            items.forEach(item => selected.delete(item));
            this.setState({
                selected: selected,
                selectAll: all
            }, () => {
                this.haveChanges();
            });
        }
    }

    haveChanges = () => {

        let haveChanges = false;

        if (this.state.orgSelected.size !== this.state.selected.size) {
            haveChanges = true;
        }
        else {
            for (var a of this.state.orgSelected) {
                if (!this.state.selected.has(a)) {
                    haveChanges = true;
                    break;
                }
            }
        }

        this.setState({
            haveChanges: haveChanges
        });
    }

    render() {

        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />

                <div className="modal-head">
                    <h3>Visibility</h3>
                </div>
                <div className="modal-body">
                    <AppOrganisationsTable
                        selected={this.state.selected}
                        selectAll={this.state.selectAll}
                        onSelectedRow={this.handleSelectedRow}
                        onSelectedAll={this.handleSelectAll}
                        onDidFetch={() => this.setState({selectAll: false})}
                        pageSize={5}
                    />
                    <p>Selected: {this.state.selected.size} {this.state.selected.size === 1 ? "row" : "rows"}</p>
                    <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                        <div className="cell small-12">
                            <span>{this.state.errors.genericError}</span>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                    Cancel
                                </button>
                                <button className="button success" type="submit" disabled={!this.state.haveChanges} onClick={this.handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

    }

}