export const Toast = {
    DURATION_SHORT : 6000,
    DURATION_LONG : 10000,
    TYPE_SUCCESS : "success",
    TYPE_ERROR : "error",
    show : (message, duration, type) => {
        if (window._uwbToast !== undefined) {
            const id = Math.random();
            if (duration === undefined) {
                duration = Toast.DURATION_LONG;
            }
            window._uwbToast(id, message, duration, type);
        }
    }
}