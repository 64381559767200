import React from 'react'
import Axios from "axios";
import moment from "moment";
import {NotificationManager} from "react-notifications";
import ReportTable from 'components/reports/ReportTable'
import Utils from 'utils/Utils'

import SummaryChart from "components/reports/SummaryChart";

export default class DetailedReport extends React.Component {

    requestSource = undefined;

    constructor(props) {
        super(props);

        this.state = {
            data: Object.assign({
                total: 0,
                period: 0,
                today: 0,
                graphData: [],
                tableData: (Utils.getNested(props, "data", "graphData") || []).filter((i) => i.value > 0)
            }, props.data),
            isLoadingData: false
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startDate !== this.props.startDate ||
            prevProps.endDate !== this.props.endDate ||
            prevProps.filters !== this.props.filters ||
            prevProps.endpoint !== this.props.endpoint ||
            prevProps.reportID !== this.props.reportID) {

            if (prevProps.endpoint !== this.props.endpoint) {
                this.setState({
                    data: {
                        total: 0,
                        period: 0,
                        today: 0,
                        graphData: [],
                        tableData: []
                    },
                    dp: false
                })
            }

            this.fetchData();
        }
    }



    fetchData = () => {

        if (this.requestSource) {
            console.log("cancelling previous request");
            this.requestSource.cancel(this.props.endpoint);
        }

        this.setState({
            isLoadingData: true
        }, () => {

            const cancelToken = Axios.CancelToken;
            this.requestSource = cancelToken.source();

            const self = this;

            Axios
                .get(this.props.endpoint, {
                    params: {
                        filters: this.props.filters,
                        startDate: this.props.startDate.format("YYYY-MM-DD"),
                        endDate: this.props.endDate.format("YYYY-MM-DD"),
                        tz: moment().local().format("Z")
                    },
                    cancelToken: this.requestSource.token
                })
                .catch(function(thrown) {
                    if (Axios.isCancel(thrown)) {
                        self.requestSource = undefined;
                        console.log('Request canceled', thrown.message);
                    }
                })
                .then((res) => {
                    if (!res) { return; } // For some reason if request is cancelled it still returns a promise but with an empty result
                    if (res.data.success) {
                        let data = res.data.data;
                        const dp = data.dp || false;

                        if (dp) {
                            this.setState({
                                data: {
                                    total: 0,
                                    period: 0,
                                    today: 0,
                                    graphData: [],
                                    tableData: []
                                },
                                dp: true,
                                isLoadingData: false
                            });
                            return;
                        }

                        const graphData = data.items.map((item) => {
                            return Object.assign({label: moment(item.date, "YYYY-MM-DD").format("YYYY/MM/DD")}, item)
                        });

                        let parsedData = {
                            total: data.total,
                            period: data.period,
                            today: data.today,
                            graphData: graphData
                        };

                        if (!this.props.tableEndpoint) {
                            this.requestSource = undefined;
                            const tableData = data.items.filter((i) => i.value > 0).map((item) => {
                                return {label: moment(item.date, "YYYY-MM-DD").format("dddd, MMMM Do YYYY"), value: item.value}
                            }).reverse();
                            parsedData.tableData = tableData;

                            this.setState({
                                isLoadingData: false,
                                data: parsedData,
                                dp: false
                            })
                        }
                        else {
                            const cancelToken = Axios.CancelToken;
                            this.requestSource = cancelToken.source();

                            Axios
                                .get(this.props.tableEndpoint, {
                                    params: {
                                        filters: this.props.filters,
                                        startDate: this.props.startDate.format("YYYY-MM-DD"),
                                        endDate: this.props.endDate.format("YYYY-MM-DD"),
                                        tz: moment().local().format("Z")
                                    },
                                    cancelToken: this.requestSource.token
                                })
                                .catch(function(thrown) {
                                    if (Axios.isCancel(thrown)) {
                                        self.requestSource = undefined;
                                        console.log('Request canceled', thrown.message);
                                    }
                                })
                                .then((res) => {
                                    if (!res) { return; } // For some reason if request is cancelled it still returns a promise but with an empty result
                                    this.requestSource = undefined;
                                    if (res.data.success) {
                                        let data = res.data.data;
                                        const dp = data.dp || false;

                                        if (dp) {
                                            this.setState({
                                                data: {
                                                    total: 0,
                                                    period: 0,
                                                    today: 0,
                                                    graphData: [],
                                                    tableData: []
                                                },
                                                dp: true,
                                                isLoadingData: false
                                            });
                                            return;
                                        }

                                        const tableData = data.items.filter((i) => i.value > 0).map((item) => {
                                            return {label: moment(item.date, "YYYY-MM-DD").format("dddd, MMMM Do YYYY"), value: item.value}
                                        }).reverse();
                                        parsedData.tableData = tableData;

                                        this.setState({
                                            isLoadingData: false,
                                            data: parsedData,
                                            dp: false
                                        })
                                    }
                                    else {
                                        NotificationManager.error(res.data.error.desc);
                                        this.setState({
                                            isLoadingData: false
                                        })
                                    }
                                })
                        }
                    }
                    else {
                        this.requestSource = undefined;
                        NotificationManager.error(res.data.error.desc);
                        this.setState({
                            isLoadingData: false
                        })
                    }
                });
        })
    }

    render() {

        console.log("DATA REFRESHED IN DETAILED REPORT");

        return (
            <React.Fragment>
                <div className="grid-x grid-padding-x">
                    <div className="cell">
                        <div className="panel-box">
                            <SummaryChart
                                color={this.props.color}
                                title={this.props.title}
                                data={this.state.data.graphData}
                                graphType={this.props.graphType}
                                periodTotal={this.state.data.period}
                                todayTotal={this.state.data.today}
                                dp={this.state.dp}
                                total={this.state.data.total}
                                isLoadingData={this.state.isLoadingData}
                                addShowMore={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid-x grid-padding-x">
                    <div className="cell">
                        <ReportTable {...this.props} data={this.state.data.tableData} isLoadingData={this.state.isLoadingData} />
                    </div>
                </div>
            </React.Fragment>

        )
    }
}