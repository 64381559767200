import React from 'react'
import DetailedReport from 'components/reports/DetailedReport'
import PodcastTotalsTable from 'components/reports/PodcastTotalsTable'
import Utils from 'utils/Utils'
import AppComponent from 'components/common/AppComponent'


export default class PodcastReport extends AppComponent {


    constructor(props, context) {
        super(props);

        this.state = {
            selectedPodcast: null
        }
    }

    handlePodcastSelected = (podcast) => {
        this.setState({
            selectedPodcast: podcast
        })
    }

    render() {

        let { filters, title, ...props } = this.props;

        let f = Object.assign({}, filters);
        f.podcastID = this.state.selectedPodcast ? this.state.selectedPodcast.id : null;

        title = Utils.getNested(this.state.selectedPodcast, "title") || "All Podcasts";
        title += " Views";

        return (
            <React.Fragment>
                <div className="grid-x grid-padding-x">
                    <div className="small-12 cell">
                        <div className="panel-box">
                            <PodcastTotalsTable {...this.props}  onPodcastSelected={this.handlePodcastSelected} />
                        </div>
                    </div>
                </div>
                <DetailedReport {...props} filters={f} title={title} />
            </React.Fragment>

        )
    }
}