import React, {useEffect, useState} from 'react';
import ModalProgress from "components/common/ModalProgress";
import {CommonUtil} from "utils/CommonUtil";
import Modal from "react-responsive-modal";
import {PermissionUtil} from "utils/PermissionUtil";
import ReactSelect from "components/common/ReactSelect";
import Axios from "axios";
import {API} from "api/APIClient";
import {Toast} from "utils/ToastUtil";
import {NotificationManager} from "react-notifications";

export const KnowledgeDocumentTypeEditor = (props) => {

    const {knowledgeDocument} = props;
    const {callback} = props;

    const [isUploading, setIsUploading] = useState(false);

    const [formData, setFormData] = useState({});

    const [touched, setTouched] = useState({});
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState([]);

    useEffect(() => {
        if (knowledgeDocument) {
            setFormData(knowledgeDocument);
        }
    }, []);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function handleSubmit(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (validateForm()) {
            submitKnowledgeDocumentOverNetwork();
        }
    }

    function validateForm() {
        let out = true;
        const errors = {};

        const requiredFields = [
            { "key" : "name", "label" : "Title" }
        ];

        for (let i = 0; i < requiredFields.length; i++) {
            let problem = false;

            const field = requiredFields[i];
            if (formData.hasOwnProperty(field.key)) {
                if (formData[field.key] === undefined || formData[field.key] === null) {
                    problem = true;
                }
            } else {
                problem = true;
            }

            if (problem) {
                out = false;
                errors[field.key] = "Please provide value for '" + field.label + "'";
            }
        }

        //setErrors(errors);

        return out;
    }

    function putFormData(data) {
        if (!CommonUtil.isObject(data)) {
            return;
        }

        const newFormData = CommonUtil.cloneObject(formData);
        const keys = Object.keys(data);

        keys.forEach((key) => {
            const newData = data[key];
            if (newData !== undefined) {
                newFormData[key] = data[key];
            } else {
                delete newFormData[key];
            }
        });

        setFormData(newFormData);
    }

    function handleBlur(event) {
        const name = event.target.name;
        const newTouched = CommonUtil.cloneObject(touched);
        newTouched[name] = true;
        setTouched(touched);
    }

    function handleChange(event) {
        const value = event.target.type === "checkbox" ? (event.target.checked ? 1 : 0) : event.target.value;

        let outData = {};
        outData[event.target.name] = value;
        putFormData(outData);
    }

    function pluginModalDidDismiss() {
        setModalOpen(false);
        setModalContent([]);
    }

    function submitKnowledgeDocumentOverNetwork() {
        if (isUploading) return;
        setIsUploading(true);

        setErrorMessage(null);

        const data = {
            name : CommonUtil.getOrDefault(formData, "name", "")
        };

        let endpoint = API.knowledgeDocumentType.createType;
        let successMessage = "Successfully created Knowledge Document Type";
        if (knowledgeDocument) {
            data.id = knowledgeDocument.id;
            successMessage = "Successfully updated Knowledge Document Type";
            endpoint = API.knowledgeDocumentType.updateType;
        }

        Axios.post(endpoint, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    NotificationManager.success(successMessage);

                    handleCallback("submit");
                } else {
                    setErrorMessage(API.formatError(resp));
                }
                setIsUploading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsUploading(false);
            });
    }

    // RENDER

    let modalTitle = "Create Knowledge Document Type";
    if (knowledgeDocument) {
        modalTitle = "Edit Knowledge Document Type";
    }

    let errorMessageElem = [];
    if (errorMessage) {
        errorMessageElem = (
            <div className={"alert alert-danger"}>
                {errorMessage}
            </div>
        );
    }

    return (
        <>
            <ModalProgress show={isUploading}/>
            <div className={"modal-head"}>
                <div className={"modal-head"}>
                    <h3>{modalTitle}</h3>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className={"modal-body"}>

                    <KnowledgeDocumentEditorTextInput
                        label={"* Title"}
                        name={"name"}
                        error={CommonUtil.getOrDefault(errors, "name", "")}
                        value={CommonUtil.getOrDefault(formData, "name", "")}
                        onChange={handleChange}
                        onBlur={handleBlur}/>

                    <KnowledgeDocumentEditorTextInput
                        label={"Key"}
                        name={"key"}
                        readonly={true}
                        placeholder={"(Automatically Generated)"}
                        value={CommonUtil.getOrDefault(formData, "key", "")} />

                    {errorMessageElem}

                </div>

                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                {
                                    <React.Fragment>
                                        <button
                                            className="button secondary"
                                            type="button"
                                            onClick={() => handleCallback("submit")}
                                        >
                                            Cancel
                                        </button>

                                        <button
                                            className="button success"
                                            type="submit"
                                            disabled={!validateForm()}
                                        >
                                            Submit
                                        </button>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <Modal
                classNames={{modal: "modal-medium"}}
                open={modalOpen}
                modalId="PluginModal"
                onClose={pluginModalDidDismiss}
                center
            >
                {modalContent}
            </Modal>
        </>
    )

}

const KnowledgeDocumentEditorTextInput = (props) => {

    const {label} = props;
    const {name} = props;
    const {type} = props;
    const {value} = props;
    const {error} = props;
    const {onChange} = props;
    const {onBlur} = props;
    const {readonly} = props;
    const {placeholder} = props;

    let inputElem = (
        <input
            type={type ? type : "text"}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            readOnly={readonly}
            placeholder={placeholder}
        />
    )

    if (type === "textarea") {
        inputElem = (
            <textarea
                name={name}
                value={value}
                rows={10}
                onChange={onChange}
                onBlur={onBlur}
                readOnly={readonly}
                placeholder={placeholder}
            />
        );
    }

    return (
        <div className="grid-x grid-padding-x">
            <div className="small-3 cell">
                <label htmlFor="name" className="text-right middle ">{label}</label>
            </div>
            <div className={"small-9 cell " + (error && "error")}>
                {inputElem}

                <small className="error">{error}</small>
            </div>
        </div>
    )

}