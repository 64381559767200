
// This is mapped to the DB

export const WellbeingCategory = {
    CONNECT: 1,
    BE_ACTIVE: 2,
    TAKE_NOTICE: 3,
    KEEP_LEARNING: 4,
    GIVE: 5
};

Object.freeze(WellbeingCategory);