import React, {useEffect, useState} from 'react';
import PageContent from "../common/PageContent";
import TableContainer from "../common/TableContainer";
import Modal from "react-responsive-modal";
import ReactTable from "react-table";
import DateHelper from "../../utils/DateHelper";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import {PermissionUtil} from "../../utils/PermissionUtil";
import {ContextUtil} from "../../utils/ContextUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Axios from "axios";
import {API} from "../../api/APIClient";
import {Redirect} from "react-router-dom";
import {PluginChatFlowEditor} from "./PluginChatFlowEditor";

export const PluginChatFlowTable = (props) => {

    const MODAL_MODE_CHAT_FLOW = 1;

    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState(-1);
    const [modalData, setModalData] = useState(null);

    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [sorted, setSorted] = useState(null);

    useEffect(() => {
        fetchChatFlowsFromNetwork();
    }, []);

    useEffect(() => {
        fetchChatFlowsFromNetwork();
    }, [page, pageSize, sorted]);

    function showChatFlowModal(id) {
        setModalOpen(true);
        setModalMode(MODAL_MODE_CHAT_FLOW);
        setModalData(id);
    }

    function pluginModalDidDismiss() {
        setModalOpen(false);
        fetchChatFlowsFromNetwork();
    }

    function promptForDeletion(id) {

    }

    function fetchChatFlowsFromNetwork() {
        if (isLoading) return;
        setIsLoading(true);

        Axios.post(API.plugin.getPluginChatFlowsDT)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setItems(resp.data.rows);
                    setTotalPages(resp.data.recordsTotal);
                } else {
                    console.log(API.formatError(resp));
                }
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    }

    // RENDER

    if (!PermissionUtil.allOrgs("plugin")) {
        return(
            <Redirect to={"/plugin"} />
        );
    }

    const pathElements = [
        {id : 0, title : "Home", url : "/"},
        {id : 1, title : "Plugin", url : "/plugin"},
        {id : 2, title : "Chat Flows"}
    ];

    let buttons = [];

    buttons.push(
        <button type={"button"} className={"button small"} onClick={() => showChatFlowModal()}>
            Create List
        </button>
    );

    let filters = [];

    let columns = [
        {
            Header: 'ID',
            accessor: 'id',
            width: 60
        },
        {
            Header: 'Name',
            accessor: 'name',
            minWidth: 200
        },
        {
            Header: 'Created By',
            accessor: 'organisationName',
            width: 150
        },
        {
            Header: 'Created At',
            accessor: 'createdAt',
            width: 180,
            Cell: row => {
                return DateHelper.convertToLocalDateTime(row.value);
            }
        },
        {
            Header: 'Actions',
            Cell: row => (
                <ButtonGroup size={Sizes.TINY}>
                    {PermissionUtil.edit("plugin") && (ContextUtil.getOrganisation().id === row.original.organisationID || PermissionUtil.allOrgs("plugin")) &&
                        <Button color={Colors.PRIMARY} onClick={() => { showChatFlowModal(row.original) }}><FontAwesomeIcon icon="edit" /></Button>
                    }
                    {PermissionUtil.delete("plugin") && (ContextUtil.getOrganisation().id === row.original.organisationID || PermissionUtil.allOrgs("plugin")) &&
                        <Button color={Colors.ALERT} onClick={() => promptForDeletion(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                    }
                </ButtonGroup>
            ),
            maxWidth: 100,
            sortable: false,
            show: PermissionUtil.delete("plugin") || PermissionUtil.edit("plugin")
        }
    ]

    let table = (
        <ReactTable
            columns={columns}
            data={items}
            page={page}
            pages={totalPages}
            loading={isLoading}
            multisort={true}
            manual
            className={"-striped"}
            onFetchData={(state) => {
                setPageSize(state.pageSize);
                setSorted(state.sorted);
                setPage(state.page);
            }} />
    );

    let modalContent = [];
    if (modalMode === MODAL_MODE_CHAT_FLOW) {
        modalContent = (
            <PluginChatFlowEditor
                id={modalData}
                callback={(action, data) => {
                    if (action === "submit") {
                        setModalOpen(false);
                        fetchChatFlowsFromNetwork();
                    }
                }}
            />
        );
    }

    return (
        <PageContent pathElements={pathElements}>
            <TableContainer
                title="Plugin Chat Flows"
                buttons={buttons}
                filters={filters}
                table={table}
            />

            <Modal
                classNames={{modal: "modal-medium"}}
                open={modalOpen}
                modalId="PluginModal"
                onClose={pluginModalDidDismiss}
                center
            >
                {modalContent}
            </Modal>
        </PageContent>
    )

}