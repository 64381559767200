import React from 'react'
import PageContent from 'components/common/PageContent'
import DepartmentsTable from 'components/organisation/departments/DepartmentsTable'

export default function Departments(props) {

    const pathElements = [
        {id: 0, title: "Home", url: "/"},
        {id: 1, title: "Organisation", url: "/organisation"},
        {id: 2, title: "Departments"}
    ];

    return (
        <PageContent pathElements={pathElements}>
            <DepartmentsTable/>
        </PageContent>
    )
}