import React, {useEffect, useRef, useState} from 'react';
import ReactTable from 'react-table'
import Axios from 'axios'
import SearchBar from 'components/common/SearchBar'
import { Colors, Sizes, ButtonGroup, Button} from 'react-foundation'
import TableContainer from 'components/common/TableContainer'
import { API } from 'api/APIClient'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Modal from "react-responsive-modal";
import StandardModal from "../../common/StandardModal";
import {Toast} from "../../../utils/ToastUtil";
import ReactSelect from "../../common/ReactSelect";
import {PermissionUtil} from "../../../utils/PermissionUtil";
import {PromptTemplateEditor} from "./PromptTemplateEditor";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NotificationManager} from "react-notifications";

export const PromptTemplateTable = (props) => {

    const {onSelected} = props;
    const {showCopyButton} = props;
    const {showSelectButton} = props;
    const {showDeleteButton} = props;

    const [items, setItems] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [searchOrganisationID, setSearchOrganisationID] = useState(undefined);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [organisationOptions, setOrganisationOptions] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState([]);
    const [modalLoading, setModalLoading] = useState(false);

    const searchTimer = useRef();

    useEffect(() => {
        fetchItems();

        if (PermissionUtil.allOrgs(PermissionUtil.PermissionCategories.promptTemplates)) {
            fetchOrganisations();
        }
    }, []);

    useEffect(() => {
        fetchItems();
    }, [searchKey, searchOrganisationID]);

    function searchTextDidChange(newValue) {
        clearTimeout(searchTimer);
        searchTimer.current = setTimeout(() => {
            setSearchKey(newValue);
        }, 1000)
    }

    function organisationDidChange(newValue) {
        setSearchOrganisationID(newValue);
    }

    function handleSelected(item) {
        if (onSelected instanceof Function) {
            onSelected(item);
        }
    }

    function handleAddNewItem() {
        openModal(
            <PromptTemplateEditor
                callback={modalDidCallback}
            />
        );
    }

    function handleEditItem(item) {
        openModal(
            <PromptTemplateEditor
                promptTemplate={item}
                callback={modalDidCallback}
            />
        );
    }

    function promptDeleteItem(item) {
        openModal(
            <StandardModal
                title="Delete Conversation"
                content={(
                    <div>Are you sure to delete the &quot;<strong>{item.name}</strong>&quot; Prompt Template? This action cannot be reverted.</div>
                )}
                buttons={(
                    <React.Fragment>
                        <button className="button secondary" type="button" onClick={closeModal}>
                            Cancel
                        </button>
                        <button className="button alert" type="button" onClick={() => handleDeleteItem(item)}>
                            Delete
                        </button>
                    </React.Fragment>
                )}
                isUploading={modalLoading}
            />
        )
    }

    function handleDeleteItem(item) {
        if (modalLoading) return;
        setModalLoading(true);

        const data = {
            id : item.id
        };

        Axios.post(API.promptTemplate.deletePromptTemplate, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    NotificationManager.success("Deleted item: " + item.name);
                } else {
                    NotificationManager.error(API.formatError(resp));
                }

                closeModal();
            })
            .catch((e) => {
                NotificationManager.error("Could not delete item. An unknown error has occurred.");

                closeModal();
            });
    }

    function openModal(content) {
        setModalOpen(true);
        setModalContent(content);
    }

    function closeModal() {
        setModalOpen(false);
        setModalContent([]);
        setModalLoading(false);

        fetchItems();
    }

    function modalDidCallback(action, data) {
        if (action === "submit") {
            closeModal();
        }
    }

    function fetchItems() {
        setIsLoading(true);

        let organisations = undefined;
        if (searchOrganisationID) {
            organisations = [searchOrganisationID];
        }

        const data = {
            page : page,
            pageSize : pageSize,
            sorted : [{id: "id", "desc": true}],
            filters : {
                search: searchKey,
                organisations
            }
        };

        Axios.post(API.promptTemplate.getPromptTemplatesDT, data)
            .then((res) => {
                if (res.data.success) {
                    setItems(Object.values(res.data.data.rows));
                } else if (res.data.error) {
                    console.log(API.formatError(res));
                }
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    }

    function fetchOrganisations() {
        Axios.get(API.organisation.getOrganisations, {
            props : {
                appUsersOnly : 1
            }
        })
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    const organisations = resp.data.organisations;
                    if (organisations) {
                        const orgOptions = organisations.map((org) => {
                            return {
                                value : org.id.toString(),
                                label : org.name
                            }
                        });

                        orgOptions.unshift({
                            value : null,
                            label : "All Organisations"
                        });

                        setOrganisationOptions(orgOptions)
                    }
                } else {
                    console.log(API.formatError(resp));
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // RENDER

    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Key',
            accessor: 'key',
        },
        {
            Header: 'Organisation',
            accessor: 'organisationName',
        },
        {
            Header: 'Actions',
            Cell: row => (
                <ButtonGroup size={Sizes.TINY}>

                    {showCopyButton === true &&
                        <CopyToClipboard
                            text={"{{ST.prompt.[" + row.original.key + "] " + row.original.name + "}}"}
                            onCopy={() => handleSelected(row.original)}
                        >
                            <Button color={Colors.PRIMARY}>Copy to clipboard</Button>
                        </CopyToClipboard>
                    }

                    {showSelectButton === true &&
                        <Button
                            color={Colors.PRIMARY}
                            onClick={() => handleEditItem(row.original)}
                        >
                            <FontAwesomeIcon icon="edit"/>
                        </Button>
                    }

                    {showDeleteButton === true &&
                        <Button
                            color={Colors.ALERT}
                            onClick={() => promptDeleteItem(row.original)}
                        >
                            <FontAwesomeIcon icon="trash"/>
                        </Button>
                    }
                </ButtonGroup>
            ),
            show: showCopyButton || showSelectButton || showDeleteButton
        }
    ]

    const filters = (
        <React.Fragment>
            <div className="cell small-12 medium-6 large-3">
                <span>Search</span>
                <SearchBar
                    onDidChange={(newValue) => searchTextDidChange(newValue)}
                />
            </div>

            <div className="cell small-12 medium-6 large-3">
                <span>Organisation</span>
                <ReactSelect
                    name="organisationID"
                    className="react-select"
                    onChange={(e) => organisationDidChange(e.target.value)}
                    value={searchOrganisationID}
                    options={organisationOptions}
                />
            </div>
        </React.Fragment>

    )

    const table = (
        <ReactTable
            columns={columns}
            data={items}
            pageSize={pageSize}
            loading={isLoading}
            multisort={true}
            className='-striped'
        />
    )

    const buttons = [];

    if (PermissionUtil.create(PermissionUtil.PermissionCategories.promptTemplates)) {
        buttons.push(
            <button
                type="button"
                className="button small"
                onClick={handleAddNewItem}
            >
                New Template
            </button>
        )
    }

    return (
        <React.Fragment>
            <TableContainer
                title=""
                filters={filters}
                buttons={buttons}
                table={table}
            />

            <Modal
                classNames={{modal:"modal-medium"}}
                open={modalOpen}
                modalId="Modal"
                onClose={closeModal}
                center
            >
                {modalContent}
            </Modal>

        </React.Fragment>
    );

}
