
// This is mapped to the report types in the API

export const ReportType = {
    USAGE: "1",
    WELLBEING: "2",
    ACTIVE_USERS: "3",
    REGISTRATIONS: "4",
    WELLBEING_DAYS: "5",
    MOOD: "6",
    PODCASTS: "7",
    APP_EVENTS: "8",
    CTA: "9"
};

Object.freeze(ReportType);