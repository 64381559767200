import React from 'react'
import PageContent from 'components/common/PageContent'
import {AccessKeysTable} from "./AccessKeysTable";

export const AccessKeys = (props) => {

    const pathElements = [
        {id: 0, title: "Home", url: "/"},
        {id: 1, title: "Organisation", url: "/organisation"},
        {id: 2, title: "Access Keys"}
    ];

    return (
        <PageContent pathElements={pathElements}>
            <AccessKeysTable />
        </PageContent>
    )

}