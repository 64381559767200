import React from 'react'
import ContentEditor from "../contentEditor/ContentEditor";
import {NotificationManager} from "react-notifications";

export default class TranscriptForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            updatedRawContent: null
        };
    }

    handleChange = (content) => {
        this.setState({
            updatedRawContent: content
        })
    }

    handleSave = () => {
        if (typeof this.props.onSave === 'function') {

            if (this.state.updatedRawContent) {
                if (this.state.updatedRawContent.elements) {
                    let hasFormItem = false;
                    let hasSubmitButton = false;
                    this.state.updatedRawContent.elements.forEach((item) => {
                        if (item.type === "button" && item.data && item.data.action && item.data.action.type === "submit") {
                            hasSubmitButton = true
                        }
                        if (item.type === "textField" || item.type === "textView" || item.type === "checkbox" || item.type === "questionHeader") {
                            hasFormItem = true;
                        }
                    });

                    if (hasSubmitButton && !hasFormItem) {
                        NotificationManager.error("You must have at least one form element if submit button is added");
                        return;
                    }
                    else if (!hasSubmitButton && hasFormItem) {
                        NotificationManager.error("You must add the submit button if you have at least one form element");
                        return;
                    }
                }
            }

            this.props.onSave(this.state.updatedRawContent)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Edit Transcript</h3>
                </div>
                <div className="modal-body">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <ContentEditor
                                organisationID={this.props.organisationID}
                                content={this.props.content}
                                showFormElements={this.props.showFormElements}
                                labelsOnly={this.props.labelsOnly}
                                rawStructure={this.props.rawContent}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                    Cancel
                                </button>
                                <button className="button success" type="button" onClick={this.handleSave}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }

}