import React from 'react'
import { ServiceType } from 'constants/ServiceType'
import { SubmenuLayoutType } from "constants/SubmenuLayoutType";
import ReactSelect from "./ReactSelect";
import UUID from 'utils/UUID'

export default class SubmenuForm extends React.Component {

    constructor(props) {
        super(props);

        let node = props.node;

        if (node.data === undefined || node.data === null) {
            node.data = {};
        }

        if (node.typeText === null) {
            node.typeText = ServiceType.getTitle(null);
        }

        this.state = {
            item: Object.assign({
                id: UUID.uuidv4(),
                titleText: "",
                subtitleText: "",
                typeText: ServiceType.getTitle(null),
                data: {
                    carouselSize: null,
                    showHeader: 0,
                    showHeaderSubtitle: 0,
                    showCellHeader: 0
                },
                typeID: null,
                typeName: null,
            },props.node),
            validated: false,
            errors: {},
            touched: {}
        };
    }

    componentDidMount() {
        this.validate();
    }


    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        console.log(name);

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        let item = Object.assign({}, this.state.item);

        if (name.includes("data.")) {
            let data = Object.assign({}, item.data);
            name = name.replace("data.", "");
            data[name] = value;
            item.data = data;
        }
        else {
            item[name] = value;
        }


        this.setState({
            item: item
        }, () => {
            this.validate();
        });
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.onSubmitted instanceof Function) {
            this.props.onSubmitted(this.state.item)
        }
    }


    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.titleText) {
            if (touched.titleText) {
                errors.titleText = "Title is required";
            }
            validated = false;
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    render() {

        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Create new submenu</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="titleText" className="text-right middle">* Title</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.titleText && "error")}>
                                <input name="titleText" type="text" value={this.state.item.titleText} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <small className="error">{this.state.errors.titleText}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="subtitleText" className="text-right middle">Subtitle</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.subtitleText && "error")}>
                                <input name="subtitleText" type="text" value={this.state.item.subtitleText} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <small className="error">{this.state.errors.subtitleText}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="typeText" className="text-right middle">Type title</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.typeText && "error")}>
                                <input name="typeText" type="text" value={this.state.item.typeText} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <small className="error">{this.state.errors.typeText}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="data.showCarousel" className="text-right middle">Show as carousel</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="data.showCarousel" onChange={this.handleChange}
                                           checked={this.state.item.data.showCarousel || 0 === 1}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.data.showCarousel === 1 &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="data.carouselSize" className="text-right middle">Carousel Size</label>
                                </div>
                                <div className="large-9 small-9 cell">
                                    <ReactSelect
                                        name="data.carouselSize"
                                        onChange={this.handleChange}
                                        value={this.state.item.data.carouselSize || null}
                                        options={SubmenuLayoutType.getTypes()}
                                        className="react-select"
                                    />
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="data.showHeader" className="text-right middle">Show header</label>
                                </div>
                                <div className="large-4 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="data.showHeader" onChange={this.handleChange}
                                               checked={this.state.item.data.showHeader || 0 === 1}/>
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            {this.state.item.data.showHeader === 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="data.showHeaderSubtitle" className="text-right middle">Show subtitle</label>
                                </div>
                                <div className="large-4 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="data.showHeaderSubtitle" onChange={this.handleChange}
                                               checked={this.state.item.data.showHeaderSubtitle || 0 === 1}/>
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            }
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="data.showCellHeader" className="text-right middle">Item shows type title</label>
                                </div>
                                <div className="large-4 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="data.showCellHeader" onChange={this.handleChange}
                                               checked={this.state.item.data.showCellHeader || 0 === 1}/>
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </React.Fragment>
        );
    }
}
