import React from 'react'
import UUID from 'utils/UUID'

export default class SectionHeaderForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: {
                titleText: "",
                isSectionHeader: true,
                noChildren: true,
                id: UUID.uuidv4()
            },
            validated: false,
            errors: {},
            touched: {}
        };
    }

    componentDidMount() {
        this.validate();
    }


    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        console.log(name);

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let item = this.state.item;
        item[name] = value;

        this.setState({
            item: item
        }, () => {
            this.validate();
        });
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.onSubmitted instanceof Function) {
            this.props.onSubmitted(this.state.item)
        }
    }


    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.titleText) {
            if (touched.titleText) {
                errors.titleText = "Title is required";
            }
            validated = false;
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    render() {

        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Create new section header</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="titleText" className="text-right middle">* Title</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.titleText && "error")}>
                                <input name="titleText" type="text" value={this.state.item.titleText} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <span className="error">{this.state.errors.titleText}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </React.Fragment>
        );
    }
}
