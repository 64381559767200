import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


export default class FileInput extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            file: null
        }
    }

    onChange = (e) => {
        if (this.props.onChange instanceof Function) {
            this.props.onChange(e);
        }
        this.setState({
            file: e.target.files[0]
        });
    }

    handleClick = () => {
        this.inputElement.click();
    }

    handleDelete = () => {
        if (this.props.onDelete instanceof Function) {
            this.props.onDelete(this.props.name || "")
        }
        this.setState({
            file: null
        })
    }

    render() {

        let { onChange, disabled, ...props } = this.props;

        let color = this.state.file ? "#000" : "#666";

        if (disabled === undefined) {
            disabled = false;
        }

        return (
            <React.Fragment>
                <input
                    ref={input => this.inputElement = input}
                    type="file"
                    onChange={this.onChange}
                    style={{
                        opacity: "0",
                        position: "absolute",
                        pointerEvents: "none",
                        width: "1px",
                        height: "1px"
                    }}
                    {...props}
                />
                <div className="input-group">
                    <input className="input-group-field" type="text" value={this.state.file ? this.state.file.name : (this.props.placeholder || "Not selected") } style={{color: color}} disabled/>
                        <div className="input-group-button">
                            {this.state.file &&
                            <button type="button" className="button alert" onClick={this.handleDelete} disabled={disabled}><FontAwesomeIcon icon="times-circle"/></button>
                            }
                            <button type="button" className="button" onClick={this.handleClick} disabled={disabled}>Browse</button>
                        </div>
                </div>
            </React.Fragment>
        )

    }

}