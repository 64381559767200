import ConfigsTable from "../sectionConfig/ConfigsTable";
import {SectionType} from "../../constants/SectionType";
import PageContent from "../common/PageContent";
import React, {useEffect, useState} from "react";
import {PermissionUtil} from "../../utils/PermissionUtil";
import ReactSelect from "../common/ReactSelect";
import {PluginUtil} from "../../utils/PluginUtil";

export const PluginSupportTable = (props) => {

    const TABLE_MODE_SUPPORT = 1;
    const TABLE_MODE_NEED_HELP = 2;

    const [tableMode, setTableMode] = useState(PluginUtil._lastSupportPageTableMode);

    useEffect(() => {
        PluginUtil._lastSupportPageTableMode = tableMode;
    }, [tableMode]);

    const pathElements = [
        {id: 0, title: "Home", url: "/"},
        {id: 1, title: "Plugin", url : "/plugin"},
        {id: 2, title : "Support"}
    ];

    let sectionType = SectionType.ASSISTANT_SUPPORT;
    let title = "Support Sections";
    if (tableMode === TABLE_MODE_NEED_HELP) {
        sectionType = SectionType.ASSISTANT_NEED_HELP;
        title = "I Need Help";
    }

    return (
        <PageContent pathElements={pathElements}>
            <div className="grid-x grid-padding-x">
                <div className="small-3 cell">
                    <label htmlFor="tableMode" className="text-right middle">Show Items for</label>
                </div>
                <div className={"small-6 cell"}>
                    <ReactSelect
                        name="tableMode"
                        className="react-select"
                        onChange={(e) => { setTableMode(parseInt(e.target.value)) }}
                        value={tableMode}
                        options={[
                            { value : TABLE_MODE_SUPPORT, label : "Support" },
                            { value : TABLE_MODE_NEED_HELP, label : "I Need Help"}
                        ]}
                    />
                </div>
            </div>

            <ConfigsTable
                title={title}
                permissions={PermissionUtil.get("plugin")}
                sectionType={sectionType}
            />
        </PageContent>
    )

}