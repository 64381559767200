import React from 'react';
import { Link } from 'react-router-dom'

function SubSectionButton(props) {
    return (
        <div className={"cell small-12 medium-6 sub-option " + props.item.iconClass}>
            <Link to={props.item.link}>
                <div className="grid-x grid-padding-x">
                    <div className="cell large-2 medium-3 small-4 subwrap">
                        <div className="subthumb"></div>
                    </div>
                    <div className="cell large-10 medium-9 small-8 subtext">
                        <h3>{props.item.title}</h3>
                        {props.item.subtitle &&
                        <span>{props.item.subtitle}</span>}
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default SubSectionButton;