import podcastIcon from "assets/img/dynamic_podcast.png";
import nativeFeatureIcon from "assets/img/dynamic_native_feature.png";
import websiteIcon from "assets/img/dynamic_website.png";
import challengeIcon from "assets/img/dynamic_challenge_service.png";
import pageIcon from "assets/img/dynamic_page.png";
import videoIcon from "assets/img/dynamic_video.png";
import submenuIcon from "assets/img/dynamic_submenu.png";
import habitIcon from "assets/img/dynamic_habits.png";
import dividerIcon from "assets/img/dynamic_divider.png";

export const ServiceType = {
    NATIVE_FEATURE: 1,
    WEBSITE: 2,
    PODCAST: 3,
    PAGE: 4,
    CHALLENGE: 5,
    VIDEO: 6,
    HABIT: 7,
    ASSESSMENT: 8,
    SSO_LOGIN : 9,

    getTitle: (serviceType) => {
        switch (serviceType) {
            case ServiceType.NATIVE_FEATURE:
                return "Native feature";
            case ServiceType.PODCAST:
                return "Podcast";
            case ServiceType.WEBSITE:
                return "Website";
            case ServiceType.PAGE:
                return "Page";
            case ServiceType.CHALLENGE:
                return "Challenge";
            case ServiceType.VIDEO:
                return "Video";
            case ServiceType.HABIT:
                return "Habit";
            case ServiceType.ASSESSMENT:
                return "Assessment";
            case ServiceType.SSO_LOGIN:
                return "SSO Login";
            default:
                return "Submenu";
        }
    },

    getBackgroundColor: (serviceType) => {
        switch (serviceType) {
            case ServiceType.NATIVE_FEATURE:
                return "#CC7AC6";
            case ServiceType.WEBSITE:
                return "#9BCC7A";
            case ServiceType.PODCAST:
                return "#7ACCCB";
            case ServiceType.PAGE:
                return "#CC7A9B";
            case ServiceType.CHALLENGE:
                return "#BBCC7A";
            case ServiceType.VIDEO:
                return "#CC7A7A";
            case ServiceType.HABIT:
                return "#7A8FCD";
            default:
                return "#BDBDBD";
        }
    },

    getIcon: (serviceType) => {
        switch (serviceType) {
            case ServiceType.NATIVE_FEATURE:
                return nativeFeatureIcon;
            case ServiceType.WEBSITE:
                return websiteIcon;
            case ServiceType.PODCAST:
                return podcastIcon;
            case ServiceType.PAGE:
                return pageIcon;
            case ServiceType.CHALLENGE:
                return challengeIcon;
            case ServiceType.VIDEO:
                return videoIcon;
            case ServiceType.HABIT:
                return habitIcon;
            case ServiceType.ASSESSMENT:
                return habitIcon;
            default:
                return submenuIcon;
        }
    },

    getNodeClass: (serviceType) => {
        switch (serviceType) {
            case ServiceType.NATIVE_FEATURE:
                return "node-native";
            case ServiceType.WEBSITE:
                return "node-website";
            case ServiceType.PODCAST:
                return "node-podcast";
            case ServiceType.PAGE:
                return "node-page";
            case ServiceType.CHALLENGE:
                return "node-challenge";
            case ServiceType.VIDEO:
                return "node-video";
            case ServiceType.HABIT:
                return "node-habit";
            case ServiceType.ASSESSMENT:
                return "node-habit";
            default:
                return "node-submenu";
        }
    }
};

Object.freeze(ServiceType);