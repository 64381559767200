import ConfigsTable from 'components/sectionConfig/ConfigsTable'
import React from 'react'
import PageContent from 'components/common/PageContent'
import { SectionType } from 'constants/SectionType'
import AppComponent from 'components/common/AppComponent'

const pathElements = [
    {id: 0, title: "Home", url: "/"},
    {id: 1, title: "I Need Help Now"}
];

export default class NeedHelpNow extends AppComponent {

    render() {
        return (
            <PageContent pathElements={pathElements} >
                <ConfigsTable
                    permissions={this.context.permissions.needHelpNow}
                    sectionType={SectionType.I_NEED_HELP_NOW}
                />
            </PageContent>
        );
    }
}