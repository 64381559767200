import React from 'react'
import Utils from 'utils/Utils'
import ReactSelect from 'components/common/ReactSelect'
import BlockUI from 'react-block-ui'
import ServicesSelector from 'components/services/ServicesSelector'
import Modal from "react-responsive-modal";


export default class ButtonEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            actionTypeOptions: [
                {value: "service", label: "Service"},
                {value: "website", label: "Website"},
                {value: "email", label: "Email"},
                {value: "phone", label: "Phone Call"}
            ],
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            }
        }
    }

    handleChange = (event) => {

        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = Object.assign({}, this.props.item);

        if (name === "margin-left" || name === "margin-right" || name === "margin-top" || name === "margin-bottom") {
            let type = name.replace("margin-", "");
            let style = Object.assign({}, this.props.item.style);
            let margins = Object.assign({}, style.margins);

            if (value !== null && value.toString().length > 0) {
                margins[type] = parseInt(value);
            }
            else {
                delete margins[type];
            }

            if (Object.keys(margins).length > 0) {
                style.margins = margins;
            }
            else {
                delete style.margins;
            }

            if (Object.keys(style).length > 0) {
                item.style = style;
            }
            else {
                delete item.style;
            }
        }
        else if (name === "data-url" || name === "data-email" || name === "data-phoneNumber") {
            let attribute = name.replace("data-", "");
            let itemData = Object.assign({}, this.props.item.data);
            let action = Object.assign({}, itemData.action);
            let data = {};
            data[attribute] = value;
            action.data = data;
            itemData.action = action;
            item.data = itemData;
        }
        else if (name === "action-type") {
            let itemData = Object.assign({}, this.props.item.data);
            let action = Object.assign({}, itemData.action);
            action.type = value;
            action.data = {};
            itemData.action = action;
            item.data = itemData;
        }
        else if (name === "title" || name === "subtitle") {
            let data = Object.assign({}, item.data);
            data[name] = value;
            item.data = data;
        }

        if (this.props.onChange instanceof Function) {
            this.props.onChange(item);
        }
    }

    handleDelete = () => {
        if (this.props.onDelete instanceof Function) {
            this.props.onDelete(this.props.item);
        }
    }

    showServiceModal = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        let serviceID = Utils.getNested(this.props.item, "action", "data", "serviceID");
        if (serviceID) {
            selected.push({id: serviceID})
        }

        const content = (
            <ServicesSelector
                organisationID={this.props.organisationID}
                isSingleSelection
                selected={selected}
                onSubmitted={(service) => {

                    let item = Object.assign({}, this.props.item);
                    let itemData = Object.assign({}, item.data);
                    let action = Object.assign({}, itemData.action);
                    action.data = {
                        serviceID: service.id,
                        serviceTitle: service.title,
                        serviceTypeName: service.typeName
                    };
                    itemData.action = action;
                    item.data = itemData;

                    this.closeModal(() => {
                        if (this.props.onChange instanceof Function) {
                            this.props.onChange(item);
                        }
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        })
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null,
                    isUploading: false
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    render() {

        let leftMargin = Utils.getNested(this.props.item, "style", "margins", "left");
        let rightMargin = Utils.getNested(this.props.item, "style", "margins", "right");
        let topMargin = Utils.getNested(this.props.item, "style", "margins", "top");
        let bottomMargin = Utils.getNested(this.props.item, "style", "margins", "bottom");

        let actionType = Utils.getNested(this.props.item, "data", "action", "type");

        let serviceTitle = Utils.getNested(this.props.item, "data", "action", "data", "serviceTitle");
        let serviceType = Utils.getNested(this.props.item, "data", "action", "data", "serviceTypeName");

        let url = Utils.getNested(this.props.item, "data", "action", "data", "url");
        let email = Utils.getNested(this.props.item, "data", "action", "data", "email");
        let phoneNumber = Utils.getNested(this.props.item, "data", "action", "data", "phoneNumber");

        const title = Utils.value(Utils.getNested(this.props.item, "data", "title"), "");
        const subtitle = Utils.value(Utils.getNested(this.props.item, "data", "subtitle"), "");

        return (
            <div className="grid-x grid-padding-x">
                <div className="small-9 cell">
                    <h3>{ this.props.item.type === "button" ? "Button" : "List Item"}</h3>
                </div>
                <div className="cell auto text-right">
                    <button className="button alert small" onClick={this.handleDelete}>Delete</button>
                </div>
                <div className="cell small-12 medium-12 large-8">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <fieldset className="fieldset">
                                <legend>Properties</legend>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell">
                                        <span>* Title</span>
                                        <input type="text" name="title" onChange={this.handleChange} value={title} />
                                    </div>
                                    {Utils.getNested(this.props.item, "data", "subtitle") !== undefined &&
                                    <div className="small-12 cell">
                                        <span>Subtitle</span>
                                        <input type="text" name="subtitle" onChange={this.handleChange} value={subtitle} />
                                    </div>
                                    }
                                    <div className="small-12 cell">
                                        <span>Action</span>
                                        <ReactSelect
                                            name="action-type"
                                            className="react-select"
                                            onChange={this.handleChange}
                                            value={actionType}
                                            options={this.state.actionTypeOptions}
                                        />
                                    </div>
                                    {actionType === "service" &&
                                    <div className="small-12 cell" style={{marginTop: 15}}>
                                        <span>Service</span>
                                        <div className="input-group">
                                            {serviceType &&
                                            <span className="input-group-label" style={{fontSize: 10}}>{serviceType}</span>
                                            }
                                            <input className="input-group-field" type="text" value={serviceTitle || "Not selected" } disabled/>
                                            <div className="input-group-button">
                                                <button type="button" className="button" onClick={this.showServiceModal}>Browse</button>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {actionType === "website" &&
                                    <div className="small-12 cell">
                                        <span>* URL</span>
                                        <input type="text" name="data-url" onChange={this.handleChange} value={url} />
                                    </div>
                                    }
                                    {actionType === "email" &&
                                    <div className="small-12 cell">
                                        <span>* Email</span>
                                        <input type="email" name="data-email" onChange={this.handleChange} value={email} />
                                    </div>
                                    }
                                    {actionType === "phone" &&
                                    <div className="small-12 cell">
                                        <span>* Phone number</span>
                                        <input type="text" name="data-phoneNumber" onChange={this.handleChange} value={phoneNumber} />
                                    </div>
                                    }
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div className="cell small-12 medium-12 large-4">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell small-setts-box">
                            <fieldset className="fieldset">
                                <legend>Margins</legend>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-6 cell">
                                        <span>Left</span>
                                        <input name="margin-left" type="number" placeholder="inherited" min={0} value={leftMargin} onChange={this.handleChange} />
                                    </div>
                                    <div className="small-6 cell">
                                        <span>Top</span>
                                        <input name="margin-top" type="number" placeholder="inherited" min={0} value={topMargin} onChange={this.handleChange} />
                                    </div>
                                    <div className="small-6 cell">
                                        <span>Right</span>
                                        <input name="margin-right" type="number" placeholder="inherited" min={0} value={rightMargin} onChange={this.handleChange} />
                                    </div>
                                    <div className="small-6 cell">
                                        <span>Bottom</span>
                                        <input name="margin-bottom" type="number" placeholder="inherited" min={0} value={bottomMargin} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="SupportModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </div>
        )
    }

}