import React from 'react'
import PageContent from 'components/common/PageContent'
import MoneyOffersTable from 'components/money/offers/MoneyOffersTable'

export default function MoneyOffers(props) {

    const pathElements = [
        {id: 0, title: "Home", url: "/"},
        {id: 1, title: "Money", url: "/money"},
        {id: 2, title: "Offers"}
    ];

    return (
        <PageContent pathElements={pathElements}>
            <MoneyOffersTable/>
        </PageContent>
    )
}