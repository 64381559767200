import React from 'react'
import ReactTable from 'react-table'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchBar from 'components/common/SearchBar'
import DatatableHelper from 'utils/DatatableHelper'
import { NotificationManager } from 'react-notifications'
import Modal from 'react-responsive-modal'
import EmotionForm from 'components/emotions/EmotionForm'
import { Colors, Sizes, ButtonGroup, Button, Switch} from 'react-foundation'
import TableContainer from 'components/common/TableContainer'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ReactSelect from 'components/common/ReactSelect'
import Image from 'components/common/Image'
import StandardModal from 'components/common/StandardModal'
import DateHelper from 'utils/DateHelper'
import PodcastsSelector from "../podcasts/PodcastsSelector";


export default class EmotionsTable extends AppComponent {

    constructor(props, context) {
        super(props);

        this.state = {
            items: [],
            searchKey: "",
            organisationID: context.organisation.id,
            organisationOptions: [{value: context.organisation.id, label: context.organisation.name}],
            page: 0,
            pageSize: 10,
            sorted: [{id: "id", "desc": true}],
            totalPages: -1,
            isLoading: true,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            },
            filtersAreOpen: false
        };

        this.searchTimer = null;
    }

    componentDidMount() {
        if (this.context.permissions.emotions.allOrgs) {
            this.getOrganisationOptions();
        }
    }

    searchTextDidChange(newValue) {
        this.setState({
            searchKey: newValue || ""
        }, () => {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.fetchItems();
            }, 1000);
        });
    }

    handleChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "organisationID") {
            this.setState({
                organisationID: value,
                page: 0
            }, () => {
                this.fetchItems();
            });
        }
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations, {
                props: {
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    fetchItems = () => {

        this.setState({
            loading: true
        });

        Axios
            .post(API.emotion.getEmotionsDT, {
                    page: this.state.page,
                    pageSize: this.state.pageSize,
                    sorted: this.state.sorted,
                    filters: {
                        search: this.state.searchKey,
                        organisationID: this.state.organisationID
                    }
                }
            )
            .then((res) => {
                this.setState({
                    items: res.data.data.rows,
                    totalPages: DatatableHelper.getTotalPages(res.data.data),
                    isLoading: false
                })
            })
    }

    handleEmotionVisibility = (row, event) => {

        const value = event.target.checked ? 1 : 0;

        const index = this.state.items.findIndex(i => i.id === row.id);
        if (index > -1) {
            let items = this.state.items;
            row.visible = value;
            items[index] = row;
            this.setState({
                items: items
            });
        }

        Axios
            .post(API.emotion.updateOrganisationVisibility, {
                id: row.id,
                visible: value,
                organisationID: this.state.organisationID
            })
            .then((res) => {
                if (res.data.success !== true) {
                    //TODO show error toast
                    const index = this.state.items.findIndex(i => i.id === row.id);
                    if (index) {
                        let items = this.state.items;
                        row.visible = value === 1 ? 0 : 1;
                        items[index] = row;
                        this.setState({
                            items: items
                        });
                    }
                }
            })
    }


    handleAddNewItem = () => {
        this.showItemModal({});
    }


    handleEditItem = (item) => {
        this.showItemModal(item);
    }

    showItemModal = (item) => {
        if (!this.state.modal.isOpen) {
            const content = (
                <EmotionForm
                    item={item}
                    onSubmitted={() => this.closeModal(this.fetchItems)}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: {
                    isOpen: true,
                    content: content
                }
            })
        }
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null,
                    isUploading: false
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    showDeleteModal = (item) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Emotion"
                    content={(
                        <React.Fragment>
                            <p>Are you sure to delete <strong>{item.name}</strong> emotion ? This action cannot be reverted.</p>
                            <p>Please note that the deleted emotion will still be visible in reports and in the journals to users who have already used it.</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteItem(item)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                    isUploading={this.state.modal.isUploading}
                />
            );

            this.setState({
                modal: {
                    isOpen: true,
                    content: content
                }
            })
        }
    }

    handleDeleteItem = (item) => {

        if (!this.state.modal.isUploading) {
            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isUploading: true
                })
            }, () => {
                Axios
                    .post(API.emotion.deleteEmotion, {
                        id: item.id
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.closeModal(() => {
                                this.fetchItems();
                                NotificationManager.success("Emotion deleted");
                            });
                        }
                        else {
                            this.closeModal(() => {
                                NotificationManager.error(res.data.error.desc);
                            });
                        }
                    })
                    .catch(error => {
                        this.closeModal(() => {
                            NotificationManager.error(error.response);
                        });
                    });
            })
        }
    }

    toggleRow = (item) => {
        if (this.props.onSelectedRow instanceof Function) {
            this.props.onSelectedRow(item);
        }
    }

    toggleSelectAll = () => {
        if (this.props.onSelectedAll instanceof Function) {
            this.props.onSelectedAll(!this.props.selectAll, this.state.items);
        }
    }

    handleOrderChange = (row, e) => {
        const value = e.target.value;
        let prevValue = value;
        const index = this.state.items.findIndex(i => i.id === row.id);
        if (index > -1) {
            let items = this.state.items;
            prevValue = row.order;
            row.order = value;
            items[index] = row;
            this.setState({
                items: items
            });
        }

        Axios
            .post(API.emotion.updateOrganisationOrder, {
                id: row.id,
                order: value,
                organisationID: this.state.organisationID
            })
            .then((res) => {
                if (res.data.success !== true) {
                    //TODO show error toast
                    const index = this.state.items.findIndex(i => i.id === row.id);
                    if (index) {
                        let items = this.state.items;
                        row.order = prevValue;
                        items[index] = row;
                        this.setState({
                            items: items
                        });
                    }
                }
            })
    }

    showPodcastModal = (item, key) => {

        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <PodcastsSelector
                isSingleSelection
                onSubmitted={(podcast) => {

                    let item = Object.assign({}, this.state.item);
                    item.params[key] = {
                        title: podcast.title,
                        value: podcast.id
                    };

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                        this.updateNodeText();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })

    }

    render() {

        let columns = [
            {
                id: "checkbox",
                accessor: "",
                Cell: row => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.props.selected.find((o) => o.id === row.original.id) ? true : false}
                            onChange={() => this.toggleRow(row.original)}
                        />
                    );
                },
                Header: () => {
                    if (this.props.isSingleSelection) {
                        return null;
                    }
                    else {
                        return (
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={this.props.selectAll}
                                onChange={this.toggleSelectAll}
                            />
                        );
                    }
                },
                sortable: false,
                width: 45,
                show: this.props.isSelector || false
            },
            {
                Header: 'ID',
                accessor: 'id',
                width: 60
            },
            {
                Header: "Image",
                accessor: 'imageURL',
                width: 70,
                Cell: row => (
                    <Image src={row.original.imageURL} width={50} height={25} fit={"contain"} />
                ),
                sortable: false,
                className: "text-center"
            },
            {
                Header: 'Name',
                accessor: 'name',
                minWidth: 200
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                width: 180,
                Cell: row => {
                    return DateHelper.convertToLocalDateTime(row.value);
                }
            },
            {
                Header: "Visible",
                accessor: 'visible',
                width: 90,
                Cell: row => {
                    if (this.context.permissions.emotions.edit) {
                        return (<Switch size={Sizes.TINY} onChange={(event) => this.handleEmotionVisibility(row.original, event)} input={{checked: row.original.visible}} />);
                    }
                    else {
                        return row.original.visible === 1 ? "Yes" : "No";
                    }
                },
                className: "text-center",
                show: !this.props.isSelector && this.state.organisationID !== null
            },
            {
                Header: "Order",
                accessor: "order",
                width: 90,
                Cell: row => {
                    if (this.context.permissions.emotions.edit) {
                        return (<input type="text" onChange={(event) => this.handleOrderChange(row.original, event)} value={row.original.order} style={{height: "28px"}} />)
                    }
                    else {
                        return row.original.order;
                    }
                },
                className: "text-center",
                show: !this.props.isSelector && this.state.organisationID !== null
            },
            {
                Header: 'Actions',
                Cell: row => (
                    <ButtonGroup size={Sizes.TINY}>
                        {this.context.permissions.emotions.edit && (this.context.organisation.id === row.original.organisationID || this.context.permissions.emotions.allOrgs) &&
                        <Button color={Colors.PRIMARY} onClick={() => this.handleEditItem(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                        }
                        {this.context.permissions.emotions.delete && (this.context.organisation.id === row.original.organisationID || this.context.permissions.emotions.allOrgs) &&
                        <Button color={Colors.ALERT} onClick={() => this.showDeleteModal(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                        }
                    </ButtonGroup>
                ),
                maxWidth: 100,
                sortable: false,
                show: !this.props.isSelector && (this.context.permissions.emotions.delete || this.context.permissions.emotions.edit)
            }
        ];

        const filters = (
            <React.Fragment>
                {this.context.permissions.emotions.allOrgs && !this.props.isSelector &&
                <div className="cell small-12 medium-6 large-3">
                    <span>Visibility for</span>
                    <ReactSelect
                        name="organisationID"
                        onChange={this.handleChange}
                        value={this.state.organisationID}
                        options={this.state.organisationOptions}
                        isClearable
                        className="react-select"
                        placeholder="None"
                    />
                </div>
                }
                <div className="cell small-12 medium-6 large-3">
                    <span>Search</span>
                    <SearchBar
                        onDidChange={(newValue) => this.searchTextDidChange(newValue)}
                    />
                </div>
            </React.Fragment>
        );

        const buttons = (
            <React.Fragment>
                {this.context.permissions.emotions.create && !this.props.isSelector &&
                <button type="button" className="button small" onClick={this.handleAddNewItem}>Add New Emotion</button>
                }
            </React.Fragment>
        );

        const table = (
            <ReactTable
                columns={columns}
                data={this.state.items}
                page={this.state.page}
                pages={this.state.totalPages}
                pageSize={this.state.pageSize}
                loading={this.state.isLoading}
                multisort={true}
                manual
                className='-striped'
                onFetchData={(state) => {
                    this.setState({
                        page: state.page,
                        pageSize: state.pageSize,
                        sorted: state.sorted
                    }, () => {
                        this.fetchItems()
                    })
                }}
            />
        )

        return (

            <React.Fragment>
                <TableContainer
                    title="Emotions table"
                    buttons={buttons}
                    filters={filters}
                    table={table}
                />
                <Modal
                    classNames={{modal: "modal-medium"}}
                    open={this.state.modal.isOpen}
                    modalId="EmotionModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }

}