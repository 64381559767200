import React from 'react';
import ReactTable from 'react-table'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchBar from 'components/common/SearchBar'
import DatatableHelper from 'utils/DatatableHelper'
import Modal from 'react-responsive-modal'
import PostForm from 'components/posts/PostForm'
import { Colors, Sizes, ButtonGroup, Button} from 'react-foundation'
import { API } from 'api/APIClient'
import TableContainer from 'components/common/TableContainer'
import AppComponent from 'components/common/AppComponent'
import { NotificationManager } from "react-notifications"
import StandardModal from 'components/common/StandardModal'
import {Link} from "react-router-dom";
import DateHelper from 'utils/DateHelper'

class ChallengesTable extends AppComponent {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            searchKey: "",
            organisations: [],
            page: 0,
            pageSize: props.pageSize || 10,
            sorted: [{id: "id", "desc": true}],
            totalPages: -1,
            isLoading: true,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            },
            filtersAreOpen: false
        };

        this.searchTimer = null;
    }


    searchTextDidChange(newValue) {
        this.setState({
            searchKey: newValue || ""
        }, () => {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.fetchItems();
            }, 1000);
        });
    }


    handleChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "organisations") {

            if (value.length === this.state.organisationOptions.length) {
                value = [];
            }

            this.setState({
                organisations: value
            }, () => {
                this.fetchItems();
            });
        }
    }


    fetchItems = () => {
        this.setState({
            loading: true
        });

        if (this.props.onWillFetch instanceof Function) {
            this.props.onWillFetch();
        }

        Axios
            .post(API.challenge.getChallengesDT, {
                    page: this.state.page,
                    pageSize: this.state.pageSize,
                    sorted: this.state.sorted,
                    filters: {
                        search: this.state.searchKey,
                        organisations: this.state.organisations
                    }
                }
            )
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        items: res.data.data.rows,
                        totalPages: DatatableHelper.getTotalPages(res.data.data),
                        isLoading: false
                    }, () => {
                        if (this.props.onDidFetch instanceof Function) {
                            this.props.onDidFetch();
                        }
                    })
                }
                else {
                    this.setState({
                        isLoading: false
                    })
                }
            })
    }

    handleAddNewItem = () => {
        this.showItemModal({});
    }


    handleEditItem = (item) => {
        this.showItemModal(item);
    }

    showItemModal = (item) => {
        if (!this.state.modal.isOpen) {
            const content = (
                <PostForm
                    item={item}
                    onSubmitted={() => this.closeModal(this.fetchItems)}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null,
                    isUploading: false
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    toggleRow = (item) => {
        if (this.props.onSelectedRow instanceof Function) {
            this.props.onSelectedRow(item);
        }
    }

    toggleSelectAll = () => {
        if (this.props.onSelectedAll instanceof Function) {
            this.props.onSelectedAll(!this.props.selectAll, this.state.items);
        }
    }

    showDeleteModal = (item) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Challenge"
                    content={(
                        <div>Are you sure to delete <strong>{item.title}</strong> challenge ? This action cannot be reverted.</div>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteItem(item)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                    isUploading={this.state.modal.isUploading}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }

    handleDeleteItem = (item) => {

        if (!this.state.modal.isUploading) {
            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isUploading: true
                })
            }, () => {

                Axios
                    .post(API.challenge.deleteChallenge, {
                        id: item.id
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.closeModal(() => {
                                this.fetchItems();
                                NotificationManager.success("Challenge deleted");
                            });
                        }
                        else {
                            this.closeModal(() => {
                                NotificationManager.error(res.data.error.desc);
                            });
                        }
                    })
                    .catch(error => {
                        this.closeModal(() => {
                            NotificationManager.error(error.response);
                        });
                    });
            })
        }
    }

    render() {

        const columns = [
            {
                id: "checkbox",
                accessor: "",
                Cell: row => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.props.selected.find((o) => o.id === row.original.id) ? true : false}
                            onChange={() => this.toggleRow(row.original)}
                        />
                    );
                },
                Header: () => {
                    if (this.props.isSingleSelection) {
                        return null;
                    }
                    else {
                        return (
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={this.props.selectAll}
                                onChange={this.toggleSelectAll}
                            />
                        );
                    }
                },
                sortable: false,
                width: 45,
                show: this.props.isSelector || false
            },
            {
                Header: 'ID',
                accessor: 'id',
                maxWidth: 60
            },
            {
                Header: "Title",
                accessor: "title"
            },
            {
                Header: 'Type',
                accessor: 'typeName',
                maxWidth: 180
            },
            {
                Header: 'Start date',
                accessor: 'startDate',
                maxWidth: 160,
                Cell: row => {
                    return DateHelper.convertToLocalDateTime(row.value);
                }
            },
            {
                Header: 'End date',
                accessor: 'endDate',
                maxWidth: 160,
                Cell: row => {
                    return DateHelper.convertToLocalDateTime(row.value);
                }
            },
            {
                Header: "Target",
                accessor: 'target',
                maxWidth: 200,
                Cell: row => {
                    if (row.original.target) {
                        return String(row.original.target) + " " + (row.original.target === 1 ? row.original.unitName : row.original.unitNamePlural).toLowerCase();
                    }
                    else {
                        return "No target";
                    }
                }
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                maxWidth: 180,
                Cell: row => {
                    return DateHelper.convertToLocalDateTime(row.value);
                }
            },
            {
                Header: 'Actions',
                Cell: row => (
                    <ButtonGroup size={Sizes.TINY}>
                        <Link
                            to={{
                                pathname: "/challenges/editChallenge",
                                state: {
                                    permissions: this.context.permissions.challenges,
                                    item: row.original
                                }
                            }}
                        >
                            <Button color={Colors.PRIMARY}><FontAwesomeIcon icon="edit" /></Button>
                        </Link>
                        <Button color={Colors.ALERT} onClick={() => this.showDeleteModal(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                    </ButtonGroup>
                ),
                maxWidth: 100,
                className: "center",
                sortable: false,
                show: !this.props.isSelector && (this.context.permissions.challenges.edit || this.context.permissions.challenges.delete)
            }
        ]

        const table = (
            <ReactTable
                columns={columns}
                data={this.state.items}
                pages={this.state.totalPages}
                pageSize={this.state.pageSize}
                loading={this.state.isLoading}
                multisort={true}
                manual
                className='-striped'
                onFetchData={(state) => {
                    this.setState({
                        page: state.page,
                        pageSize: state.pageSize,
                        sorted: state.sorted
                    }, () => {
                        this.fetchItems()
                    })
                }}
            />
        );

        const filters = (
            <React.Fragment>
                <div className="cell small-12 medium-6 large-3">
                    <span>Search</span>
                    <SearchBar
                        onDidChange={(newValue) => this.searchTextDidChange(newValue)}
                    />
                </div>
            </React.Fragment>
        )

        const buttons = (
            <React.Fragment>
                {this.context.permissions.challenges.create && !this.props.isSelector &&
                <Link
                    to={{
                        pathname: "/challenges/createChallenge",
                        state: {
                            permissions: this.context.permissions.service
                        }
                    }}
                >
                    <button type="button" className="button small" >Add New Challenge</button>
                </Link>
                }
            </React.Fragment>
        )

        return (
            <React.Fragment>
                <TableContainer
                    title="Challenges table"
                    buttons={buttons}
                    filters={filters}
                    table={table}
                />
                <Modal
                    classNames={{modal:"modal-medium"}}
                    open={this.state.modal.isOpen}
                    modalId="challengeModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}

export default ChallengesTable;