export const SSOUtil = {
    keys : {
        EMAIL : "email",
        FIRST_NAME : "firstName",
        LAST_NAME : "lastName",
        DOB : "dob"
    },
    defaults : {
        email : "email",
        firstName : "first_name",
        lastName : "last_name",
        dob : "dob"
    }
}