import React from 'react'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from "../common/ModalProgress"
import ReactSelect from "../common/ReactSelect"
import Image from "../common/Image"

import logoImage from 'assets/img/uniwellbeing-logo.png'

import mobile_1 from 'assets/img/post-help/image_1.jpg'
import mobile_2 from 'assets/img/post-help/image_2.jpg'
import mobile_3 from 'assets/img/post-help/image_3.jpg'
import mobile_4 from 'assets/img/post-help/image_4.jpg'
import desktop_1 from 'assets/img/post-help/post_1.jpeg'
import desktop_2 from 'assets/img/post-help/post_2.jpeg'
import desktop_3 from 'assets/img/post-help/post_3.jpeg'
import desktop_4 from 'assets/img/post-help/post_4.jpeg'


export default class PostHelp extends AppComponent {

    render() {
        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Post examples</h3>
                </div>
                <div className="modal-body">
                    <div className="grid-x grid-padding-x" style={{marginBottom: 40, backgroundColor: "#ededed", padding: 20}}>
                        <div className="small-12 cell">
                            <h4>Post with YouTube video</h4>
                        </div>
                        <div className="large-8 small-12 cell">
                            <Image src={desktop_1} width="100%" height={600} fit="contain" />
                        </div>
                        <div className="large-4 small-12 cell">
                            <Image src={mobile_4} width="100%" height={600} fit="contain" />
                        </div>
                    </div>
                    <div className="grid-x grid-padding-x" style={{marginBottom: 40, backgroundColor: "#ededed", padding: 20}}>
                        <div className="small-12 cell">
                            <h4>Post with URL Link in text</h4>
                        </div>
                        <div className="large-8 small-12 cell">
                            <Image src={desktop_2} width="100%" height={600} fit="contain" />
                        </div>
                        <div className="large-4 small-12 cell">
                            <Image src={mobile_3} width="100%" height={600} fit="contain" />
                        </div>
                    </div>
                    <div className="grid-x grid-padding-x" style={{marginBottom: 40, backgroundColor: "#ededed", padding: 20}}>
                        <div className="small-12 cell">
                            <h4>Post with emojis in text. Also, a pinned post - they are created by the UniWellBeing Team</h4>
                        </div>
                        <div className="large-8 small-12 cell">
                            <Image src={desktop_3} width="100%" height={600} fit="contain" />
                        </div>
                        <div className="large-4 small-12 cell">
                            <Image src={mobile_2} width="100%" height={600} fit="contain" />
                        </div>
                    </div>
                    <div className="grid-x grid-padding-x" style={{backgroundColor: "#ededed", padding: 20}}>
                        <div className="small-12 cell">
                            <h4>Post with a button that links directly to a podcast</h4>
                        </div>
                        <div className="large-8 small-12 cell">
                            <Image src={desktop_4} width="100%" height={600} fit="contain" />
                        </div>
                        <div className="large-4 small-12 cell">
                            <Image src={mobile_1} width="100%" height={600} fit="contain" />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}