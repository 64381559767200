import React from 'react'
import { ChatItemType } from 'constants/ChatItemType'
import UUID from "../../utils/UUID";
import isSvg from 'is-svg'
import ReactSelect from "../common/ReactSelect";
import {DayTime} from "../../constants/DayTime";
import Modal from "react-responsive-modal";
import {TranslationEditor, TranslationEditorHelper} from "../common/TranslationEditor";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import {ChatAnimationFormTranslations} from "./ChatAnimationFormTranslations";
import {CommonUtil} from "../../utils/CommonUtil";
import {ChatTextFieldFormTranslations} from "./ChatTextFieldFormTranslations";
import {TranslationUtil} from "../../utils/TranslationUtil";
import ReactTable from "react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import StandardModal from "../common/StandardModal";

export default class ChatAnimationForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: Object.assign({
                code: null,
                title: null,
                nodeText: null,
                altText: null,
                size: "small",
                typeText: "Animation",
                useCustomDisplayTime: null,
                displayTime: 0,
                useCustomTypingTime: null,
                typingTime: 0,
                typeID: ChatItemType.ANIMATED_SVG,
                uuid: UUID.uuidv4()
            }, props.node),
            preview: props.node ? (isSvg(props.node.code) ? props.node.code : null) : null,
            sizeOptions: [{value: "small", label: "Small"},{value: "medium", label: "Medium"}, {value: "large", label: "Large"}],
            validated: false,
            errors: {},
            touched: {},
            modal: {
                isOpen: false,
                content: null
            }
        };
    }

    componentDidMount() {
        if (this.state.item && this.state.item.translations) {
            this.processTranslations();
        }

        TranslationUtil.fetchChatLanguages((action, data) => {
            if (action === "success") {
                this.setState({
                    languageOptions : data
                });
            } else {
                console.log(data);
            }
        });

        this.validate();
    }


    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        let item = Object.assign({}, this.state.item);
        let preview = this.state.preview;

        if (name === "title") {
            item.nodeText = value;
        }

        if (name === "code") {
            if (value && value.length > 0 && isSvg(value)) {
                preview = value;
            }
            else {
                preview = null;
            }
        }

        if (name === "useCustomDisplayTime") {
            item.displayTime = 0;
        }

        if (name === "useCustomTypingTime") {
            item.typingTime = 0;
        }

        item[name] = value;

        this.setState({
            item: item,
            preview: preview
        }, () => {
            this.validate();
        });
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.onSubmitted instanceof Function) {
            this.props.onSubmitted(this.state.item)
        }
    }


    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.code) {
            if (touched.code) {
                errors.code = "SVG Code is required";
            }
            validated = false;
        }
        else {
            if (!isSvg(item.code)) {
                errors.code = "Not a valid SVG code. The code must start and end with <svg></svg> tags.";
                validated = false;
            }
        }

        if (!item.title) {
            if (touched.title) {
                errors.title = "Title is required";
            }
            validated = false;
        }


        this.setState({
            validated: validated,
            errors: errors
        });
    }

    processTranslations = () => {
        const translationRows = [];
        Object.keys(this.state.item.translations).forEach((code, index) => {
            let rowData = CommonUtil.cloneObject(
                this.state.item.translations[code]
            );
            rowData.id = index;
            rowData.code = code;
            translationRows.push(rowData);
        });
        this.setState({
            translations : translationRows
        });
    }

    showTranslationEditor = (languageCode) => {
        const content = (
            <ChatAnimationFormTranslations
                languageCode={languageCode}
                item={this.state.item}
                onSubmit={() => {
                    this.closeModal();
                    this.processTranslations();
                }}
                onCancel={this.closeModal}
            />
        )

        this.setState({
            modal : {
                isOpen: true,
                content,
                size : "modal-medium"
            }
        });
    }

    closeModal = () => {
        this.setState({
            modal : {
                isOpen: false,
                content: []
            }
        });
    }

    lookupLanguage = (languageCode) => {
        let out = languageCode;
        if (this.state.languageOptions) {
            for (let i = 0; i < this.state.languageOptions.length; i++) {
                if (this.state.languageOptions[i].value === languageCode) {
                    out = this.state.languageOptions[i].label;
                    break;
                }
            }
        }
        return out;
    }

    showDeleteTranslation = (languageCode) => {
        let languageName = this.lookupLanguage(languageCode);

        const content = (
            <StandardModal
                title="Delete Translation"
                content={(
                    <React.Fragment>
                        <p>Are you sure to delete the <strong>{languageName}</strong> translation? This action cannot be reverted.</p>
                    </React.Fragment>
                )}
                buttons={(
                    <React.Fragment>
                        <button className="button secondary" type="button" onClick={this.closeModal}>
                            Cancel
                        </button>
                        <button className="button alert" type="button" onClick={() => this.handleDeleteTranslation(languageCode)}>
                            Delete
                        </button>
                    </React.Fragment>
                )}
            />
        );

        this.setState({
            modal: Object.assign({}, this.state.modal, {
                isOpen: true,
                size: "modal-medium",
                isUploading: false,
                content: content
            })
        })
    }

    handleDeleteTranslation = (languageCode) => {
        if (this.state.item.translations) {
            if (this.state.item.translations.hasOwnProperty(languageCode)) {
                delete this.state.item.translations[languageCode];
            }

            this.setState({
                item : this.state.item
            }, () => {
                this.processTranslations();
                this.closeModal();
            });
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>SVG Animation</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">* Title</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.title && "error")}>
                                <input type="text" name="title" value={this.state.item.title} onBlur={this.handleBlur}
                                       onChange={this.handleChange}/>
                                <small className="error">{this.state.errors.title}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="altText" className="text-right middle">
                                    Alt text
                                </label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <textarea rows={5} name="altText" value={this.state.item.altText}
                                          onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <small className="field-info">Animation's description used by Voice Over reader in the
                                    app. If not set, title would be used instead.</small>
                                <small className="error">{this.state.errors["altText"]}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">
                                    * SVG Code
                                </label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.code && "error")}>
                                <textarea
                                    rows={5}
                                    name="code"
                                    value={this.state.item.code}
                                    onBlur={this.handleBlur}
                                    onChange={this.handleChange}
                                />

                                <small className="error">{this.state.errors.code}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="size" className="text-right middle">* Size</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <ReactSelect
                                    name="size"
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.item.size}
                                    options={this.state.sizeOptions}
                                />
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">Preview</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <div style={{maxWidth: 200}}
                                     dangerouslySetInnerHTML={{__html: this.state.preview}}></div>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="useCustomDisplayTime" className="text-right middle">Custom display
                                    time</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="useCustomDisplayTime"
                                           onChange={this.handleChange}
                                           checked={this.state.item.useCustomDisplayTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.useCustomDisplayTime === 1 &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="displayTime" className="text-right middle">* Display
                                            Time</label>
                                    </div>
                                    <div
                                        className={"large-9 small-9 cell " + (this.state.errors.displayTime && "error")}>
                                        <input name="displayTime" type="number" value={this.state.item.displayTime}
                                               onBlur={this.handleBlur} onChange={this.handleChange}/>
                                        <span className="error">{this.state.errors.displayTime}</span>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="useCustomTypingTime" className="text-right middle">Custom typing
                                    time</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="useCustomTypingTime"
                                           onChange={this.handleChange}
                                           checked={this.state.item.useCustomTypingTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.useCustomTypingTime === 1 &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="typing" className="text-right middle">* Typing Time</label>
                                    </div>
                                    <div
                                        className={"large-9 small-9 cell " + (this.state.errors.typingTime && "error")}>
                                        <input name="typingTime" type="number" value={this.state.item.typingTime}
                                               onBlur={this.handleBlur} onChange={this.handleChange}/>
                                        <span className="error">{this.state.errors.typingTime}</span>
                                    </div>
                                </div>
                            </React.Fragment>
                        }

                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset">
                                    <legend>Translations</legend>
                                    <div className="grid-x grid-padding-x">
                                        <div className="cell small-12">
                                            <span>Add translation for</span>
                                            <ReactSelect
                                                name="selectedLanguage"
                                                className="react-select translation-selector"
                                                onChange={(e) => this.setState({selectedLanguage: e.target.value})}
                                                value={this.state.selectedLanguage}
                                                options={this.state.languageOptions}
                                                onBlur={this.handleBlur}
                                            />

                                            <Button
                                                type="button"
                                                className="translation-button"
                                                color={Colors.PRIMARY}
                                                isDisabled={!this.state.selectedLanguage}
                                                onClick={() => this.showTranslationEditor(this.state.selectedLanguage)}>
                                                Add
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="grid-x grid-padding-x">
                                        <div className="cell small-12">
                                            <ReactTable
                                                columns={[
                                                    {
                                                        Header: 'Language',
                                                        accessor: 'code'
                                                    },
                                                    {
                                                        Header: 'Actions',
                                                        Cell: row => (
                                                            <ButtonGroup size={Sizes.TINY}>
                                                                {true && // this.context.permissions.chatFlows.edit
                                                                    <Button
                                                                        type="button"
                                                                        color={Colors.PRIMARY}
                                                                        onClick={() => this.showTranslationEditor(row.original.code)}>
                                                                        <FontAwesomeIcon icon="edit"/>
                                                                    </Button>
                                                                }
                                                                {true && // this.context.permissions.chatFlows.delete
                                                                    <Button
                                                                        type="button"
                                                                        color={Colors.ALERT}
                                                                        onClick={() => this.showDeleteTranslation(row.original.code)}>
                                                                        <FontAwesomeIcon icon="trash"/>
                                                                    </Button>
                                                                }
                                                            </ButtonGroup>
                                                        ),
                                                        maxWidth: 100,
                                                        show: true //this.context.permissions.chatFlows.delete || this.context.permissions.chatFlows.edit
                                                    }
                                                ]}
                                                data={this.state.translations}
                                                className='-striped'
                                                showPagination={false}
                                                noDataText={"No translations"}
                                                pageSize={this.state.translations ? this.state.translations.length : 1}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}
