import React from 'react'
import { ChatItemType } from 'constants/ChatItemType'
import UUID from "../../utils/UUID";
import ChatVariablesSelector from "./ChatVariablesSelector";
import Modal from "react-responsive-modal";
import ChatCustomVariablesSelector from "./ChatCustomVariablesSelector";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactSelect from "../common/ReactSelect";
import {AIIntegration} from "../../utils/AIIntegration";
import {CommonUtil} from "../../utils/CommonUtil";
import {KnowledgeDocumentSelector} from "../knowledgeDocument/KnowledgeDocumentSelector";
import {PermissionUtil} from "../../utils/PermissionUtil";
import {ConversationSelector} from "./conversations/ConversationSelector";
import {PromptTemplateSelector} from "./promptTemplate/PromptTemplateSelector";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import ReactTable from "react-table";
import {TranslationUtil} from "../../utils/TranslationUtil";
import {ChatTextFieldFormTranslations} from "./ChatTextFieldFormTranslations";
import StandardModal from "../common/StandardModal";
import Axios from "axios";
import {API} from "../../api/APIClient";

export default class ChatTextFieldForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: Object.assign({
                texts: [{id: UUID.uuidv4(), text: props.node.text || ""}], //legacy
                validation: {
                    type: null,
                    error: null,
                    data: null
                },
                nodeText: null,
                responseProperty: null,
                responseValue: null,
                saveResponse: 0,
                typeText: "Message with text field prompt",
                typeID: ChatItemType.TEXTFIELD,
                useCustomTypingTime: null,
                typingTime: 0,
                uuid: UUID.uuidv4(),
                isGenAI: 0,
                aiPrompt: "",
                aiSystemPrompt: "",
                aiTemperature: 1.0,
                aiModel: "gpt-3.5-turbo",
                aiResponsePairs: [],
                aiIntegrationType : AIIntegration.TYPES.CHATGPT,
                aiReturnResponse : 0,
                aiReturnResponseKey : undefined,
                aiUseOrganisationKeys : 1, // Should be checked by default
                aiSuppressResponse: 0,
                aiSuppressResponseConditionKey: null,
                aiSuppressResponseConditionValue: null,
                aiSuppressResponseReplacementText: '',
                aiSuppressResponseConditionOperator: "equal",
                aiSaveConversation: 0,
                aiSaveConversationConditionally: 0,
                aiSaveConversationConditionKey: '',
                aiSaveConversationConditionValue: '',
                aiStreamResponse : 0,
                responseIsHTML : 0
            }, props.node),
            aiIntegrationTypes : [],
            aiIntegrationTypesOptions : [],
            aiIntegrationTypeDefaultModel : null,
            aiIntegrationTypeModelsURL : null,
            validated: false,
            validationTypes: [
                {"value": null, "label": "None"},
                {"value": "valid_time", "label" : "Valid time"},
                {"value": "in_list", "label": "Response in list"}
            ],
            errors: {},
            touched: {},
            modal: {
                isOpen: false,
                content: null
            }
        };
    }

    componentDidMount() {
        if (this.state.item && this.state.item.translations) {
            this.processTranslations();
        }

        TranslationUtil.fetchChatLanguages((action, data) => {
            if (action === "success") {
                this.setState({
                    languageOptions : data
                });
            } else {
                console.log(data);
            }
        });

        this.fetchAIIntegrationsFromNetwork();

        this.validate();
    }


    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        const newState = {};

        let item = Object.assign({}, this.state.item);

        if (name.startsWith("text_")) {
            const index = parseInt(name.replace("text_", ""));
            item.texts[index].text = value;
            item.nodeText = item.texts[0].text;
        }
        else if (name.startsWith("responseGPTItemKey_")) {
            const index = parseInt(name.replace("responseGPTItemKey_", ""));
            item.aiResponsePairs[index].key = value;
        }
        else if (name.startsWith("validation_")) {
            const paramName = name.replace("validation_", "");
            item.validation = Object.assign({}, item.validation);
            item.validation[paramName] = value;
        } else if (name === "saveResponse") {
            item.responseProperty = null;
            item.aiResponsePairs = [];
        } else if (name === "useCustomTypingTime") {
            item.typingTime = 0;
        } else if (name === "aiIntegrationType") {
            // When Integration Type changes, change the Default Model
            let aiIntegrationType = parseInt(value);
            for (let i = 0; i < this.state.aiIntegrationTypes.length; i++) {
                if (parseInt(this.state.aiIntegrationTypes[i].id) === aiIntegrationType) {
                    let intType = this.state.aiIntegrationTypes[i];
                    item.aiModel = intType.defaultModel;
                    newState.aiIntegrationTypeModelsURL = intType.modelsURL;
                    break;
                }
            }
        }

        item[name] = value;

        newState.item = item;

        this.setState(newState, () => {
            this.validate();
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.onSubmitted instanceof Function) {
            this.props.onSubmitted(this.state.item)
        }
    }

    // a little function to help us with reordering the result
    reorderItems = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    addAlternateText = () => {
        let item = Object.assign({}, this.state.item);
        item.texts.push({id: UUID.uuidv4(), text: ""});
        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    removeTextVariation = (index) => {
        let item = Object.assign({}, this.state.item);
        item.texts.splice(index, 1);
        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const texts = this.reorderItems(
            this.state.item.texts,
            result.source.index,
            result.destination.index
        );

        let item = Object.assign({}, this.state.item);
        item.texts = texts;

        this.setState({
            item: item
        }, () => {
            this.validate();
        })
    }


    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (item.isGenAI === 1) {
            if (item.aiPrompt.trim().length === 0) {
                validated = false;
            }

            if (CommonUtil.getOrDefault(item, "aiReturnResponse", 0) === 1) {
                // If AI Return Response is checked, make sure Response Key is set to a value
                let aiReturnResponseKey = CommonUtil.getOrDefault(item, "aiReturnResponseKey", null);
                if (aiReturnResponseKey === null || aiReturnResponseKey === "") {
                    validated = false;
                }
            }
        }

        if (item.saveResponse) {
            if (item.isGenAI === 1) {
                if (item.aiResponsePairs.length === 0) {
                    validated = false;
                }
            }
            else {
                if (!item.responseProperty) {
                    if (touched.responseProperty) {
                        errors.text = "Property is required is save response selected"
                    }
                    validated = false;
                }
            }
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    showVariablesSelector = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatVariablesSelector
                showCopyButton={true}
                showSelectButton={false}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={() => this.closeModal()}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    showCustomVariablesSelectorForAIItem = (index) => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatCustomVariablesSelector
                showCopyButton={false}
                showSelectButton={true}
                onSelected={(variable) => {
                    let item = Object.assign({}, this.state.item);
                    item.aiResponsePairs[index].property = variable;
                    item.aiResponsePairs[index].propertyName = variable.key;
                    this.setState({
                        item: item
                    }, () => {
                        this.closeModal();
                        this.validate();
                    })
                }}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    showCustomVariablesSelector = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatCustomVariablesSelector
                showCopyButton={false}
                showSelectButton={true}
                onSelected={(variable) => {
                    let item = Object.assign({}, this.state.item);
                    item.responseProperty = variable;
                    this.setState({
                        item: item
                    }, () => {
                        this.closeModal();
                        this.validate();
                    })
                }}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    showCustomVariablesSelectorForText = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatCustomVariablesSelector
                showCopyButton={true}
                showSelectButton={false}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={() => this.closeModal()}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    showKnowledgeDocuments = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <KnowledgeDocumentSelector
                showCopyButton={true}
                showSelectButton={PermissionUtil.edit(PermissionUtil.PermissionCategories.knowledgeDocuments)}
                showDeleteButton={PermissionUtil.delete(PermissionUtil.PermissionCategories.knowledgeDocuments)}
                onSelected={() => this.closeModal()}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {
            this.validate();
        })
    }

    showConversations = (pickerMode, keyName, selectedCallback) => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ConversationSelector
                showCopyButton={!pickerMode}
                showSelectButton={pickerMode}
                showEditButton={PermissionUtil.edit(PermissionUtil.PermissionCategories.conversationTypes)}
                showDeleteButton={PermissionUtil.delete(PermissionUtil.PermissionCategories.conversationTypes)}
                onSelected={(item) => { if (selectedCallback) selectedCallback(item, keyName); this.closeModal(); }}
                onCancel={() => this.closeModal()}
             />
        );

        this.setState({
            modal : {
                isOpen : true,
                content,
                size : "modal-medium"
            }
        });
    }

    conversationWasSelected = (conversation, keyName) => {
        const item = CommonUtil.cloneObject(this.state.item);
        item[keyName] = conversation;

        this.setState({
            item
        });
    }

    conversationWasRemoved = (keyName) => {
        const item = CommonUtil.cloneObject(this.state.item);
        delete item[keyName];

        this.setState({
            item
        });
    }

    getFormattedConversation = (conversation) => {
        if (conversation) {
            if (typeof conversation === "string") {
                conversation = JSON.parse(conversation);
            }

            if (CommonUtil.isObject(conversation)) {
                let out = "";
                if (conversation.name) {
                    out = conversation.name;
                }

                if (conversation.organisationName) {
                    if (out !== "") {
                        out += " ";
                    }

                    out += "(" + conversation.organisationName + ")";
                }

                return out;
            }
        }
        return "";
    }

    showPromptTemplates = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <PromptTemplateSelector
                showCopyButton={true}
                showSelectButton={PermissionUtil.edit(PermissionUtil.PermissionCategories.promptTemplates)}
                showDeleteButton={PermissionUtil.delete(PermissionUtil.PermissionCategories.promptTemplates)}
                onSelected={() => this.closeModal()}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal : {
                isOpen: true,
                content,
                size : "modal-medium"
            }
        });
    }

    addResponseGPTPair = () => {
        let item = Object.assign({}, this.state.item);
        item.aiResponsePairs.push({id: UUID.uuidv4(), key: "", propertyName: "", property: null});
        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    removeResponseGPTPair = (index) => {
        let item = Object.assign({}, this.state.item);
        item.aiResponsePairs.splice(index, 1);
        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    processTranslations = () => {
        const translationRows = [];
        Object.keys(this.state.item.translations).forEach((code, index) => {
            let rowData = CommonUtil.cloneObject(
                this.state.item.translations[code]
            );
            rowData.id = index;
            rowData.code = code;
            translationRows.push(rowData);
        });
        this.setState({
            translations : translationRows
        });
    }

    showTranslationEditor = (languageCode) => {
        const content = (
            <ChatTextFieldFormTranslations
                languageCode={languageCode}
                item={this.state.item}
                onSubmit={() => {
                    this.closeModal();
                    this.processTranslations();
                }}
                onCancel={this.closeModal}
            />
        )

        this.setState({
            modal : {
                isOpen: true,
                content,
                size : "modal-medium"
            }
        });
    }

    lookupLanguage = (languageCode) => {
        let out = languageCode;
        if (this.state.languageOptions) {
            for (let i = 0; i < this.state.languageOptions.length; i++) {
                if (this.state.languageOptions[i].value === languageCode) {
                    out = this.state.languageOptions[i].label;
                    break;
                }
            }
        }
        return out;
    }

    showDeleteTranslation = (languageCode) => {
        let languageName = this.lookupLanguage(languageCode);

        const content = (
            <StandardModal
                title="Delete Translation"
                content={(
                    <React.Fragment>
                        <p>Are you sure to delete the <strong>{languageName}</strong> translation? This action cannot be reverted.</p>
                    </React.Fragment>
                )}
                buttons={(
                    <React.Fragment>
                        <button className="button secondary" type="button" onClick={this.closeModal}>
                            Cancel
                        </button>
                        <button className="button alert" type="button" onClick={() => this.handleDeleteTranslation(languageCode)}>
                            Delete
                        </button>
                    </React.Fragment>
                )}
            />
        );

        this.setState({
            modal: Object.assign({}, this.state.modal, {
                isOpen: true,
                size: "modal-medium",
                isUploading: false,
                content: content
            })
        })
    }

    handleDeleteTranslation = (languageCode) => {
        if (this.state.item.translations) {
            if (this.state.item.translations.hasOwnProperty(languageCode)) {
                delete this.state.item.translations[languageCode];
            }

            this.setState({
                item : this.state.item
            }, () => {
                this.processTranslations();
                this.closeModal();
            });
        }
    }

    fetchAIIntegrationsFromNetwork = () => {
        if (this.state.integrationsLoading) return;
        this.setState({
            integrationsLoading: true
        });

        Axios.get(API.chat.getGenAIIntegrationTypes)
            .then((r) => {
                let aiIntegrationTypes = [];
                let aiIntegrationTypesOptions = [];

                const item = CommonUtil.cloneObject(this.state.item);
                let aiIntegrationTypeModelsURL = this.state.aiIntegrationTypeModelsURL;

                const resp = API.parse(r);
                if (resp.success) {
                    aiIntegrationTypes = resp.data.integrations;

                    if (item.aiIntegrationType && (!item.aiModel || item.aiModel === "")) {
                        // If an Integration Type is selected, make sure we use updated model and url data
                        for (let i = 0; i < aiIntegrationTypes.length; i++) {
                            if (parseInt(aiIntegrationTypes[i].id) === parseInt(item.aiIntegrationType)) {
                                item.aiModel = aiIntegrationTypes[i].defaultModel;
                                aiIntegrationTypeModelsURL = aiIntegrationTypes[i].modelsURL;
                                break;
                            }
                        }
                    }

                    aiIntegrationTypesOptions = aiIntegrationTypes.map((type) => {
                        return {
                            label : type.name,
                            value : type.id
                        }
                    })
                } else {
                    console.log(API.formatError(resp));
                }

                this.setState({
                    integrationsLoading: false,
                    aiIntegrationTypes,
                    aiIntegrationTypesOptions,
                    item,
                    aiIntegrationTypeModelsURL
                });
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    integrationsLoading: false
                });
            })
    }

    render() {

        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Chat message with text field prompt</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {this.state.item.texts.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            userSelect: "none",
                                                            background: snapshot.isDragging ? "lightgreen" : "white",
                                                            ...provided.draggableProps.style
                                                        }}
                                                    >
                                                        <div className="grid-x grid-padding-x" key={index}>
                                                            <div className="small-3 cell">

                                                                <label htmlFor={"text_" + index}
                                                                       className="text-right middle">
                                                                    {index !== 0 &&
                                                                        <button style={{height: 5}}
                                                                                className="button clear alert"
                                                                                type="button"
                                                                                onClick={() => this.removeTextVariation(index)}>
                                                                            <FontAwesomeIcon icon="times-circle"/>
                                                                        </button>
                                                                    }
                                                                    {index == 0 ? "* Text" : "* Alternate text"}
                                                                </label>
                                                            </div>
                                                            <div
                                                                className={"large-9 small-9 cell " + (this.state.errors["text_" + index] && "error")}>
                                                                <textarea rows={5} name={"text_" + index}
                                                                          value={item.text} onBlur={this.handleBlur}
                                                                          onChange={this.handleChange}/>
                                                                <small
                                                                    className="error">{this.state.errors["text_" + index]}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">

                            </div>
                            <div className="large-9 small-9 cell ">
                                <button className="button primary" type="button" onClick={this.addAlternateText}>Add
                                    alternate text
                                </button>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="isGenAI" className="text-right middle">Use GenAI</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="isGenAI" onChange={this.handleChange}
                                           checked={this.state.item.isGenAI}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.isGenAI === 1 &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="aiIntegrationType" className="text-right middle">* AI
                                            Integration Type</label>
                                    </div>
                                    <div className="large-9 small-9 cell ">
                                        <ReactSelect
                                            name="aiIntegrationType"
                                            className="react-select"
                                            onChange={this.handleChange}
                                            value={this.state.item.aiIntegrationType}
                                            options={this.state.aiIntegrationTypesOptions}
                                            onBlur={this.handleBlur}
                                        />
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="aiReturnResponse" className="text-right middle">
                                            Use Organisation's Access Keys
                                        </label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <label className="chkbx-container">
                                            <input
                                                className="chkbx"
                                                type="checkbox"
                                                name="aiUseOrganisationKeys"
                                                onChange={this.handleChange}
                                                checked={this.state.item.aiUseOrganisationKeys === 1}
                                            />
                                            <span className="chkmk"></span>
                                        </label>
                                    </div>
                                </div>

                                {CommonUtil.getOrDefault(this.state.item, "aiIntegrationType", AIIntegration.TYPES.CHATGPT) !== AIIntegration.TYPES.AWS_BEDROCK &&
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label
                                                htmlFor="aiSystemPrompt"
                                                className="text-right middle">
                                                * AI System Prompt
                                            </label>
                                        </div>
                                        <div className="large-9 small-9 cell ">
                                            <textarea
                                                rows="9"
                                                name="aiSystemPrompt"
                                                onChange={this.handleChange}
                                                value={this.state.item.aiSystemPrompt}
                                                onBlur={this.handleBlur}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="aiPrompt" className="text-right middle">* AI User Prompt</label>
                                    </div>
                                    <div className="large-9 small-9 cell ">
                                        <textarea rows="9" name="aiPrompt" onChange={this.handleChange}
                                                  value={this.state.item.aiPrompt} onBlur={this.handleBlur}/>
                                        <small className="field-info">You can inject user's response
                                            with <strong>&#123;&#123;ST_userInput&#125;&#125;</strong> tag.</small>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="aiModel" className="text-right middle">
                                            * AI Model
                                        </label>
                                    </div>
                                    <div className="large-9 small-9 cell ">
                                        <input
                                            type="text"
                                            name="aiModel"
                                            onChange={this.handleChange}
                                            value={this.state.item.aiModel}
                                            onBlur={this.handleBlur}
                                        />

                                        <small className="field-info">
                                            You can find the list of all available models&nbsp;
                                            <a href={this.state.aiIntegrationTypeModelsURL ? this.state.aiIntegrationTypeModelsURL : "https://platform.openai.com/docs/models"} target={"_blank"} rel={"noopener noreferrer"}>
                                                <span style={{color: "#1779ba"}}>here</span>
                                            </a>
                                        </small>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="aiTemperature" className="text-right middle">* AI
                                            Temperature</label>
                                    </div>
                                    <div className="large-9 small-9 cell ">
                                        <input type="number" min="0" max="2" step="0.1" name="aiTemperature"
                                               onChange={this.handleChange} value={this.state.item.aiTemperature}
                                               onBlur={this.handleBlur}/>
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="aiMaxTokens" className="text-right middle">Max Tokens</label>
                                    </div>
                                    <div className="large-9 small-9 cell ">
                                        <input
                                            type="number"
                                            name="aiMaxTokens"
                                            onChange={this.handleChange}
                                            value={this.state.item.aiMaxTokens}
                                            onBlur={this.handleBlur}
                                        />
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="aiReturnResponse" className="text-right middle">
                                            Return Response
                                        </label>
                                    </div>
                                    <div className="large-4 small-9 cell">
                                        <label className="chkbx-container">
                                            <input
                                                className="chkbx"
                                                type="checkbox"
                                                name="aiReturnResponse"
                                                onChange={this.handleChange}
                                                checked={this.state.item.aiReturnResponse}
                                            />
                                            <span className="chkmk"></span>
                                        </label>
                                    </div>
                                </div>

                                {CommonUtil.getOrDefault(this.state.item, "aiReturnResponse", 0) === 1 &&
                                    <React.Fragment>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="aiReturnResponseKey" className="text-right middle">
                                                    * Response Key Name
                                                </label>
                                            </div>
                                            <div className="large-9 small-9 cell ">
                                                <input
                                                    type="text"
                                                    name="aiReturnResponseKey"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.aiReturnResponseKey}
                                                    onBlur={this.handleBlur}
                                                />
                                            </div>
                                        </div>

                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="responseIsHTML" className="text-right middle">
                                                    Response is HTML
                                                </label>
                                            </div>
                                            <div className="large-9 small-9 cell ">
                                                <label className="chkbx-container">
                                                    <input
                                                        className="chkbx"
                                                        type="checkbox"
                                                        name="responseIsHTML"
                                                        onChange={this.handleChange}
                                                        checked={this.state.item.responseIsHTML}
                                                    />
                                                    <span className="chkmk"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="aiStreamResponse" className="text-right middle">
                                                    Stream Response
                                                </label>
                                            </div>
                                            <div className="large-9 small-9 cell ">
                                                <label className="chkbx-container">
                                                    <input
                                                        className="chkbx"
                                                        type="checkbox"
                                                        name="aiStreamResponse"
                                                        onChange={this.handleChange}
                                                        checked={this.state.item.aiStreamResponse}
                                                    />
                                                    <span className="chkmk"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="aiSuppressResponse" className="text-right middle">
                                                    Replace Response Conditionally
                                                </label>
                                            </div>
                                            <div className="large-9 small-9 cell ">
                                                <label className="chkbx-container">
                                                    <input
                                                        className="chkbx"
                                                        type="checkbox"
                                                        name="aiSuppressResponse"
                                                        onChange={this.handleChange}
                                                        checked={this.state.item.aiSuppressResponse}
                                                    />
                                                    <span className="chkmk"></span>
                                                </label>
                                            </div>
                                        </div>

                                        {this.state.item.aiSuppressResponse === 1 &&
                                            <React.Fragment>
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="aiSuppressResponseConditionKey" className="text-right middle">
                                                            Replace response condition
                                                        </label>
                                                    </div>
                                                    <div className="large-9 small-9 cell">
                                                        <div className="input-group">
                                                            <input className="input-group-field" style={{width: "50%"}}
                                                                   type="text" placeholder="Key name"
                                                                   name="aiSuppressResponseConditionKey"
                                                                   value={this.state.item.aiSuppressResponseConditionKey}
                                                                   onBlur={this.handleBlur} onChange={this.handleChange}/>
                                                            &nbsp;
                                                            <ReactSelect
                                                                name="aiSuppressResponseConditionOperator"
                                                                className="react-select input-group-field"
                                                                style={{width: "40"}}
                                                                onChange={this.handleChange}
                                                                value={this.state.item.aiSuppressResponseConditionOperator}
                                                                options={[
                                                                    {
                                                                        value: "equal",
                                                                        label: "equal"
                                                                    },
                                                                    {
                                                                        value: "not_equal",
                                                                        label: "not equal"
                                                                    }
                                                                ]}
                                                                onBlur={this.handleBlur}
                                                            />
                                                            &nbsp;
                                                            <input className="input-group-field" style={{width: "50%"}}
                                                                   type="text" placeholder="Equals to value"
                                                                   name="aiSuppressResponseConditionValue"
                                                                   value={this.state.item.aiSuppressResponseConditionValue}
                                                                   onBlur={this.handleBlur} onChange={this.handleChange}/>
                                                        </div>
                                                        <small className="field-info">Please note that the parameter specified in this condition must be included by the AI in the JSON structure before the response parameter.</small>
                                                    </div>
                                                </div>
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label
                                                            htmlFor="aiSuppressResponseReplacementText"
                                                            className="text-right middle">
                                                            Response Replacement Text
                                                        </label>
                                                    </div>
                                                    <div className="large-9 small-9 cell ">
                                                <textarea
                                                    rows="3"
                                                    name="aiSuppressResponseReplacementText"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.aiSuppressResponseReplacementText}
                                                    onBlur={this.handleBlur}
                                                />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }

                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="aiReturnResponse" className="text-right middle">
                                                    Save Conversation
                                                </label>
                                            </div>
                                            <div className="large-4 small-9 cell">
                                                <label className="chkbx-container">
                                                    <input
                                                        className="chkbx"
                                                        type="checkbox"
                                                        name="aiSaveConversation"
                                                        onChange={this.handleChange}
                                                        checked={this.state.item.aiSaveConversation}
                                                    />
                                                    <span className="chkmk"></span>
                                                </label>
                                                {this.state.item.aiSuppressResponse === 1 &&
                                                    <small className="field-info">Please note that conversation will not be saved if AI response was conditionally replaced.</small>
                                                }
                                            </div>
                                        </div>

                                        { (this.state.item.aiSaveConversation === 1) &&
                                            <React.Fragment>
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="aiSaveConversationConditionally" className="text-right middle">
                                                            Save Conversation conditionally
                                                        </label>
                                                    </div>
                                                    <div className="large-4 small-9 cell">
                                                        <label className="chkbx-container">
                                                            <input
                                                                className="chkbx"
                                                                type="checkbox"
                                                                name="aiSaveConversationConditionally"
                                                                onChange={this.handleChange}
                                                                checked={this.state.item.aiSaveConversationConditionally}
                                                            />
                                                            <span className="chkmk"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {this.state.item.aiSaveConversationConditionally === 1 &&
                                                    <div className="grid-x grid-padding-x">
                                                        <div className="small-3 cell">
                                                            <label htmlFor="aiSaveConversationConditionKey" className="text-right middle">
                                                                Save Conversation condition
                                                            </label>
                                                        </div>
                                                        <div className="large-9 small-9 cell">
                                                            <div className="input-group">
                                                                <input className="input-group-field" style={{width: "50%"}}
                                                                type="text" placeholder="Key name"
                                                                name="aiSaveConversationConditionKey"
                                                                value={this.state.item.aiSaveConversationConditionKey}
                                                                onBlur={this.handleBlur} onChange={this.handleChange}/>
                                                                &nbsp;<strong><span style={{lineHeight: "40px"}}>=</span></strong>&nbsp;
                                                                <input className="input-group-field" style={{width: "50%"}}
                                                                       type="text" placeholder="Equals to value"
                                                                       name="aiSaveConversationConditionValue"
                                                                       value={this.state.item.aiSaveConversationConditionValue}
                                                                       onBlur={this.handleBlur} onChange={this.handleChange}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="grid-x grid-padding-x">
                                                    <div className="small-3 cell">
                                                        <label htmlFor="aiReturnResponse" className="text-right middle">
                                                            Save Conversation As
                                                        </label>
                                                    </div>
                                                    <div className="small-9 cell">
                                                        <div className="input-group">
                                                            <input
                                                                className="input-group-field"
                                                                type="text"
                                                                value={this.state.item.aiConversation ? this.getFormattedConversation(this.state.item.aiConversation) : ""}
                                                                disabled
                                                            />

                                                            <div className="input-group-button">
                                                                {this.state.item.aiConversation &&
                                                                    <button
                                                                        type="button" className="button alert"
                                                                        onClick={() => this.conversationWasRemoved("aiConversation")}
                                                                    >
                                                                        <FontAwesomeIcon icon="times-circle"/>
                                                                    </button>
                                                                }

                                                                <button
                                                                    type="button"
                                                                    className="button"
                                                                    onClick={() => this.showConversations(true, "aiConversation", this.conversationWasSelected)}
                                                                >
                                                                    Pick Conversation
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                        {(!this.state.item.isGenAI || this.state.item.isGenAI === 0) &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="validation_type"
                                               className="text-right middle">Validation</label>
                                    </div>
                                    <div
                                        className={"large-9 small-9 cell " + (this.state.errors.validation_type && "error")}>
                                        <ReactSelect
                                            name={"validation_type"}
                                            className="react-select"
                                            onChange={this.handleChange}
                                            value={this.state.item.validation.type}
                                            options={this.state.validationTypes}
                                            onBlur={this.handleBlur}
                                        />
                                    </div>
                                </div>
                                {this.state.item.validation.type === "in_list" &&
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="validation_data" className="text-right middle">* Allowed
                                                responses</label>
                                        </div>
                                        <div
                                            className={"large-9 small-9 cell " + (this.state.errors.validation_data && "error")}>
                                            <input name="validation_data" type="text"
                                                   value={this.state.item.validation.data} onBlur={this.handleBlur}
                                                   onChange={this.handleChange}/>
                                            <span className="error">{this.state.errors.validation_data}</span>
                                            <small className="field-info">Please enter valid responses separated by
                                                comma. Ideally each response is a single word however multiple words are
                                                allowed too. Validation is case insensitive.</small>
                                        </div>
                                    </div>
                                }
                                {this.state.item.validation.type &&
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="validation_error" className="text-right middle">* Validation
                                                error</label>
                                        </div>
                                        <div
                                            className={"large-9 small-9 cell " + (this.state.errors.validation_error && "error")}>
                                            <input name="validation_error" type="text"
                                                   value={this.state.item.validation.error} onBlur={this.handleBlur}
                                                   onChange={this.handleChange}/>
                                            <span className="error">{this.state.errors.validation_error}</span>
                                            <small className="field-info">Validation error will be shown to user if the
                                                validation fails.</small>
                                        </div>
                                    </div>
                                }
                            </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">System properties</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                <button className="button primary" type="button"
                                        onClick={this.showVariablesSelector}>Show properties
                                </button>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">Custom properties</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                <button className="button primary" type="button"
                                        onClick={this.showCustomVariablesSelectorForText}>Show properties
                                </button>
                            </div>
                        </div>

                        { this.state.item.isGenAI === 1 &&
                            <>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="text" className="text-right middle">
                                            Knowledge Documents
                                        </label>
                                    </div>
                                    <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                        <button
                                            className="button primary"
                                            type="button"
                                            onClick={this.showKnowledgeDocuments}
                                        >
                                            Show documents
                                        </button>
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="text" className="text-right middle">
                                            Conversations
                                        </label>
                                    </div>
                                    <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                        <button
                                            className="button primary"
                                            type="button"
                                            onClick={() => this.showConversations(false)}
                                        >
                                            Show conversations
                                        </button>
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="text" className="text-right middle">
                                            Prompt Templates
                                        </label>
                                    </div>
                                    <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                        <button
                                            className="button primary"
                                            type="button"
                                            onClick={this.showPromptTemplates}
                                        >
                                            Show templates
                                        </button>
                                    </div>
                                </div>
                            </>
                        }

                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="saveResponse" className="text-right middle">Save response</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="saveResponse"
                                           onChange={this.handleChange}
                                           checked={this.state.item.saveResponse}/>
                                    <span className="chkmk"></span>
                                </label>
                                {this.state.item.saveResponse === 1 && this.state.item.isGenAI === 1 &&
                                    <button type="button" className="button" onClick={this.addResponseGPTPair}>Add new
                                        pairs</button>
                                }
                            </div>
                        </div>
                        {this.state.item.saveResponse === 1 && this.state.item.isGenAI === 1 &&
                            <React.Fragment>
                                {this.state.item.aiResponsePairs.map((item, index) => (
                                    <div className="grid-x grid-padding-x" key={index}>
                                        <div className="small-3 cell">

                                            <label htmlFor={"saveItem_" + index} className="text-right middle">
                                            <button style={{height: 5, marginTop: "-10px"}}
                                                        className="button clear alert" type="button"
                                                        onClick={() => this.removeResponseGPTPair(index)}>
                                                    <FontAwesomeIcon icon="times-circle"/>
                                                </button>
                                            </label>
                                        </div>
                                        <div className="large-9 small-9 cell">
                                            <div>
                                                <div className="input-group">
                                                    <input className="input-group-field" style={{width: 140}}
                                                           type="text" placeholder="Chat GPT key"
                                                           name={"responseGPTItemKey_" + index} value={item.key}
                                                           onBlur={this.handleBlur} onChange={this.handleChange}/>
                                                    <input className="input-group-field" type="text"
                                                           value={item.propertyName} placeholder="Property not set"
                                                           disabled/>
                                                    <div className="input-group-button">
                                                        <button type="button" style={{marginLeft: 2}} className="button"
                                                                onClick={() => this.showCustomVariablesSelectorForAIItem(index)}>Select
                                                            Custom Property
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                }
                            </React.Fragment>
                        }
                        {this.state.item.saveResponse === 1 && this.state.item.isGenAI === 0 &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="condition" className="text-right middle">* Save response
                                            as</label>
                                    </div>
                                    <div className="small-9 cell ">
                                        <div>
                                            <div className="input-group">
                                                <input className="input-group-field" type="text"
                                                       value={this.state.item.responseProperty ? this.state.item.responseProperty.key : "Not selected"}
                                                       disabled/>
                                                <div className="input-group-button">
                                                    <button type="button" style={{marginLeft: 2}} className="button"
                                                            onClick={this.showCustomVariablesSelector}>Select Custom
                                                        Property
                                                    </button>
                                                </div>
                                            </div>
                                            <small className="error">{this.state.errors.responseProperty}</small>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="useCustomTypingTime" className="text-right middle">Custom typing
                                    time</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="useCustomTypingTime"
                                           onChange={this.handleChange}
                                           checked={this.state.item.useCustomTypingTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.useCustomTypingTime === 1 &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="typing" className="text-right middle">* Typing Time</label>
                                    </div>
                                    <div
                                        className={"large-9 small-9 cell " + (this.state.errors.typingTime && "error")}>
                                        <input name="typingTime" type="number" value={this.state.item.typingTime}
                                               onBlur={this.handleBlur} onChange={this.handleChange}/>
                                        <span className="error">{this.state.errors.typingTime}</span>
                                    </div>
                                </div>
                            </React.Fragment>
                        }

                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset">
                                    <legend>Translations</legend>
                                    <div className="grid-x grid-padding-x">
                                        <div className="cell small-12">
                                            <span>Add translation for</span>
                                            <ReactSelect
                                                name="selectedLanguage"
                                                className="react-select translation-selector"
                                                onChange={(e) => this.setState({selectedLanguage: e.target.value})}
                                                value={this.state.selectedLanguage}
                                                options={this.state.languageOptions}
                                                onBlur={this.handleBlur}
                                            />

                                            <Button
                                                type="button"
                                                className="translation-button"
                                                color={Colors.PRIMARY}
                                                isDisabled={!this.state.selectedLanguage}
                                                onClick={() => this.showTranslationEditor(this.state.selectedLanguage)}>
                                                Add
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="grid-x grid-padding-x">
                                        <div className="cell small-12">
                                            <ReactTable
                                                columns={[
                                                    {
                                                        Header: 'Language',
                                                        accessor: 'code'
                                                    },
                                                    {
                                                        Header: 'Actions',
                                                        Cell: row => (
                                                            <ButtonGroup size={Sizes.TINY}>
                                                                {true && // this.context.permissions.chatFlows.edit
                                                                    <Button
                                                                        type="button"
                                                                        color={Colors.PRIMARY}
                                                                        onClick={() => this.showTranslationEditor(row.original.code)}>
                                                                        <FontAwesomeIcon icon="edit"/>
                                                                    </Button>
                                                                }
                                                                {true && // this.context.permissions.chatFlows.delete
                                                                    <Button
                                                                        type="button"
                                                                        color={Colors.ALERT}
                                                                        onClick={() => this.showDeleteTranslation(row.original.code)}>
                                                                        <FontAwesomeIcon icon="trash"/>
                                                                    </Button>
                                                                }
                                                            </ButtonGroup>
                                                        ),
                                                        maxWidth: 100,
                                                        show: true //this.context.permissions.chatFlows.delete || this.context.permissions.chatFlows.edit
                                                    }
                                                ]}
                                                data={this.state.translations}
                                                className='-striped'
                                                showPagination={false}
                                                noDataText={"No translations"}
                                                pageSize={this.state.translations ? this.state.translations.length : 1}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}
