import React from 'react'
import { Redirect } from 'react-router-dom'
import AppComponent from 'components/common/AppComponent'
import PageContent from 'components/common/PageContent'
import {changeNodeAtPath, removeNodeAtPath, getVisibleNodeCount, getNodeAtPath} from 'react-sortable-tree'
import SortableTree from 'react-sortable-tree'
import Axios from 'axios'
import { API } from 'api/APIClient'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors, Sizes, ButtonGroup, Button} from 'react-foundation'
import Modal from 'react-responsive-modal'
import BlockUi from 'react-block-ui'


import ChatMessageForm from 'components/chatflows/ChatMessageForm'
import ChatAnswerForm from 'components/chatflows/ChatAnswerForm'
import ChatConditionForm from 'components/chatflows/ChatConditionForm'
import {ChatItemType} from "../../constants/ChatItemType";
import ChatActionForm from 'components/chatflows/ChatActionForm'
import ChatServiceForm from 'components/chatflows/ChatServiceForm'
import ChatFlowLinkForm from 'components/chatflows/ChatFlowLinkForm'
import ChatImageForm from 'components/chatflows/ChatImageForm'
import ChatTextFieldForm from 'components/chatflows/ChatTextFieldForm'
import ChatRedirectForm from 'components/chatflows/ChatRedirectForm'
import ChatAnimationForm from 'components/chatflows/ChatAnimationForm'
import { NotificationManager } from "react-notifications"
import Utils from "utils/Utils"
import UUID from "utils/UUID"
import Image from "../common/Image";
import FileInput from "../common/FileInput";
import AxiosHelper from "../../utils/AxiosHelper";
import {PluginUtil} from "../../utils/PluginUtil";

import "./ChatFlowEditor.css";
import ChatCustomVariablesSelector from "./ChatCustomVariablesSelector";
import ChatVariablesSelector from "./ChatVariablesSelector";
import ChatGenAIForm from "./ChatGenAIForm";

const pathElements = [
    {id: 0, title: "Home", url: "/"},
    {id: 1, title: "Chat Flows", url: "/chatflows"},
    {id: 2, title: "Editor"}
];


export default class ChatFlowEditor extends AppComponent {

    treeObject = {};

    constructor(props, context) {
        super(props);

        this.state = {
            item: Object.assign({
                isQuickAccess: 0,
                pinned: 0,
                pinnedOrder: null,
                qaOrder: null,
                inAppImageURL: null,
                inAppTitle: null,
                inAppSubtitle: null,
                title: null,
                data: [],
            }, props.location.state.item),
            touched: [],
            errors: [],
            modal: {
                isOpen: false,
                content: null,
                size: "modal-small"
            },
            isUploading: false,
            isLoading: false,
            validated: false,
            redirect: false,
            imagePickerURL: null,
            imagePickerFile: null
        };

    }



    componentDidMount() {

        if (this.state.item.id) {
            this.fetchChatFlow(this.state.item.id);
        }

        this.validate();
    }

    fetchChatFlow = (id) => {

        if (this.state.isLoading) {
            return;
        }

        this.setState({
            isLoading: true
        }, () => {
            Axios
                .get(API.chat.getChatFlow, {
                    params: {
                        id: id
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        this.setState({
                            item: res.data.data.chatFlow,
                            isLoading: false
                        }, () => {
                            this.validate();
                        })
                    }
                    else {
                        let error = Utils.getNested(res, "data", "error", "desc") || "Unknown error";
                        NotificationManager.error(error);
                    }
                })
        })
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        console.log(name);

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let state = {};
        let item = Object.assign({}, this.state.item);

        if (name === "isQuickAccess") {
            if (value === null || value === 0) {
                item.qaOrder = null;
                if (state.pinned === 0) {
                    item.inAppTitle = null;
                    item.inAppSubtitle = null;
                    state.imagePickerURL = null;
                    state.imagePickerFile = null;
                }
            }
        }
        else if (name === "pinned") {
            if (value === null || value === 0 ) {
                item.pinnedOrder = null;
                if (state.isQuickAccess === 0) {
                    item.inAppTitle = null;
                    item.inAppSubtitle = null;
                    state.imagePickerURL = null;
                    state.imagePickerFile = null;
                }
            }
        }

        item[name] = value;

        state.item = item;

        this.setState(state, () => {
            this.validate();
            this.checkPluginCompatibility(true);
        });
    }

    handleSubmit = () => {

        if (!this.state.isUploading) {

            this.setState({
                isUploading: true
            }, () => {

                let formData = new FormData();

                if (this.state.item.id) {
                    formData.append("id", this.state.item.id);
                }

                formData.append("isQuickAccess", this.state.item.isQuickAccess);
                formData.append("pinned", this.state.item.pinned);
                formData.append("pluginCompatible", this.state.item.pluginCompatible);

                if (this.state.item.pinned === 1 && this.state.item.pinnedOrder) {
                    formData.append("pinnedOrder", this.state.item.pinnedOrder);
                }

                if (this.state.item.isQuickAccess === 1 && this.state.item.qaOrder) {
                    formData.append("qaOrder", this.state.item.qaOrder);
                }

                if (this.state.item.isQuickAccess === 1 || this.state.item.pinned === 1) {
                    formData.append("inAppTitle", this.state.item.inAppTitle);
                    formData.append("inAppSubtitle", this.state.item.inAppSubtitle);
                }

                formData.append("title", this.state.item.title);
                formData.append("data", JSON.stringify(this.state.item.data));

                if (this.state.imagePickerFile) {
                    formData.append("file", this.state.imagePickerFile);
                }

                Axios
                    .post(this.state.item.id ? API.chat.updateChatFlow : API.chat.createChatFlow, formData, {
                        headers: {
                            'Content-Type': formData.has('file') ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
                        },
                        onUploadProgress: (progressEvent) => {
                            this.setState({
                                uploadProgress: AxiosHelper.getUploadProgress(progressEvent)
                            });
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isUploading: false,
                                redirect: true
                            });
                        }
                        else {

                            this.setState({
                                isUploading: false
                            }, () => {
                                let error = Utils.getNested(res.data, "error", "desc") || "Unknown error";
                                NotificationManager.error(error);
                            });
                        }
                    })
            })
        }
    }


    removeAll = () => {
        let item = Object.assign({}, this.state.item);
        item.data = [];
        this.setState({
            item: item
        }, () => {
            this.checkPluginCompatibility(true);
        });
    }

    closeModal = () => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null
                }
            });
        }
    }

    validate = () => {

        let validated = true;
        const touched = this.state.touched;
        let errors = this.state.errors;

        // Only one top level object should be there
        if (this.state.item.data.length !== 1) {
            validated = false;
        }

        if (!this.state.item.title || this.state.item.title.length === 0) {
            validated = false;
        }

        this.checkPluginCompatibility(true);

        this.setState({
            validated: validated,
            errors: errors
        });
    }



    /*
    New
     */

    showMessageModal = (path) => {

        const getNodeKey = ({ treeIndex }) => treeIndex;

        let node = {};
        if (path !== undefined && path !== null) {
            node = getNodeAtPath({
                treeData: this.state.item.data,
                path,
                getNodeKey
            }).node;
        }

        const content = (
            <ChatMessageForm
                node={node}
                onSubmitted={(item) => {
                    let treeData = null;
                    if (path !== undefined && path !== null) {
                        treeData = changeNodeAtPath({
                            treeData: this.state.item.data,
                            path,
                            newNode: item,
                            getNodeKey
                        })
                    }
                    else {
                        treeData = Array.from(this.state.item.data);
                        treeData.unshift(item);
                    }

                    let chatFlow = Object.assign({}, this.state.item);
                    chatFlow.data = treeData;

                    this.setState({
                        item: chatFlow,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })

    }


    showAnswerModal = (path) => {

        const getNodeKey = ({ treeIndex }) => treeIndex;

        let node = {};
        if (path !== undefined && path !== null) {
            node = getNodeAtPath({
                treeData: this.state.item.data,
                path,
                getNodeKey
            }).node;
        }

        const content = (
            <ChatAnswerForm
                node={node}
                onSubmitted={(item) => {
                    let treeData = null;
                    if (path !== undefined && path !== null) {
                        treeData = changeNodeAtPath({
                            treeData: this.state.item.data,
                            path,
                            newNode: item,
                            getNodeKey
                        })
                    }
                    else {
                        treeData = Array.from(this.state.item.data);
                        treeData.unshift(item);
                    }

                    let chatFlow = Object.assign({}, this.state.item);
                    chatFlow.data = treeData;

                    this.setState({
                        item: chatFlow,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })

    }

    showConditionModal = (path) => {

        const getNodeKey = ({ treeIndex }) => treeIndex;

        let node = {};
        if (path !== undefined && path !== null) {
            node = getNodeAtPath({
                treeData: this.state.item.data,
                path,
                getNodeKey
            }).node;
        }

        const content = (
            <ChatConditionForm
                node={node}
                onSubmitted={(item) => {
                    let treeData = null;
                    if (path !== undefined && path !== null) {
                        treeData = changeNodeAtPath({
                            treeData: this.state.item.data,
                            path,
                            newNode: item,
                            getNodeKey
                        })
                    }
                    else {
                        treeData = Array.from(this.state.item.data);
                        treeData.unshift(item);
                    }

                    let chatFlow = Object.assign({}, this.state.item);
                    chatFlow.data = treeData;

                    this.setState({
                        item: chatFlow,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })

    }


    showAnimationModal = (path) => {

        const getNodeKey = ({ treeIndex }) => treeIndex;

        let node = {};
        if (path !== undefined && path !== null) {
            node = getNodeAtPath({
                treeData: this.state.item.data,
                path,
                getNodeKey
            }).node;
        }

        const content = (
            <ChatAnimationForm
                node={node}
                onSubmitted={(item) => {
                    let treeData = null;
                    if (path !== undefined && path !== null) {
                        treeData = changeNodeAtPath({
                            treeData: this.state.item.data,
                            path,
                            newNode: item,
                            getNodeKey
                        })
                    }
                    else {
                        treeData = Array.from(this.state.item.data);
                        treeData.unshift(item);
                    }

                    let chatFlow = Object.assign({}, this.state.item);
                    chatFlow.data = treeData;

                    this.setState({
                        item: chatFlow,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })

    }

    showActionModal = (path) => {

        const getNodeKey = ({ treeIndex }) => treeIndex;

        let node = {};
        if (path !== undefined && path !== null) {
            node = getNodeAtPath({
                treeData: this.state.item.data,
                path,
                getNodeKey
            }).node;
        }

        const content = (
            <ChatActionForm
                node={node}
                onSubmitted={(item) => {
                    let treeData = null;
                    if (path !== undefined && path !== null) {
                        treeData = changeNodeAtPath({
                            treeData: this.state.item.data,
                            path,
                            newNode: item,
                            getNodeKey
                        })
                    }
                    else {
                        treeData = Array.from(this.state.item.data);
                        treeData.unshift(item);
                    }

                    let chatFlow = Object.assign({}, this.state.item);
                    chatFlow.data = treeData;

                    this.setState({
                        item: chatFlow,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })

    }

    showServiceModal = (path) => {

        const getNodeKey = ({ treeIndex }) => treeIndex;

        let node = {};
        if (path !== undefined && path !== null) {
            node = getNodeAtPath({
                treeData: this.state.item.data,
                path,
                getNodeKey
            }).node;
        }

        let serviceTypes = undefined;
        if (this.state.item.pluginCompatible === 1) {
            serviceTypes = PluginUtil.serviceTypesSupported;
        }

        const content = (
            <ChatServiceForm
                node={node}
                serviceTypes={serviceTypes}
                onSubmitted={(item) => {
                    let treeData = null;
                    if (path !== undefined && path !== null) {
                        treeData = changeNodeAtPath({
                            treeData: this.state.item.data,
                            path,
                            newNode: item,
                            getNodeKey
                        })
                    }
                    else {
                        treeData = Array.from(this.state.item.data);
                        treeData.unshift(item);
                    }

                    let chatFlow = Object.assign({}, this.state.item);
                    chatFlow.data = treeData;

                    this.setState({
                        item: chatFlow,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })

    }


    showChatFlowModal = (path) => {

        const getNodeKey = ({ treeIndex }) => treeIndex;

        let node = {};
        if (path !== undefined && path !== null) {
            node = getNodeAtPath({
                treeData: this.state.item.data,
                path,
                getNodeKey
            }).node;
        }

        const content = (
            <ChatFlowLinkForm
                node={node}
                onSubmitted={(item) => {
                    let treeData = null;
                    if (path !== undefined && path !== null) {
                        treeData = changeNodeAtPath({
                            treeData: this.state.item.data,
                            path,
                            newNode: item,
                            getNodeKey
                        })
                    }
                    else {
                        treeData = Array.from(this.state.item.data);
                        treeData.unshift(item);
                    }

                    let chatFlow = Object.assign({}, this.state.item);
                    chatFlow.data = treeData;

                    this.setState({
                        item: chatFlow,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })

    }

    showImageModal = (path) => {

        console.log(path);

        const getNodeKey = ({ treeIndex }) => treeIndex;

        let node = {};
        if (path !== undefined && path !== null) {
            node = getNodeAtPath({
                treeData: this.state.item.data,
                path,
                getNodeKey
            }).node;
        }

        const content = (
            <ChatImageForm
                node={node}
                onSubmitted={(item) => {
                    let treeData = null;
                    if (path !== undefined && path !== null) {
                        treeData = changeNodeAtPath({
                            treeData: this.state.item.data,
                            path,
                            newNode: item,
                            getNodeKey
                        })
                    }
                    else {
                        treeData = Array.from(this.state.item.data);
                        treeData.unshift(item);
                    }

                    let chatFlow = Object.assign({}, this.state.item);
                    chatFlow.data = treeData;

                    this.setState({
                        item: chatFlow,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })

    }


    showTextFieldModal = (path) => {

        const getNodeKey = ({ treeIndex }) => treeIndex;

        let node = {};
        if (path !== undefined && path !== null) {
            node = getNodeAtPath({
                treeData: this.state.item.data,
                path,
                getNodeKey
            }).node;
        }

        const content = (
            <ChatTextFieldForm
                node={node}
                onSubmitted={(item) => {
                    let treeData = null;
                    if (path !== undefined && path !== null) {
                        treeData = changeNodeAtPath({
                            treeData: this.state.item.data,
                            path,
                            newNode: item,
                            getNodeKey
                        })
                    }
                    else {
                        treeData = Array.from(this.state.item.data);
                        treeData.unshift(item);
                    }

                    let chatFlow = Object.assign({}, this.state.item);
                    chatFlow.data = treeData;

                    this.setState({
                        item: chatFlow,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })

    }

    showGenAIModal = (path) => {
        const getNodeKey = ({ treeIndex }) => treeIndex;

        let node = {};
        if (path !== undefined && path !== null) {
            node = getNodeAtPath({
                treeData: this.state.item.data,
                path,
                getNodeKey
            }).node;
        }

        const content = (
            <ChatGenAIForm
                node={node}
                onSubmitted={(item) => {
                    let treeData = null;
                    if (path !== undefined && path !== null) {
                        treeData = changeNodeAtPath({
                            treeData: this.state.item.data,
                            path,
                            newNode: item,
                            getNodeKey
                        })
                    }
                    else {
                        treeData = Array.from(this.state.item.data);
                        treeData.unshift(item);
                    }

                    let chatFlow = Object.assign({}, this.state.item);
                    chatFlow.data = treeData;

                    this.setState({
                        item: chatFlow,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })
    }

    showVariableModal = (type) => {
        let modalContent = [];

        if (type === "custom") {
            modalContent = (
                <ChatCustomVariablesSelector
                    showCopyButton={true}
                    showSelectButton={false}
                    showCondVariables={false}
                    showTextVariables={true}
                    onSelected={() => this.closeModal()}
                    onCancel={() => this.closeModal()}
                />
            );
        } else {
            modalContent = (
                <ChatVariablesSelector
                    showCopyButton={true}
                    showSelectButton={false}
                    showCondVariables={false}
                    showTextVariables={true}
                    onSelected={() => this.closeModal()}
                    onCancel={() => this.closeModal()}
                />
            );
        }

        this.setState({
            modal: {
                isOpen: true,
                content: modalContent,
                size: "modal-large"
            }
        });
    }

    getItemWithUUID = (parent, uuid) => {

        console.log(parent);

        if (parent instanceof Array) {
            console.log("is array");
            for(var i=0; i<parent.length; i++) {
                console.log("iteration: " + i);
                let res = this.getItemWithUUID(parent[i], uuid);
                if (res !== null) { return res; }
            }
            return null;
        }

        console.log("not array");

        if (parent.uuid === uuid) {
            return parent;
        }
        else if (parent.hasOwnProperty("children") && parent.children instanceof Array) {
            for(var i=0; i<parent.children.length; i++) {
                let res = this.getItemWithUUID(parent.children[i], uuid);
                if (res !== null) { return res; }
            }
        }
        return null;
    }


    showRedirectModal = (path) => {

        const getNodeKey = ({ treeIndex }) => treeIndex;

        let node = {};
        if (path !== undefined && path !== null) {
            node = getNodeAtPath({
                treeData: this.state.item.data,
                path,
                getNodeKey
            }).node;
        }

        const content = (
            <ChatRedirectForm
                node={node}
                onSubmitted={(item) => {

                    if (item.redirectID === item.uuid) {
                        NotificationManager.error("Cannot be redirected to itself");
                        return;
                    }

                    let redirectItem = this.getItemWithUUID(this.state.item.data, item.redirectID);
                    console.log("found " + redirectItem);
                    if (!redirectItem) {
                        NotificationManager.error("Redirect ID cannot be found in the tree");
                        return;
                    }

                    if (redirectItem.typeID === ChatItemType.ANSWER) {
                        NotificationManager.error("Cannot redirect to answer element");
                        return;
                    }

                    if (redirectItem.typeID === ChatItemType.CONDITION) {
                        NotificationManager.error("Cannot redirect to condition element");
                        return;
                    }


                    let treeData = null;
                    if (path !== undefined && path !== null) {
                        treeData = changeNodeAtPath({
                            treeData: this.state.item.data,
                            path,
                            newNode: item,
                            getNodeKey
                        })
                    }
                    else {
                        treeData = Array.from(this.state.item.data);
                        treeData.unshift(item);
                    }

                    let chatFlow = Object.assign({}, this.state.item);
                    chatFlow.data = treeData;

                    this.setState({
                        item: chatFlow,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })

    }


    addReviewRequest = () => {
        const getNodeKey = ({ treeIndex }) => treeIndex;


        let item = {
            text: null,
            nodeText: "System asks user to review the app",
            typeText: "Review request",
            useCustomDisplayTime: null,
            displayTime: 0,
            useCustomTypingTime: null,
            typingTime: 0,
            typeID: ChatItemType.REVIEW,
            uuid: UUID.uuidv4()
        };

        let treeData = Array.from(this.state.item.data);
        treeData.unshift(item);

        let chatFlow = Object.assign({}, this.state.item);
        chatFlow.data = treeData;

        this.setState({
            item: chatFlow,
            modal: {
                isOpen: false,
                content: null,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })

    }

    handleFileDelete = (name) => {
        if (name === "imageFile") {
            this.setState({
                imagePickerFile: null,
                imagePickerURL: null
            })
        }
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];
        let name = event.target.name;

        let self = this;

        reader.onloadend = () => {

            let newState = {};

            if (name === "imageFile") {
                newState = {
                    imagePickerFile: file ? file : null,
                    imagePickerURL: file ? reader.result : null
                }
            }

            self.setState(newState, () => {
                self.validate()
            })
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    checkPluginCompatibility = (putIntoState) => {
        if (putIntoState === undefined) {
            putIntoState = false;
        }

        if (this.state.item.pluginCompatible && parseInt(this.state.item.pluginCompatible) === 1) {
            let compatible = true;
            let compatibilityMessages = [];

            if (this.state.item.data && this.state.item.data.length > 0) {
                let result = PluginUtil.checkChatFlowCompatibility(this.state.item.data);
                compatible = result.compatible;
                compatibilityMessages = result.results;
            }

            if (putIntoState) {
                this.setState({
                    pluginConformity : compatible,
                    compatibilityMessages
                });
            }
        }
    }

    render() {

        if (this.state.redirect) {
            return (<Redirect to="/chatflows" />);
        }

        const getNodeKey = ({ treeIndex }) => treeIndex;
        const nodeHeight = 90;
        const nodesCount = getVisibleNodeCount({treeData:this.state.item.data});

        const structureButtons = [
            { element : <Button onClick={() => this.showMessageModal(null)}>Message</Button>, pluginCompatible : true },
            { element : <Button onClick={() => this.showAnswerModal(null)}>Answer</Button>, pluginCompatible : true },
            { element : <Button onClick={() => this.showConditionModal(null)}>Condition</Button>, pluginCompatible : true },
            { element : <Button onClick={() => this.showActionModal(null)}>Action</Button>, pluginCompatible : true },
            { element : <Button onClick={() => this.showChatFlowModal(null)}>Chat Flow Link</Button>, pluginCompatible : true },
            { element : <Button onClick={() => this.showImageModal(null)}>Image</Button>, pluginCompatible : true },
            { element : <Button onClick={() => this.showTextFieldModal(null)}>Text Field</Button>, pluginCompatible : true },
            { element : <Button onClick={() => this.showGenAIModal(null)}>GenAI Request</Button>, pluginCompatible : true },
            { element : <Button onClick={() => this.showServiceModal(null)}>Service Link</Button>, pluginCompatible : true },
            { element : <Button onClick={() => this.showRedirectModal(null)}>Redirect</Button>, pluginCompatible : true },
            { element : <Button onClick={() => this.addReviewRequest()}>Review Request</Button>, pluginCompatible : false },
            { element : <Button onClick={() => this.showAnimationModal(null)}>Animation</Button>, pluginCompatible : true }
        ];

        let pluginCompatibilityElem = [];
        console.log("PC: ", this.state.item.pluginCompatible);
        console.log("CONFORM: ", this.state.pluginConformity);
        if (parseInt(this.state.item.pluginCompatible) === 1 && this.state.pluginConformity === false) {
            pluginCompatibilityElem = (
                <div className="cell small-12">
                    <div className={"alert-message alert-warning"}>
                        <div><strong>Some items in this Chat Flow are incompatible with the Plugin. You may still use this Chat Flow with the Plugin but the user experience may be diminished.</strong></div>
                        <div>Please review the items and resolve any incompatibility warnings.</div>
                    </div>
                </div>
            )
        }

        return (
            <BlockUi tag="div" blocking={this.state.isUploading}>
                <PageContent pathElements={pathElements} >
                    <div className="gird-x grid-padding-x">

                        <div className="small-12 cell" style={{margin: "20px 0"}}>
                            <fieldset className="fieldset">
                                <legend>Details</legend>
                                <div className="cell small-12">
                                    <div className="grid-x grid-padding-x">
                                        <div
                                            className={"small-12 large-6 cell " + (this.state.errors.title && "error")}>
                                            <span>* Chat Flow name</span>
                                            <input name="title" type="text" value={this.state.item.title || ""}
                                                   onBlur={this.handleBlur} onChange={this.handleChange}/>
                                            <span className="error">{this.state.errors.title}</span>
                                        </div>

                                        <div className={"small-12 large-6 cell " + (this.state.errors.title && "error")}>
                                            <label>&nbsp;</label>
                                            <label htmlFor="active" style={{display: "inline-block"}}>Plugin Compatible</label>
                                            <label className="chkbx-container" style={{display: "inline-block", marginLeft: 10}}>
                                                <input className="chkbx" type="checkbox" name="pluginCompatible"
                                                       onChange={this.handleChange}
                                                       checked={this.state.item.pluginCompatible || 0 === 1}/>
                                                <span className="chkmk"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className={"grid-x grid-padding-x"}>
                                    <div className="cell small-6">
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-12 cell">
                                                <label htmlFor="active" style={{display: "inline-block"}}>Pinned</label>
                                                <label className="chkbx-container"
                                                       style={{display: "inline-block", marginLeft: 10}}>
                                                    <input className="chkbx" type="checkbox" name="pinned" onChange={this.handleChange} checked={this.state.item.pinned || 0 === 1} />
                                                    <span className="chkmk"></span>
                                                </label>
                                                {this.state.item.pinned === 1 &&
                                                <React.Fragment>
                                                    <label htmlFor="pinnedOrder" style={{display: "inline-block", marginLeft: 30}}>Order</label>
                                                    <input style={{display: "inline-block",  width: 100, marginLeft: 10}} name="pinnedOrder" type="number"
                                                           value={this.state.item.pinnedOrder} onBlur={this.handleBlur}
                                                           onChange={this.handleChange}/>
                                                </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cell small-6">
                                        <label htmlFor="active" style={{display: "inline-block"}}>Is Quick Access chat?</label>
                                        <label className="chkbx-container" style={{display:"inline-block", marginLeft: 10}}>
                                            <input className="chkbx" type="checkbox" name="isQuickAccess" onChange={this.handleChange} checked={this.state.item.isQuickAccess || 0 === 1} />
                                            <span className="chkmk"></span>
                                        </label>
                                        {this.state.item.isQuickAccess === 1 &&
                                        <React.Fragment>
                                            <label htmlFor="qaOrder" style={{display: "inline-block", marginLeft: 30}}>Order</label>
                                            <input style={{display: "inline-block",  width: 100, marginLeft: 10}} name="qaOrder" type="number"
                                                   value={this.state.item.qaOrder} onBlur={this.handleBlur}
                                                   onChange={this.handleChange}/>
                                        </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        {(this.state.item.isQuickAccess === 1 || this.state.item.pinned === 1) &&
                        <div className="small-12 cell" style={{margin: "20px 0"}}>
                            <fieldset className="fieldset edit-field">
                                <legend>In App properties</legend>

                                <div className={"grid-x grid-padding-x"}>
                                    <div className={"small-12 large-6 cell"} style={{margin: "auto"}}>
                                        <div className={"alert-widget"}>
                                            <div className={"alert-widget-header"}>
                                                Information
                                            </div>
                                            <div className={"alert-widget-content"}>
                                                <div>You can use System Properties and Custom Properties in your In App Title and In App Subtitle fields. These properties will be populated when displayed in-app.</div>
                                                <div className={"text-end"}>
                                                    <button className="button primary" type="button" onClick={() => this.showVariableModal("system")}>
                                                        Show System Properties
                                                    </button>

                                                    <button className="button primary" type="button" onClick={() => this.showVariableModal("custom")}>
                                                        Show Custom Properties
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid-x grid-padding-x">
                                <div className={"small-12 large-6 cell " + (this.state.errors.inAppTitle && "error")}>
                                        <span>* Title</span>
                                        <input name="inAppTitle" type="text" value={this.state.item.inAppTitle} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                        <span className="error">{this.state.errors.inAppTitle}</span>
                                    </div>
                                    <div className={"small-12 large-6 cell " + (this.state.errors.inAppSubtitle && "error")}>
                                        <span>* Subtitle</span>
                                        <input name="inAppSubtitle" type="text" value={this.state.item.inAppSubtitle} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                        <span className="error">{this.state.errors.inAppSubtitle}</span>
                                    </div>

                                    <div className="small-12 large-6 cell">
                                        <span>* Image</span>
                                        <FileInput
                                            name="imageFile"
                                            onChange={this.handleFilePickerChange}
                                            onBlur={this.handleBlur}
                                            onDelete={this.handleFileDelete}
                                            placeholder="Not selected."
                                            accept="image/jpeg,image/jpg,image/png"
                                        />
                                    </div>
                                    <div className={"small-12 large-6 cell " + (this.state.errors.imageFile && "error")} style={{marginBottom: 20}}>
                                        <span>Image Preview</span>
                                        <div style={{display: "block"}}>
                                            {this.state.imagePickerURL &&
                                            <Image src={this.state.imagePickerURL} width={200} height={120} border={true} fit="contain"/>
                                            }
                                            {!this.state.imagePickerURL && this.state.item.inAppImageURL &&
                                            <Image src={this.state.item.inAppImageURL} width={200} height={120} border={true} fit="contain"/>
                                            }
                                            {!this.state.imagePickerURL && !this.state.item.inAppImageURL &&
                                            <p style={{marginTop: 5}}>No image selected</p>
                                            }
                                        </div>
                                        <small className="error">{this.state.errors.imageFile}</small>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        }
                    </div>
                    <div className="grid-x grid-padding-x" style={{marginBottom: 100}}>
                        <div className="small-12 cell">
                            <fieldset className="fieldset">
                                <legend>Structure</legend>
                                <div className="grid-x grid-padding-x">
                                    <div className="cell small-12 medium-10">
                                        <ButtonGroup className="float-left" style={{display: "block"}}>
                                            {structureButtons.map((button) => {
                                                if (!this.state.item.pluginCompatible || button.pluginCompatible === true) {
                                                    return button.element;
                                                }
                                                return [];
                                            })}
                                        </ButtonGroup>
                                    </div>
                                    <div className="cell small-12 medium-2">
                                        <ButtonGroup className="float-right">
                                            <Button color={Colors.ALERT} onClick={this.removeAll} >Remove all</Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    {pluginCompatibilityElem}

                                    {this.state.item.data.length > 1 &&
                                    <div className="cell small-12">
                                        <p style={{color: "red"}}>Only single item allowed at the top level. Items must be re-arranged before saving.</p>
                                    </div>
                                    }
                                    <div className="cell small-12">
                                        {this.state.item.data.length > 0 ?
                                            <SortableTree
                                                ref={input => this.treeObject = input}
                                                style={{height: nodeHeight * nodesCount + 50}}
                                                treeData={this.state.item.data}
                                                rowHeight={nodeHeight}
                                                onChange={treeData => {
                                                        let item = Object.assign({}, this.state.item);
                                                        item.data = treeData;
                                                        this.setState({
                                                            item: item
                                                        }, () => {
                                                            this.validate();
                                                        });
                                                    }
                                                }
                                                canDrop={({nextParent, node}) => {


                                                    if (!nextParent) {
                                                        return true;
                                                    }

                                                    let children = nextParent.children.filter((childNode) => childNode.uuid !== node.uuid);

                                                    if (children.length > 0) {
                                                        /*
                                                        Only answers and conditions can be on same level
                                                         */
                                                        if (node.typeID !== ChatItemType.ANSWER && node.typeID !== ChatItemType.CONDITION) {
                                                            return false
                                                        }
                                                        /*
                                                        Answer and Condition items - only allow multiples if same type or no children
                                                         */
                                                        else {
                                                            let allowed = true;
                                                            if (node.typeID === ChatItemType.CONDITION) {
                                                                for (let i=0; i < children.length; i++) {
                                                                    let childNode = children[i];
                                                                    /*
                                                                    Only allow if other elements are the same type
                                                                     */
                                                                    if (childNode.typeID !== ChatItemType.CONDITION) {
                                                                        allowed = false;
                                                                        break;
                                                                    }
                                                                    /*
                                                                    Some conditions can only by allowed to stack on the same level if
                                                                    there is no other condition with the same ID
                                                                     */
                                                                    else if (childNode.multipleAllowed === false) {
                                                                        allowed = false;
                                                                        break;
                                                                    }
                                                                }
                                                            }
                                                            else if (node.typeID === ChatItemType.ANSWER) {
                                                                for (let i=0; i < children.length; i++) {
                                                                    let childNode = children[i];
                                                                    /*
                                                                    Only allow if other elements are the same type
                                                                     */
                                                                    if (childNode.typeID !== ChatItemType.ANSWER) {
                                                                        allowed = false;
                                                                        break;
                                                                    }
                                                                }
                                                            }


                                                            if (!allowed) {
                                                                return false;
                                                            }
                                                        }
                                                    }

                                                    return true;
                                                }}
                                                generateNodeProps={({node, path}) => {
                                                    let classExtra = "";
                                                    let warningElem = [];
                                                    if (this.state.item.pluginCompatible && parseInt(this.state.item.pluginCompatible) === 1) {
                                                        if (this.state.compatibilityMessages) {
                                                            this.state.compatibilityMessages.forEach((msg) => {
                                                                if (msg.id === node.uuid) {
                                                                    classExtra += " node-warning";

                                                                    warningElem = (
                                                                        <div className={"error"}>{msg.message}</div>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    }

                                                    return {
                                                        class: ChatItemType.getNodeClass(node.typeID) + classExtra,
                                                        title: (
                                                            <div className="tree-node">
                                                                <div className="float-left">
                                                                    <div>{node.typeText} </div>
                                                                    {node.typeID === ChatItemType.CONDITION && node.comparator !== null &&
                                                                    <span className="label submenu">{node.isCustomVariable ? "Custom property" : "System property"}</span>
                                                                    }
                                                                    {node.typeID === ChatItemType.ANSWER && !!node.saveResponse &&
                                                                    <span className="label submenu">Save response - {node.responseProperty.key} : {node.responseValue}</span>
                                                                    }
                                                                    {node.typeID === ChatItemType.TEXTFIELD && !!node.saveResponse && node.isGenAI === 0 &&
                                                                    <span className="label submenu">Save response - {node.responseProperty.key}</span>
                                                                    }
                                                                    {(node.typeID === ChatItemType.TEXTFIELD && node.isGenAI === 1 || node.typeID === ChatItemType.AI_REQUEST) &&
                                                                        <span className="label submenu">Gen AI</span>
                                                                    }
                                                                    {node.typeID === ChatItemType.SERVICE && node.dynamicService === 1 &&
                                                                        <span className="label submenu">Dynamic Service</span>
                                                                    }
                                                                    {node.useCustomDisplayTime === 1 &&
                                                                    <span className="label submenu">Display time: {node.displayTime} sec.</span>
                                                                    }
                                                                    {node.useCustomTypingTime === 1 &&
                                                                    <span className="label submenu">Typing time: {node.typingTime} sec.</span>
                                                                    }
                                                                    {Array.isArray(node.texts) && node.texts.length > 1 &&
                                                                    <span className="label submenu">Text variations</span>
                                                                    }
                                                                    <div style={{display: "block", float: "none"}}>
                                                                        <div className="title">{node.nodeText}</div>
                                                                        {warningElem}
                                                                        <div style={{fontSize: 11, fontWeight: 200, color: "#565656"}}>ID: {node.uuid}</div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                        ,
                                                        buttons: [
                                                            node.typeID !== ChatItemType.REVIEW ?
                                                                (<Button
                                                                color={Colors.PRIMARY}
                                                                size={Sizes.TINY}
                                                                onClick={() => {
                                                                                switch (node.typeID) {
                                                                                    case ChatItemType.CONDITION:
                                                                                        this.showConditionModal(path);
                                                                                        break;
                                                                                    case ChatItemType.MESSAGE:
                                                                                        this.showMessageModal(path);
                                                                                        break;
                                                                                    case ChatItemType.ANSWER:
                                                                                        this.showAnswerModal(path);
                                                                                        break;
                                                                                    case ChatItemType.ACTION:
                                                                                        this.showActionModal(path);
                                                                                        break;
                                                                                    case ChatItemType.SERVICE:
                                                                                        this.showServiceModal(path);
                                                                                        break;
                                                                                    case ChatItemType.IMAGE:
                                                                                        this.showImageModal(path);
                                                                                        break;
                                                                                    case ChatItemType.TEXTFIELD:
                                                                                        this.showTextFieldModal(path);
                                                                                        break;
                                                                                    case ChatItemType.REDIRECT:
                                                                                        this.showRedirectModal(path);
                                                                                        break;
                                                                                    case ChatItemType.ANIMATED_SVG:
                                                                                        this.showAnimationModal(path);
                                                                                        break;
                                                                                    case ChatItemType.AI_REQUEST:
                                                                                        this.showGenAIModal(path);
                                                                                        break;
                                                                                }
                                                                            }}
                                                            >
                                                                <FontAwesomeIcon icon="edit" />
                                                            </Button>)
                                                                :
                                                                (<span></span>),
                                                            (
                                                                <Button
                                                                    color={Colors.ALERT}
                                                                    size={Sizes.TINY}
                                                                    onClick={() => {
                                                                        let item = Object.assign({}, this.state.item);
                                                                        item.data = removeNodeAtPath({
                                                                            treeData: item.data,
                                                                            path,
                                                                            getNodeKey
                                                                        })
                                                                        this.setState({
                                                                            item
                                                                        }, () => {
                                                                            this.checkPluginCompatibility(true);
                                                                        });
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon="trash"/>
                                                                </Button>
                                                            )
                                                        ]
                                                    }
                                                }}
                                            /> :
                                            <p>No structure yet - start by adding submenus or services</p>
                                        }
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div className="fix-save">
                        <ButtonGroup>
                            <Button className="edit-button" onClick={() => this.setState({redirect: true})}>Cancel</Button>
                            <Button className="edit-button" color={Colors.SUCCESS} disabled={!this.state.validated} onClick={this.handleSubmit} >Save</Button>
                        </ButtonGroup>
                    </div>
                    <Modal
                        classNames={{modal: this.state.modal.size}}
                        open={this.state.modal.isOpen}
                        modalId="SupportModal"
                        onClose={this.closeModal}
                        center
                    >
                        {this.state.modal.content}
                    </Modal>
                </PageContent>
            </BlockUi>
        );
    }
}