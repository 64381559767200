import React from 'react'
import { ChatItemType } from 'constants/ChatItemType'
import UUID from "../../utils/UUID";
import ChatVariablesSelector from "./ChatVariablesSelector";
import Modal from "react-responsive-modal";

export default class ChatRedirectForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: Object.assign({
                redirectID: null,
                nodeText: null,
                typeText: "Redirect",
                typeID: ChatItemType.REDIRECT,
                uuid: UUID.uuidv4()
            }, props.node),
            validated: false,
            errors: {},
            touched: {}
        };
    }

    componentDidMount() {
        this.validate();
    }


    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        let item = Object.assign({}, this.state.item);

        if (name === "redirectID" && value) {
            value = value.trim();
            item.nodeText = "Redirect to ID: " + value;
        }

        item[name] = value;

        this.setState({
            item: item
        }, () => {
            this.validate();
        });
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.onSubmitted instanceof Function) {
            this.props.onSubmitted(this.state.item)
        }
    }


    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.redirectID) {
            if (touched.redirectID) {
                errors.redirectID = "Redirect ID is required";
            }
            validated = false;
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }


    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }



    render() {

        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Chat redirect</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">* Redirect item ID</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                <input type="text" name="redirectID" value={this.state.item.redirectID} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <small className="error">{this.state.errors.redirectID}</small>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </React.Fragment>
        );
    }
}
