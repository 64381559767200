import React, {useEffect, useRef, useState} from 'react';
import hljs from "highlight.js";

import "highlight.js/styles/github.css";
import "./CodeBlock.css";

export const CodeBlock = (props) => {

    const {code} = props;
    const {fullWidth} = props;

    const [copied, setCopied] = useState(false);

    const codeBlock = useRef();

    const copyTimeout = useRef();

    useEffect(() => {
        runHighlight();

        return (() => {
            clearTimeout(copyTimeout.current)
        });
    }, []);

    useEffect(() => {
        runHighlight();
    }, [code]);

    useEffect(() => {
        runHighlight();
    }, [codeBlock.current]);

    function runHighlight() {
        if (codeBlock.current) {
            // Highlight.js sets this property so that it doesn't highlight more than once
            // This is not desirable for us. So we'll just remove this property before
            // running the highlighter again.
            delete codeBlock.current.dataset.highlighted;
            hljs.highlightElement(codeBlock.current);
        }
    }

    function copyToClipboard(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(code)
                .then((r) => {
                    console.log(r);
                    setCopied(true);

                    copyTimeout.current = setTimeout(() => {
                        setCopied(false);
                    }, 10000);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    // RENDER

    let classExtra = "";
    if (fullWidth === true) {
        classExtra = " fullwidth";
    }

    return (
        <div className={"code-block-wrapper" + classExtra}>
            <pre>
                <code className={"code-block"} ref={codeBlock}>
                    {code}
                </code>
            </pre>

            <div className={"actions"}>
                <button className={"action-button"} onClick={copyToClipboard}>
                    {copied ? "Copied" : "Copy"}
                </button>
            </div>
        </div>
    );

}