import React from 'react'
import { ChatItemType } from 'constants/ChatItemType'
import UUID from "../../utils/UUID";
import ChatVariablesSelector from "./ChatVariablesSelector";
import ChatCustomVariablesSelector from "./ChatCustomVariablesSelector"
import Modal from "react-responsive-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import {CommonUtil} from "../../utils/CommonUtil";
import {TranslationUtil} from "../../utils/TranslationUtil";
import ReactSelect from "../common/ReactSelect";
import ReactTable from "react-table";
import {ChatMessageFormTranslations} from "./ChatMessageFormTranslations";
import StandardModal from "../common/StandardModal";

export default class ChatMessageForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: Object.assign({
                texts: [{id: UUID.uuidv4(), text: props.node.text || ""}], //legacy
                nodeText: null,
                typeText: "Message",
                useCustomDisplayTime: null,
                displayTime: 0,
                useCustomTypingTime: null,
                typingTime: 0,
                typeID: ChatItemType.MESSAGE,
                uuid: UUID.uuidv4(),
                responseIsHTML: 0
            }, props.node),
            validated: false,
            errors: {},
            touched: {},
            modal: {
                isOpen: false,
                content: null
            }
        };
    }

    componentDidMount() {
        if (this.state.item && this.state.item.translations) {
            this.processTranslations();
        }

        TranslationUtil.fetchChatLanguages((action, data) => {
            if (action === "success") {
                this.setState({
                    languageOptions : data
                });
            } else {
                console.log(data);
            }
        });

        this.validate();
    }


    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        let item = Object.assign({}, this.state.item);

        if (name.startsWith("text_")) {
            const index = parseInt(name.replace("text_", ""));
            item.texts[index].text = value;
            item.nodeText = item.texts[0].text;
        }

        if (name === "useCustomDisplayTime") {
            item.displayTime = 0;
        }

        if (name === "useCustomTypingTime") {
            item.typingTime = 0;
        }

        item[name] = value;

        this.setState({
            item: item
        }, () => {
            this.validate();
        });
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.onSubmitted instanceof Function) {
            this.props.onSubmitted(this.state.item)
        }
    }


    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.texts || item.texts.length === 0) {
            validated = false;
        }

        for (let i=0; i < item.texts.length; i++) {
            let textValue = item.texts[i].text;
            if (!textValue || textValue.length === 0) {
                let name = "text_".i;
                if (touched[name]) {
                    errors[name] = "Text is required";
                }
                validated = false;
            }
        }


        this.setState({
            validated: validated,
            errors: errors
        });
    }

    // a little function to help us with reordering the result
    reorderItems = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    showVariablesSelector = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatVariablesSelector
                showCopyButton={true}
                showSelectButton={false}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={() => this.closeModal()}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    showCustomVariablesSelector = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatCustomVariablesSelector
                showCopyButton={true}
                showSelectButton={false}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={() => this.closeModal()}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    addAlternateText = () => {
        let item = Object.assign({}, this.state.item);
        item.texts.push({id: UUID.uuidv4(), text: ""});
        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    removeTextVariation = (index) => {
        let item = Object.assign({}, this.state.item);
        item.texts.splice(index, 1);
        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }


    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const texts = this.reorderItems(
            this.state.item.texts,
            result.source.index,
            result.destination.index
        );

        let item = Object.assign({}, this.state.item);
        item.texts = texts;

        this.setState({
            item: item
        }, () => {
            this.validate();
        })
    }

    processTranslations = () => {
        const translationRows = [];
        Object.keys(this.state.item.translations).forEach((code, index) => {
            let rowData = CommonUtil.cloneObject(
                this.state.item.translations[code]
            );
            rowData.id = index;
            rowData.code = code;
            translationRows.push(rowData);
        });
        this.setState({
            translations : translationRows
        });
    }

    showTranslationEditor = (languageCode) => {
        const content = (
            <ChatMessageFormTranslations
                languageCode={languageCode}
                item={this.state.item}
                onSubmit={() => {
                    this.closeModal();
                    this.processTranslations();
                }}
                onCancel={this.closeModal}
            />
        )

        this.setState({
            modal : {
                isOpen: true,
                content,
                size : "modal-medium"
            }
        });
    }

    lookupLanguage = (languageCode) => {
        let out = languageCode;
        if (this.state.languageOptions) {
            for (let i = 0; i < this.state.languageOptions.length; i++) {
                if (this.state.languageOptions[i].value === languageCode) {
                    out = this.state.languageOptions[i].label;
                    break;
                }
            }
        }
        return out;
    }

    showDeleteTranslation = (languageCode) => {
        let languageName = this.lookupLanguage(languageCode);

        const content = (
            <StandardModal
                title="Delete Translation"
                content={(
                    <React.Fragment>
                        <p>Are you sure to delete the <strong>{languageName}</strong> translation? This action cannot be reverted.</p>
                    </React.Fragment>
                )}
                buttons={(
                    <React.Fragment>
                        <button className="button secondary" type="button" onClick={this.closeModal}>
                            Cancel
                        </button>
                        <button className="button alert" type="button" onClick={() => this.handleDeleteTranslation(languageCode)}>
                            Delete
                        </button>
                    </React.Fragment>
                )}
            />
        );

        this.setState({
            modal: Object.assign({}, this.state.modal, {
                isOpen: true,
                size: "modal-medium",
                isUploading: false,
                content: content
            })
        })
    }

    handleDeleteTranslation = (languageCode) => {
        if (this.state.item.translations) {
            if (this.state.item.translations.hasOwnProperty(languageCode)) {
                delete this.state.item.translations[languageCode];
            }

            this.setState({
                item : this.state.item
            }, () => {
                this.processTranslations();
                this.closeModal();
            });
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Chat message</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {this.state.item.texts.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            userSelect: "none",
                                                            background: snapshot.isDragging ? "lightgreen" : "white",
                                                            ...provided.draggableProps.style
                                                        }}
                                                    >
                                                        <div className="grid-x grid-padding-x" key={index}>
                                                            <div className="small-3 cell">

                                                                <label htmlFor={"text_" + index}
                                                                       className="text-right middle">
                                                                    {index !== 0 &&
                                                                        <button style={{height: 5}}
                                                                                className="button clear alert"
                                                                                type="button"
                                                                                onClick={() => this.removeTextVariation(index)}>
                                                                            <FontAwesomeIcon icon="times-circle"/>
                                                                        </button>
                                                                    }
                                                                    {index == 0 ? "* Text" : "* Alternate text"}
                                                                </label>
                                                            </div>
                                                            <div
                                                                className={"large-9 small-9 cell " + (this.state.errors["text_" + index] && "error")}>
                                                                <textarea rows={5} name={"text_" + index}
                                                                          value={item.text} onBlur={this.handleBlur}
                                                                          onChange={this.handleChange}/>
                                                                <small
                                                                    className="error">{this.state.errors["text_" + index]}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">

                            </div>
                            <div className="large-9 small-9 cell ">
                                <button className="button primary" type="button" onClick={this.addAlternateText}>Add
                                    alternate text
                                </button>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="responseIsHTML" className="text-right middle">
                                    Message is HTML
                                </label>
                            </div>
                            <div className="large-9 small-9 cell ">
                                <label className="chkbx-container">
                                    <input
                                        className="chkbx"
                                        type="checkbox"
                                        name="responseIsHTML"
                                        onChange={this.handleChange}
                                        checked={this.state.item.responseIsHTML}
                                    />
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">System properties</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                <button className="button primary" type="button"
                                        onClick={this.showVariablesSelector}>Show properties
                                </button>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">Custom properties</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                <button className="button primary" type="button"
                                        onClick={this.showCustomVariablesSelector}>Show properties
                                </button>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="useCustomDisplayTime" className="text-right middle">Custom display
                                    time</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="useCustomDisplayTime"
                                           onChange={this.handleChange}
                                           checked={this.state.item.useCustomDisplayTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.useCustomDisplayTime === 1 &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="displayTime" className="text-right middle">* Display
                                            Time</label>
                                    </div>
                                    <div
                                        className={"large-9 small-9 cell " + (this.state.errors.displayTime && "error")}>
                                        <input name="displayTime" type="number" value={this.state.item.displayTime}
                                               onBlur={this.handleBlur} onChange={this.handleChange}/>
                                        <span className="error">{this.state.errors.displayTime}</span>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="useCustomTypingTime" className="text-right middle">Custom typing
                                    time</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="useCustomTypingTime"
                                           onChange={this.handleChange}
                                           checked={this.state.item.useCustomTypingTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.useCustomTypingTime === 1 &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="typing" className="text-right middle">* Typing Time</label>
                                    </div>
                                    <div
                                        className={"large-9 small-9 cell " + (this.state.errors.typingTime && "error")}>
                                        <input name="typingTime" type="number" value={this.state.item.typingTime}
                                               onBlur={this.handleBlur} onChange={this.handleChange}/>
                                        <span className="error">{this.state.errors.typingTime}</span>
                                    </div>
                                </div>
                            </React.Fragment>
                        }

                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset">
                                    <legend>Translations</legend>
                                    <div className="grid-x grid-padding-x">
                                        <div className="cell small-12">
                                            <span>Add translation for</span>
                                            <ReactSelect
                                                name="selectedLanguage"
                                                className="react-select translation-selector"
                                                onChange={(e) => this.setState({selectedLanguage: e.target.value})}
                                                value={this.state.selectedLanguage}
                                                options={this.state.languageOptions}
                                                onBlur={this.handleBlur}
                                            />

                                            <Button
                                                type="button"
                                                className="translation-button"
                                                color={Colors.PRIMARY}
                                                isDisabled={!this.state.selectedLanguage}
                                                onClick={() => this.showTranslationEditor(this.state.selectedLanguage)}>
                                                Add
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="grid-x grid-padding-x">
                                        <div className="cell small-12">
                                            <ReactTable
                                                columns={[
                                                    {
                                                        Header: 'Language',
                                                        accessor: 'code'
                                                    },
                                                    {
                                                        Header: 'Actions',
                                                        Cell: row => (
                                                            <ButtonGroup size={Sizes.TINY}>
                                                                {true && // this.context.permissions.chatFlows.edit
                                                                    <Button
                                                                        type="button"
                                                                        color={Colors.PRIMARY}
                                                                        onClick={() => this.showTranslationEditor(row.original.code)}>
                                                                        <FontAwesomeIcon icon="edit"/>
                                                                    </Button>
                                                                }
                                                                {true && // this.context.permissions.chatFlows.delete
                                                                    <Button
                                                                        type="button"
                                                                        color={Colors.ALERT}
                                                                        onClick={() => this.showDeleteTranslation(row.original.code)}>
                                                                        <FontAwesomeIcon icon="trash"/>
                                                                    </Button>
                                                                }
                                                            </ButtonGroup>
                                                        ),
                                                        maxWidth: 100,
                                                        show: true //this.context.permissions.chatFlows.delete || this.context.permissions.chatFlows.edit
                                                    }
                                                ]}
                                                data={this.state.translations}
                                                className='-striped'
                                                showPagination={false}
                                                noDataText={"No translations"}
                                                pageSize={this.state.translations ? this.state.translations.length : 1}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}
