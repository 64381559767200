const Utils = {
    getNested: (obj, level,  ...rest) => {
        if (obj === undefined || obj === null) return undefined;
        if (rest.length === 0 && obj.hasOwnProperty(level)) return obj[level];
        return Utils.getNested(obj[level], ...rest);
    },
    value: (value, defaultValue) => {
         if (value === undefined || value === null) return defaultValue;
         return value;
    }
}

export default Utils;