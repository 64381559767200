import React from 'react'

function SubSectionHeader(props) {
    return (
        <React.Fragment>
            <div className={"cell large-2 medium-3 small-5 home-menu-item selected-menu-item " + props.item.iconClass}>
                <div className="m-icon"></div>
            </div>
            <div className="cell large-10 medium-9 small-7 selecttext">
                <h2>{props.item.title}</h2>
                {props.item.subtitle &&
                <p>{props.item.subtitle}</p>
                }
            </div>
        </React.Fragment>
    );
}

export default SubSectionHeader;