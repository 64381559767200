import React from 'react'

export const AppContext = React.createContext({
    user: null,
    organisation: null,
    permissions: null,
    authToken: null,
    menu: [],
    login: () => void 0,
    logout: () => void 0
});

