import React from 'react';
import ReactTable from 'react-table'
import Axios from 'axios'
import SearchBar from 'components/common/SearchBar'
import DatatableHelper from 'utils/DatatableHelper'
import { Colors, Sizes, ButtonGroup, Button} from 'react-foundation'
import { API } from 'api/APIClient'
import TableContainer from 'components/common/TableContainer'
import AppComponent from 'components/common/AppComponent'
import { NotificationManager } from "react-notifications"
import StandardModal from 'components/common/StandardModal'
import Modal from "react-responsive-modal";
import {CopyToClipboard} from "react-copy-to-clipboard";
import ChatCustomVariableForm from "components/chatflows/ChatCustomVariableForm"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ChatCustomVariablesTable extends AppComponent {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            searchKey: "",
            page: 0,
            pageSize: props.pageSize || 10,
            sorted: [{id: "id", "desc": true}],
            totalPages: -1,
            isLoading: true,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            },
            filtersAreOpen: false
        };

        this.searchTimer = null;
    }


    searchTextDidChange(newValue) {
        this.setState({
            searchKey: newValue || ""
        }, () => {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.fetchItems();
            }, 1000);
        });
    }


    fetchItems = () => {
        this.setState({
            loading: true
        });

        Axios
            .post(API.chat.getCustomPropertiesDT, {
                    page: this.state.page,
                    pageSize: this.state.pageSize,
                    sorted: this.state.sorted,
                    filters: {
                        search: this.state.searchKey
                    }
                }
            )
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        items: res.data.data.rows,
                        totalPages: DatatableHelper.getTotalPages(res.data.data),
                        isLoading: false
                    }, () => {
                        if (this.props.onDidFetch instanceof Function) {
                            this.props.onDidFetch();
                        }
                    })
                }
                else {
                    this.setState({
                        isLoading: false
                    })
                }
            })
    }


    toggleRow = (item) => {
        if (this.props.onSelectedRow instanceof Function) {
            this.props.onSelectedRow(item);
        }
    }

    toggleSelectAll = () => {
        if (this.props.onSelectedAll instanceof Function) {
            this.props.onSelectedAll(!this.props.selectAll, this.state.items);
        }
    }

    showDeleteModal = (item) => {

        if (!this.state.modal.isOpen) {
            console.log("in");
            const content = (
                <StandardModal
                    title="Delete Chat Flow"
                    content={(
                        <div>Are you sure to delete <strong>{item.key}</strong> property ? This action cannot be reverted and all the existing responses submitted for this property will also be removed.</div>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteItem(item)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                    isUploading={this.state.modal.isUploading}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content,
                    size: "modal-medium"
                })
            })
        }
    }

    showItemModal = (item) => {

        console.log(this.state);

        if (!this.state.modal.isOpen) {
            const content = (
                <ChatCustomVariableForm
                    item={item}
                    onSubmitted={() => this.closeModal(this.fetchItems)}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content,
                    size: "modal-medium"
                })
            })
        }

    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    handleDeleteItem = (item) => {

        if (!this.state.modal.isUploading) {
            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isUploading: true
                })
            }, () => {

                Axios
                    .post(API.chat.deleteCustomProperty, {
                        id: item.id
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.closeModal(() => {
                                this.fetchItems();
                                NotificationManager.success("Custom property deleted");
                            });
                        }
                        else {
                            this.closeModal(() => {
                                NotificationManager.error(res.data.error.desc);
                            });
                        }
                    })
                    .catch(error => {
                        this.closeModal(() => {
                            NotificationManager.error(error.response);
                        });
                    });
            })
        }
    }

    handleSelected = (item) => {
        if (this.props.onSelected instanceof Function) {
            this.props.onSelected(item);
        }
    }

    render() {

        const columns = [
            {
                Header: 'Key',
                accessor: 'key',
                minWidth: 100
            },
            {
                Header: 'Type',
                accessor: 'typeText',
                minWidth: 40
            },
            {
                Header: 'Description',
                accessor: 'label'
            },
            {
                Header: "Default value",
                accessor: "defaultValueKey",
                Cell: (row) => {
                    if (!row.value) {
                        return "N/A"
                    }
                    return row.value;
                }
            },
            {
                Header: "Save history",
                accessor: "saveHistory",
                Cell: (row) => {
                    if (row.value === 1) {
                        return "Yes";
                    }
                    else {
                        return "No";
                    }
                }
            },
            {
                Header: "",
                sortable: false,
                Cell: row => (
                    <ButtonGroup size={Sizes.TINY}>

                        {this.props.showSelectButton === true &&
                        <Button color={Colors.PRIMARY} onClick={() => this.handleSelected(row.original)}>Select</Button>}

                        {this.props.showCopyButton === true &&
                        <CopyToClipboard
                            text={"[["+row.original.key+"]]"}
                            onCopy={() => this.handleSelected(row.original)}
                        >
                            <Button color={Colors.PRIMARY}>Copy to clipboard</Button>
                        </CopyToClipboard>
                        }
                    </ButtonGroup>
                )
            },
            {
                Header: 'Actions',
                Cell: row => (
                    <ButtonGroup size={Sizes.TINY}>
                        <Button color={Colors.PRIMARY} onClick={() => this.showItemModal(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                        <Button color={Colors.ALERT} onClick={() => this.showDeleteModal(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                    </ButtonGroup>
                ),
                maxWidth: 130,
                sortable: false
            }
        ]

        const filters = (
            <React.Fragment>
                <div className="cell small-12 medium-6 large-3">
                    <span>Search</span>
                    <SearchBar
                        onDidChange={(newValue) => this.searchTextDidChange(newValue)}
                    />
                </div>
            </React.Fragment>

        )

        const buttons = (
            <React.Fragment>
                {this.context.permissions.chatFlows.create &&
                <Button color={Colors.PRIMARY} size={Sizes.SMALL} onClick={() => this.showItemModal(null)}>New Property</Button>
                }
            </React.Fragment>
        )

        const table = (
            <ReactTable
                columns={columns}
                data={this.state.items}
                pages={this.state.totalPages}
                pageSize={this.state.pageSize}
                loading={this.state.isLoading}
                multisort={true}
                manual
                className='-striped'
                onFetchData={(state) => {
                    this.setState({
                        page: state.page,
                        pageSize: state.pageSize,
                        sorted: state.sorted
                    }, () => {
                        this.fetchItems()
                    })
                }}
            />
        );

        return (
            <React.Fragment>
                <TableContainer
                    title="Custom Properties"
                    buttons={buttons}
                    filters={filters}
                    table={table}
                />
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatCustomVariablesTableModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}

export default ChatCustomVariablesTable;