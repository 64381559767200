import React, {useEffect, useState} from 'react';
import ModalProgress from "../../common/ModalProgress";
import {CommonUtil} from "../../../utils/CommonUtil";
import Modal from "react-responsive-modal";
import {PermissionUtil} from "../../../utils/PermissionUtil";
import ReactSelect from "../../common/ReactSelect";
import Axios from "axios";
import {API} from "../../../api/APIClient";
import {Toast} from "../../../utils/ToastUtil";
import ChatVariablesSelector from "../ChatVariablesSelector";
import ChatCustomVariablesSelector from "../ChatCustomVariablesSelector";
import {KnowledgeDocumentSelector} from "../../knowledgeDocument/KnowledgeDocumentSelector";
import {ConversationSelector} from "../conversations/ConversationSelector";
import {PromptTemplateSelector} from "./PromptTemplateSelector";
import {NotificationManager} from "react-notifications";

export const PromptTemplateEditor = (props) => {

    const {promptTemplate} = props;
    const {callback} = props;

    const [isLoadingOrganisations, setIsLoadingOrganisations] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const [formData, setFormData] = useState({});
    const [organisationOptions, setOrganisationOptions] = useState([]);

    const [touched, setTouched] = useState({});
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState([]);

    useEffect(() => {
        if (promptTemplate) {
            setFormData(promptTemplate);
        }

        if (PermissionUtil.allOrgs(PermissionUtil.PermissionCategories.promptTemplates)) {
            fetchOrganisationsOverNetwork();
        }
    }, []);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function handleSubmit(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (validateForm()) {
            submitPromptTemplateOverNetwork();
        }
    }

    function validateForm() {
        let out = true;
        const errors = {};

        const requiredFields = [
            { "key" : "name", "label" : "Title" },
            { "key" : "content", "label" : "Content" }
        ];

        for (let i = 0; i < requiredFields.length; i++) {
            let problem = false;

            const field = requiredFields[i];
            if (formData.hasOwnProperty(field.key)) {
                if (formData[field.key] === undefined || formData[field.key] === null) {
                    problem = true;
                }
            } else {
                problem = true;
            }

            if (problem) {
                out = false;
                errors[field.key] = "Please provide value for '" + field.label + "'";
            }
        }

        //setErrors(errors);

        return out;
    }

    function putFormData(data) {
        if (!CommonUtil.isObject(data)) {
            return;
        }

        const newFormData = CommonUtil.cloneObject(formData);
        const keys = Object.keys(data);

        keys.forEach((key) => {
            const newData = data[key];
            if (newData !== undefined) {
                newFormData[key] = data[key];
            } else {
                delete newFormData[key];
            }
        });

        setFormData(newFormData);
    }

    function handleBlur(event) {
        const name = event.target.name;
        const newTouched = CommonUtil.cloneObject(touched);
        newTouched[name] = true;
        setTouched(touched);
    }

    function handleChange(event) {
        const value = event.target.type === "checkbox" ? (event.target.checked ? 1 : 0) : event.target.value;

        let outData = {};
        outData[event.target.name] = value;
        putFormData(outData);
    }

    function modalDidDismiss() {
        setModalOpen(false);
        setModalContent([]);
    }

    function showSystemPropertiesModal() {
        setModalOpen(true);
        setModalContent(
            <ChatVariablesSelector
                showCopyButton={true}
                showSelectButton={false}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={() => modalDidDismiss()}
                onCancel={() => modalDidDismiss()}
            />
        );
    }

    function showCustomPropertiesModal() {
        setModalOpen(true);
        setModalContent(
            <ChatCustomVariablesSelector
                showCopyButton={true}
                showSelectButton={false}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={() => modalDidDismiss()}
                onCancel={() => modalDidDismiss()}
            />
        )
    }

    function showKnowledgeDocumentsModal() {
        setModalOpen(true);
        setModalContent(
            <KnowledgeDocumentSelector
                showCopyButton={true}
                showSelectButton={false}
                showDeleteButton={false}
                onSelected={() => modalDidDismiss()}
                onCancel={() => modalDidDismiss()}
            />
        )
    }

    function showConversationsModal() {
        setModalOpen(true);
        setModalContent(
            <ConversationSelector
                showCopyButton={true}
                showSelectButton={false}
                showDeleteButton={false}
                onSelected={() => modalDidDismiss()}
                onCancel={() => modalDidDismiss()}
            />
        );
    }

    function showPromptTemplateModal() {
        setModalOpen(true);
        setModalContent(
            <PromptTemplateSelector
                showCopyButton={true}
                showSelectButton={PermissionUtil.edit(PermissionUtil.PermissionCategories.promptTemplates)}
                showDeleteButton={PermissionUtil.delete(PermissionUtil.PermissionCategories.promptTemplates)}
                onSelected={() => modalDidDismiss()}
                onCancel={() => modalDidDismiss()}
            />
        );
    }

    function fetchOrganisationsOverNetwork() {
        if (isLoadingOrganisations) return;
        setIsLoadingOrganisations(true);

        Axios.get(API.organisation.getOrganisations, {
            props : {
                appUsersOnly : 1
            }
        })
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    const organisations = resp.data.organisations;
                    if (organisations) {
                        const orgOptions = organisations.map((org) => {
                            return {
                                value : org.id.toString(),
                                label : org.name
                            }
                        });

                        orgOptions.unshift({
                            value : null,
                            label : "None (Global)"
                        });
                        setOrganisationOptions(orgOptions);
                    }
                } else {
                    console.log(API.formatError(resp));
                }
                setIsLoadingOrganisations(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoadingOrganisations(false);
            });
    }

    function submitPromptTemplateOverNetwork() {
        if (isUploading) return;
        setIsUploading(true);

        setErrorMessage(null);

        const data = {
            organisationID : CommonUtil.getOrDefault(formData, "organisationID", null),
            name : CommonUtil.getOrDefault(formData, "name", ""),
            content : CommonUtil.getOrDefault(formData, "content", "")
        };

        let endpoint = API.promptTemplate.createPromptTemplate;
        let successMessage = "Successfully created Prompt Template";
        if (promptTemplate) {
            data.id = promptTemplate.id;
            successMessage = "Successfully updated Prompt Template";
            endpoint = API.promptTemplate.updatePromptTemplate;
        }

        Axios.post(endpoint, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    NotificationManager.success(successMessage);

                    handleCallback("submit");
                } else {
                    setErrorMessage(API.formatError(resp));
                    setIsUploading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setIsUploading(false);
            });
    }

    // RENDER

    let modalTitle = "Create Prompt Template";
    if (promptTemplate) {
        modalTitle = "Edit Prompt Template";
    }

    let organisationPicker = [];
    if (PermissionUtil.allOrgs(PermissionUtil.PermissionCategories.promptTemplates)) {
        if (promptTemplate && promptTemplate.id) {
            organisationPicker = (
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="organisationID" className="text-right middle">Organisation</label>
                    </div>
                    <div className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "organisationID", null) && "error")}>
                        <input type={"text"} value={promptTemplate.organisationName} readOnly={true} />
                    </div>
                </div>
            )
        } else {
            organisationPicker = (
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="organisationID" className="text-right middle">Organisation</label>
                    </div>
                    <div
                        className={"small-9 cell " + (CommonUtil.getOrDefault(errors, "organisationID", null) && "error")}>
                        <ReactSelect
                            name="organisationID"
                            className="react-select"
                            onChange={handleChange}
                            value={CommonUtil.getOrDefault(formData, "organisationID", null)}
                            options={organisationOptions}
                            onBlur={handleBlur}
                        />
                        <small className="error">{CommonUtil.getOrDefault(errors, "organisationID", "")}</small>
                    </div>
                </div>
            )
        }
    }

    let errorMessageElem = [];
    if (errorMessage) {
        errorMessageElem = (
            <div className={"alert alert-danger"}>
                {errorMessage}
            </div>
        );
    }

    return (
        <>
            <ModalProgress show={isUploading}/>
            <div className={"modal-head"}>
                <div className={"modal-head"}>
                    <h3>{modalTitle}</h3>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className={"modal-body"}>

                    {organisationPicker}

                    <PromptTemplateEditorTextInput
                        label={"* Title"}
                        name={"name"}
                        error={CommonUtil.getOrDefault(errors, "name", "")}
                        value={CommonUtil.getOrDefault(formData, "name", "")}
                        onChange={handleChange}
                        onBlur={handleBlur}/>

                    <PromptTemplateEditorTextInput
                        label={"Key"}
                        name={"key"}
                        readonly={true}
                        placeholder={"(Automatically Generated)"}
                        value={CommonUtil.getOrDefault(formData, "key", "")}/>

                    <PromptTemplateEditorTextInput
                        type={"textarea"}
                        label={"* Prompt Template"}
                        name={"content"}
                        value={CommonUtil.getOrDefault(formData, "content", "")}
                        onChange={handleChange}
                        onBlur={handleBlur}/>

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="text" className="text-right middle">System properties</label>
                        </div>
                        <div className={"large-9 small-9 cell"}>
                            <button
                                className="button primary"
                                type="button"
                                onClick={showSystemPropertiesModal}
                            >
                                Show properties
                            </button>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="text" className="text-right middle">Custom properties</label>
                        </div>
                        <div className={"large-9 small-9 cell"}>
                            <button
                                className="button primary"
                                type="button"
                                onClick={showCustomPropertiesModal}
                            >
                                Show properties
                            </button>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="text" className="text-right middle">Custom properties</label>
                        </div>
                        <div className={"large-9 small-9 cell"}>
                            <button
                                className="button primary"
                                type="button"
                                onClick={showKnowledgeDocumentsModal}
                            >
                                Show documents
                            </button>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="text" className="text-right middle">Knowledge documents</label>
                        </div>
                        <div className={"large-9 small-9 cell"}>
                            <button
                                className="button primary"
                                type="button"
                                onClick={showKnowledgeDocumentsModal}
                            >
                                Show conversations
                            </button>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="text" className="text-right middle">Conversations</label>
                        </div>
                        <div className={"large-9 small-9 cell"}>
                            <button
                                className="button primary"
                                type="button"
                                onClick={showConversationsModal}
                            >
                                Show conversations
                            </button>
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="text" className="text-right middle">Prompt Templates</label>
                        </div>
                        <div className={"large-9 small-9 cell"}>
                            <button
                                className="button primary"
                                type="button"
                                onClick={showPromptTemplateModal}
                            >
                                Show templates
                            </button>
                        </div>
                    </div>

                    {errorMessageElem}

                </div>

                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                {
                                    <React.Fragment>
                                        <button
                                            className="button secondary"
                                            type="button"
                                            onClick={() => handleCallback("submit")}
                                        >
                                            Cancel
                                        </button>

                                        <button
                                            className="button success"
                                            type="submit"
                                            disabled={!validateForm()}
                                        >
                                            Submit
                                        </button>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <Modal
                classNames={{modal: "modal-medium"}}
                open={modalOpen}
                modalId="PromptTemplateModal"
                onClose={modalDidDismiss}
                center
            >
                {modalContent}
            </Modal>
        </>
    )

}

const PromptTemplateEditorTextInput = (props) => {

    const {label} = props;
    const {name} = props;
    const {type} = props;
    const {value} = props;
    const {error} = props;
    const {onChange} = props;
    const {onBlur} = props;
    const {readonly} = props;
    const {placeholder} = props;

    let inputElem = (
        <input
            type={type ? type : "text"}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            readOnly={readonly}
            placeholder={placeholder}
        />
    )

    if (type === "textarea") {
        inputElem = (
            <textarea
                name={name}
                value={value}
                rows={10}
                onChange={onChange}
                onBlur={onBlur}
                readOnly={readonly}
                placeholder={placeholder}
            />
        );
    }

    return (
        <div className="grid-x grid-padding-x">
            <div className="small-3 cell">
                <label htmlFor="name" className="text-right middle ">{label}</label>
            </div>
            <div className={"small-9 cell " + (error && "error")}>
                {inputElem}

                <small className="error">{error}</small>
            </div>
        </div>
    )

}