import React from 'react';
import ReactTable from 'react-table'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchBar from 'components/common/SearchBar'
import DatatableHelper from 'utils/DatatableHelper'
import { NotificationManager } from 'react-notifications'
import Modal from 'react-responsive-modal'
import AdminUserForm from 'components/adminUsers/AdminUserForm'
import { Colors, Sizes, ButtonGroup, Button} from 'react-foundation'
import TableContainer from 'components/common/TableContainer'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ReactSelect from 'components/common/ReactSelect'
import StandardModal from 'components/common/StandardModal'
import DateHelper from 'utils/DateHelper'

class AdminUsersTable extends AppComponent {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            searchKey: "",
            organisations: [],
            organisationOptions: [],
            page: 0,
            pageSize: 10,
            sorted: [{id: "id", "desc": true}],
            totalPages: -1,
            isLoading: true,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            },
            filtersAreOpen: false
        };

        this.searchTimer = null;
    }

    componentDidMount() {
        if (this.context.permissions.adminUser.allOrgs) {
            this.getOrganisationOptions();
        }
    }

    searchTextDidChange(newValue) {
        this.setState({
            searchKey: newValue || ""
        }, () => {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.fetchItems();
            }, 1000);
        });
    }

    handleChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "organisations") {

            if (value.length === this.state.organisationOptions.length) {
                value = [];
            }

            this.setState({
                organisations: value
            }, () => {
                this.fetchItems();
            });
        }
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    fetchItems = () => {

        this.setState({
            loading: true
        });

        Axios
            .post(API.adminUser.getUsersDT, {
                    page: this.state.page,
                    pageSize: this.state.pageSize,
                    sorted: this.state.sorted,
                    filters: {
                        search: this.state.searchKey,
                        organisations: this.state.organisations
                    }
                }
            )
            .then((res) => {
                this.setState({
                    items: res.data.data.rows,
                    totalPages: DatatableHelper.getTotalPages(res.data.data),
                    isLoading: false
                })
            })
    }

    handleAddNewItem = () => {
        this.showItemModal({});
    }


    handleEditItem = (item) => {
        this.showItemModal(item);
    }

    showItemModal = (item) => {
        if (!this.state.modal.isOpen) {
            const content = (
                <AdminUserForm
                    item={item}
                    onSubmitted={() => this.closeModal(this.fetchItems)}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null,
                    isUploading: false
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    showDeleteModal = (item) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                   title="Delete Admin User"
                   content={(
                       <div>Are you sure to delete <strong>{item.firstName} {item.lastName}</strong> user ? This action cannot be reverted.</div>
                   )}
                   buttons={(
                       <React.Fragment>
                           <button className="button secondary" type="button" onClick={this.closeModal}>
                               Cancel
                           </button>
                           <button className="button alert" type="button" onClick={() => this.handleDeleteItem(item)}>
                               Delete
                           </button>
                       </React.Fragment>
                   )}
                   isUploading={this.state.modal.isUploading}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }

    handleDeleteItem = (item) => {

        if (!this.state.modal.isUploading) {
            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isUploading: true
                })
            }, () => {
                Axios
                    .post(API.adminUser.deleteUser, {
                        userID: item.id
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.closeModal(() => {
                                this.fetchItems();
                                NotificationManager.success("User deleted");
                            });
                        }
                        else {
                            this.closeModal(() => {
                                NotificationManager.error(res.data.error.desc);
                            });
                        }
                    })
                    .catch(error => {
                        this.closeModal(() => {
                            NotificationManager.error(error.response);
                        });
                    });
            })
        }
    }

    render() {

        let columns = [
            {
                Header: 'ID',
                accessor: 'id',
                width: 60
            },
            {
                Header: 'First Name',
                accessor: 'firstName',
                width: 120
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
                width: 120
            },
            {
                Header: 'Email',
                accessor: 'email',
                minWidth: 250
            },
            {
                Header: 'Organisation',
                accessor: 'organisationName',
                minWidth: 120,
                show: this.context.permissions.adminUser.allOrgs
            },
            {
                Header: 'Role',
                accessor: 'roleName',
                width: 100
            },
            {
                Header: 'Last Access',
                accessor: 'lastAccess',
                width: 180,
                Cell: row => {
                    return row.value ? DateHelper.convertToLocalDateTime(row.value) : "";
                }
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                width: 180,
                Cell: row => {
                    return DateHelper.convertToLocalDateTime(row.value);
                }
            },
            {
                Header: "Status",
                accessor: 'active',
                width: 120,
                Cell: row => (
                    row.original.active ?
                        <span className="label success">active</span> :
                        <span className="label alert">suspended</span>
                )
            },
            {
                Header: 'Actions',
                Cell: row => (
                    <ButtonGroup size={Sizes.TINY}>
                        {this.context.permissions.adminUser.edit && this.context.user.id !== row.original.id &&
                        <Button color={Colors.PRIMARY} onClick={() => this.handleEditItem(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                        }
                        {this.context.permissions.adminUser.delete && this.context.user.id !== row.original.id &&
                        <Button color={Colors.ALERT} onClick={() => this.showDeleteModal(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                        }
                    </ButtonGroup>
                ),
                maxWidth: 100,
                sortable: false,
                show: this.context.permissions.adminUser.delete || this.context.permissions.adminUser.edit
            }
        ];

        const filters = (
            <React.Fragment>
                <div className="cell small-12 medium-6 large-3">
                    <span>Search</span>
                    <SearchBar
                        onDidChange={(newValue) => this.searchTextDidChange(newValue)}
                    />
                </div>
                {this.context.permissions.adminUser.allOrgs &&
                <div className="cell small-12 medium-6 large-3">
                    <span>Organisations</span>
                    <ReactSelect
                        name="organisations"
                        onChange={this.handleChange}
                        value={this.state.organisations}
                        options={this.state.organisationOptions}
                        isMulti={true}
                        placeholder="All"
                        className="react-select"
                    />
                </div>
                }
            </React.Fragment>
        );

        const buttons = (
            <React.Fragment>
                {this.context.permissions.adminUser.create &&
                <button type="button" className="button small" onClick={this.handleAddNewItem}>Add New User</button>
                }
            </React.Fragment>
        );

        const table = (
            <ReactTable
                columns={columns}
                data={this.state.items}
                pages={this.state.totalPages}
                pageSize={this.state.pageSize}
                loading={this.state.isLoading}
                multisort={true}
                manual
                className='-striped'
                onFetchData={(state) => {
                    this.setState({
                        page: state.page,
                        pageSize: state.pageSize,
                        sorted: state.sorted
                    }, () => {
                        this.fetchItems()
                    })
                }}
            />
        )

        return (

        <React.Fragment>
            <TableContainer
                title="Admin Users table"
                buttons={buttons}
                filters={filters}
                table={table}
            />
            <Modal
                classNames={{modal:"modal-medium"}}
                open={this.state.modal.isOpen}
                modalId="adminUsersModal"
                onClose={this.closeModal}
                center
            >
                {this.state.modal.content}
            </Modal>
        </React.Fragment>
        );
    }
}

export default AdminUsersTable;