import React from 'react';
import { Link } from 'react-router-dom'

class Breadcrumb extends React.Component {

    render() {
        return (
            <div className="cell small-12 page-head">
                {this.props.links.map((link) => {
                    if (link.url) {
                        return (
                            <Link key={link.id} to={link.url}>
                                <h2>{link.title}</h2>
                            </Link>
                        );
                    }
                    else {
                        return (
                            <h2 key={link.id}>{link.title}</h2>
                        )
                    }
                })}
            </div>
        );
    }
}

export default Breadcrumb;