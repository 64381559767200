import React from 'react'
import Axios from 'axios'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'
import moment from 'moment'
import DateHelper from 'utils/DateHelper'
import ChatVariablesSelector from "./ChatVariablesSelector";
import ChatCustomVariablesSelector from "./ChatCustomVariablesSelector";
import Modal from "react-responsive-modal";

const MESSAGE_MAX_LENGTH = 140;
const TITLE_MAX_LENGTH = 30;
const NAME_MAX_LENGTH = 500;

export default class ChatNotificationForm extends AppComponent {


    constructor(props, context) {
        super(props);

        this.state = {
            item: Object.assign({
                createdAt: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                name: null,
                title: null,
                message: null
            }, props.item),
            validated: false,
            messageCharLeft: MESSAGE_MAX_LENGTH,
            titleCharLeft: TITLE_MAX_LENGTH,
            nameCharLeft: NAME_MAX_LENGTH,
            errors: {},
            touched: {},
            isUploading: false,
            modal: {
                isOpen: false,
                content: null
            }
        };
    }

    componentDidMount() {
        this.validate();
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {

                let formData = new FormData();


                if (this.state.item.id) {
                    formData.append("id", this.state.item.id);
                }
                formData.append("name", this.state.item.name);
                formData.append("title", this.state.item.title);
                formData.append("message", this.state.item.message);

                Axios
                    .post((this.state.item.id ? API.chat.updateNotification : API.chat.createNotification), formData)
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted(res.data.data.item);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }

                        this.setState({
                            isUploading: false
                        });
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        let newState = {};

        let item = Object.assign({}, this.state.item);

        if (name === "title") {
            if (value.length > TITLE_MAX_LENGTH) {
                value = value.substring(0, TITLE_MAX_LENGTH - 1);
            }
            newState.titleCharLeft = TITLE_MAX_LENGTH - value.length;
        }
        else if (name === "message") {
            if (value.length > MESSAGE_MAX_LENGTH) {
                value = value.substring(0, MESSAGE_MAX_LENGTH - 1);
            }
            newState.messageCharLeft = MESSAGE_MAX_LENGTH - value.length;
        }
        if (name === "name") {
            if (value.length > NAME_MAX_LENGTH) {
                value = value.substring(0, NAME_MAX_LENGTH - 1);
            }
            newState.nameCharLeft = NAME_MAX_LENGTH - value.length;
        }

        item[name] = value;
        newState.item = item;


        this.setState(newState, () => {
            this.validate()
        });
    }

    showVariablesSelector = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatVariablesSelector
                showCopyButton={true}
                showSelectButton={false}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={() => this.closeModal()}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    showCustomVariablesSelector = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatCustomVariablesSelector
                showCopyButton={true}
                showSelectButton={false}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={() => this.closeModal()}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }


    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null,
                    isUploading: false
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }




    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.name) {
            if (touched.name) {
                errors.name = "Name is required";
            }
            validated = false;
        }

        if (!item.message) {
            if (touched.message) {
                errors.message = "Message is required";
            }
            validated = false;
        }


        this.setState({
            validated: validated,
            errors: errors
        });
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />
                <div className="modal-head">
                    <h3>{this.state.item.id ? "Edit Notification" : "New Notification"}</h3>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="createdAt" className="text-right middle">Created At</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <input name="createdAt" type="text" value={moment(DateHelper.convertToLocalDateTime(this.state.item.createdAt), "YYYY-MM-DD HH:mm:ss").format("LLLL")} disabled={true} />
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="name" className="text-right middle ">* Name</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.name && "error")}>
                                <input type="text" name="name" onChange={this.handleChange} value={this.state.item.name} onBlur={this.handleBlur}/>
                                <small className="field-info">{this.state.nameCharLeft} characters left</small>
                                <small className="field-info">Name is used internally.</small>
                                <small className="error">{this.state.errors.name}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="title" className="text-right middle ">Title</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.title && "error")}>
                                <input type="text" name="title" onChange={this.handleChange} value={this.state.item.title} onBlur={this.handleBlur} />
                                <small className="field-info">{this.state.titleCharLeft} characters left</small>
                                <small className="field-info">Title is send to users in the notification. It is optional. Title accepts system and custom properties.</small>
                                <small className="error">{this.state.errors.title}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="message" className="text-right middle">* Message</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.message && "error")}>
                                <textarea rows="6" type="text" name="message" onChange={this.handleChange} value={this.state.item.message} onBlur={this.handleBlur} />
                                <small className="field-info">{this.state.messageCharLeft} characters left</small>
                                <small className="field-info">Message is send to users in the notification. It is mandatory. Message accepts system and custom properties.</small>
                                <small className="error">{this.state.errors.message}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">System properties</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                <button className="button primary" type="button" onClick={this.showVariablesSelector}>Show properties</button>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="text" className="text-right middle">Custom properties</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                <button className="button primary" type="button" onClick={this.showCustomVariablesSelector}>Show properties</button>
                            </div>
                        </div>
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatNotificationInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}
