import React from 'react'
import logoImage from 'assets/img/uniwellbeing-logo.png'
import Image from 'components/common/Image'
import Axios from 'axios'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import {Link, Redirect} from 'react-router-dom'
import validator from "validator"
import BlockUi from "react-block-ui"

class PasswordNotCompliant extends AppComponent {

    constructor(props) {
        super(props);

        this.state = {
            item: {
                email: props.email
            },
            validated: false,
            errors: {},
            touched: {},
            isUploading: false,
            submitted: false
        };
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {
                Axios
                    .post((API.auth.requestPasswordReset), this.state.item)
                    .then((res) => {
                        if (res.data.success === true) {
                            this.setState({
                                submitted: true,
                                isUploading: false
                            })
                        }
                        else {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors,
                                isUploading: false
                            });
                        }
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1:0) : target.value;
        const name = target.name;

        let item = this.state.item;
        item[name] = value;

        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (! item.email) {
            if (touched.email) {
                errors.email = "Please enter your username"
            }
            validated = false;
        }
        else {
            if (!validator.isEmail(item.email)) {
                if (touched.email) {
                    errors.email = "Invalid email address"
                }
                validated = false;
            }
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    render() {

        if (this.context.user !== null) return <Redirect to="/" />;

        if (this.state.redirect) return <Redirect to="/login/resetSend" />;

        return (
            <BlockUi tag="div" blocking={this.state.isUploading}>
                {this.state.submitted ?
                    <div className="login-wrapper">
                        <div className="login-box">
                            <div className="grid-container">
                                <div className="grid-x grid-padding-x">
                                    <div
                                        className="cell large-2 large-offset-5 medium-6 medium-offset-3 small-10 small-offset-1">
                                        <Image src={logoImage} alt="Uniwellbeing Logo" fit="contain"/>
                                    </div>
                                    <div className="cell large-4 large-offset-4 medium-8 medium-offset-2 small-12">
                                        <h1>Password reset</h1>
                                        <p>Ok, we just sent you an email message. Inside that message is a link you can use to
                                            change your password. The message should show up within a few seconds. If it doesn't
                                            check your spam or junk folder</p>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="cell large-4 large-offset-4 medium-8 medium-offset-2 small-12">
                                        <Link to="/login" className="button">Back to login screen</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="login-wrapper">
                        <div className="login-box">
                            <div className="grid-container">
                                <div className="grid-x grid-padding-x">
                                    <div className="cell large-2 large-offset-5 medium-6 medium-offset-3 small-10 small-offset-1">
                                        <Image src={logoImage} alt="Uniwellbeing Logo" fit="contain"/>
                                    </div>
                                    <div className="cell large-4 large-offset-4 medium-8 medium-offset-2 small-12">
                                        <h1>Password Change</h1>
                                        <p>Your password no longer complies with our Password Policy and needs to be updated. Please request a password reset link below and follow the instructions in the email you will receive.</p>
                                    </div>
                                </div>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="grid-x grid-padding-x">
                                        <div className={"cell large-4 large-offset-4 medium-8 medium-offset-2 small-12 " + (this.state.errors.username && "error")}>
                                            <label htmlFor="email">
                                                Email
                                                <input type="email" name="email" placeholder="Email" onChange={this.handleChange} onBlur={this.handleBlur} value={this.state.item.email}/>
                                                <small className="error">{this.state.errors.username}</small>
                                            </label>
                                        </div>
                                        <div className="cell small-12">
                                            <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                                                <div className="cell small-12">
                                                    <span>{this.state.errors.genericError}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cell large-4 large-offset-4 medium-8 medium-offset-2 small-12">
                                            <button className="button" type="submit" value="Submit" >Request password reset email</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            </BlockUi>
        );
    }

}

export default PasswordNotCompliant;