import ConfigsTable from 'components/sectionConfig/ConfigsTable'
import React from 'react'
import PageContent from 'components/common/PageContent'
import { SectionType } from 'constants/SectionType'
import AppComponent from 'components/common/AppComponent'

const pathElements = [
    {id: 0, title: "Home", url: "/"},
    {id: 1, title: "Support"}
];

export default class Support extends AppComponent {

    render() {
        return (
            <PageContent pathElements={pathElements} >
                <ConfigsTable
                    permissions={this.context.permissions.support}
                    sectionType={SectionType.SUPPORT}
                />
            </PageContent>
        );
    }
}