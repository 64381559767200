import React from 'react'
import EditorHelper from 'components/contentEditor/EditorHelper'
import Utils from 'utils/Utils'

export default class Button extends React.Component {

    render() {

        const {item, defaults, style, innerRef, ...props} = this.props;

        const placeholder = Utils.value(Utils.getNested(this.props.item, "data", "placeholder"), "");

        const leftText = Utils.value(Utils.getNested(this.props.item, "data", "leftText"), "");
        const hasLeftLabel = leftText.length > 0;

        const fieldWidth = hasLeftLabel ? "80%" : "100%";

        return (
            <div
                ref={innerRef}
                style={Object.assign(style,{
                    paddingLeft: EditorHelper.getMargin(item, defaults, "left") * this.props.scale,
                    paddingRight: EditorHelper.getMargin(item, defaults, "right") * this.props.scale,
                    paddingBottom: EditorHelper.getMargin(item, defaults, "bottom") * this.props.scale,
                    paddingTop: EditorHelper.getMargin(item, defaults, "top") * this.props.scale,
                    display: "block"
                })}

                {...props}
            >
                {hasLeftLabel &&
                    <div
                        style={{
                            fontFamily: "Barlow Condensed, sans-serif",
                            fontSize: 20 * this.props.scale,
                            color: "#575757",
                            height: 50 * this.props.scale,
                            paddingTop: 12 * this.props.scale,
                            marginRight: 10,
                            textAlign: "left",
                            display: "block",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            float: "left"
                        }}
                    >
                        {leftText}
                    </div>
                }
                <div
                    style={{
                        fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                        fontSize: 20 * this.props.scale,
                        color: "rgb(193 193 193)",
                        height: 50 * this.props.scale,
                        paddingTop: 12 * this.props.scale,
                        textAlign: "left",
                        borderBottom: "1px solid #575757",
                        lineHeight: "normal",
                        display: "block",
                        fontStyle: "italic",
                        verticalAlign: "top",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                    }}
                >
                    {placeholder}
                </div>
            </div>
        )
    }

}