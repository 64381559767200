import React, {useEffect, useState} from 'react'
import Axios from 'axios'
import ReactSelect from 'components/common/ReactSelect'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'
import moment from 'moment'
import DateHelper from 'utils/DateHelper'
import {PermissionUtil} from "../../../utils/PermissionUtil";
import {CommonUtil} from "../../../utils/CommonUtil";
import {ContextUtil} from "../../../utils/ContextUtil";
import {AIIntegration} from "../../../utils/AIIntegration";
import {Toast} from "../../../utils/ToastUtil";
import {NotificationManager} from "react-notifications";

export const AccessKeysForm = (props) => {

    const {itemId} = props;
    const {organisationId} = props;

    const {editable} = props;

    const typeOptions = [
        {
            value : AIIntegration.TYPES.AWS_BEDROCK,
            label : "AWS Bedrock AI"
        },
        {
            value : AIIntegration.TYPES.CHATGPT,
            label : "ChatGPT",
        }
    ];

    const [item, setItem] = useState(Object.assign({
        createdAt: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
        organisationID: ContextUtil.getOrganisation().id,
        typeID : null,
        data : {},
        active: 1,
    }))

    const [organisationOptions, setOrganisationOptions] = useState([]);

    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [loading, setLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        if (itemId) {
            fetchAccessKeyFromNetwork();
        }

        validate();

        if (PermissionUtil.allOrgs(PermissionUtil.PermissionCategories.aiAccessKeys)) {
            getOrganisationOptions();
        }
    }, []);

    useEffect(() => {
        validate();
    }, [item]);

    function fetchAccessKeyFromNetwork() {
        if (loading) return;
        setLoading(true);

        const data = {
            id : itemId,
            organisationID : organisationId
        };

        Axios.post(API.aiAccessKey.getAiAccessKey, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.accessKey.data) {
                        if (!CommonUtil.isObject(resp.data.accessKey.data)) {
                            resp.data.accessKey.data = JSON.parse(resp.data.accessKey.data);
                        }
                        setItem(resp.data.accessKey);
                    }
                } else {
                    console.log(API.formatError(resp));
                    NotificationManager.error(API.formatError(resp));
                }

                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error("Failed to load Access Key. [C110]");
            });
    }

    function getOrganisationOptions() {
        Axios.get(API.organisation.getOrganisations, {
                props: {
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    setOrganisationOptions(res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} }));
                }
            })
            .catch((e) => {
                console.log(e);
            })
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (!isUploading) {
            setIsUploading(true);

            let outData = {
                organisationID : item.organisationID,
                typeID : item.typeID,
                data : JSON.stringify(item.data)
            };

            let endpoint = API.aiAccessKey.createAiAccessKey;
            if (item.id) {
                outData.id = item.id;
                endpoint = API.aiAccessKey.updateAiAccessKey;
            }

            Axios.post(endpoint, outData)
                .then((res) => {
                    if (res.data.success) {
                        if (props.onSubmitted) {
                            props.onSubmitted(res.data.data.item);
                        }
                    }
                    else if (res.data.error) {
                        let errors = CommonUtil.cloneObject(errors);
                        errors.genericError = res.data.error.desc;
                        setErrors(errors);
                    }

                    setIsUploading(false)
                })
                .catch((e) => {
                    console.log(e);
                    setIsUploading(false);
                })
        }
    }

    function handleBlur(event) {
        const target = event.target;
        const name = target.name;

        let nTouched = CommonUtil.cloneObject(touched);
        nTouched[name] = true;

        setTouched(nTouched);
    }

    function handleChange(event, useData) {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let nItem = CommonUtil.cloneObject(item);

        if (!useData) {
            nItem[name] = value;
        } else {
            if (!nItem.data) {
                nItem.data = {};
            }
            nItem.data[name] = value;
        }

        setItem(nItem);
    }

    function validate() {
        let nValidated = true;

        const requiredKeys = [
            "key", "secret"
        ];

        if (item.typeID === AIIntegration.TYPES.AWS_BEDROCK) {
            requiredKeys.push("region");
        }

        requiredKeys.forEach((key) => {
            if (!item.data.hasOwnProperty(key)) {
                nValidated = false;
            } else if (item.data[key].trim() === "") {
                nValidated = false;
            }
        });

        setValidated(nValidated);
    }

    // RENDER

    let organisationElem = [];
    if (PermissionUtil.allOrgs(PermissionUtil.PermissionCategories.aiAccessKeys)) {
        if (!item.id && editable) {
            organisationElem = (
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="organisationID" className="text-right middle">* Organisation</label>
                    </div>
                    <div className={"small-9 cell " + (errors.organisationID && "error")}>
                        <ReactSelect
                            name="organisationID"
                            className="react-select"
                            onChange={handleChange}
                            value={item.organisationID}
                            options={organisationOptions}
                            onBlur={handleBlur}
                        />
                        <small className="error">{errors.organisationID}</small>
                    </div>
                </div>
            )
        } else {
            organisationElem = (
                <div className="grid-x grid-padding-x">
                    <div className="small-3 cell">
                        <label htmlFor="organisationID" className="text-right middle">* Organisation</label>
                    </div>
                    <div className={"small-9 cell " + (errors.organisationID && "error")}>
                        <input
                            type={"text"}
                            value={item.organisationName}
                            readOnly={true}
                        />
                    </div>
                </div>
            )
        }
    }

    return (
        <React.Fragment>
            <ModalProgress show={isUploading}/>
            <ModalProgress show={loading} title={"Please wait..."}/>
            <div className="modal-head">
                <h3>{editable ? (item.id ? "Edit Access Key" : "New Access Key") : "Access Key"}</h3>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="modal-body">
                    {organisationElem}

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="typeID" className="text-right middle">* Type</label>
                        </div>
                        <div className={"small-9 cell " + (errors.typeID && "error")}>
                            { (!item.id && editable) &&
                                <>
                                    <ReactSelect
                                        name="typeID"
                                        className="react-select"
                                        onChange={handleChange}
                                        value={item.typeID}
                                        options={typeOptions}
                                        readOnly={!editable}
                                        onBlur={handleBlur}
                                    />
                                    <small className="error">{errors.typeID}</small>
                                </>
                            }

                            { (item.id || !editable) &&
                                <input
                                    type={"text"}
                                    value={item.typeName}
                                    readOnly={true}
                                />
                            }
                        </div>
                    </div>

                    {item.typeID === AIIntegration.TYPES.AWS_BEDROCK &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="key" className="text-right middle ">
                                        <div>* Key</div>
                                        <small>Key for the IAM role</small>
                                    </label>
                                </div>
                                <div className="small-9 cell">
                                    <input
                                        type="text"
                                        name="key"
                                        onChange={(e) => handleChange(e, true)}
                                        value={item.data.key}
                                        readOnly={!editable}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </div>

                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="secret" className="text-right middle">
                                        <div>* Secret</div>
                                        <small>Secret for the IAM role</small>
                                    </label>
                                </div>
                                <div className="small-9 cell">
                                    <input
                                        type="text"
                                        name="secret"
                                        onChange={(e) => handleChange(e, true)}
                                        value={item.data.secret}
                                        readOnly={!editable}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </div>

                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="region" className="text-right middle">
                                        <div>* Region</div>
                                        <small>Region name for the IAM role and services</small>
                                    </label>
                                </div>
                                <div className="small-9 cell">
                                    <input
                                        type="text"
                                        name="region"
                                        onChange={(e) => handleChange(e, true)}
                                        value={item.data.region}
                                        readOnly={!editable}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    }

                    {item.typeID === AIIntegration.TYPES.CHATGPT &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="key" className="text-right middle">
                                        <div>* API Key</div>
                                        <small>Key for API access</small>
                                    </label>
                                </div>
                                <div className="small-9 cell">
                                    <input
                                        type="text"
                                        name="key"
                                        onChange={(e) => handleChange(e, true)}
                                        value={item.data.key}
                                        readOnly={!editable}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </div>

                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="secret" className="text-right middle">
                                        <div>* Secret</div>
                                        <small>Secret for API access</small>
                                    </label>
                                </div>
                                <div className="small-9 cell">
                                    <input
                                        type="text"
                                        name="secret"
                                        onChange={(e) => handleChange(e, true)}
                                        value={item.data.secret}
                                        readOnly={!editable}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
                {editable &&
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </form>
        </React.Fragment>
    );
}
