export const CommonUtil = {
    getOrDefault : (obj, keyName, defaultValue, allowNulls) => {
        if (allowNulls === undefined) {
            allowNulls = false;
        }

        if (obj !== undefined && obj !== null && typeof obj === 'object') {
            if (obj.hasOwnProperty(keyName)) {
                if ((!allowNulls && obj[keyName] != null) || allowNulls) {
                    return obj[keyName];
                }
            }
        }
        return defaultValue;
    },
    getStringOrDefault : (obj, keyName, defaultValue) => {
        const value = CommonUtil.getOrDefault(obj, keyName, defaultValue);
        if (value && value !== "") {
            return value;
        }
        return defaultValue;
    },
    cloneObject : (object) => {
        return Object.assign({}, object)
    },
    isObject : (object) => {
        return typeof(object) === "object";
    },
    mergeObjects : (o1, o2) => {
        let newFormData = CommonUtil.cloneObject(o1);

        Object.keys(o2).forEach((key) => {
            if (key !== "step") {
                newFormData[key] = o2[key];
            }
        });

        return newFormData;
    },
    getGoogleMapsLink : (addressParts) => {
        let searchQuery = "";
        if (Array.isArray(addressParts)) {
            addressParts.forEach((part) => {
                if (part !== undefined) {
                    if (searchQuery !== "") {
                        searchQuery += "+";
                    }
                    searchQuery += encodeURIComponent(part.toLowerCase().replaceAll(" ", "+"));
                }
            });
        } else {
            searchQuery = encodeURIComponent(addressParts.replaceAll(" ", "+"));
        }

        return "https://www.google.com/maps/search/" + searchQuery + "/";
    },
    implode : (data, delimiter) => {
        if (delimiter === undefined) {
            delimiter = ", ";
        }

        let out = "";

        for (let i = 0; i < data.length; i++) {
            if (data[i] !== null && data[i] !== "") {
                if (out !== "") {
                    out += delimiter;
                }
                out += data[i];
            }
        }

        return out;
    },
    objectToFormData : (object) => {
        let out = new FormData();

        let keys = Object.keys(object);

        keys.forEach((key) => {
            if (object[key] !== null && object[key] !== undefined) {
                out.append(key, object[key]);
            }
        });

        return out;
    },
    toUint8Array : (input) => {
        const out = [];
        (new Uint8Array(input)).forEach((v) => {
            out.push(v);
        });
        return out;
    }
}