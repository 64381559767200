import React from 'react'
import PageContent from 'components/common/PageContent'
import AppComponent from 'components/common/AppComponent'
import ChatImport from 'components/chatflows/ChatImport'

const pathElements = [
    {id: 0, title: "Home", url: "/"},
    {id: 1, title: "Chat Flows", url: "/chatflows"},
    {id: 2, title: "Chat Flows Import"}
];

export default class ChatImportPage extends AppComponent {

    render() {
        return (
            <PageContent pathElements={pathElements} >
                <ChatImport />
            </PageContent>
        );
    }
}