import React from 'react'
import Select from 'react-select';

class ReactSelect extends React.Component {

    handleChange = (option, el) => {
        if (this.props.onChange instanceof Function) {

            let value = null;

            if (option) {
                if (this.props.isMulti) {
                    value = option.map((o) => o.value);
                }
                else {
                    value = option.value;
                }
            }

            this.props.onChange({
                target: {
                    type: "select",
                    name: el.name,
                    value: value
                }
            });
        }
    }

    convertValue = (value) => {
        if (value === null || value === undefined || this.props.options === undefined) {
            return value;
        }
        else if (value instanceof Array) {
            return value.map((val) => this.props.options.find(o => ((o.value !== null) ? o.value.toString() : o.value) === ((val !== null) ? val.toString() : val)));
        }
        else if (typeof value === 'object') {
            return this.props.options.find(o => ((o.value !== null) ? o.value.toString() : o.value) === ((value !== null) ? value.value.toString() : value));
        }
        else {
            return this.props.options.find(o => {
                    return ((o.value !== null) ? o.value.toString() : o.value) === ((value !== null) ? value.toString() : value)
                }
            );
        }
    };

    render() {
        let { value, onChange, ...props } = this.props;
        value = this.convertValue(value);

        return (
            <Select value={value} onChange={this.handleChange} {...props} >
                {this.props.children}
            </Select>
        );
    }
}

export default ReactSelect;