import React from 'react'
import AppComponent from 'components/common/AppComponent'
import {Button, ButtonGroup, Colors} from "react-foundation"
import ReactSelect from "../common/ReactSelect"
import Axios from "axios";
import {API} from "../../api/APIClient"
import BlockUI from 'react-block-ui'
import { NotificationManager } from "react-notifications"
import ServicesSelector from "../services/ServicesSelector";
import Modal from "react-responsive-modal";
import { Redirect } from 'react-router-dom'

export default class WellbeingPlan extends AppComponent {

    constructor(props, context) {
        super(props);

        this.state = {
            item: {
                organisationID: context.organisation.id,
                habit1: null,
                habit2: null,
                habit3: null,
                habit4: null,
                podcast1: null,
                podcast2: null,
                podcast3: null,
                podcast4: null
            },
            initialItem: {
                organisationID: context.organisation.id,
                habit1: null,
                habit2: null,
                habit3: null,
                habit4: null,
                podcast1: null,
                podcast2: null,
                podcast3: null,
                podcast4: null
            },
            organisationOptions: [
                {value: context.organisation.id, label: context.organisation.name}
            ],
            redirect: null,
            habitOptions: [],
            podcastOptions: [],
            isLoadingHabits: false,
            isLoadingPodcasts: false,
            isLoadingPlan: false,
            isUploading: false,
            isLoadingSOSPage: false,
            errors: {},
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            }
        };
    }

    componentDidMount() {
        if (this.context.permissions.onboardingSetup.allOrgs) {
            this.getOrganisationOptions();
        }
        this.getHabits();
        this.getPodcasts();
        this.getWellBeingPlan();
        this.validate();
    }


    getWellBeingPlan = () => {
        if (!this.isLoadingPlan) {

            this.setState({
                isLoadingPlan: true
            }, () => {

                Axios
                    .get(API.onboarding.getWellbeingPlan, {
                        params: {
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingPlan: false,
                                item: res.data.data.plan,
                                initialItem: Object.assign({}, res.data.data.plan)
                            }, () => {
                                this.validate();
                            })
                        }
                        else {
                            this.setState({
                                isLoadingPlan: false
                            }, () => {
                                this.validate();
                            })
                        }
                    })

            })

        }
    }

    getPodcasts = () => {

        if (!this.state.isLoadingPodcasts) {

            this.setState({
                isLoadingPodcasts: true
            }, () => {

                Axios
                    .get(API.podcast.getPodcasts, {
                        params: {
                            organisationID: this.context.organisation.id
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingPodcasts: false,
                                podcastOptions: res.data.data.podcasts.map((item) => { return {value: item.id, label: item.title} })
                            }, () => {
                                this.validate();
                            })
                        }
                        else {
                            this.setState({
                                isLoadingPodcasts: false
                            }, () => {
                                this.validate();
                            })
                        }
                    })

            })

        }

    }

    getHabits = () => {

        if (!this.state.isLoadingHabits) {

            this.setState({
                isLoadingHabits: true
            }, () => {

                Axios
                    .get(API.habit.getHabits)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingHabits: false,
                                habitOptions: res.data.data.habits.map((item) => { return {value: item.id, label: item.name} })
                            }, () => {
                                this.validate();
                            })
                        }
                        else {
                            this.setState({
                                isLoadingHabits: false
                            }, () => {
                                this.validate();
                            })
                        }
                    })

            })

        }
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations, {
                props: {
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id, label: item.name} })
                    })
                }
            })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = this.state.item;
        item[name] = value;

        let change = {
            item: item
        };

        if (name === "organisationID") {
            change.item = {
                organisationID: value,
                habit1: null,
                habit2: null,
                habit3: null,
                habit4: null,
                podcast1: null,
                podcast2: null,
                podcast3: null,
                podcast4: null
            };

            change.habitOptions = [];
        }

        this.setState(change, () => {
            this.validate();
            if (name === "organisationID") {
                this.getHabits();
                this.getWellBeingPlan();
            }
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {
                Axios
                    .post((API.onboarding.updateWellbeingPlan), this.state.item)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isUploading: false,
                                initialItem: Object.assign({}, this.state.item)
                            }, () => {
                                NotificationManager.success("Saved successfully");
                                this.validate();
                            })
                        }
                        else {
                            this.setState({
                                isUploading: false
                            }, () => {
                                NotificationManager.error(res.data.error.desc);
                            })
                        }
                    })
            })
        }
    }

    validate = () => {

        let validated = true;

        const item = this.state.item;

        if (!item.habit1 ||
            !item.habit2 ||
            !item.habit3 ||
            !item.habit4 ||
            !item.podcast1 ||
            !item.podcast2 ||
            !item.podcast3 ||
            !item.podcast4) {

            validated = false;
        }

        const orgItem = this.state.initialItem;

        if (item.habit1 === orgItem.habit1 &&
            item.habit2 === orgItem.habit2 &&
            item.habit3 === orgItem.habit3 &&
            item.habit4 === orgItem.habit4 &&
            item.podcast1 === orgItem.podcast1 &&
            item.podcast2 === orgItem.podcast2 &&
            item.podcast3 === orgItem.podcast3 &&
            item.podcast4 === orgItem.podcast4) {

            validated = false;

        }


        this.setState({
            validated: validated
        });
    }


    render() {

        if (this.state.redirect) {
            return (<Redirect to="/organisation" />);
        }

        return (
            <BlockUI tag="div" blocking={this.isUploading}>
                <div className="gird-x grid-padding-x" style={{marginBottom: 100}}>
                    <div className="cell small-12" style={{margin: "20px 0"}}>
                        <div className="grid-x grid-padding-x">
                            {this.context.permissions.onboardingSetup.allOrgs &&
                            <div className="small-12 medium-6 large-3 cell ">
                                <span>Organisation</span>
                                <ReactSelect
                                    name="organisationID"
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.item.organisationID}
                                    options={this.state.organisationOptions}
                                    isDisabled={this.state.isLoadingHabits}
                                />
                            </div>
                            }
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset">
                                    <legend>WellBeing Initial Plan</legend>
                                    <BlockUI tag="div" blocking={this.state.isLoadingHabits || this.state.isLoadingPodcasts || this.state.isLoadingPlan}>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-12 medium-6 cell">
                                                <fieldset className="fieldset">
                                                    <legend>Habits</legend>
                                                    <small className="header">If user excercise more than once a week then habits 1 and 2 are recommended, otherwise the system uses habits 3 and 4.</small>
                                                    <div className="grid-x grid-padding-x">
                                                        <div className="small-3 cell">
                                                            <label htmlFor="habit1" className="text-right middle">* Habit 1</label>
                                                        </div>
                                                        <div className="small-9 cell">
                                                            <ReactSelect
                                                                name="habit1"
                                                                className="react-select"
                                                                onChange={this.handleChange}
                                                                value={this.state.item.habit1}
                                                                options={this.state.habitOptions}
                                                                onBlur={this.handleBlur}
                                                                isDisabled={!this.context.permissions.onboardingSetup.edit}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grid-x grid-padding-x">
                                                        <div className="small-3 cell">
                                                            <label htmlFor="habit2" className="text-right middle">* Habit 2</label>
                                                        </div>
                                                        <div className="small-9 cell ">
                                                            <ReactSelect
                                                                name="habit2"
                                                                className="react-select"
                                                                onChange={this.handleChange}
                                                                value={this.state.item.habit2}
                                                                options={this.state.habitOptions}
                                                                onBlur={this.handleBlur}
                                                                isDisabled={!this.context.permissions.onboardingSetup.edit}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grid-x grid-padding-x">
                                                        <div className="small-3 cell">
                                                            <label htmlFor="habit3" className="text-right middle">* Habit 3</label>
                                                        </div>
                                                        <div className="small-9 cell ">
                                                            <ReactSelect
                                                                name="habit3"
                                                                className="react-select"
                                                                onChange={this.handleChange}
                                                                value={this.state.item.habit3}
                                                                options={this.state.habitOptions}
                                                                onBlur={this.handleBlur}
                                                                isDisabled={!this.context.permissions.onboardingSetup.edit}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grid-x grid-padding-x">
                                                        <div className="small-3 cell">
                                                            <label htmlFor="habit4" className="text-right middle">* Habit 4</label>
                                                        </div>
                                                        <div className="small-9 cell ">
                                                            <ReactSelect
                                                                name="habit4"
                                                                className="react-select"
                                                                onChange={this.handleChange}
                                                                value={this.state.item.habit4}
                                                                options={this.state.habitOptions}
                                                                onBlur={this.handleBlur}
                                                                isDisabled={!this.context.permissions.onboardingSetup.edit}
                                                            />
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className="small-12 medium-6 cell">
                                                <fieldset className="fieldset">
                                                    <legend>Podcasts</legend>
                                                    <small className="header">If user excercise more than once a week then podcasts 1, 2 and 3 are recommended, otherwise the system uses podcasts 1, 2 and 4.</small>
                                                    <div className="grid-x grid-padding-x">
                                                        <div className="small-3 cell">
                                                            <label htmlFor="podcast1" className="text-right middle">* Podcast 1</label>
                                                        </div>
                                                        <div className="small-9 cell ">
                                                            <ReactSelect
                                                                name="podcast1"
                                                                className="react-select"
                                                                onChange={this.handleChange}
                                                                value={this.state.item.podcast1}
                                                                options={this.state.podcastOptions}
                                                                onBlur={this.handleBlur}
                                                                isDisabled={!this.context.permissions.onboardingSetup.edit}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grid-x grid-padding-x">
                                                        <div className="small-3 cell">
                                                            <label htmlFor="podcast2" className="text-right middle">* Podcast 2</label>
                                                        </div>
                                                        <div className="small-9 cell ">
                                                            <ReactSelect
                                                                name="podcast2"
                                                                className="react-select"
                                                                onChange={this.handleChange}
                                                                value={this.state.item.podcast2}
                                                                options={this.state.podcastOptions}
                                                                onBlur={this.handleBlur}
                                                                isDisabled={!this.context.permissions.onboardingSetup.edit}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grid-x grid-padding-x">
                                                        <div className="small-3 cell">
                                                            <label htmlFor="podcast3" className="text-right middle">* Podcast 3</label>
                                                        </div>
                                                        <div className="small-9 cell ">
                                                            <ReactSelect
                                                                name="podcast3"
                                                                className="react-select"
                                                                onChange={this.handleChange}
                                                                value={this.state.item.podcast3}
                                                                options={this.state.podcastOptions}
                                                                onBlur={this.handleBlur}
                                                                isDisabled={!this.context.permissions.onboardingSetup.edit}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grid-x grid-padding-x">
                                                        <div className="small-3 cell">
                                                            <label htmlFor="podcast4" className="text-right middle">* Podcast 4</label>
                                                        </div>
                                                        <div className="small-9 cell ">
                                                            <ReactSelect
                                                                name="podcast4"
                                                                className="react-select"
                                                                onChange={this.handleChange}
                                                                value={this.state.item.podcast4}
                                                                options={this.state.podcastOptions}
                                                                onBlur={this.handleBlur}
                                                                isDisabled={!this.context.permissions.onboardingSetup.edit}
                                                            />
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </BlockUI>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>

                { this.context.permissions.onboardingSetup.edit &&
                <div className="fix-save">
                    <ButtonGroup>
                        <Button className="edit-button" onClick={() => this.setState({redirect: true})}>Cancel</Button>
                        <Button className="edit-button" color={Colors.SUCCESS} disabled={!this.state.validated} onClick={this.handleSubmit} >Save</Button>
                    </ButtonGroup>
                </div>
                }
            </BlockUI>
        )
    }

}


