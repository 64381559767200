import React from 'react'
import Axios from 'axios'
import { API } from 'api/APIClient'
import Image from 'components/common/Image'
import FileInput from "../common/FileInput";
import Placeholder from "assets/img/placeholder.png"
import UUID from "../../utils/UUID";
import {ChatItemType} from "../../constants/ChatItemType";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import {TranslationEditor, TranslationEditorHelper} from "../common/TranslationEditor";
import Modal from "react-responsive-modal";
import ReactSelect from "../common/ReactSelect";
import ReactTable from "react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CommonUtil} from "../../utils/CommonUtil";
import {ChatTextFieldFormTranslations} from "./ChatTextFieldFormTranslations";
import {ChatImageFormTranslations} from "./ChatImageFormTranslations";
import {TranslationUtil} from "../../utils/TranslationUtil";
import StandardModal from "../common/StandardModal";

class ChatImageForm extends React.Component {

    static defaultProps = {
        item: {},
        onSubmitted: () => void 0
    }

    constructor(props) {
        super(props);

        console.log(props);

        this.state = {
            item: Object.assign({
                imageURL: null,
                imageID: null,
                altText: null,
                typeText: "Media",
                useCustomDisplayTime: null,
                displayTime: 0,
                useCustomTypingTime: null,
                typingTime: 0,
                typeID: ChatItemType.IMAGE,
                uuid: UUID.uuidv4()
            }, props.node),
            validated: false,
            errors: {},
            touched: {},
            imagePickerURL: null,
            imagePickerFile: null,
            isUploading: false,
            modal: {
                isOpen: false,
                content: null
            }
        };
    }

    componentDidMount() {
        if (this.state.item && this.state.item.translations) {
            this.processTranslations();
        }

        TranslationUtil.fetchChatLanguages((action, data) => {
            if (action === "success") {
                this.setState({
                    languageOptions : data
                });
            } else {
                console.log(data);
            }
        });

        this.validate();
    }

    handleFileDelete = (name) => {
        this.setState({
            imagePickerFile: null,
            imagePickerURL: null
        }, () => {
            this.validate()
        })
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        let self = this;

        reader.onloadend = () => {
            self.setState({
                imagePickerFile: file,
                imagePickerURL: reader.result
            }, () => {
                self.validate()
            })
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        console.log(name);

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = Object.assign({}, this.state.item);
        item[name] = value;

        if (name === "useCustomDisplayTime") {
            item.displayTime = 0;
        }

        if (name === "useCustomTypingTime") {
            item.typingTime = 0;
        }

        this.setState({
            item: item
        }, () => {
            this.validate();
        })
    }


    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.item.imageID && !this.state.imagePickerFile) {
            this.updateImageOverNetwork();
        } else {
            this.uploadImageOverNetwork();
        }
    }

    uploadImageOverNetwork = () => {
        if (!this.state.isUploading) {
            this.setState({
                isUploading: true
            });

            let formData = new FormData();

            formData.append("typeID", 23); // Chat flow image
            formData.append("altText", this.state.item.altText);

            if (this.state.imagePickerFile) {
                formData.append("file", this.state.imagePickerFile);
            }

            Axios
                .post(API.image.uploadImage, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        let newItem = Object.assign({}, this.state.item);
                        newItem.imageID = res.data.data.imageID;
                        newItem.imageURL = res.data.data.imageURL;
                        newItem.nodeText = "Image";
                        newItem.typeText = "Media";
                        this.props.onSubmitted(newItem);
                    }
                    else if (res.data.error) {
                        let errors = this.state.errors;
                        errors.genericError = res.data.error.desc;
                        this.setState({
                            errors: errors
                        });
                    }
                    this.setState({
                        isUploading: false
                    })
                })
        }
    }

    updateImageOverNetwork = () => {
        if (!this.state.isUploading) {
            this.setState({
                isUploading: true
            });

            let formData = new FormData();
            formData.append("id", this.state.item.imageID);
            formData.append("altText", this.state.item.altText);

            Axios.post(API.image.updateImage, formData)
                .then((r) => {
                    let errors = this.state.errors;

                    const resp = API.parse(r);
                    if (resp.success) {
                        let newItem = Object.assign({}, this.state.item);
                        newItem.imageID = resp.data.imageID;
                        newItem.imageURL = resp.data.imageURL;
                        newItem.nodeText = "Image";
                        newItem.typeText = "Media";
                        this.props.onSubmitted(newItem);
                    } else {
                        errors.genericError = resp.error.desc;
                    }

                    this.setState({
                        isUploading: false,
                        errors
                    });
                })
                .catch((e) => {
                    console.log(e);

                    const errors = this.state.errors;
                    errors.genericError = "An unknown error has occurred.";

                    this.setState({
                        isUploading : false,
                        errors
                    });
                })
        }
    }

    validate = () => {
        let validated = true;
        if (!this.state.item.imageURL) {
            validated = this.state.imagePickerURL !== null;
        }

        this.setState({
            validated: validated
        });
    }

    processTranslations = () => {
        const translationRows = [];
        Object.keys(this.state.item.translations).forEach((code, index) => {
            let rowData = CommonUtil.cloneObject(
                this.state.item.translations[code]
            );
            rowData.id = index;
            rowData.code = code;
            translationRows.push(rowData);
        });
        this.setState({
            translations : translationRows
        });
    }

    showTranslationEditor = (languageCode) => {
        const content = (
            <ChatImageFormTranslations
                languageCode={languageCode}
                item={this.state.item}
                onSubmit={() => {
                    this.closeModal();
                    this.processTranslations();
                }}
                onCancel={this.closeModal}
            />
        )

        this.setState({
            modal : {
                isOpen: true,
                content,
                size : "modal-medium"
            }
        });
    }

    closeModal = () => {
        this.setState({
            modal : {
                isOpen: false,
                content: []
            }
        });
    }

    lookupLanguage = (languageCode) => {
        let out = languageCode;
        if (this.state.languageOptions) {
            for (let i = 0; i < this.state.languageOptions.length; i++) {
                if (this.state.languageOptions[i].value === languageCode) {
                    out = this.state.languageOptions[i].label;
                    break;
                }
            }
        }
        return out;
    }

    showDeleteTranslation = (languageCode) => {
        let languageName = this.lookupLanguage(languageCode);

        const content = (
            <StandardModal
                title="Delete Translation"
                content={(
                    <React.Fragment>
                        <p>Are you sure to delete the <strong>{languageName}</strong> translation? This action cannot be reverted.</p>
                    </React.Fragment>
                )}
                buttons={(
                    <React.Fragment>
                        <button className="button secondary" type="button" onClick={this.closeModal}>
                            Cancel
                        </button>
                        <button className="button alert" type="button" onClick={() => this.handleDeleteTranslation(languageCode)}>
                            Delete
                        </button>
                    </React.Fragment>
                )}
            />
        );

        this.setState({
            modal: Object.assign({}, this.state.modal, {
                isOpen: true,
                size: "modal-medium",
                isUploading: false,
                content: content
            })
        })
    }

    handleDeleteTranslation = (languageCode) => {
        if (this.state.item.translations) {
            if (this.state.item.translations.hasOwnProperty(languageCode)) {
                delete this.state.item.translations[languageCode];
            }

            this.setState({
                item : this.state.item
            }, () => {
                this.processTranslations();
                this.closeModal();
            });
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="modblock">
                    <h2>Saving</h2>
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>

                <div className="modal-head">
                    <h3>Edit Image</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="imageFile" className="text-right middle">Change Image</label>
                            </div>
                            <div className="large-9 small-9 cell ">
                                <FileInput
                                    name="file"
                                    onChange={this.handleFilePickerChange}
                                    onBlur={this.handleBlur}
                                    onDelete={this.handleFileDelete}
                                    placeholder="Not selected."
                                    accept="image/jpeg,image/jpg,image/png"
                                />
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="imageFile" className="text-right middle">Current Image</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.imageFile ? "error" : "")}
                                 style={{marginBottom: 20}}>
                                {this.state.imagePickerURL &&
                                    <React.Fragment>
                                        <Image src={this.state.imagePickerURL} width={200} height={200} border={true}
                                               style={{marginRight: 10}}/>
                                        <Image src={this.state.imagePickerURL} width={200} height={100} border={true}/>
                                    </React.Fragment>
                                }
                                {!this.state.imagePickerURL && this.state.item.imageURL &&
                                    <React.Fragment>
                                        <Image src={this.state.item.imageURL} width={200} height={200} border={true}
                                               placeholder={Placeholder} style={{marginRight: 10}}/>
                                        <Image src={this.state.item.imageURL} width={200} height={100} border={true}
                                               placeholder={Placeholder}/>
                                    </React.Fragment>
                                }
                                {!this.state.imagePickerURL && !this.state.item.imageURL &&
                                    <p>No image selected</p>
                                }
                                <small className="error">{this.state.errors.imageFile}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="altText" className="text-right middle">
                                    Alt text
                                </label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <textarea rows={5} name="altText" value={this.state.item.altText}
                                          onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <small className="field-info">Image's description used by Voice Over reader in the
                                    app</small>
                                <small className="error">{this.state.errors["altText"]}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="useCustomDisplayTime" className="text-right middle">Custom display
                                    time</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="useCustomDisplayTime"
                                           onChange={this.handleChange}
                                           checked={this.state.item.useCustomDisplayTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.useCustomDisplayTime === 1 &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="displayTime" className="text-right middle">* Display
                                            Time</label>
                                    </div>
                                    <div
                                        className={"large-9 small-9 cell " + (this.state.errors.displayTime && "error")}>
                                        <input name="displayTime" type="number" value={this.state.item.displayTime}
                                               onBlur={this.handleBlur} onChange={this.handleChange}/>
                                        <span className="error">{this.state.errors.displayTime}</span>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="useCustomTypingTime" className="text-right middle">Custom typing
                                    time</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="useCustomTypingTime"
                                           onChange={this.handleChange}
                                           checked={this.state.item.useCustomTypingTime}/>
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.item.useCustomTypingTime === 1 &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="typing" className="text-right middle">* Typing Time</label>
                                    </div>
                                    <div
                                        className={"large-9 small-9 cell " + (this.state.errors.typingTime && "error")}>
                                        <input name="typingTime" type="number" value={this.state.item.typingTime}
                                               onBlur={this.handleBlur} onChange={this.handleChange}/>
                                        <span className="error">{this.state.errors.typingTime}</span>
                                    </div>
                                </div>
                            </React.Fragment>
                        }

                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset">
                                    <legend>Translations</legend>
                                    <div className="grid-x grid-padding-x">
                                        <div className="cell small-12">
                                            <span>Add translation for</span>
                                            <ReactSelect
                                                name="selectedLanguage"
                                                className="react-select translation-selector"
                                                onChange={(e) => this.setState({selectedLanguage: e.target.value})}
                                                value={this.state.selectedLanguage}
                                                options={this.state.languageOptions}
                                                onBlur={this.handleBlur}
                                            />

                                            <Button
                                                type="button"
                                                className="translation-button"
                                                color={Colors.PRIMARY}
                                                isDisabled={!this.state.selectedLanguage}
                                                onClick={() => this.showTranslationEditor(this.state.selectedLanguage)}>
                                                Add
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="grid-x grid-padding-x">
                                        <div className="cell small-12">
                                            <ReactTable
                                                columns={[
                                                    {
                                                        Header: 'Language',
                                                        accessor: 'code'
                                                    },
                                                    {
                                                        Header: 'Actions',
                                                        Cell: row => (
                                                            <ButtonGroup size={Sizes.TINY}>
                                                                {true && // this.context.permissions.chatFlows.edit
                                                                    <Button
                                                                        type="button"
                                                                        color={Colors.PRIMARY}
                                                                        onClick={() => this.showTranslationEditor(row.original.code)}>
                                                                        <FontAwesomeIcon icon="edit"/>
                                                                    </Button>
                                                                }
                                                                {true && // this.context.permissions.chatFlows.delete
                                                                    <Button
                                                                        type="button"
                                                                        color={Colors.ALERT}
                                                                        onClick={() => this.showDeleteTranslation(row.original.code)}>
                                                                        <FontAwesomeIcon icon="trash"/>
                                                                    </Button>
                                                                }
                                                            </ButtonGroup>
                                                        ),
                                                        maxWidth: 100,
                                                        show: true //this.context.permissions.chatFlows.delete || this.context.permissions.chatFlows.edit
                                                    }
                                                ]}
                                                data={this.state.translations}
                                                className='-striped'
                                                showPagination={false}
                                                noDataText={"No translations"}
                                                pageSize={this.state.translations ? this.state.translations.length : 1}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        <div className="form-error-msg grid-x grid-padding-x"
                             style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">

                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>

            </React.Fragment>
        );
    }
}

export default ChatImageForm;
