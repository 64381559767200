import React, {useEffect, useState} from 'react';
import {CommonUtil} from "../../utils/CommonUtil";

export const ChatAnimationFormTranslations = (props) => {

    const {languageCode} = props;
    const {item} = props;
    const {onSubmit} = props;
    const {onCancel} = props;

    const [translations, setTranslations] = useState({});

    useEffect(() => {
        if (item) {
            if (item.translations) {
                setTranslations(item.translations[languageCode]);
            }
        }
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        if (!item.translations) {
            item.translations = {};
        }

        item.translations[languageCode] = translations;

        if (onSubmit) {
            onSubmit();
        }
    }

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.value;

        const newTranslations = CommonUtil.cloneObject(translations);
        if (value.trim() !== "") {
            newTranslations[name] = value;
        } else {
            delete newTranslations[name];
        }
        setTranslations(newTranslations);
    }

    function getTranslation(id) {
        if (translations) {
            if (translations.hasOwnProperty(id)) {
                return translations[id];
            }
        }
        return "";
    }

    // RENDER

    return (
        <>
            <div className="modal-head">
                <h3>Translations</h3>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="modal-body translation-editor">
                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="aiReturnResponse" className="text-right middle">
                                Alt text
                            </label>
                        </div>
                        <div className="small-9 cell">
                            <textarea
                                rows={5}
                                name={"altText"}
                                value={getTranslation("altText")}
                                placeholder={item.altText}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x">
                        <div className="small-3 cell">
                            <label htmlFor="aiReturnResponse" className="text-right middle">
                                SVG Code
                            </label>
                        </div>
                        <div className="small-9 cell">
                            <textarea
                                rows={5}
                                name={"code"}
                                value={getTranslation("code")}
                                placeholder={item.code}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                <button
                                    className="button secondary"
                                    type="button"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </button>

                                <button
                                    className="button success"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}