import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactExport from "react-export-excel";
import ReactTable from "react-table";
import jsPDF from "jspdf";
import 'jspdf-autotable'


export default class ReportTable extends React.Component {

    exportPDF = () => {

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = this.props.title;
        const headers = [["DATE", "UNITS"]];

        const data = this.props.data.map(i=> [i.label, i.value]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Report.pdf");
    }

    render() {

        const columns = [
            {
                Header: 'Date',
                accessor: 'label'
            },
            {
                Header: 'Units',
                accessor: 'value',
                width: 100
            }
        ];

        return (

            <div className="panel-box">
                <div className="grid-x">
                    <div className="cell small-12 medium-6">
                        <h4>Details</h4>
                    </div>
                    <div className="cell small-12 medium-6">
                        <div className="btn-wrap">
                            <button type="button" className="button small hollow" style={{marginTop: 0}} onClick={this.exportPDF}>
                                <FontAwesomeIcon icon="file-pdf" />
                                &nbsp;PDF
                            </button>
                            <ReactExport.ExcelFile
                                element={
                                    <button type="button" className="button small hollow" style={{marginTop: 0}}>
                                        <FontAwesomeIcon icon="file-excel" />
                                        &nbsp;EXCEL
                                    </button>
                                }
                            >
                                <ReactExport.ExcelFile.ExcelSheet data={this.props.data} name={this.props.title}>
                                    <ReactExport.ExcelFile.ExcelColumn label="Date" value="label" />
                                    <ReactExport.ExcelFile.ExcelColumn label="Units" value="value" />
                                </ReactExport.ExcelFile.ExcelSheet>
                            </ReactExport.ExcelFile>
                        </div>
                    </div>
                </div>
                <ReactTable
                    columns={columns}
                    data={this.props.data}
                    loading={this.props.isLoadingData}
                    multisort={true}
                    defaultPageSize={10}
                    className='-striped'
                />
            </div>
        )
    }

}