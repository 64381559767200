import React from 'react'
import Axios from 'axios'
import moment from 'moment'
import { API } from 'api/APIClient'
import Image from 'components/common/Image'
import ReactSelect from 'components/common/ReactSelect'
import DatePicker from 'components/common/DatePicker'
import DateHelper from 'utils/DateHelper'

class MoneyOfferForm extends React.Component {

    static defaultProps = {
        item: {},
        onSubmitted: () => void 0
    }

    constructor(props) {
        super(props);

        this.state = {
            item: Object.assign({
                buttonType: "nothing",
                buttonTitle: "",
                url: "",
                appStoreURL: "",
                googlePlayURL: "",
                createdAt: moment.utc().format("YYYY-MM-DD HH:mm:ss")
            }, props.item),
            validated: false,
            errors: {},
            touched: {},
            imagePickerURL: null,
            imagePickerFile: null,
            isUploading: false,
            buttonTypes: [
                {value:"nothing", label:"Nothing"},
                {value:"appLink", label:"App link"},
                {value:"website", label:"Website"}
            ]
        };
    }

    componentDidMount() {
        this.validate();

        if (this.state.item.id) {
            this.getContent();
        }
    }

    getContent = () => {
        Axios
            .get(API.money.getOfferContent, {
                params: {
                    id: this.state.item.id
                }
            })
            .then((res) => {
                if (res.data.success) {

                    let content = res.data.data.content;

                    if (content.elements && content.elements instanceof Array) {
                        let header = "";
                        let subheader = "";
                        let text = [];
                        let button = null;

                        content.elements.forEach((el) => {
                            if (el.key === "header" && el.type === "label" && el.data && el.data.text) {
                                header = el.data.text;
                            }
                            else if (el.key === "subheader" && el.type === "label" && el.data && el.data.text) {
                                subheader = el.data.text
                            }
                            else if (el.key === "dealButton" && el.type === "button" && el.data) {
                                button = el.data;
                            }
                            else if (el.type === "label" && el.data && el.data.text) {
                                text.push(el.data.text)
                            }
                        });

                        text = text.join("\n\n");

                        let item = this.state.item;
                        item.header = header;
                        item.subheader = subheader;
                        item.content = text;

                        if (button) {
                            item.buttonType = button.action.type;
                            item.buttonTitle = button.title;
                            item.url = button.action.data.url || "";
                            item.appStoreURL = button.action.data.appStoreURL || "";
                            item.googlePlayURL = button.action.data.googlePlayURL || "";
                        }

                        this.setState({
                            item: item
                        })
                    }
                }
            });
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        let self = this;

        reader.onloadend = () => {
            self.setState({
                imagePickerFile: file,
                imagePickerURL: reader.result
            }, () => {
                self.validate()
            })
        };

        reader.readAsDataURL(file);
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        console.log(name);

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {

        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if ((name === "startDate" || name === "endDate") && value) {
            value = DateHelper.convertFromLocalToUTCDateTime(value);
        }

        let item = this.state.item;
        item[name] = value;

        if (name === "buttonType") {
            item.appStoreURL = "";
            item.googlePlayURL = "";
            item.url = "";
            if (value === "nothing") {
                item.buttonTitle = "";
            }
        }

        this.setState({
            item: item
        }, () => {
            this.validate();
            console.log(this.state.item);
        });
    }


    handleSubmit = (event) => {
        event.preventDefault();

        if (!this.state.isUploading) {
            this.setState({
                isUploading: true
            });

            let formData = new FormData();

            if (this.state.item.id) {
                formData.append("id", this.state.item.id);
            }

            formData.append("title", this.state.item.title);
            formData.append("active", this.state.item.active ? 1 : 0);
            formData.append("content", this.state.item.content);
            formData.append("header", this.state.item.header);
            formData.append("subheader", this.state.item.subheader);
            formData.append("startDate", this.state.item.startDate);
            formData.append("endDate", this.state.item.endDate);
            formData.append("linkButton", this.state.item.buttonType);

            if (this.state.item.buttonType === "website") {
                formData.append("buttonTitle", this.state.item.buttonTitle);
                formData.append("url", this.state.item.url);
            }
            else if (this.state.item.buttonType === "appLink") {
                formData.append("buttonTitle", this.state.item.buttonTitle);
                formData.append("appStoreURL", this.state.item.appStoreURL);
                formData.append("googlePlayURL", this.state.item.googlePlayURL);
            }

            if (this.state.imagePickerFile) {
                formData.append("file", this.state.imagePickerFile);
            }

            Axios
                .post((this.state.item.id ? API.money.updateOffer : API.money.createOffer), formData, {
                    headers: {
                        'Content-Type': formData.has('file') ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        this.props.onSubmitted(res.data.data.post);
                    }
                    else if (res.data.error) {
                        let errors = this.state.errors;
                        errors.genericError = res.data.error.desc;
                        this.setState({
                            errors: errors
                        });
                    }
                    this.setState({
                        isUploading: false
                    })
                })
        }
    }


    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.content) {
            if (touched.content) {
                errors.content = "Content is required";
            }
            validated = false;
        }

        if (!item.title) {
            if (touched.title) {
                errors.title = "Caption text is required";
            }
            validated = false;
        }

        if (item.buttonType !== "nothing" && !item.buttonTitle) {
            if (touched.buttonTitle) {
                errors.buttonTitle = "Button title is required";
            }
            validated = false;
        }

        if (item.buttonType === "website" && !item.url) {
            if (touched.url) {
                errors.url = "Website url is required if `Website` button is selected";
            }
            validated = false;
        }

        if (item.buttonType === "appLink") {
            if (!item.appStoreURL) {
                if (touched.appStoreURL) {
                    errors.appStoreURL = "App Store application URL is required if `App Link` button is selected";
                }
                validated = false;
            }
            if (!item.googlePlayURL) {
                if (touched.googlePlayURL) {
                    errors.googlePlayURL = "Google Play Store application URL is required if `App Link` button is selected";
                }
                validated = false;
            }
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    render() {

        return (
            <React.Fragment>
                <div className="modblock">
                    <h2>Saving</h2>
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>

                <div className="modal-head">
                    <h3>{this.state.item.id ? "Edit Offer" : "New Offer"}</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="active" className="text-right middle">Active</label>
                            </div>
                            <div className="large-4 small-9 cell">
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="active" onChange={this.handleChange} checked={this.state.item.active || 0 === 1} />
                                    <span className="chkmk"></span>
                                </label>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="createdAt" className="text-right middle">Created At</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <input name="createdAt" type="text" value={moment(DateHelper.convertToLocalDateTime(this.state.item.createdAt), "YYYY-MM-DD HH:mm:ss").format("LLLL")} disabled={true} />
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="title" className="text-right middle">* Caption</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.title && "error")}>
                                <input name="title" type="text" value={this.state.item.title} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <span className="error">{this.state.errors.title}</span>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="startDate" className="text-right middle">* Start Date</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.startDate && "error")}>
                                <DatePicker
                                    name="startDate"
                                    data-enable-time
                                    value={DateHelper.convertToLocalDateTime(this.state.item.startDate)}
                                    onChange={this.handleChange}
                                    options={{
                                        dateFormat: "Y-m-d H:i:S",
                                        altFormat: "l, F j, Y h:i K",
                                        altInput: true,
                                        static: true
                                    }}
                                />
                                <small className="error">{this.state.errors.startDate}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="endDate" className="text-right middle">* End Date</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.endDate && "error")}>
                                <DatePicker
                                    name="endDate"
                                    data-enable-time
                                    value={DateHelper.convertToLocalDateTime(this.state.item.endDate)}
                                    onChange={this.handleChange}
                                    options={{
                                        dateFormat: "Y-m-d H:i:S",
                                        altFormat: "l, F j, Y h:i K",
                                        altInput: true,
                                        static: true
                                    }}
                                />
                                <small className="error">{this.state.errors.endDate}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="imageFile" className="text-right middle">Select Image</label>
                            </div>
                            <div className="large-9 small-9 cell ">
                                <input name="imageFile" type="file" onChange={this.handleFilePickerChange} onBlur={this.handleBlur} />
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="imageFile" className="text-right middle">* Current Image</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.imageFile ? "error" : "")} style={{marginBottom: 20}}>
                                {this.state.imagePickerURL &&
                                <Image src={this.state.imagePickerURL} width={200} height={120} border={true} />
                                }
                                {!this.state.imagePickerURL && this.state.item.headerImageURL &&
                                <Image src={this.state.item.headerImageURL} width={200} height={120} border={true} />
                                }
                                {!this.state.imagePickerURL && !this.state.item.headerImageURL &&
                                <p>No image selected</p>
                                }
                                <small className="error">{this.state.errors.imageFile}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="header" className="text-right middle">Header</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.header && "error")}>
                                <input name="header" type="text" value={this.state.item.header} onBlur={this.handleBlur} onChange={this.handleChange} />
                                <span className="error">{this.state.errors.header}</span>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="subheader" className="text-right middle">Sub-Header</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.subheader && "error")}>
                                <input name="subheader" type="text" value={this.state.item.subheader} onBlur={this.handleBlur} onChange={this.handleChange} />
                                <span className="error">{this.state.errors.subheader}</span>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="content" className={"text-right middle " + (this.state.errors.content && "error")}>* Content</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <textarea rows="9" type="text" name="content" onChange={this.handleChange} value={this.state.item.content} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.content}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="buttonType" className="text-right middle">Link Button</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <ReactSelect
                                    name="buttonType"
                                    onChange={this.handleChange}
                                    value={this.state.item.buttonType}
                                    options={this.state.buttonTypes}
                                    className="react-select"
                                />
                            </div>
                        </div>
                        {(this.state.item.buttonType === "website" || this.state.item.buttonType === "appLink") &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="buttonTitle" className="text-right middle">Button Title</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.buttonTitle && "error")}>
                                <input name="buttonTitle" type="text" value={this.state.item.buttonTitle} onBlur={this.handleBlur} onChange={this.handleChange} />
                                <span className="error">{this.state.errors.buttonTitle}</span>
                            </div>
                        </div>
                        }
                        {this.state.item.buttonType === "website" &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="url" className="text-right middle">Website URL</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.url && "error")}>
                                <input
                                    name="url"
                                    type="text"
                                    value={this.state.item.url}
                                    onBlur={this.handleBlur}
                                    onChange={this.handleChange}
                                />
                                <span className="error">{this.state.errors.url}</span>
                            </div>
                        </div>
                        }
                        {this.state.item.buttonType === "appLink" &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="appStoreURL" className="text-right middle">AppStore URL</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.appStoreURL && "error")}>
                                    <input
                                        name="appStoreURL"
                                        type="text"
                                        value={this.state.item.appStoreURL}
                                        onBlur={this.handleBlur}
                                        onChange={this.handleChange}
                                    />
                                    <span className="error">{this.state.errors.appStoreURL}</span>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="googlePlayURL" className="text-right middle">Google Play URL</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.googlePlayURL && "error")}>
                                    <input
                                        name="googlePlayURL"
                                        type="text"
                                        value={this.state.item.googlePlayURL}
                                        onBlur={this.handleBlur}
                                        onChange={this.handleChange}
                                    />
                                    <span className="error">{this.state.errors.googlePlayURL}</span>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">

                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </React.Fragment>
        );
    }
}

export default MoneyOfferForm;
