import React from 'react'
import ReactTable from 'react-table'
import SearchBar from 'components/common/SearchBar'
import TableContainer from 'components/common/TableContainer'
import AppComponent from 'components/common/AppComponent'

export default class CourseImportTable extends AppComponent {


    searchTextDidChange(newValue) {
        this.setState({
            searchKey: newValue || ""
        }, () => {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {

            }, 1000);
        });
    }

    render() {

        const columns = [];

        const headers = this.props.headers;
        const selectedColumn = this.props.selectedColumn;

        for(let index = 0; index<headers.length; index++) {
            columns.push({
                Header: headers[index],
                id: index.toString(),
                accessor: row => row[index] || "",
                headerClassName: selectedColumn !== null && selectedColumn !== undefined && parseInt(selectedColumn) === index ? "selected-table-cell" : "",
                className: selectedColumn !== null && selectedColumn !== undefined && parseInt(selectedColumn) === index ? "selected-table-cell" : ""
            });
        }

        const table = (
            <ReactTable
                data={this.props.items}
                columns={columns}
                defaultPageSize={5}
                className='-striped'
            />
        );

        const filters = (
            <React.Fragment>
                <div className="cell small-12 medium-6 large-3">
                    <span>Search</span>
                    <SearchBar
                        onDidChange={(newValue) => this.searchTextDidChange(newValue)}
                    />
                </div>
            </React.Fragment>
        )

        return (
            <React.Fragment>
                <TableContainer
                    title="Found data"
                    filters={filters}
                    table={table}
                />
            </React.Fragment>
        );
    }
}