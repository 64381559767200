import React from 'react'
import logoImage from 'assets/img/uniwellbeing-logo.png'
import Image from 'components/common/Image'
import Axios from 'axios'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import { Redirect, Link } from 'react-router-dom'
import validator from 'validator'
import BlockUi from "react-block-ui";
import PasswordNotCompliant from "./PasswordNotCompliant";

class Login extends AppComponent {

    constructor(props) {
        super(props);

        console.log("Login mounted");

        this.state = {
            item: {
                username: "",
                password: ""
            },
            validated: false,
            errors: {},
            touched: {},
            isUploading: false,
            showNoCompliant: false
        };
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {
                Axios
                    .post((API.auth.login), this.state.item)
                    .then((res) => {
                        if (res.data.success === true) {
                            if (res.data.data.needsNewPassword) {
                                this.setState({
                                    showNoCompliant: true
                                })
                            }
                            else {
                                this.context.login(res.data.data);
                            }
                        }
                        else {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors,
                                isUploading: false
                            });
                        }
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1:0) : target.value;
        const name = target.name;

        let item = this.state.item;
        item[name] = value;

        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (! item.username) {
            if (touched.username) {
                errors.username = "Please enter your username"
            }
            validated = false;
        }
        else {
            if (!validator.isEmail(item.username)) {
                if (touched.username) {
                    errors.username = "Invalid email address"
                }
                validated = false;
            }
        }

        if (! item.password) {
            if (touched.password) {
                errors.password = "Please enter your password"
            }
            validated = false;
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    render() {

        if (this.context.user !== null) return <Redirect to="/" />;

        if (this.state.showNoCompliant) return <PasswordNotCompliant email={this.state.item.username} />

        return (
            <BlockUi tag="div" blocking={this.state.isUploading}>
                <div className="login-wrapper">
                    <div className="login-box">
                        <div className="grid-container">
                            <div className="grid-x grid-padding-x">
                                <div className="cell large-2 large-offset-5 medium-6 medium-offset-3 small-10 small-offset-1">
                                    <Image src={logoImage} alt="Uniwellbeing Logo" fit="contain"/>
                                </div>
                                <div className="cell large-4 large-offset-4 medium-8 medium-offset-2 small-12">
                                    <h1>Admin Login</h1>
                                </div>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="grid-x grid-padding-x">
                                    <div className={"cell large-4 large-offset-4 medium-8 medium-offset-2 small-12 " + (this.state.errors.username && "error")}>
                                        <label htmlFor="username">
                                            Email
                                            <input type="email" name="username" placeholder="Email" onChange={this.handleChange} onBlur={this.handleBlur} />
                                            <small className="error">{this.state.errors.username}</small>
                                        </label>
                                    </div>
                                    <div className={"cell large-4 large-offset-4 medium-8 medium-offset-2 small-12 " + (this.state.errors.password && "error")}>
                                        <label htmlFor="password">Password
                                            <input type="password" name="password" placeholder="Password" onChange={this.handleChange} onBlur={this.handleBlur} />
                                            <small className="error">{this.state.errors.password}</small>
                                        </label>
                                    </div>
                                    <div className="cell small-12">
                                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                                            <div className="cell small-12">
                                                <span>{this.state.errors.genericError}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cell large-4 large-offset-4 medium-8 medium-offset-2 small-12">
                                        <button className="button" type="submit" value="Submit" >Login</button>
                                    </div>
                                    <div className="cell large-4 large-offset-4 medium-8 medium-offset-2 small-12">
                                        <Link to="/login/forgottenPassword" className="forgot">Forgotten Password</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </BlockUi>
        );
    }

}

export default Login;