
const AxiosHelper = {
    getUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
        if (totalLength !== null) {
            return (Math.round( (progressEvent.loaded * 100) / totalLength ));
        }
        else {
            return 1.0
        }
    }
}

export default AxiosHelper;