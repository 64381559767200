import React from 'react'
import AutosizeInput from 'react-input-autosize';


export default class TreeNodeInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            value: props.value
        }
    }

    static getDerivedStateFromProps(props, prevState) {
        if (props.value !== prevState.value && !prevState.isEditing) {
            return {
                value: props.value
            };
        }
        return null;
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'Esc') {
            this.input.blur();
        }
    }

    handleOnBlur = (e) => {
        if (this.state.isEditing) {
            const value = e.target.value;
            if (value !== this.props.value) {
                this.setState({
                    value: value
                }, () => {
                    if (this.props.onChange instanceof Function) {
                        this.props.onChange(value);
                    }
                })
            }
            this.setState({
                isEditing: false
            });
        }
    }

    handleOnChange = (e) => {
        let value = e.target.value;
        this.setState({
            value: value
        })
    }

    startEditing = () => {
        if (!this.state.isEditing) {
            this.setState({
                isEditing: true
            }, () => {
                this.input.focus();
            })
        }
    };

    render() {
       return (
           <React.Fragment>
               {this.state.isEditing ?
                   <AutosizeInput
                       ref={(input) => this.input = input}
                       className={this.props.className}
                       type="text"
                       onChange={this.handleOnChange}
                       value={this.state.value}
                       onBlur={this.handleOnBlur}
                       onKeyDown={this.handleKeyDown}
                   /> :
                   this.props.value ?
                   <p className={this.props.className} onClick={this.startEditing}>{this.props.value}</p> :
                       <p className={this.props.className + " item-placeholder"} onClick={this.startEditing}>{this.props.placeholder}</p>
               }
           </React.Fragment>
       )
    }
}