import React from 'react'
import PageContent from 'components/common/PageContent'
import CampusesTable from 'components/organisation/campuses/CampusesTable'

export default function Campuses(props) {

    const pathElements = [
        {id: 0, title: "Home", url: "/"},
        {id: 1, title: "Organisation", url: "/organisation"},
        {id: 2, title: "Campuses"}
    ];

    return (
        <PageContent pathElements={pathElements}>
            <CampusesTable/>
        </PageContent>
    )
}