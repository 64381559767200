import React from 'react'
import { AppContext } from 'contexts/AppContext'
import { APIHelper, API } from 'api/APIClient'
import axios from 'axios'
import Axios from "axios";
import {ContextUtil} from "../utils/ContextUtil";

const localStorageSessionKey = "uw.localstorage.sessiondata";

export default class AppProvider extends React.Component {

    state = {
        user: null,
        organisation: null,
        permissions: null,
        authToken: null,
        menu: []
    }

    componentDidMount() {
        let session = sessionStorage.getItem(localStorageSessionKey);
        if (session) {
            session = JSON.parse(session);
            this.setState(session);

            // CADE 2024/04/03 - Init Context Util here as well.
            ContextUtil.init(session);
        }
    }

    componentDidUpdate() {
        sessionStorage.setItem(localStorageSessionKey, JSON.stringify(this.state));
        if (this.state.authToken) {
            APIHelper.setAuthToken(this.state.authToken);
            APIHelper.setOrganisationID(this.state.organisation.id);
            APIHelper.onAuthFailed(this.logout)
        }
        else {
            APIHelper.removeAuthToken();
            APIHelper.removeOrganisationID();
        }
    }

    login = (data) => {
        if (data.user && data.organisation && data.permissions && data.authToken) {
            // CADE 2024-04-03 - Mirror Context data in a static state so we can
            // read from within functionised components.
            ContextUtil.init(data);

            this.setState({
                user: data.user,
                organisation: data.organisation,
                permissions: data.permissions,
                authToken: data.authToken,
                menu: data.menu
            });
        }
    }

    logout = () => {
        Axios
            .post(API.auth.logout)
            .then((res) => {
                this.setState({
                    user: null,
                    organisation: null,
                    permissions: null,
                    authToken: null,
                    menu: []
                }, () => {
                    APIHelper.removeAuthToken();
                    APIHelper.removeOrganisationID();
                });
            })
    }

    // apiClient = (function(that){
    //     return axios.create({
    //         baseURL: "http://10.128.0.152:8999/cmsapi/",
    //         headers: {
    //             "AuthToken": that.state.authToken || ""
    //         },
    //         validateStatus: (status) => {
    //             if (status === 401 || status === 403) {
    //                 that.logout();
    //             }
    //             else {
    //                 return status;
    //             }
    //         }
    //     });
    // })(this);

    render() {
        return (
            <AppContext.Provider
                value={{
                    user: this.state.user,
                    organisation: this.state.organisation,
                    permissions: this.state.permissions,
                    menu: this.state.menu,
                    login: this.login,
                    logout: this.logout
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        )
    }

}