import React from 'react'
import PageContent from 'components/common/PageContent'
import AppComponent from 'components/common/AppComponent'
import ChatTriggers from 'components/chatflows/ChatTriggers'

const pathElements = [
    {id: 0, title: "Home", url: "/"},
    {id: 1, title: "Chat Flows", url: "/chatflows"},
    {id: 2, title: "Chat Triggers"}
];

export default class ChatTriggersPage extends AppComponent {

    render() {
        return (
            <PageContent pathElements={pathElements} >
                <ChatTriggers />
            </PageContent>
        );
    }
}