import PodcastsTable from 'components/podcasts/PodcastsTable'
import React from 'react'
import PageContent from 'components/common/PageContent'

export default function Podcasts(props) {
    const pathElements = [
        {id: 0, title: "Home", url: "/"},
        {id: 1, title: "Podcasts"}
    ];

    return (
        <PageContent pathElements={pathElements} >
            <PodcastsTable />
        </PageContent>
    );
}