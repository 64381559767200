import React from 'react'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'
import ServicesTable from 'components/services/ServicesTable'

export default class ServicesSelector extends AppComponent {

    constructor(props) {
        super(props);

        this.state = {
            selected: this.props.selected || [],
            selectAll: false,
            isUploading: false,
            errors: {}
        }
    }


    handleSelectedRow = (item) => {
        let selected = Array.from(this.state.selected);

        const index = selected.findIndex(i => {
            console.log(i);
            return i.id === item.id;
        });

        if (index > -1) {
            selected.splice(index,1)
        }
        else {
            if (this.props.isSingleSelection) {
                selected = [];
            }
            selected.push(item);
        }

        this.setState({
            selected: selected,
            selectAll: false
        });
    }

    handleSubmit = () => {
        if (this.props.onSubmitted instanceof Function) {
            let selected = this.state.selected;
            selected.forEach(o => o.noChildren = true);

            if (this.props.isSingleSelection) {
                if (selected.length > 0) {
                    this.props.onSubmitted(selected[0]);
                }
                else {
                    this.props.onSubmitted(undefined);
                }
            }
            else {
                this.props.onSubmitted(selected);
            }
        }
    }

    handleSelectAll = (all, items) => {
        if (all) {
            const selected = Array.from(this.state.selected);
            selected.push(...items);
            this.setState({
                selected: selected,
                selectAll: all
            });
        }
        else {
            let selected = Array.from(this.state.selected);
            items.forEach(item => {
                const index = selected.findIndex(i => i.id === item.id);
                if (index > -1) {
                    selected.splice(index,1);
                }
            });
            this.setState({
                selected: selected,
                selectAll: all
            });
        }
    }

    render() {

        let submitDisabled = this.state.selected.length === 0;

        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />

                <div className="modal-head">
                    <h3>Add Service</h3>
                </div>
                <div className="modal-body">
                    <ServicesTable
                        organisationID={this.props.organisationID}
                        serviceTypes={this.props.serviceTypes}
                        selected={this.state.selected}
                        selectAll={this.state.selectAll}
                        onSelectedRow={this.handleSelectedRow}
                        onSelectedAll={this.handleSelectAll}
                        onDidFetch={() => this.setState({selectAll: false})}
                        pageSize={5}
                        isSelector
                        isSingleSelection={this.props.isSingleSelection === true}
                    />
                    <p>Selected: {this.state.selected.length} {this.state.selected.length === 1 ? "row" : "rows"}</p>
                    <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                        <div className="cell small-12">
                            <span>{this.state.errors.genericError}</span>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <div className="btn-wrap">
                                <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                    Cancel
                                </button>
                                <button className="button success" type="submit" disabled={submitDisabled} onClick={this.handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}