import React from 'react'
import Axios from 'axios'
import ReactSelect from 'components/common/ReactSelect'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'
import moment from 'moment'
import { ServiceType } from "constants/ServiceType";
import DateHelper from 'utils/DateHelper'
import ChallengesSelector from 'components/challenges/ChallengesSelector'
import Modal from "react-responsive-modal";
import FileInput from "../common/FileInput";
import Image from "../common/Image";
import Placeholder from "../../assets/img/placeholder.png";
import PodcastsSelector from 'components/podcasts/PodcastsSelector';
import HabitsSelector from 'components/habits/HabitsSelector';
import AssessmentsSelector from 'components/common/AssessmentsSelector';
import UUID from "../../utils/UUID";
import StandardModal from "../common/StandardModal";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import ReactTable from "react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class ServiceForm extends AppComponent {

    static defaultProps = {
        item: {}
    }

    constructor(props, context) {
        super(props);

        this.state = {
            item: props.item.langID ? Object.assign({}, props.item) : Object.assign({
                langID: null,
                translations: [],
                createdAt: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                organisationID: context.organisation.id,
                organisationName: context.organisation.name,
                data: {},
                typeID: null
            }, props.item),
            organisationOptions: [
                props.item.organisationID ? {value: props.item.organisationID, label: props.item.organisationName} : {value: context.organisation.id, label: context.organisation.name}
            ],
            typeOptions: [
                {value: ServiceType.WEBSITE, label: ServiceType.getTitle(ServiceType.WEBSITE)}, //TODO that must come from server later
                {value: ServiceType.CHALLENGE, label: ServiceType.getTitle(ServiceType.CHALLENGE)},
                {value: ServiceType.PODCAST, label: ServiceType.getTitle(ServiceType.PODCAST)},
                {value: ServiceType.VIDEO, label: ServiceType.getTitle(ServiceType.VIDEO)},
                {value: ServiceType.HABIT, label: ServiceType.getTitle(ServiceType.HABIT)},
                {value: ServiceType.ASSESSMENT, label: ServiceType.getTitle(ServiceType.ASSESSMENT)},
                {value : ServiceType.SSO_LOGIN, label : ServiceType.getTitle(ServiceType.SSO_LOGIN)}
            ],
            validated: false,
            errors: {},
            touched: {},
            roleOptions: [],
            isUploading: false,
            imagePickerURL: null,
            imagePickerFile: null,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            },
            isLoadingTranslations: false,
            languageOptions: [],
            selectedLanguage: null
        };
    }

    componentDidMount() {
        this.validate();
        if (this.context.permissions.service.allOrgs) {
            this.getOrganisationOptions();
        }

        if (this.state.item.langID === null) {
            if (this.state.item.id) {
                this.getTranslations();
            }
            else {
                this.getLanguages();
            }
        }
    }

    getTranslations = () => {

        if (this.state.isLoadingTranslations) { return; }

        this.setState({
            isLoadingTranslations: true
        }, () => {
            Axios
                .get(API.service.getTranslations, {
                    params: {
                        id: this.state.item.id
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        this.setState(prevState => ({
                            ...prevState,
                            item: {
                                ...prevState.item,
                                translations: res.data.data.translations
                            }
                        }), () => {
                            this.getLanguages();
                        })
                    }
                })
        })
    }

    getLanguages = () => {
        Axios
            .get(API.organisation.getLanguages)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        isLoadingTranslations: false,
                        languageOptions: res.data.data.languages.filter((item) => { return !this.state.item.translations.find((o) => o.langID.toString() === item.id.toString()) }).map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations, {
                props: {
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    let organisationOptions = res.data.data.organisations.map((item) => { return {value: item.id, label: item.name}});
                    organisationOptions.splice(0, 0, {value: "-1", label: "System Service"});

                    this.setState({
                        organisationOptions: organisationOptions
                    })
                }
            })
    }

    getYoutubeImage = (size) => {

        let video, results;

        let getThumb = function (url, size) {
            if (url === null) {
                return '';
            }
            size    = (size === null) ? 'big' : size;
            results = url.match('[\\?&]v=([^&#]*)');
            video   = (results === null) ? url : results[1];

            if (size === 'small') {
                return 'http://img.youtube.com/vi/' + video + '/2.jpg';
            }
            return 'http://img.youtube.com/vi/' + video + '/0.jpg';
        };

        let imageURL = null;

        if (this.state.item.data.url) {
            imageURL = getThumb(this.state.item.data.url);
        }

        console.log(this.state.item.data);
        console.log(imageURL);

        let xhr = new XMLHttpRequest();
        xhr.open('GET', imageURL, true);
        xhr.responseType = 'blob';

        let self = this;

        xhr.onload = function(e) {
            console.log(this);
            if (this.status !== 200) return;
            var blob = new Blob([this.response], {type: this.response.type});

            self.setState({
                imagePickerURL: imageURL,
                imagePickerFile: blob
            }, () => {
                self.validate();
            })
        };

        xhr.send();
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {

                let formData = new FormData();


                if (this.state.item.id) {
                    formData.append("id", this.state.item.id);
                }

                formData.append("typeID", this.state.item.typeID);
                formData.append("organisationID", this.state.item.organisationID);
                formData.append("data", JSON.stringify(this.state.item.data));
                formData.append("title", this.state.item.title);
                formData.append("translations", JSON.stringify(this.state.item.translations));

                if (this.state.item.subtitle) {
                    formData.append("subtitle", this.state.item.subtitle);
                }


                if (this.state.item.imageID) {
                    formData.append("imageID", this.state.item.imageID);
                }

                if (this.state.imagePickerFile) {
                    formData.append("file", this.state.imagePickerFile);
                }

                Axios
                    .post((this.state.item.id ? API.service.updateService : API.service.createService), formData, {
                        headers: {
                            'Content-Type': formData.has('file') ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted(res.data.data.item);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }

                        this.setState({
                            isUploading: false
                        });
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        let newState = {};

        if (name === "selectedLanguage") {
            newState[name] = value;
        }
        else {
            newState.item = Object.assign({}, this.state.item);
            newState.item[name] = value;

            if (name === "typeID" || name === "organisationID") {
                newState.item.data = {};
                newState.imagePickerURL = null;
                newState.imagePickerFile = null;
                newState.item.imageID = null;
                newState.item.imageURL = null;

                if (name === "organisationID") {
                    newState.item.typeID = null;
                }
            }

            if (name.includes("data_")) {
                name = name.replace("data_", "");
                newState.item.data = Object.assign({}, newState.item.data);
                newState.item.data[name] = value;
            }
        }


        this.setState(newState, () => {
            this.validate()
        });
    }

    handleFileDelete = (name) => {
        this.setState({
            imagePickerFile: null,
            imagePickerURL: null
        }, () => {
            this.validate()
        })
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        let self = this;

        reader.onloadend = () => {
            self.setState({
                imagePickerFile: file,
                imagePickerURL: reader.result
            }, () => {
                self.validate()
            })
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null,
                    isUploading: false
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    showChallengeModal = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.item.data.id) {
            selected.push({id: this.state.item.data.id})
        }

        const content = (
            <ChallengesSelector
                organisationID={this.state.item.organisationID}
                isSingleSelection
                selected={selected}
                onSubmitted={(challenge) => {

                    let item = Object.assign({}, this.state.item);
                    item.imageID = challenge.imageID;
                    item.imageURL = challenge.imageURL;
                    item.data = challenge;

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onClose={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {
            this.validate();
        })
    }
    
    
    showAssessmentModal = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.item.data.podcastID) {
            selected.push({id: this.state.item.data.podcastID})
        }

        const content = (
            <AssessmentsSelector
                isSingleSelection
                selected={selected}
                onSubmitted={(assessment) => {

                    let item = Object.assign({}, this.state.item);
                    item.data = {
                        name: assessment.name,
                        id: assessment.id
                    };

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {
            this.validate();
        })
    }


    showHabitModal = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.item.data.habitID) {
            selected.push({id: this.state.item.data.habitID})
        }

        const content = (
            <HabitsSelector
                organisationID={this.state.item.organisationID}
                isSingleSelection
                selected={selected}
                onSubmitted={(habit) => {

                    let item = Object.assign({}, this.state.item);
                    item.imageID = habit.imageID;
                    item.imageURL = habit.imageURL;
                    item.data = {
                        name: habit.name,
                        id: habit.id
                    };

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {
            this.validate();
        })
    }


    showPodcastModal = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.item.data.podcastID) {
            selected.push({id: this.state.item.data.podcastID})
        }

        const content = (
            <PodcastsSelector
                organisationID={this.state.item.organisationID}
                isSingleSelection
                selected={selected}
                onSubmitted={(podcast) => {

                    let item = Object.assign({}, this.state.item);
                    item.imageID = podcast.bgImageID;
                    item.imageURL = podcast.bgImageURL;
                    item.data = {
                        title: podcast.title,
                        podcastID: podcast.id
                    };

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {
            this.validate();
        })

    }

    

    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.title) {
            if (touched.name) {
                errors.name = "Title is required";
            }
            validated = false;
        }

        if (!item.typeID) {
            if (touched.typeID) {
                errors.name = "Type is required";
            }
            validated = false;
        }
        else {
            if (item.typeID === ServiceType.WEBSITE && !item.data.url) {
                if (touched.data_url) {
                    errors.data_url = "URL is required";
                }
                validated = false;
            }
            else if (item.typeID === ServiceType.CHALLENGE) {
                if (!item.data.id) {
                    if (touched.data_id) {
                        errors.data_id = "Challenge needs to be selected";
                    }
                    validated = false;
                }
            }
            else if (item.typeID === ServiceType.PODCAST) {
                if (!item.data.podcastID) {
                    if (touched.data_podcastID) {
                        errors.data_podcastID = "Podcast needs to be selected";
                    }
                    validated = false;
                }
            }
            else if (item.typeID === ServiceType.HABIT) {
                if (!item.data.id) {
                    if (touched.data_id) {
                        errors.data_id = "Habit needs to be selected";
                    }
                    validated = false;
                }
            }
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    handleAddTranslation = (langID) => {

        const language = this.state.languageOptions.find((i) => i.value.toString() === langID.toString());

        let translation = {
            langID: language.value,
            langName: language.label,
            title: "",
            subtitle: "",
            typeTitle: "",
            typeID: this.state.item.typeID,
            id: UUID.uuidv4(),
            isNew: 1,
            data: {}
        };

        if (!this.state.modal.isOpen) {
            const content = (
                <ServiceForm
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        console.log(updatedTranslation)
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState(prevState => ({
                languageOptions: this.state.languageOptions.filter((i) => i.value.toString() !== language.value.toString()),
                selectedLanguage: null,
                item: {
                    ...prevState.item,
                    translations: [...prevState.item.translations, translation]
                },
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    isUploading: false,
                    content: content
                })
            }))
        }

    }

    updateTranslation = (updatedTranslation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.map((o) => {
                    if (o.langID.toString() === updatedTranslation.langID.toString()) {
                        return updatedTranslation;
                    }
                    else {
                        return o;
                    }
                })
            }
        }))
    }

    handleEditTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            translation.typeID = this.state.item.typeID;
            translation.data = translation.data || {};
            const content = (
                <ServiceForm
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    content: content
                })
            })
        }
    }

    handleDeleteTranslation = (translation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.filter((i) => i.langID.toString() !== translation.langID.toString() )
            },
            languageOptions: [...this.state.languageOptions, {label: translation.langName, value: translation.langID}].sort((a,b) => a.name < b.name ? 1 : -1 )
        }), () => {
            this.closeModal();
        })
    }

    showDeleteTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Translation"
                    content={(
                        <React.Fragment>
                            <p>Are you sure to delete <strong>{translation.langName}</strong> translation ? This action cannot be reverted.</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteTranslation(translation)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    isUploading: false,
                    content: content
                })
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />
                <div className="modal-head">
                    {this.state.item.langID === null ?
                        <h3>{this.state.item.id ? "Edit Service" : "New Service"}</h3>
                        :
                        <h3>Service ({this.state.item.langName} translation)</h3>
                    }
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        {this.state.item.langID === null &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="createdAt" className="text-right middle">Created At</label>
                                </div>
                                <div className="large-9 small-9 cell">
                                    <input name="createdAt" type="text" value={moment(DateHelper.convertToLocalDateTime(this.state.item.createdAt), "YYYY-MM-DD HH:mm:ss").format("LLLL")} disabled={true} />
                                </div>
                            </div>
                            {this.context.permissions.service.allOrgs && !this.props.item.id ?
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="organisationID" className="text-right middle">* Organisation</label>
                                    </div>
                                    <div className={"small-9 cell " + (this.state.errors.organisationID && "error")}>
                                        <ReactSelect
                                            name="organisationID"
                                            className="react-select"
                                            onChange={this.handleChange}
                                            value={this.state.item.organisationID}
                                            options={this.state.organisationOptions}
                                            onBlur={this.handleBlur}
                                        />
                                        <small className="error">{this.state.errors.organisationID}</small>
                                    </div>
                                </div>
                                :
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="organisationName" className="text-right middle ">* Organisation</label>
                                    </div>
                                    <div className="small-9 cell">
                                        <input type="text" name="organisationName" value={this.state.item.organisationName} disabled/>
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="title" className="text-right middle ">{this.state.item.langID === null ? "*" : ""} Title</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.title && "error")}>
                                <input type="text" name="title" onChange={this.handleChange} value={this.state.item.title} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.title}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="subtitle" className="text-right middle ">Subtitle</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.subtitle && "error")}>
                                <input type="text" name="subtitle" onChange={this.handleChange} value={this.state.item.subtitle} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.subtitle}</small>
                            </div>
                        </div>
                        {this.state.item.langID === null &&
                        <React.Fragment>
                            {this.state.item.typeID !== ServiceType.NATIVE_FEATURE &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="typeID" className="text-right middle">Type</label>
                                </div>
                                <div className="large-9 small-9 cell">
                                    <ReactSelect
                                        name="typeID"
                                        onChange={this.handleChange}
                                        value={this.state.item.typeID || null}
                                        options={this.state.typeOptions}
                                        className="react-select"
                                    />
                                </div>
                            </div>
                            }
                            {this.state.item.typeID === ServiceType.NATIVE_FEATURE &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="typeName" className="text-right middle">Type</label>
                                </div>
                                <div className="large-9 small-9 cell">
                                    <input name="typeName" type="text" value={this.state.item.typeName} disabled/>
                                </div>
                            </div>
                            }
                            {this.state.item.typeID === ServiceType.WEBSITE && this.context.permissions.service.allOrgs &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="data_personalised" className="text-right middle">Append identification token</label>
                                </div>
                                <div className="large-4 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="data_personalised" onChange={this.handleChange} checked={this.state.item.data.personalised || 0 === 1} />
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            }
                        </React.Fragment>
                        }
                        {(this.state.item.typeID === ServiceType.WEBSITE || this.state.item.typeID === ServiceType.VIDEO) &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="data_url" className="text-right middle ">{this.state.item.lang === null && "*"} URL</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.data_url && "error")}>
                                <input type="text" name="data_url" onChange={this.handleChange} value={this.state.item.data.url} onBlur={this.handleBlur} />
                                <small className="field-info">Please note! Only secure links with <strong>HTTPS</strong> protocol are allowed. {this.state.item.lang !== null && "URL in translation is optional, if not set, the translation will point to the same URL as the base language."}</small>
                                <small className="error">{this.state.errors.data_url}</small>
                            </div>
                        </div>
                        }
                        {this.state.item.langID === null &&
                            <React.Fragment>
                            {this.state.item.typeID === ServiceType.CHALLENGE &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="data_id" className="text-right middle">* Challenge</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.data_id && "error")}>
                                    <div className="input-group">
                                        <input className="input-group-field" name="data_id" type="text" value={this.state.item.data.title || "Not selected" } disabled/>
                                        <div className="input-group-button">
                                            <button type="button" className="button" onClick={() => this.showChallengeModal()}>Browse</button>
                                        </div>
                                    </div>
                                    <small className="error">{this.state.errors.data_id}</small>
                                </div>
                            </div>
                            }
                            {this.state.item.typeID === ServiceType.PODCAST &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="data_podcastID" className="text-right middle">* Podcast</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.data_id && "error")}>
                                    <div className="input-group">
                                        <input className="input-group-field" name="data_podcastID" type="text" value={this.state.item.data.title || "Not selected" } disabled/>
                                        <div className="input-group-button">
                                            <button type="button" className="button" onClick={() => this.showPodcastModal()}>Browse</button>
                                        </div>
                                    </div>
                                    <small className="error">{this.state.errors.data_id}</small>
                                </div>
                            </div>
                            }
                            {this.state.item.typeID === ServiceType.HABIT &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="data_habitID" className="text-right middle">* Habit</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.data_id && "error")}>
                                    <div className="input-group">
                                        <input className="input-group-field" name="data_habitID" type="text" value={this.state.item.data.name || "Not selected" } disabled/>
                                        <div className="input-group-button">
                                            <button type="button" className="button" onClick={() => this.showHabitModal()}>Browse</button>
                                        </div>
                                    </div>
                                    <small className="error">{this.state.errors.data_id}</small>
                                </div>
                            </div>
                            }
                            {this.state.item.typeID === ServiceType.ASSESSMENT &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="data_assessmentID" className="text-right middle">* Assessment</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.data_id && "error")}>
                                    <div className="input-group">
                                        <input className="input-group-field" name="data_assessmentID" type="text" value={this.state.item.data.name || "Not selected" } disabled/>
                                        <div className="input-group-button">
                                            <button type="button" className="button" onClick={() => this.showAssessmentModal()}>Browse</button>
                                        </div>
                                    </div>
                                    <small className="error">{this.state.errors.data_id}</small>
                                </div>
                            </div>
                            }
                            {(this.state.item.typeID !== ServiceType.CHALLENGE || (this.state.item.typeID === ServiceType.CHALLENGE && this.state.item.data.id) || (this.state.item.typeID === ServiceType.PODCAST && this.state.item.data.podcastID)) &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="imageFile" className="text-right middle">Change Image</label>
                                    </div>
                                    <div className="large-9 small-9 cell ">
                                        <FileInput
                                            name="file"
                                            onChange={this.handleFilePickerChange}
                                            onBlur={this.handleBlur}
                                            onDelete={this.handleFileDelete}
                                            placeholder="Not selected. Ideal size W:500px H:500px (jpg)"
                                            accept="image/jpeg,image/jpg,image/png"
                                            disabled={this.state.useDefaultImage}
                                        />
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="imageFile" className="text-right middle">Cover Image</label>
                                    </div>
                                    <div className={"large-9 small-9 cell " + (this.state.errors.imageFile ? "error" : "")} style={{marginBottom: 20}}>
                                        <React.Fragment>
                                            {this.state.imagePickerURL &&
                                            <React.Fragment>
                                                <Image src={this.state.imagePickerURL} width={200} height={200} border={true} style={{marginRight: 10}}/>
                                                <Image src={this.state.imagePickerURL} width={200} height={100} border={true} />
                                            </React.Fragment>
                                            }
                                            {!this.state.imagePickerURL &&
                                            <React.Fragment>
                                                <Image src={this.state.item.imageURL} width={200} height={200} border={true} placeholder={Placeholder} style={{marginRight: 10}}/>
                                                <Image src={this.state.item.imageURL} width={200} height={100} border={true} placeholder={Placeholder}/>
                                            </React.Fragment>
                                            }
                                        </React.Fragment>
                                        <small className="error">{this.state.errors.imageFile}</small>
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <fieldset className="fieldset">
                                        <legend>Translations</legend>
                                        <div className="grid-x grid-padding-x">
                                            <div className="cell small-12">
                                                <span>Add translation for</span>
                                                <ReactSelect
                                                    name="selectedLanguage"
                                                    className="react-select translation-selector"
                                                    onChange={this.handleChange}
                                                    value={this.state.selectedLanguage}
                                                    options={this.state.languageOptions}
                                                    onBlur={this.handleBlur}
                                                />
                                                <Button type="button" className="translation-button" color={Colors.PRIMARY} isDisabled={!this.state.selectedLanguage} onClick={() => this.handleAddTranslation(this.state.selectedLanguage)}>Add</Button>
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="cell small-12">
                                                <ReactTable
                                                    columns={[
                                                        {
                                                            Header: 'Language',
                                                            accessor: 'langName'
                                                        },
                                                        {
                                                            Header: 'Actions',
                                                            Cell: row => (
                                                                <ButtonGroup size={Sizes.TINY}>
                                                                    {this.context.permissions.campus.edit &&
                                                                    <Button type="button" color={Colors.PRIMARY} onClick={() => this.handleEditTranslation(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                                                                    }
                                                                    {this.context.permissions.campus.delete &&
                                                                    <Button type="button" color={Colors.ALERT} onClick={() => this.showDeleteTranslation(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                                                                    }
                                                                </ButtonGroup>
                                                            ),
                                                            maxWidth: 100,
                                                            show: this.context.permissions.campus.delete || this.context.permissions.campus.edit
                                                        }
                                                    ]}
                                                    data={this.state.item.translations}
                                                    className='-striped'
                                                    showPagination={false}
                                                    noDataText={"No translations"}
                                                    pageSize={this.state.item.translations ? this.state.item.translations.length : 1}
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    {this.state.item.langID === null ?
                                        <React.Fragment>
                                            <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                                Cancel
                                            </button>
                                            <button className="button success" type="submit" disabled={!this.state.validated || this.state.isLoadingTranslations}>
                                                Submit
                                            </button>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <button className="button secondary" type="button" onClick={() => this.props.onCompleted(this.state.item)}>
                                                Update
                                            </button>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="PostInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}
