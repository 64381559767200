import React from "react";
import {CommonUtil} from "../../../utils/CommonUtil";
import UUID from "../../../utils/UUID";

import iconDelete from "../../../assets/img/sso/icon_delete.svg";

export const SSOProviderRuleBuilderActions = {
    CHANGE : "ssoproviderrulebuilder.change",
    DELETE : "ssoproviderrulebuilder.delete"
};

export const SSOProviderRuleBuilder = (props) => {

    const {label} = props;
    const {values} = props;
    const {data} = props;
    const {callback} = props;

    function notifyChange(data) {
        if (callback) {
            const out = [...data];
            callback(SSOProviderRuleBuilderActions.CHANGE, out);
        }
    }

    function addRule(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (data) {
            data.push({
                id : UUID.uuidv4(),
                value : null,
                specifications : []
            });

            notifyChange(data);
        }
    }

    const mainContent = [];

    if (data && data.length > 0) {
        data.forEach((item) => {
            mainContent.push(
                <SSOProviderRuleBuilderItem
                    label={label}
                    values={values}
                    item={item}
                    data={data}
                    callback={(action, cData) => {
                        if (action === SSOProviderRuleBuilderActions.DELETE) {
                            if (data) {
                                for (let i = data.length - 1; i >= 0; i--) {
                                    if (data[i].id === cData.id) {
                                        data.splice(i, 1);
                                    }
                                }
                            }
                        }

                        notifyChange(data);
                    }}
                />
            )
        });
    }

    if (mainContent.length === 0) {
        mainContent.push(
            <div className={"organisation-form-sso-empty-message"}>
                No rules have been defined.
            </div>
        );
    }

    return (
        <>
            {mainContent}

            <div className={"organisation-form-sso-rules-action"}>
                <button className={"button primary"} onClick={addRule}>
                    Add Rule
                </button>
            </div>
        </>
    );

}

const SSOProviderRuleBuilderItem = (props) => {

    const {label} = props;
    const {values} = props;
    const {item} = props;
    const {data} = props;
    const {callback} = props;

    function handleCallback(action, cData) {
        if (action === SSOProviderRuleBuilderActions.DELETE) {
            if (item.hasOwnProperty("specifications")) {
                for (let i = item.specifications.length - 1; i >= 0; i--) {
                    if (item.specifications[i].id === cData.id) {
                        item.specifications.splice(i, 1);
                    }
                }
            }
        }

        if (callback) {
            callback(SSOProviderRuleBuilderActions.CHANGE, data);
        }
    }

    function handleValueChange(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        item.value = e.target.value;

        if (callback) {
            callback(SSOProviderRuleBuilderActions.CHANGE, data);
        }
    }

    function addSpecification(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (item.hasOwnProperty("specifications")) {
            item.specifications.push({
                id : UUID.uuidv4(),
                conditions : []
            });

            callback(SSOProviderRuleBuilderActions.CHANGE, data);
        }
    }

    // RENDER

    const itemContent = [];

    if (item.hasOwnProperty("specifications")) {
        item.specifications.forEach((specification, idx) => {
            if (idx > 0) {
                itemContent.push(
                    <div className={"organisation-form-sso-rule-slot"}>
                        <div className={"organisation-form-sso-rule-slot-vline"}/>
                        <div className={"organisation-form-sso-rule-slot-container-separator"}>
                            or
                        </div>
                        <div className={"organisation-form-sso-rule-slot-vline-over"}/>
                    </div>
                )
            }

            itemContent.push(
                <SSOProviderRuleBuilderSpecification
                    specification={specification}
                    data={data}
                    callback={handleCallback} />
            )
        });
    }

    if (itemContent.length > 0) {
        itemContent.push(
            <div className={"organisation-form-sso-rule-slot-ender"}>
                <div className={"organisation-form-sso-rule-slot-ender-indicator"}/>
            </div>
        );
    } else {
        itemContent.push(
            <div className={"organisation-form-sso-empty-message"}>
                No specifications have been defined.
            </div>
        );
    }

    return (
        <div className={"organisation-form-sso-rules"}>
            <div className={"organisation-form-sso-rule-category"}>
                <div className={"organisation-form-sso-rule-category-content"}>
                    <label>{label}</label>
                    <select
                        className={"custom-select"}
                        value={CommonUtil.getOrDefault(item, "value", null)}
                        onChange={handleValueChange}
                    >
                        {values.map((value) => (
                            <option value={value.id}>{value.name}</option>
                        ))}
                    </select>
                </div>

                <button
                    className={"organisation-form-sso-action-button"}
                    style={{backgroundImage: "url(" + iconDelete + ")"}}
                    onClick={(e) => {
                        if (e) {
                            e.preventDefault();
                            e.stopPropagation();
                        }

                        if (callback) {
                            callback(SSOProviderRuleBuilderActions.DELETE, item);
                        }
                    }} />
            </div>

            {itemContent}

            <div className={"organisation-form-sso-rules-action"}>
                <button
                    className={"button primary"}
                    onClick={addSpecification}
                >
                    Add Specification
                </button>
            </div>

        </div>
    )
}

const SSOProviderRuleBuilderSpecification = (props) => {

    const {specification} = props;
    const {data} = props;
    const {callback} = props;

    function handleCallback(action, cData) {
        if (action === SSOProviderRuleBuilderActions.DELETE) {
            // Intercept Delete callback, then forward a change message
            if (specification.hasOwnProperty("conditions")) {
                for (let i = specification.conditions.length - 1; i >= 0; i--) {
                    if (specification.conditions[i].id === cData.id) {
                        specification.conditions.splice(i, 1);
                        break;
                    }
                }
            }
        }

        if (callback) {
            callback(SSOProviderRuleBuilderActions.CHANGE, data);
        }
    }

    function addCondition(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (specification.hasOwnProperty("conditions")) {
            specification.conditions.push({
                id : UUID.uuidv4(),
                propertyName : "",
                values : ""
            });

            callback(SSOProviderRuleBuilderActions.CHANGE, data);
        }
    }

    // RENDER

    const slotContent = [];

    if (specification.hasOwnProperty("conditions")) {
        specification.conditions.forEach((condition, idx) => {
            if (idx > 0) {
                slotContent.push(
                    <div className={"organisation-form-sso-rule-slot-container-item-separator"}>
                        and
                    </div>
                );
            }

            slotContent.push(
                <SSOProviderRuleBuilderCondition
                    condition={condition}
                    data={data}
                    callback={handleCallback}
                />
            );
        });
    }

    if (slotContent.length === 0) {
        slotContent.push(
            <div className={"organisation-form-sso-empty-message"}>
                No conditions have been defined.
            </div>
        );
    }

    return (
        <div className={"organisation-form-sso-rule-slot"}>
            <div className={"organisation-form-sso-rule-slot-vline"}/>
            <div className={"organisation-form-sso-rule-slot-hline"}/>
            <div className={"organisation-form-sso-rule-slot-container"}>

                {slotContent}

                <div className={"organisation-form-sso-rule-slot-container-item-action"}>
                    <button
                        className={"button primary"}
                        onClick={addCondition}
                    >
                        Add Condition
                    </button>
                </div>
            </div>

            <button
                className={"organisation-form-sso-action-button"}
                style={{backgroundImage : "url(" + iconDelete + ")"}}
                onClick={(e) => {
                    if (e) {
                        e.preventDefault();
                        e.stopPropagation();
                    }

                    if (callback) {
                        callback(SSOProviderRuleBuilderActions.DELETE, specification);
                    }
                }}
            />

            <div className={"organisation-form-sso-rule-slot-vline-over"}/>
        </div>
    )

}

const SSOProviderRuleBuilderCondition = (props) => {

    const {condition} = props;
    const {data} = props;
    const {callback} = props;

    function setConditionValue(propertyName, values) {
        // When we change values, we write directly to the passed condition
        // as we will update the reference. We just need to float this
        // change to the top and notify the parent that the data has changed
        if (propertyName || propertyName === "") {
            condition.propertyName = propertyName;
        }

        if (values || values === "") {
            condition.values = values;
        }

        if (callback) {
            callback(SSOProviderRuleBuilderActions.CHANGE, data);
        }
    }

    return (
        <div className={"organisation-form-sso-rule-slot-container-item"}>
            <div className={"organisation-form-sso-rule-slot-container-item-input"}>
                <label>Profile property name</label>
                <input
                    type={"text"}
                    value={CommonUtil.getOrDefault(condition, "propertyName", "")}
                    onChange={(e) => setConditionValue(e.target.value)}
                />
            </div>

            <div className={"organisation-form-sso-rule-slot-container-item-inner"}>
                =
            </div>

            <div className={"organisation-form-sso-rule-slot-container-item-input"}>
                <label>Values</label>
                <input
                    type={"text"}
                    value={CommonUtil.getOrDefault(condition, "values", "")}
                    placeholder={"Use ; to separate multiple values"}
                    onChange={(e) => setConditionValue(undefined, e.target.value)}
                />
            </div>

            <button
                className={"organisation-form-sso-action-button"}
                style={{backgroundImage : "url(" + iconDelete + ")"}}
                onClick={(e) => {
                    if (e) {
                        e.preventDefault();
                        e.stopPropagation()
                    }

                    if (callback) {
                        callback(SSOProviderRuleBuilderActions.DELETE, condition);
                    }
                }}
            />
        </div>
    )

}