import moment from 'moment'

const DateHelper = {
    convertToLocalDateTime: function(datetime) {
        return moment.utc(datetime, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss");
    },
    convertFromLocalToUTCDateTime: function(datetime) {
        return moment(datetime, "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss")
    }
}

export default DateHelper;