export const UploadType = {
    USER_PROFILE_IMAGE : 1,
    HABIT_IMAGE : 2,
    PODCAST_AUDIO_FILE : 3,
    PODCAST_BG_IMAGE : 4,
    PODCAST_HEADER_IMAGE : 5,
    PODCAST_SMALL_IMAGE : 6,
    SURVEY_IMAGE : 7,
    EMOTION_IMAGE : 8,
    OFFER_HEADER_IMAGE : 9,
    POST_ICON_IMAGE : 10,
    POST_IMAGE : 11,
    ORGANISATION_LOGO : 12,
    ORGANISATION_FULL_LOGO : 13,
    PAGE_SERVICE_IMAGE : 14,
    CHALLENGE_IMAGE : 15,
    CHALLENGE_DESC_IMAGE : 16,
    AWARD_IMAGE : 17,
    ORGANISATION_APP_LOGO : 18,
    SERVICE_ACCESS_IMAGE : 19,
    SERVICE_IMAGE : 20,
    CTA_IMAGE : 21,
    WELLBEING_MENU_ITEM_IMAGE : 22,
    CHAT_FLOW_IMAGE : 23,
    CHAT_FLOW_QUICK_ACCESS_IMAGE : 24,
    PLUGIN_ASSISTANT_WIDGET_IMAGE : 25,
    PLUGIN_ASSISTANT_WELCOME_IMAGE : 26
}