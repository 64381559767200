import React from 'react'
import PageContent from 'components/common/PageContent'
import AppComponent from 'components/common/AppComponent'
import ReactSelect from 'components/common/ReactSelect'
import { ReportType } from 'constants/ReportType'
import ReportFilters from 'components/reports/ReportFilters'
import { API } from 'api/APIClient'
import { Label, Colors, Sizes, } from 'react-foundation'
import DashboardGraph from 'components/reports/DashboardGraph'
import DetailedReport from 'components/reports/DetailedReport'
import WellbeingDaysReport from "./WellbeingDaysReport";
import PodcastReport from 'components/reports/PodcastReport'
import MoodReport from 'components/reports/MoodReport'
import { GraphType } from "constants/GraphType";

const pathElements = [
    {id: 0, title: "Home", url: "/"},
    {id: 1, title: "Reports"}
];


const REPORTS = {
    [ReportType.USAGE]: {
        "id": ReportType.USAGE,
        "title": "Dashboard"
    },
    [ReportType.ACTIVE_USERS]: {
        "id": ReportType.ACTIVE_USERS,
        "title": "Unique Active Users",
        "color": "#8884d8",
        "endpoint": API.report.getActiveUsers,
        "graphType": GraphType.BAR
    },
    // [ReportType.APP_EVENTS]: {
    //     "id": ReportType.APP_EVENTS,
    //     "title": "App Events",
    //     "color": "#72c5bf",
    //     "endpoint": API.report.getAppEvents,
    //     "graphType": GraphType.BAR
    // },
    [ReportType.REGISTRATIONS]: {
        "id": ReportType.REGISTRATIONS,
        "title": "New Registrations",
        "color": "#80c572",
        "endpoint": API.report.getRegistrations,
        "graphType": GraphType.BAR
    },
    // [ReportType.CTA]: {
    //     "id": ReportType.CTA,
    //     "title": "Student Portal CTA Messages",
    //     "color": "#c58e72",
    //     "endpoint": API.report.getCTAMessages,
    //     "graphType": GraphType.BAR
    // },
    [ReportType.WELLBEING_DAYS]: {
        "id": ReportType.WELLBEING_DAYS,
        "title": "Wellbeing Days",
        "color": "#c57272",
        "endpoint": API.report.getWellbeingDays,
        "graphType": GraphType.BAR
    },
    [ReportType.PODCASTS]: {
        "id": ReportType.PODCASTS,
        "title": "Podcast Views",
        "color": "#c272c5",
        "endpoint": API.report.getPodcastViews,
        "graphType": GraphType.BAR
    },
    [ReportType.MOOD]: {
        "id": ReportType.MOOD,
        "title": "Mood Cloud",
        "color": "#c272c5",
        "endpoint": API.report.getUserEmotions,
        "tableEndpoint": API.report.getUserEmotionsByDay,
        "graphType": GraphType.BUBBLE
    }
}

const USAGE_DASHBOARD = [
    ReportType.ACTIVE_USERS,
    // ReportType.APP_EVENTS,
    ReportType.REGISTRATIONS,
    // ReportType.CTA,
    ReportType.WELLBEING_DAYS,
    ReportType.PODCASTS,
    ReportType.MOOD
]

const REPORT_OPTIONS = [
    ReportType.USAGE,
    ReportType.ACTIVE_USERS,
    // ReportType.APP_EVENTS,
    ReportType.REGISTRATIONS,
    // ReportType.CTA,
    ReportType.WELLBEING_DAYS,
    ReportType.PODCASTS,
    ReportType.MOOD
]

export default class Reports extends AppComponent {


    constructor(props, context) {
        super(props);

        this.state = {
            reportOptions: REPORT_OPTIONS.map(o => {return {value: REPORTS[o].id, label: REPORTS[o].title}}),
            selectedReport: REPORT_OPTIONS[0],
            filters: null,
            startDate: null,
            endDate: null
        };

        console.log("state");
        console.log(this.state);
    }

    handleReportChange = (event) => {
        let value = event.target.value;

        this.setState({
            selectedReport: value
        })
    }

    handleFiltersChange = (startDate, endDate, filters) => {
        this.setState({
            startDate: startDate,
            endDate: endDate,
            filters: filters
        })
    }

    onShowMore = (item) => {
        this.setState({
            selectedReport: item.id
        })
    }

    render() {

        let report = '';

        switch (this.state.selectedReport) {
            case ReportType.USAGE:
                report = (
                    <div className="grid-x grid-padding-x">
                        {USAGE_DASHBOARD.map((o) => {
                            const i = REPORTS[o];
                            return (
                                <div className="large-4 medium-6 small-12 cell" key={i.title}>
                                    <div className="panel-box">
                                        <DashboardGraph
                                            title={i.title}
                                            color={i.color}
                                            graphType={i.graphType}
                                            endpoint={i.endpoint}
                                            filters={this.state.filters}
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            onShowMore={() => this.onShowMore(i)}
                                            addShowMore={true}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                );
                break;
            case ReportType.WELLBEING_DAYS:
                report = (
                    <WellbeingDaysReport
                        reportID={this.state.selectedReport}
                        title={REPORTS[this.state.selectedReport].title}
                        color={REPORTS[this.state.selectedReport].color}
                        graphType={REPORTS[this.state.selectedReport].graphType}
                        endpoint={REPORTS[this.state.selectedReport].endpoint}
                        tableEndpoint={REPORTS[this.state.selectedReport].tableEndpoint}
                        filters={this.state.filters}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                    />
                );
                break;
            case ReportType.PODCASTS:
                report = (
                    <PodcastReport
                        reportID={this.state.selectedReport}
                        title={REPORTS[this.state.selectedReport].title}
                        color={REPORTS[this.state.selectedReport].color}
                        endpoint={REPORTS[this.state.selectedReport].endpoint}
                        tableEndpoint={REPORTS[this.state.selectedReport].tableEndpoint}
                        graphType={REPORTS[this.state.selectedReport].graphType}
                        filters={this.state.filters}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                    />
                );
                break;
            case ReportType.MOOD:
                report = (
                    <MoodReport
                        reportID={this.state.selectedReport}
                        title={REPORTS[this.state.selectedReport].title}
                        color={REPORTS[this.state.selectedReport].color}
                        endpoint={REPORTS[this.state.selectedReport].endpoint}
                        graphType={REPORTS[this.state.selectedReport].graphType}
                        tableEndpoint={REPORTS[this.state.selectedReport].tableEndpoint}
                        filters={this.state.filters}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                    />
                );
                break;
            default:
                report = (
                    <DetailedReport
                        reportID={this.state.selectedReport}
                        title={REPORTS[this.state.selectedReport].title}
                        color={REPORTS[this.state.selectedReport].color}
                        endpoint={REPORTS[this.state.selectedReport].endpoint}
                        tableEndpoint={REPORTS[this.state.selectedReport].tableEndpoint}
                        graphType={REPORTS[this.state.selectedReport].graphType}
                        filters={this.state.filters}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                    />
                );
        }

        return (
            <PageContent pathElements={pathElements} >
                <div className="grid-x grid-padding-x">
                    <div className="large-4 medium-6 small-12 cell">
                        <span>Section</span>
                        <ReactSelect
                            name="reportType"
                            className="react-select"
                            onChange={this.handleReportChange}
                            value={this.state.selectedReport}
                            options={this.state.reportOptions}
                        />
                    </div>
                </div>
                <ReportFilters
                    onChange={(startDate, endDate, filters) => this.handleFiltersChange(startDate, endDate, filters)}
                />
                {this.state.filters !== null && this.state.startDate !== null && this.state.endDate !== null && report}
            </PageContent>
        );
    }
}