
// This is mapped to the DB

export const UserRole = {
    STUDENT: 1,
    STAFF: 2,
    STUDENT_AND_STAFF: 3,
    FUTURE_STUDENT: 4,
    FUTURE_STAFF: 5,
    ALUMNI: 6,
    EXTERNAL_AUDITOR: 7
};

Object.freeze(UserRole);