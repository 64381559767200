import React from 'react';

class Image extends React.Component {

    static defaultProps = {
        src: "#",
        circular: false,
        width: "100%",
        height: "100%",
        fit: "cover",
        border: false,
        borderWidth: 1,
        borderColor: "#6d6d6d",
        placeholder: ""
    };

    constructor(props) {
        super(props);

        this.state = {
            src: this.props.src
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.src !== state.src) {
            return {
                src: props.src
            };
        }
        return null;
    }

    handleOnError = () => {
        this.setState({
            src: ""
        })
    }

    render() {

        let styles = {
            width: this.props.width,
            height: this.props.height,
            objectFit: this.props.fit
        };

        if (!this.state.src || this.state.src.length === 0) {
            styles.backgroundImage = "url(" + this.props.placeholder + ")";
            styles.backgroundRepeat = "no repeat";
            styles.backgroundPosition = "center";
            styles.backgroundSize = "cover";
        }

        if (this.props.border) {
            styles.borderWidth = this.props.borderWidth;
            styles.borderColor = this.props.borderColor;
            styles.borderStyle = "solid"
        }


        if (this.props.circular) {
            styles.borderRadius = "50%";
            styles.overflow = "hidden";
        }

        let {style, innerRef, placeholder, src, ...props} = this.props;

        if (!style) { style = {} }
        if (!innerRef) { innerRef = "" }

        const styling = Object.assign(Object.assign({}, style), styles);

        let imageSrc = "";

        if (this.state.src && this.state.src.length > 0 && this.state.src !== "#") {
            imageSrc = this.state.src;
        }
        else {
            imageSrc = placeholder;
        }

        return (
            <img ref={innerRef} src={imageSrc} style={styling} onError={this.handleOnError} alt="" {...props} />
        );
    }
}

export default Image;