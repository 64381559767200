import React from 'react'
import ReactTable from 'react-table'
import Axios from 'axios'
import { API } from 'api/APIClient'
import { Colors, Sizes, ButtonGroup, Button, Label} from 'react-foundation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatatableHelper from 'utils/DatatableHelper'
import TableContainer from 'components/common/TableContainer'
import SearchBar from 'components/common/SearchBar'
import { ServiceType } from 'constants/ServiceType'
import AppComponent from 'components/common/AppComponent'
import ReactSelect from 'components/common/ReactSelect'
import Modal from "react-responsive-modal"
import ServiceForm from 'components/services/ServiceForm'
import StandardModal from 'components/common/StandardModal'
import { NotificationManager } from "react-notifications"
import {SectionType} from "../../constants/SectionType";
import {Link} from "react-router-dom";
import DateHelper from "../../utils/DateHelper";


export default class ServicesTable extends AppComponent {

    constructor(props, context) {
        super(props);

        this.state = {
            items: [],
            searchKey: "",
            organisationOptions: context.permissions.service.allOrgs && !this.props.isSelector ? [] : [context.organisation.id, -1],
            organisations: props.isSelector ? [props.organisationID, -1] : (context.permissions.service.allOrgs ? [] : [context.organisation.id, -1]),
            // organisations: context.permissions.service.allOrgs ? [] : (props.isSelector ? [props.organisationID, -1] : [context.organisation.id, -1]),
            // organisationOptions: [],
            page: 0,
            pageSize: props.pageSize || 10,
            sorted: [{id: "id", "desc": true}],
            totalPages: -1,
            isLoading: true,
            filtersAreOpen: false,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            },
        };

        this.searchTimer = null;
    }

    componentDidMount() {
        if (this.context.permissions.service.allOrgs && !this.props.isSelector) {
            this.getOrganisationOptions();
        }
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations, {
                params: {
                    addGeneric: 1,
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    handleChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "organisations") {

            if (value.length === this.state.organisationOptions.length) {
                value = [];
            }

            this.setState({
                organisations: value
            }, () => {
                this.fetchItems();
            });
        }
    }

    searchTextDidChange(newValue) {
        this.setState({
            searchKey: newValue || ""
        }, () => {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.fetchItems();
            }, 1000);
        });
    }

    toggleRow = (item) => {
        if (this.props.onSelectedRow instanceof Function) {
            this.props.onSelectedRow(item);
        }
    }

    toggleSelectAll = () => {
        if (this.props.onSelectedAll instanceof Function) {
            this.props.onSelectedAll(!this.props.selectAll, this.state.items);
        }
    }

    fetchItems = () => {

        if (this.props.onWillFetch instanceof Function) {
            this.props.onWillFetch();
        }

        let filters = {
            search: this.state.searchKey,
            organisations: this.state.organisations
        }

        if (this.props.serviceTypes) {
            filters.serviceTypes = this.props.serviceTypes;
        }

        Axios
            .post(API.service.getServicesDT, {
                    page: this.state.page,
                    pageSize: this.state.pageSize,
                    sorted: this.state.sorted,
                    filters: filters
                }
            )
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        items: res.data.data.rows,
                        totalPages: DatatableHelper.getTotalPages(res.data.data),
                        isLoading: false
                    }, () => {
                        if (this.props.onDidFetch instanceof Function) {
                            this.props.onDidFetch();
                        }
                    })
                }
                else {
                    this.setState({
                        isLoading: false
                    })
                }
            })
    }

    handleAddNewItem = () => {
        this.showItemModal({});
    }


    handleEditItem = (item) => {
        this.showItemModal(item);
    }

    showItemModal = (item) => {
        if (!this.state.modal.isOpen) {
            const content = (
                <ServiceForm
                    item={item}
                    onSubmitted={() => this.closeModal(this.fetchItems)}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    error: null,
                    isUploading: false
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    showDeleteModal = (item) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Service"
                    content={(
                        <div>Are you sure to delete <strong>{item.title}</strong> service ? If deleted, the service will be removed from all configurations it was included in. This action cannot be reverted</div>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteItem(item)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                    isUploading={this.state.modal.isUploading}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    content: content
                })
            })
        }
    }

    handleDeleteItem = (item) => {

        if (!this.state.modal.isUploading) {
            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isUploading: true
                })
            }, () => {

                Axios
                    .post(API.service.deleteService, {
                        id: item.id
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.closeModal(() => {
                                this.fetchItems();
                                NotificationManager.success("Service deleted");
                            });
                        }
                        else {
                            this.closeModal(() => {
                                NotificationManager.error(res.data.error.desc);
                            });
                        }
                    })
                    .catch(error => {
                        this.closeModal(() => {
                            NotificationManager.error(error.response);
                        });
                    });
            })
        }
    }


    render() {


        const columns = [
            {
                id: "checkbox",
                accessor: "",
                Cell: row => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.props.selected.find((o) => o.id === row.original.id) ? true : false}
                            onChange={() => this.toggleRow(row.original)}
                        />
                    );
                },
                Header: () => {
                    if (this.props.isSingleSelection) {
                        return null;
                    }
                    else {
                        return (
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={this.props.selectAll}
                                onChange={this.toggleSelectAll}
                            />
                        );
                    }
                },
                sortable: false,
                width: 45,
                show: this.props.isSelector || false
            },
            {
                Header: 'Title',
                accessor: 'title',
                width: 180
            },
            {
                Header: 'Subtitle',
                accessor: 'subtitle',
                minWidth: 250
            },
            {
                Header: 'Type',
                accessor: 'typeName',
                width: 180
            },
            {
                Header: "Organisation",
                accessor: "organisationName",
                width: 240,
                Cell: row => {
                    if (row.original.organisationID) {
                        return row.original.organisationName;
                    }
                    else {
                        return (<Label>System service</Label>)
                    }
                },
            },
            {
                Header: "Data",
                accessor: 'data',
                width: 100,
                Cell: row => {
                    if (row.original.typeID === ServiceType.WEBSITE) {
                        return "url: " + row.original.data.url;
                    }
                    else {
                        return "";
                    }
                },
                sortable: false,
                show: false
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                maxWidth: 180,
                Cell: row => {
                    return DateHelper.convertToLocalDateTime(row.value);
                }
            },
            {
                Header: 'Actions',
                Cell: row => {
                    return (
                        <ButtonGroup size={Sizes.TINY}>
                            {this.context.permissions.service.edit && (this.context.organisation.id === row.original.organisationID || this.context.permissions.service.allOrgs) && ((row.original.typeID !== ServiceType.NATIVE_FEATURE && row.original.typeID !== ServiceType.PAGE) || (row.original.typeID === ServiceType.NATIVE_FEATURE && this.context.permissions.service.editSystemServices)) &&
                            <Button color={Colors.PRIMARY} onClick={() => this.handleEditItem(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                            }
                            {row.original.typeID === ServiceType.PAGE && this.context.permissions.service.edit && (this.context.organisation.id === row.original.organisationID || this.context.permissions.service.allOrgs) &&
                            <Link
                                to={{
                                    pathname: "/services/editPage",
                                    state: {
                                        permissions: this.context.permissions.service,
                                        item: row.original
                                    }
                                }}
                            >
                                <Button color={Colors.PRIMARY}><FontAwesomeIcon icon="edit" /></Button>
                            </Link>
                            }
                            {this.context.permissions.service.delete && (this.context.organisation.id === row.original.organisationID || this.context.permissions.habit.allOrgs) && row.original.typeID !== ServiceType.NATIVE_FEATURE &&
                            <Button color={Colors.ALERT} onClick={() => this.showDeleteModal(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                            }
                        </ButtonGroup>
                    )
                },
                maxWidth: 100,
                sortable: false,
                show: !this.props.isSelector && (this.context.permissions.service.delete || this.context.permissions.service.edit)
            }
        ]

        const filters = (
            <React.Fragment>
                <div className="cell small-12 medium-6 large-3">
                    <span>Search</span>
                    <SearchBar
                        onDidChange={(newValue) => this.searchTextDidChange(newValue)}
                    />
                </div>
                {this.context.permissions.service.allOrgs && !this.props.isSelector &&
                <div className="cell small-12 medium-6 large-3">
                    <span>Organisations</span>
                    <ReactSelect
                        name="organisations"
                        onChange={this.handleChange}
                        value={this.state.organisations}
                        options={this.state.organisationOptions}
                        isMulti={true}
                        placeholder="All"
                        className="react-select"
                    />
                </div>
                }
            </React.Fragment>

        )

        const buttons = (
            <React.Fragment>
                {this.context.permissions.service.create && !this.props.isSelector &&
                    <React.Fragment>
                        <Link
                            to={{
                                pathname: "/services/createPage",
                                state: {
                                    permissions: this.context.permissions.service
                                }
                            }}
                        >
                            <button type="button" className="button small" >Add Page Service</button>
                        </Link>
                        <button type="button" className="button small" onClick={this.handleAddNewItem}>Add Service</button>
                    </React.Fragment>
                }
            </React.Fragment>
        );

        const table = (
            <ReactTable
                columns={columns}
                data={this.state.items}
                pages={this.state.totalPages}
                pageSize={this.state.pageSize}
                loading={this.state.isLoading}
                multisort={true}
                manual
                className='-striped'
                onFetchData={(state) => {
                    this.setState({
                        page: state.page,
                        pageSize: state.pageSize,
                        sorted: state.sorted
                    }, () => {
                        this.fetchItems()
                    })
                }}
            />
        )

        return (
            <React.Fragment>
                <TableContainer
                    title="Services table"
                    filters={filters}
                    table={table}
                    buttons={buttons}
                />
                <Modal
                    classNames={{modal:"modal-medium"}}
                    open={this.state.modal.isOpen}
                    modalId="ServiceModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }

}