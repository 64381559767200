import React from 'react';
import PageContent from "../common/PageContent";
import {PluginTable} from "./PluginTable";

export default function UWBPlugin(props) {

    const pathElements = [
        {id : 0, title : "Home", url : "/"},
        {id : 1, title : "Plugin"}
    ];

    return (
        <PageContent pathElements={pathElements}>
            <PluginTable />
        </PageContent>
    )

}