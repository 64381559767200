export const SubmenuLayoutType = {
    EXTRA_LARGE: 1,
    LARGE: 2,
    MEDIUM: 3,
    SMALL: 4,

    getTitle: (type) => {
        switch (type) {
            case SubmenuLayoutType.EXTRA_LARGE:
                return "Carousel - Extra Large";
            case SubmenuLayoutType.LARGE:
                return "Carousel - Large";
            case SubmenuLayoutType.MEDIUM:
                return "Carousel - Medium";
            case SubmenuLayoutType.SMALL:
                return "Carousel - Small";
            default:
                return null;
        }
    },
    getTypes: () => {
        var types = [];
        for (var i=1; i<=4; i++) {
            types.push({value: i, label: SubmenuLayoutType.getTitle(i)})
        }
        return types;
    }

};

Object.freeze(SubmenuLayoutType);