// This is mapped to the DB

export const MenuItem = {
    HOME: 1,
    ORGANISATION: 2,
    ORGANISATIONS: 3,
    APP_SETTINGS: 4,
    EMAIL_DOMAINS: 5,
    DEPARTMENTS: 6,
    CAMPUSES: 7,
    COURSES: 8,
    ADMIN_USERS: 9,
    APP_USERS: 10,
    POSTS: 11,
    MONEY: 12,
    MONEY_OFFERS: 13,
    MONEY_STRUCTURE: 14,
    SUPPORT: 15,
    I_NEED_HELP_STRUCTURE: 16,
    REPORTS: 17,
    HABITS: 18,
    SERVICES: 19
};

Object.freeze(MenuItem);