import React from 'react'
import Breadcrumb from 'components/common/Breadcrumb'
import SubSectionButton from 'components/common/SubSectionButton'
import SubSectionHeader from 'components/common/SubSectionHeader'

export default function SubSection(props) {
    return (
        <React.Fragment>
            <div className="white-bg">
                <div className="grid-container">
                    <div className="grid-x grid-padding-x selection-head">
                        <Breadcrumb links={props.pathElements} />
                        <SubSectionHeader item={props.item} />
                    </div>
                </div>
            </div>
            <div className="grid-container">
                <div className="grid-x grid-padding-x">
                    {props.item.items &&
                     props.item.items.map((item) =>
                         <SubSectionButton key={item.id} item={item} />
                     )}
                </div>
            </div>
        </React.Fragment>
    );
}