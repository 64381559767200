import React from 'react'
import {Bar, BarChart, ResponsiveContainer, Tooltip, LineChart, Line, YAxis, XAxis, CartesianGrid} from "recharts";
import { GraphType } from 'constants/GraphType';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { ResponsiveBubbleHtml } from "nivo";
import ReactTooltip from 'react-tooltip';
import BlockUi from 'react-block-ui'



export default class SummaryChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            graphType: props.graphType
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.graphType !== this.props.graphType) {
            this.setState({
                graphType: this.props.graphType
            })
        }
    }

    onShowMoreClicked = () => {
        if (typeof this.props.onShowMore === 'function') {
            this.props.onShowMore();
        }
    }

    render() {

        // FIX:
        // If data is empty we must pass empty array to the bubble graph otherwise it breaks.
        // So we check here if any of the emotions have at least 1 value or above and then
        // based on the flag we either pass the graphs data or an empty array to the bubble chart
        let bubbleGraphIsEmpty = true;
        if (this.state.graphType === GraphType.BUBBLE) {
            this.props.data.forEach((item) => {
                if (item.value > 0) {
                    bubbleGraphIsEmpty = false;
                }
            })
        }

        return(
            <React.Fragment>
                <BlockUi tag="div" blocking={this.props.isLoadingData}>
                    <div className="chart-header">
                        <h4 className="pull-left" style={{color: this.props.color}}>{this.props.title}</h4>
                        {(this.state.graphType === GraphType.BAR || this.state.graphType === GraphType.LINE) && !this.props.dp &&
                        <React.Fragment>
                            <button className="chart-button pull-right" onClick={() => this.setState({graphType: GraphType.BAR})}>
                                <FontAwesomeIcon color={this.state.graphType === GraphType.BAR ? this.props.color : "grey"} icon="chart-bar" />
                            </button>
                            <button className="chart-button pull-right" onClick={() => this.setState({graphType: GraphType.LINE})} >
                                <FontAwesomeIcon color={this.state.graphType === GraphType.LINE ? this.props.color : "grey"} icon="chart-line" />
                            </button>
                        </React.Fragment>
                        }
                    </div>
                    {!this.props.dp ?
                    <React.Fragment>
                        {this.state.graphType === GraphType.BAR &&
                        <div style={{height: 150}}>
                            <ResponsiveContainer>
                                <BarChart
                                    data={this.props.data}
                                    barGap={1}
                                >
                                    <YAxis
                                        allowDecimals={false}
                                        type="number"
                                        interval="preserveStartEnd"
                                        tickCount={4}
                                        style={{fontSize: 12}}
                                    />
                                    <XAxis
                                        minTickGap={15}
                                        dataKey={"date"}
                                        interval="preserveStartEnd"
                                        style={{fontSize: 12}}
                                    />
                                    <Tooltip
                                        labelFormatter={() => undefined}
                                        formatter={(v, l, i) => {
                                            return [i.payload.value.toLocaleString(), i.payload.label];
                                        }}
                                    />
                                    <Bar
                                        dataKey="value"
                                        fill={this.props.color}
                                        name={this.props.title}
                                        minPointSize={2}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        }
                        {this.state.graphType === GraphType.LINE &&
                        <div style={{height: 150}}>
                            <ResponsiveContainer>
                                <LineChart
                                    data={this.props.data}
                                >
                                    <YAxis
                                        allowDecimals={false}
                                        type="number"
                                        interval="preserveStartEnd"
                                        tickCount={4}
                                        style={{fontSize: 12}}
                                    />
                                    <XAxis
                                        minTickGap={15}
                                        dataKey={"date"}
                                        interval="preserveStartEnd"
                                        style={{fontSize: 12}}
                                    />
                                    <Tooltip
                                        labelFormatter={() => undefined}
                                        formatter={(v, l, i) => {
                                            return [i.payload.value.toLocaleString(), i.payload.label];
                                        }}
                                    />
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <Line
                                        dataKey="value"
                                        type="monotone"
                                        stroke={this.props.color}
                                        name={this.props.title}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        }
                        {this.state.graphType === GraphType.BUBBLE &&
                        <div style={{height: 300}}>
                            {(!bubbleGraphIsEmpty || this.props.isLoadingData) ?
                                <ResponsiveBubbleHtml
                                    root={{
                                        label: "generic",
                                        children: bubbleGraphIsEmpty ? [] : this.props.data
                                    }}
                                    identity="label"
                                    value="value"
                                    padding={0}
                                    margin={0}
                                    colorBy={() => { return "#ffffff" }}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={12}
                                    leavesOnly={true}
                                    label={(item) => {
                                        console.log("change detected");
                                        console.log(item);
                                        return (
                                            <React.Fragment>
                                                <div data-tip={item.data.label + " : " + item.value} data-for={item.data.name} className="bubble-image" style={{backgroundImage: "url('" + item.data.imageURL + "')"}}></div>
                                                <ReactTooltip id={item.data.name} type="info" backgroundColor="#ffffff" borderColor="#d1d1d1" textColor="black" border={true}/>
                                            </React.Fragment>
                                        );
                                    }}
                                    isInteractive={false}
                                />
                                :
                                <div className="placeholder">
                                    No data
                                </div>
                            }
                        </div> 
                        }
                        <div style={{padding: 20}}>
                            <h4><strong>{this.props.periodTotal.toLocaleString()}</strong> selected period</h4>
                            <p className="no-margins"><strong>{this.props.todayTotal.toLocaleString()}</strong> today</p>
                            {this.props.total ?
                                <p className="no-margins"><strong>{this.props.total.toLocaleString()}</strong> total</p>
                                :
                                <p className="no-margins">&nbsp;</p>
                            }
                        </div>
                        {this.props.addShowMore &&
                        <div className="chart-footer">
                            <button type="button" onClick={this.onShowMoreClicked}>Show more</button>
                        </div>
                        }
                        {this.props.footer}
                    </React.Fragment>
                        :
                    <div className="placeholder" style={{height: 283}}>
                        Not enough data
                    </div>
                    }
                </BlockUi>
            </React.Fragment>
        )
    }

}