import React from 'react'
import PageContent from 'components/common/PageContent'
import APIKeysTable from 'components/organisation/apiKeys/APIKeysTable'

export default function APIKeys(props) {

    const pathElements = [
        {id: 0, title: "Home", url: "/"},
        {id: 1, title: "Organisation", url: "/organisation"},
        {id: 2, title: "API Keys"}
    ];

    return (
        <PageContent pathElements={pathElements}>
            <APIKeysTable/>
        </PageContent>
    )
}