import axios from 'axios'

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

axios.defaults.baseURL = BASE_URL + "/v2/cmsapi/";

export const APIHelper = (function(axios){
    return {
        setOrganisationID: (id) => {
            axios.defaults.headers.common['OrgID'] = id;
        },
        removeOrganisationID: () => {
            delete axios.defaults.headers.common['OrgID'];
        },
        setAuthToken: (token) => {
            axios.defaults.headers.common['AuthToken'] = token;
        },
        removeAuthToken: (token) => {
            delete axios.defaults.headers.common['AuthToken'];
        },
        onAuthFailed: (callback) => {
            if (callback instanceof Function) {
                axios.defaults.validateStatus = (status) => {
                    if (status === 401 || status === 403) {
                        callback();
                    }
                    else {
                        return status;
                    }
                }
            }
        }
    }
})(axios);


export const API = {
    parse : (r) => {
        const out = {
            success : false,
            error : {
                code : 0,
                desc : ""
            }
        };

        if (r && r.hasOwnProperty("data")) {
            if (r.data.hasOwnProperty("success")) {
                out.success = r.data.success
            }

            if (r.data.hasOwnProperty("data")) {
                out.data = r.data.data;
            }

            if (r.data.hasOwnProperty("error")) {
                out.error = r.data.error;
            }
        }

        return out;
    },
    formatError : (r) => {
        let errorObj = null;

        let out = "";
        if (r && r.hasOwnProperty("error")) {
            errorObj = r.error;
        } else if (r.hasOwnProperty("data")) {
            if (r.data.hasOwnProperty("error")) {
                errorObj = r.data.error;
            }
        }

        if (errorObj !== null) {
            if (errorObj.hasOwnProperty("desc")) {
                out += errorObj.desc;
            }

            if (errorObj.hasOwnProperty("code")) {
                out += " [" + errorObj.code + "]";
            }
        }

        return out;
    },
    auth: {
        login: "auth/authenticate",
        logout: "auth/removeAuthToken",
        requestPasswordReset: "auth/requestPasswordReset",
        resetPassword: "auth/resetPassword",
        getPasswordPolicy: "auth/getPasswordPolicy",
        getSSOTypes : "auth/getSSOTypes"
    },
    adminUser: {
        getUsersDT: "adminUser/getUsersDT",
        deleteUser: "adminUser/deleteUser",
        updateUser: "adminUser/updateUser",
        createUser: "adminUser/createUser",
        getRoleOptions: "adminUser/getRoleOptions"
    },
    appUser: {
        getUsersDT: "appUser/getUsersDT",
        deleteUser: "appUser/deleteUser",
        updateUser: "appUser/updateUser",
        createUser: "appUser/createUser",
        getRoleOptions: "appUser/getRoleOptions",
        getDepartmentOptions: "appUser/getDepartmentOptions",
        getCourseLengthOptions: "appUser/getCourseLengthOptions",
        getCourseOptions: "appUser/getCourseOptions",
        getGenderOptions: "appUser/getGenderOptions",
        getStudyYearOptions: "appUser/getStudyYearOptions",
        deleteAllUsers: "appUser/deleteAllUsers"
    },
    post: {
        getPost: "post/getPost",
        getPostsDT: "post/getPostsDT",
        deletePost: "post/deletePost",
        updatePost: "post/updatePost",
        createPost: "post/createPost",
        getPostVisibility: "post/getPostVisibility",
        getTranslations: "post/getTranslations",
        getActionButtonColors: "post/getActionButtonColors"
    },
    cta: {
        getAction: "cta/getAction",
        getActionsDT: "cta/getActionsDT",
        deleteAction: "cta/deleteAction",
        updateAction: "cta/updateAction",
        createAction: "cta/createAction",
        getActionVisibility: "cta/getActionVisibility"
    },
    money: {
        getOffersDT: "money/getOffersDT",
        deleteOffer: "money/deleteOffer",
        getOfferContent: "money/getOfferContent",
        updateOffer: "money/updateOffer",
        createOffer: "money/createOffer",
        getOfferOrganisations: "money/getOfferOrganisations",
        updateOfferOrganisations: "money/updateOfferOrganisations"
    },
    appConfig: {
        createAppConfig: "appConfig/createAppConfig",
        updateAppConfig: "appConfig/updateAppConfig",
        deleteAppConfig: "appConfig/deleteAppConfig",
        getAppConfigsDT: "appConfig/getAppConfigsDT",
        getAppConfigs: "appConfig/getAppConfigs",
        getAvailableAccessLevels: "appConfig/getAvailableAccessLevels",
        getCampusConfigs: "appConfig/getCampusConfigs"
    },
    campus: {
        getCampusesDT: "campus/getCampusesDT",
        getCampuses: "campus/getCampuses",
        updateCampus: "campus/updateCampus",
        createCampus: "campus/createCampus",
        deleteCampus: "campus/deleteCampus",
        getTranslations: "campus/getTranslations"
    },
    course: {
        getCourses: "course/getCourses",
        getCoursesDT: "course/getCoursesDT",
        updateCourse: "course/updateCourse",
        createCourse: "course/createCourse",
        createCourseBatch: "course/createCourseBatch",
        deleteCourse: "course/deleteCourse",
        getTranslations: "course/getTranslations"
    },
    organisation: {
        deleteOrganisation: "organisation/deleteOrganisation",
        createOrganisation: "organisation/createOrganisation",
        updateOrganisation: "organisation/updateOrganisation",
        getRoleTypes: "organisation/getRoleTypes",
        getAppUserOrganisations: "organisation/getAppUserOrganisations",
        getOrganisations: "organisation/getOrganisations",
        getOrganisationsDT: "organisation/getOrganisationsDT",
        getDomains: "organisation/getDomains",
        updateDomain: "organisation/updateDomain",
        createDomain: "organisation/createDomain",
        deleteDomain: "organisation/deleteDomain",
        getTimeZones: "organisation/getTimeZones",
        getAPICredentials: "organisation/getAPICredentials",
        generateAPICredentials: "organisation/generateAPICredentials",
        getSOSPage: "organisation/getSOSPage",
        updateSOSPage: "organisation/updateSOSPage",
        getLanguages: "organisation/getLanguages",
        getOrganisationSSO : "organisation/getOrganisationSSO",
        submitOrganisationSSO : "organisation/submitOrganisationSSO",
        getActivatedOrganisationSSO : "organisation/getActivatedOrganisationSSO"
    },
    department: {
        getDepartments: "department/getDepartments",
        getDepartmentsDT: "department/getDepartmentsDT",
        updateDepartment: "department/updateDepartment",
        createDepartment: "department/createDepartment",
        deleteDepartment: "department/deleteDepartment",
        getTranslations: "department/getTranslations"
    },
    studentCategory: {
        getCategories: "studentCategory/getStudentCategories",
        getCategoriesDT: "studentCategory/getStudentCategoriesDT",
        updateCategory: "studentCategory/updateStudentCategory",
        createCategory: "studentCategory/createStudentCategory",
        deleteCategory: "studentCategory/deleteStudentCategory",
        getTranslations: "studentCategory/getTranslations"
    },
    degreeType: {
        getTypes: "degreeType/getDegreeTypes",
        getTypesDT: "degreeType/getDegreeTypesDT",
        updateType: "degreeType/updateDegreeType",
        createType: "degreeType/createDegreeType",
        deleteType: "degreeType/deleteDegreeType",
        getTranslations: "degreeType/getTranslations"
    },
    habit: {
        getHabitsDT: "habit/getHabitsDT",
        getHabits: "habit/getHabits",
        deleteHabit: "habit/deleteHabit",
        createHabit: "habit/createHabit",
        updateHabit: "habit/updateHabit",
        updateVisibility: "habit/updateVisibility",
        updateOrganisationVisibility: "habit/updateOrganisationVisibility",
        getCategoryOptions: "habit/getCategoryOptions",
        getGroupOptions: "habit/getGroupOptions",
        getVisibility: "habit/getVisibility",
        getHabitUnits: "habit/getHabitUnits",
        getTranslations: "habit/getTranslations"
    },
    emotion: {
        getEmotionsDT: "emotion/getEmotionsDT",
        createEmotion: "emotion/createEmotion",
        updateEmotion: "emotion/updateEmotion",
        deleteEmotion: "emotion/deleteEmotion",
        updateVisibility: "emotion/updateVisibility",
        updateOrganisationVisibility: "emotion/updateOrganisationVisibility",
        updateOrganisationOrder: "emotion/updateOrganisationOrder",
        getVisibility: "emotion/getVisibility",
        getTranslations: "emotion/getTranslations"
    },
    onboarding: {
        getWellbeingPlan: "onboarding/getWellbeingPlan",
        updateWellbeingPlan: "onboarding/updateWellbeingPlan"
    },
    podcast: {
        getPodcasts: "podcast/getPodcasts",
        getPodcastsDT: "podcast/getPodcastsDT",
        updatePodcast: "podcast/updatePodcast",
        createPodcast: "podcast/createPodcast",
        getPodcast: "podcast/getPodcast",
        deletePodcast: "podcast/deletePodcast",
        getTranslations: "podcast/getTranslations",
        updateTranslation: "podcast/updateTranslation",
        deleteTranslation: "podcast/deleteTranslation"
    },
    service: {
        getServicesDT: "service/getServicesDT",
        updateService: "service/updateService",
        createService: "service/createService",
        updatePageServiceTranslation: "service/updatePageServiceTranslation",
        deleteService: "service/deleteService",
        getService: "service/getService",
        createPageService: "service/createPageService",
        getTranslations: "service/getTranslations",
        getTranslation: "service/getTranslation",
        deletePageServiceTranslation: "service/deletePageServiceTranslation"
    },
    support: {
        getServicesTree: "support/getServicesTree"
    },
    image: {
        uploadImage: "image/uploadImage",
        updateImage : "image/updateImage"
    },
    sectionConfig: {
        getAvailableAccessLevels: "sectionConfig/getAvailableAccessLevels",
        createSectionConfig: "sectionConfig/createSectionConfig",
        getConfigsDT: "sectionConfig/getConfigsDT",
        deleteConfig: "sectionConfig/deleteConfig",
        getConfigStructure: "sectionConfig/getConfigStructure",
        uploadServiceAccessImage: "sectionConfig/uploadServiceAccessImage",
        deleteServiceAccessImage: "sectionConfig/deleteServiceAccessImage",
        getTranslations: "sectionConfig/getTranslations",
        updateTranslation: "sectionConfig/updateTranslation",
        deleteTranslation: "sectionConfig/deleteTranslation"
    },
    challenge: {
        getChallengesDT: "challenge/getChallengesDT",
        deleteChallenge: "challenge/deleteChallenge",
        createChallenge: "challenge/createChallenge",
        updateChallenge: "challenge/updateChallenge",
        getChallenge: "challenge/getChallenge",
        getChallengeTypes: "challenge/getChallengeTypes",
        getHabitOptions: "challenge/getHabitOptions",
        getHabitGroupOptions: "challenge/getHabitGroupOptions",
        getCategoryOptions: "challenge/getCategoryOptions",
        getVisibilityOptions: "challenge/getVisibilityOptions",
        getTranslations: "challenge/getTranslations",
        getTranslation: "challenge/getTranslation",
        updateTranslation: "challenge/updateTranslation",
        deleteTranslation: "challenge/deleteTranslation"
    },
    award: {
        getAwardsDT: "award/getAwardsDT",
        deleteAward: "award/deleteAward",
        createAward: "award/createAward",
        updateAward: "award/updateAward",
        getHabitOptions: "award/getHabitOptions",
        getCategoryOptions: "award/getCategoryOptions",
        getAwardTypeOptions: "award/getAwardTypeOptions",
        getVisibilityOptions: "award/getVisibilityOptions",
        getTranslations: "award/getTranslations"
    },
    apiKey: {
        getKeysDT: "apiKey/getKeysDT",
        updateKey: "apiKey/updateKey",
        createKey: "apiKey/createKey",
        deleteKey: "apiKey/deleteKey",
        getClients: "apiKey/getClients"
    },
    report: {
        getActiveUsers: "report/getActiveUsers",
        getAppEvents: "report/getAppEvents",
        getRegistrations: "report/getRegistrations",
        getCTAMessages: "report/getCTAMessages",
        getWellbeingDays: "report/getWellbeingDays",
        getPodcastTotals: "report/getPodcastTotals",
        getPodcastViews: "report/getPodcastViews",
        getUserEmotions: "report/getUserEmotions",
        getUserEmotionsByDay: "report/getUserEmotionsByDay",
        getUserEmotionTotals: "report/getUserEmotionTotals",
        getWellbeingCategories: "report/getWellbeingCategories"
    },
    chat: {
        getConditionsAndComparators: "chat/getConditionsAndComparators",
        getProperties: "chat/getProperties",
        getCustomPropertiesDT: "chat/getCustomPropertiesDT",
        createCustomProperty: "chat/createCustomProperty",
        deleteCustomProperty: "chat/deleteCustomProperty",
        updateCustomProperty: "chat/updateCustomProperty",
        getChatFlows: "chat/getChatFlows",
        getChatFlowsDT: "chat/getChatFlowsDT",
        getChatFlow: "chat/getChatFlow",
        deleteChatFlow: "chat/deleteChatFlow",
        createChatFlow: "chat/createChatFlow",
        updateChatFlow: "chat/updateChatFlow",
        getEmotions: "chat/getEmotions",
        getWellbeingCategories: "chat/getWellbeingCategories",
        getTriggers: "chat/getTriggers",
        getTinyHabits: "chat/getTinyHabits",
        updateTinyHabits: "chat/updateTinyHabits",
        getChatFlowOptions: "chat/getChatFlowOptions",
        updateTriggers: "chat/updateTriggers",
        getNotificationsDT: "chat/getNotificationsDT",
        deleteNotification: "chat/deleteNotification",
        updateNotification: "chat/updateNotification",
        createNotification: "chat/createNotification",
        getChatLanguages : "chat/getChatLanguages",
        getGenAIIntegrationTypes : "chat/getGenAIIntegrationTypes"
    },
    chatImport: {
        initiateImport: "chatImport/initiateImport",
        performImport: "chatImport/performImport"
    },
    assessment: {
        getAssessmentsDT: "assessment/getAssessmentsDT"
    },
    plugin : {
        getPluginConfigs : "plugin/getPluginConfigs",
        getPluginConfigsDT : "plugin/getPluginConfigsDT",
        getPluginConfig : "plugin/getPluginConfig",
        submitPlugin : "plugin/submitPlugin",
        deletePlugin : "plugin/deletePlugin",
        getPluginChatFlowsDT : "plugin/getPluginChatFlowsDT",
        getPluginChatFlow : "plugin/getPluginChatFlow",
        submitPluginChatFlowList : "plugin/submitPluginChatFlowList",
        getCmsConfig : "plugin/getCmsConfig"
    },
    knowledgeDocument : {
        getKnowledgeDocumentsDT : "knowledgeDocument/getKnowledgeDocumentsDT",
        createKnowledgeDocument : "knowledgeDocument/createKnowledgeDocument",
        updateKnowledgeDocument : "knowledgeDocument/updateKnowledgeDocument",
        deleteKnowledgeDocument : "knowledgeDocument/deleteKnowledgeDocument"
    },
    knowledgeDocumentType: {
        getTypes: "knowledgeDocumentType/getTypes",
        getTypesDT: "knowledgeDocumentType/getTypesDT",
        createType: "knowledgeDocumentType/createType",
        updateType: "knowledgeDocumentType/updateType",
        deleteType: "knowledgeDocumentType/deleteType"
    },
    conversation : {
        getConversationTypesDT : "conversation/getConversationTypesDT",
        createConversationType : "conversation/createConversationType",
        updateConversationType : "conversation/updateConversationType",
        deleteConversationType : "conversation/deleteConversationType"
    },
    promptTemplate : {
        getPromptTemplatesDT : "promptTemplate/getPromptTemplatesDT",
        createPromptTemplate : "promptTemplate/createPromptTemplate",
        updatePromptTemplate : "promptTemplate/updatePromptTemplate",
        deletePromptTemplate : "promptTemplate/deletePromptTemplate"
    },
    aiAccessKey : {
        getAiAccessKey : "aiAccessKey/getAiAccessKey",
        getAiAccessKeysDT : "aiAccessKey/getAiAccessKeysDT",
        createAiAccessKey : "aiAccessKey/createAiAccessKey",
        updateAiAccessKey : "aiAccessKey/updateAiAccessKey",
        deleteAiAccessKey : "aiAccessKey/deleteAiAccessKey",
    }
};