import React from 'react'
import DetailedReport from 'components/reports/DetailedReport'
import { Button, Sizes, Colors } from 'react-foundation'
import { WellbeingCategory } from "constants/WellbeingCategory";
import Axios from "axios";
import {API} from "../../api/APIClient";


export default class WellbeingDaysReport extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            categoryOptions: [],
            selectedCategory: null
        }
    }

    componentDidMount() {
        this.getCategories();
    }

    getCategories = () => {
        Axios
            .get(API.report.getWellbeingCategories)
            .then((res) => {
                if (res.data.success) {

                    let categoryOptions = res.data.data.categories.map((item) => { return {value: item.id.toString(), label: item.name} });

                    categoryOptions.unshift({label: "All", value: null});

                    this.setState({
                        categoryOptions: categoryOptions
                    })
                }
            })
    }

    render() {

        let { filters, ...props } = this.props;

        let f = Object.assign({}, filters);
        f.categoryID = this.state.selectedCategory;

        return (
            <React.Fragment>
                <div className="grid-x grid-padding-x" style={{marginTop: 20}}>
                    <div className="small-12 cell">
                        <h5>Wellbeing Category</h5>
                    </div>
                    <div className="small-12 cell">
                        <div className="button-group">
                            {this.state.categoryOptions.map((c) => {
                                return (
                                    <Button onClick={() => this.setState({selectedCategory: c.value})} size={Sizes.TINY} color={Colors.PRIMARY} isHollow={this.state.selectedCategory !== c.value} >{c.label}</Button>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <DetailedReport {...props} filters={f} />
            </React.Fragment>

        )
    }
}