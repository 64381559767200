import React from 'react'
import AppComponent from 'components/common/AppComponent'
import {Button, ButtonGroup, Colors} from "react-foundation"
import ReactSelect from "../common/ReactSelect"
import Axios from "axios";
import {API} from "../../api/APIClient"
import BlockUI from 'react-block-ui'
import { NotificationManager } from "react-notifications"

export default class ChatTriggers extends AppComponent {

    constructor(props, context) {
        super(props);

        this.state = {
            chatFlowOptions: [],
            triggers: [],
            isLoadingTriggers: false,
            isLoadingChatFlowOptions: false,
            isSubmitting: false
        };
    }

    componentDidMount() {
        this.getChatFlowOptions();
        this.getTriggers();
    }
    
    getChatFlowOptions = () => {
        if (!this.isLoadingChatFlowOptions) {

            this.setState({
                isLoadingChatFlowOptions: true
            }, () => {

                Axios
                    .get(API.chat.getChatFlowOptions)
                    .then((res) => {
                        if (res.data.success) {
                            
                            let chatFlows = res.data.data.chatFlows;
                            chatFlows.unshift({value: null, label: "Not Selected"});
                            
                            this.setState({
                                isLoadingChatFlowOptions: false,
                                chatFlowOptions: res.data.data.chatFlows
                            })
                        }
                        else {
                            this.setState({
                                isLoadingChatFlowOptions: false
                            })
                        }
                    })

            })

        }
    }
    
    getTriggers = () => {
        if (!this.isLoadingTriggers) {

            this.setState({
                isLoadingTriggers: true
            }, () => {

                Axios
                    .get(API.chat.getTriggers)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingTriggers: false,
                                triggers: res.data.data.triggers
                            })
                        }
                        else {
                            this.setState({
                                isLoadingPlan: false
                            })
                        }
                    })
            })

        }
    }



    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let triggers = this.state.triggers;

        let index = parseInt(name.replace("trigger_", ""));
        let trigger = Object.assign({}, triggers[index]);
        trigger.chatFlowID = value;
        triggers[index] = trigger;

        console.log(trigger);
        console.log(triggers);

        this.setState({triggers: triggers})
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isSubmitting) {
            this.setState({
                isSubmitting: true
            }, () => {
                Axios
                    .post((API.chat.updateTriggers), {
                        triggers: this.state.triggers
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isSubmitting: false,
                                triggers: res.data.data.triggers
                            }, () => {
                                NotificationManager.success("Saved successfully");
                            })
                        }
                        else {
                            this.setState({
                                isUploading: false
                            }, () => {
                                NotificationManager.error(res.data.error.desc);
                            })
                        }
                    })
            })
        }
    }


    render() {

        return (
            <BlockUI tag="div" blocking={this.isSubmitting}>
                <div className="gird-x grid-padding-x" style={{marginBottom: 100}}>
                    <div className="cell small-12" style={{margin: "20px 0"}}>
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset edit-field">
                                    <legend>Triggers</legend>
                                    <BlockUI tag="div" blocking={this.state.isLoadingTriggers}>
                                        {this.state.triggers.map((trigger, index) => {

                                            return (

                                                <div className="grid-x grid-padding-x" key={index}>
                                                    <div className="small-3 cell">
                                                        <label htmlFor={"trigger_" + index} className="text-right middle">{trigger.name}</label>
                                                    </div>
                                                    <div className="small-9 cell">
                                                        <ReactSelect
                                                            name={"trigger_" + index}
                                                            className="react-select"
                                                            onChange={this.handleChange}
                                                            value={this.state.triggers[index].chatFlowID}
                                                            options={this.state.chatFlowOptions}
                                                        />
                                                        <small className="field-info">{trigger.desc}</small>
                                                    </div>
                                                </div>

                                            )


                                        })}
                                    </BlockUI>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fix-save">
                    <ButtonGroup>
                        <Button className="edit-button" onClick={() => this.setState({redirect: true})}>Cancel</Button>
                        <Button className="edit-button" color={Colors.SUCCESS} onClick={this.handleSubmit} >Save</Button>
                    </ButtonGroup>
                </div>
            </BlockUI>
        )
    }

}


