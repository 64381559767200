import React from 'react'
import Axios from 'axios'
import ReactSelect from 'components/common/ReactSelect'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import {Button, ButtonGroup, Colors, Sizes, Switch} from 'react-foundation'
import ModalProgress from 'components/common/ModalProgress'
import Image from 'components/common/Image'
import BlockUi from 'react-block-ui'
import ServicesSelector from "components/services/ServicesSelector"
import Modal from "react-responsive-modal"
import { UnitType } from "constants/UnitType";
import UUID from "../../utils/UUID";
import StandardModal from "../common/StandardModal";
import ReactTable from "react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class HabitForm extends AppComponent {

    static defaultProps = {
        item: {}
    }

    constructor(props, context) {
        super(props);

        this.state = {
            item: props.item.langID ? Object.assign({}, props.item) : Object.assign({
                organisationID: props.item.organisationID || context.organisation.id,
                organisations: [],
                unitID: null,
                unitDisplayName: null,
                unitDisplayNamePlural: null,
                secondaryUnit: null,
                secondaryUnitRatio: null,
                langID: null,
                translations: []
            }, props.item),
            organisationOptions: [
                props.item.organisationID ? {value: props.item.organisationID, label: props.item.organisationName} : {value: context.organisation.id, label: context.organisation.name}
            ],
            validated: false,
            errors: {},
            touched: {},
            groupOptions: props.item.groupID ? [{value: props.item.groupID, label: props.item.groupName}] : [],
            unitOptions: props.item.unitID ? [{value: props.item.unitID, label: props.item.unitSystemName}] : [],
            secondaryUnitOptions: [],
            categoryOptions: props.item.categoryID ? [{value: props.item.categoryID, label: props.item.categoryName}] : [],
            imagePickerURL: null,
            imagePickerFile: null,
            isUploading: false,
            uploadProgress: 0,
            isLoadingVisibility: false,
            isLoadingOrganisations: false,
            isLoadingGroups: false,
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            },
            isLoadingTranslations: false,
            languageOptions: [],
            selectedLanguage: null
        };
    }

    componentDidMount() {
        this.validate();

        if (this.state.item.langID === null) {
            this.getCategoryOptions();
            this.getHabitUnitOptions();

            if (this.state.item.categoryID) {
                this.getGroupOptions();
            }

            if (this.context.permissions.adminUser.allOrgs) {
                this.getOrganisationOptions();
                if (this.state.item.id) {
                    this.getVisibility(this.state.item.id);
                }
            }

            if (this.state.item.id) {
                this.getTranslations();
            }
            else {
                this.getLanguages();
            }
        }
    }

    getTranslations = () => {

        if (this.state.isLoadingTranslations) { return; }

        this.setState({
            isLoadingTranslations: true
        }, () => {
            Axios
                .get(API.habit.getTranslations, {
                    params: {
                        id: this.state.item.id
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        this.setState(prevState => ({
                            ...prevState,
                            item: {
                                ...prevState.item,
                                translations: res.data.data.translations
                            }
                        }), () => {
                            this.getLanguages();
                        })
                    }
                })
        })
    }

    getLanguages = () => {
        Axios
            .get(API.organisation.getLanguages)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        isLoadingTranslations: false,
                        languageOptions: res.data.data.languages.filter((item) => { return !this.state.item.translations.find((o) => o.langID.toString() === item.id.toString()) }).map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    getHabitUnitOptions = () => {
        Axios
            .get(API.habit.getHabitUnits)
            .then((res) => {
                if (res.data.success) {
                    let habitUnitOptions = res.data.data.units.map((item) => { return {value: item.id, label: item.systemName}});
                    habitUnitOptions.unshift({value: null, label: "No Unit"});

                    this.setState({
                        unitOptions: habitUnitOptions,
                        secondaryUnitOptions: habitUnitOptions
                    })
                }
            })
    }

    getGroupOptions = () => {
        if (!this.state.isLoadingGroups) {
            this.setState({
                isLoadingGroups: true
            }, () => {
                Axios
                    .get(API.habit.getGroupOptions, {
                        params: {
                            categoryID: this.state.item.categoryID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                groupOptions: res.data.data.groupOptions.map((item) => { return {value: item.id.toString(), label: item.title} }),
                                isLoadingGroups: false
                            })
                        }
                        else {
                            this.setState({
                                isLoadingGroups: false
                            })
                        }
                    })
            })
        }
    }

    getCategoryOptions = () => {
        Axios
            .get(API.habit.getCategoryOptions)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        categoryOptions: res.data.data.categoryOptions.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    getOrganisationOptions = () => {

        this.setState({
            isLoadingOrganisations: true
        });

        Axios
            .get(API.organisation.getOrganisations, {
                props: {
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        isLoadingOrganisations: false,
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    getVisibility = (itemID) => {

        this.setState({
            isLoadingVisibility: true
        });

        Axios
            .get(API.habit.getVisibility, {
                params: {
                    habitID: itemID
                }
            })
            .then((res) => {
                if (res.data.success) {
                    let item = this.state.item;
                    item.organisations = res.data.data.organisations;
                    this.setState({
                        isLoadingVisibility: false,
                        item: item
                    })
                }
            });
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        let self = this;

        reader.onloadend = () => {
            self.setState({
                imagePickerFile: file,
                imagePickerURL: reader.result
            }, () => {
                self.validate()
            })
        };

        reader.readAsDataURL(file);
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {


                let formData = new FormData();

                if (this.state.item.id) {
                    formData.append("id", this.state.item.id);
                }

                formData.append("name", this.state.item.name);
                formData.append("categoryID", this.state.item.categoryID);
                formData.append("groupID", this.state.item.groupID);
                formData.append("organisationID", this.state.item.organisationID);
                formData.append("translations", JSON.stringify(this.state.item.translations));

                if (this.state.item.desc && this.state.item.desc.length > 0) {
                    formData.append("desc", this.state.item.desc);
                }

                if (this.state.imagePickerFile) {
                    formData.append("file", this.state.imagePickerFile);
                }

                if (this.state.item.action === 1) {
                    formData.append("actionTitle", this.state.item.actionTitle);
                    formData.append("serviceID", this.state.item.serviceID);
                }



                if (this.state.item.unitID) {
                    formData.append("unitID", this.state.item.unitID);

                    if (this.state.item.unitDisplayName) {
                        formData.append("unitDisplayName", this.state.item.unitDisplayName);
                        formData.append("unitDisplayNamePlural", this.state.item.unitDisplayNamePlural);
                    }

                    if (this.state.item.secondaryUnitID) {
                        formData.append("secondaryUnitID", this.state.item.secondaryUnitID);
                        formData.append("secondaryUnitRatio", this.state.item.secondaryUnitRatio);
                    }

                    if (this.state.item.maxUnitValue) {
                        formData.append("maxUnitValue", this.state.item.maxUnitValue);
                    }

                    if (this.state.item.miniHabitTarget) {
                        formData.append("miniHabitTarget", this.state.item.miniHabitTarget);
                    }
                }



                if (this.context.permissions.habit.allOrgs) {
                    if (this.state.item.organisations) {
                        this.state.item.organisations.forEach((item) => {
                            formData.append("organisations[]", item)
                        });
                    }
                }
                else {
                    if (this.state.item.visible) {
                        formData.append("organisations[]", this.context.organisation.id);
                    }
                }

                Axios
                    .post((this.state.item.id ? API.habit.updateHabit : API.habit.createHabit), formData)
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted(res.data.data.item);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }

                        this.setState({
                            isUploading: false
                        });
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;


        let newState = {};

        if (name === "selectedLanguage") {
            newState[name] = value;
        }
        else {
            let item = this.state.item;
            item[name] = value;

            let touched = Object.assign({}, this.state.touched);
            let secondaryUnitOptions = [...this.state.unitOptions];


            if (name === "action") {
                item.serviceID = null;
                item.serviceTitle = "";
                item.serviceTypeName = "";
                item.actionTitle = "";
            }
            else if (name === "unitID") {

                item.unitDisplayName = null;
                item.unitDisplayNamePlural = null;
                item.secondaryUnitID = null;
                item.secondaryUnitRatio = null;
                item.maxUnitValue = null;

                if (value === null) {
                    item.miniHabitTarget = null;
                    delete  touched.miniHabitTarget;
                }

                delete touched.unitDisplayName;
                delete touched.unitDisplayNamePlural;
                delete touched.secondaryUnitID;
                delete touched.secondaryUnitRatio;
                delete touched.maxUnitValue;

                secondaryUnitOptions = this.state.unitOptions.filter(item => {
                    return item.value !== value;
                })

            }
            else if (name === "secondaryUnitID" && value === null) {
                item.secondaryUnitRatio = null;
                delete touched.secondaryUnitRatio;
            }
            else if (name === "isMiniHabit") {
                item.miniHabitTarget = value === 1 ? "" : null;
            }
            else if (name === "categoryID") {
                item.groupID = -1;
            }

            newState.item = item;
            newState.touched = touched;
            newState.secondaryUnitOptions = secondaryUnitOptions;
        }



        this.setState(newState, () => {
            this.validate()
            if (name === "categoryID") {
                this.getGroupOptions();
            }
        });
    }

    showServiceModal = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.item.serviceID) {
            selected.push({id: this.state.item.serviceID})
        }

        const content = (
            <ServicesSelector
                organisationID={this.state.item.organisationID}
                isSingleSelection
                selected={selected}
                onSubmitted={(service) => {

                    let item = Object.assign({}, this.state.item);
                    item.serviceID = service.id;
                    item.serviceTitle = service.title;
                    item.serviceTypeName = service.typeName;

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        })
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    size: "modal-medium"
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    validate = () => {

        if (this.state.item.langID !== null) { return; }

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.name) {
            if (touched.name) {
                errors.name = "Habit name is required";
            }
            validated = false;
        }

        if (!item.categoryID) {
            if (touched.categoryID) {
                errors.categoryID = "Category is required";
            }
            validated = false;
        }

        if (!item.id && !this.state.imagePickerFile) {
            if (touched.imageFile) {
                errors.imageFile = "Image is required"
            }
            validated = false;
        }

        if (item.action === 1) {
            if (!item.actionTitle) {
                if (touched.actionTitle) {
                    errors.actionTitle = "Button title is required"
                }
                validated = false;
            }
            if (!item.serviceID) {
                if (touched.serviceID) {
                    errors.serviceID = "Service is required"
                }
                validated = false;
            }
        }

        if (item.unitID !== null) {
            if (item.unitDisplayName !== null && item.unitDisplayName.length > 0) {
                if (item.unitDisplayNamePlural === null || item.unitDisplayNamePlural.length === 0) {
                    if (touched.unitDisplayName) {
                        errors.unitDisplayNamePlural = "Plural name must be set if display name is set"
                    }
                    validated = false;
                }
            }
        }

        if (item.secondaryUnitID !== null) {
            if (item.secondaryUnitRatio === null || item.secondaryUnitRatio.length === 0) {
                if (touched.secondaryUnitRatio) {
                    errors.secondaryUnitRatio = "Ratio is required when secondary unit is set"
                }
                validated = false;
            }
        }

        if (item.miniHabitTarget && item.miniHabitTarget.length === 0) {
            if (touched.miniHabitTarget) {
                errors.miniHabitTarget = "Mini Habit Target is required"
            }
            validated = false;
        }

        //TODO groupID

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    handleAddTranslation = (langID) => {

        const language = this.state.languageOptions.find((i) => i.value.toString() === langID.toString());

        let translation = {
            langID: language.value,
            langName: language.label,
            name: "",
            description: "",
            unitDisplayName: "",
            unitDisplayNamePlural: "",
            actionTitle: "",
            unitID: this.state.item.unitID,
            action: this.state.item.action,
            id: UUID.uuidv4(),
            isNew: 1
        };

        if (!this.state.modal.isOpen) {
            const content = (
                <HabitForm
                    item={translation}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState(prevState => ({
                languageOptions: this.state.languageOptions.filter((i) => i.value.toString() !== language.value.toString()),
                selectedLanguage: null,
                item: {
                    ...prevState.item,
                    translations: [...prevState.item.translations, translation]
                },
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    isUploading: false,
                    content: content
                })
            }))
        }

    }

    updateTranslation = (updatedTranslation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.map((o) => {
                    if (o.langID.toString() === updatedTranslation.langID.toString()) {
                        return updatedTranslation;
                    }
                    else {
                        return o;
                    }
                })
            }
        }))
    }

    handleEditTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <HabitForm
                    item={{
                        ...translation,
                        action: this.state.item.action,
                        unitID: this.state.item.unitID
                    }}
                    onCompleted={(updatedTranslation) => {
                        this.updateTranslation(updatedTranslation);
                        this.closeModal();
                    }}
                    onCancel={this.closeModal}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    content: content
                })
            })
        }
    }

    handleDeleteTranslation = (translation) => {

        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                translations: prevState.item.translations.filter((i) => i.langID.toString() !== translation.langID.toString() )
            },
            languageOptions: [...this.state.languageOptions, {label: translation.langName, value: translation.langID}].sort((a,b) => a.name < b.name ? 1 : -1 )
        }), () => {
            this.closeModal();
        })
    }

    showDeleteTranslation = (translation) => {

        if (!this.state.modal.isOpen) {
            const content = (
                <StandardModal
                    title="Delete Translation"
                    content={(
                        <React.Fragment>
                            <p>Are you sure to delete <strong>{translation.langName}</strong> translation ? This action cannot be reverted.</p>
                        </React.Fragment>
                    )}
                    buttons={(
                        <React.Fragment>
                            <button className="button secondary" type="button" onClick={this.closeModal}>
                                Cancel
                            </button>
                            <button className="button alert" type="button" onClick={() => this.handleDeleteTranslation(translation)}>
                                Delete
                            </button>
                        </React.Fragment>
                    )}
                />
            );

            this.setState({
                modal: Object.assign({}, this.state.modal, {
                    isOpen: true,
                    size: "modal-medium",
                    isUploading: false,
                    content: content
                })
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />
                <div className="modal-head">
                    <div className="modal-head">
                        {this.state.item.langID === null ?
                            <h3>{this.state.item.id ? "Edit Habit" : "New Habit"}</h3>
                            :
                            <h3>Habit ({this.state.item.langName} translation)</h3>
                        }
                    </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        {this.state.item.langID === null &&
                        <React.Fragment>
                            {this.context.permissions.habit.allOrgs &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="organisationID" className="text-right middle">* Created By</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.organisationID && "error")}>
                                    <ReactSelect
                                        name="organisationID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.item.organisationID}
                                        options={this.state.organisationOptions}
                                        onBlur={this.handleBlur}
                                    />
                                    <small className="error">{this.state.errors.organisationID}</small>
                                </div>
                            </div>
                            }
                            {!this.context.permissions.habit.allOrgs &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="visible" className="text-right middle ">Visible</label>
                                    </div>
                                    <div className="small-9 cell">
                                        <Switch size={Sizes.TINY} onChange={this.handleChange} input={{name:"visible", checked: this.state.item.visible === 1}} />
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="organisationName" className="text-right middle ">* CreatedBy</label>
                                    </div>
                                    <div className="small-9 cell">
                                        <input type="text" name="organisationName" value={this.context.organisation.name} disabled/>
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                        </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="name" className="text-right middle ">* Name</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.name && "error")}>
                                <input type="text" name="name" onChange={this.handleChange} value={this.state.item.name} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.name}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="desc" className="text-right middle">Description</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.desc && "error")}>
                                <textarea rows="6" type="text" name="desc" onChange={this.handleChange} value={this.state.item.desc} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.desc}</small>
                            </div>
                        </div>
                        {this.state.item.langID === null &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="categoryID" className="text-right middle">* Category</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.categoryID && "error")}>
                                    <ReactSelect
                                        name="categoryID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.item.categoryID}
                                        options={this.state.categoryOptions}
                                        onBlur={this.handleBlur}
                                    />
                                    <small className="error">{this.state.errors.categoryID}</small>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="groupID" className="text-right middle">* Group</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.groupID && "error")}>
                                    <ReactSelect
                                        name="groupID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.item.groupID}
                                        options={this.state.groupOptions}
                                        onBlur={this.handleBlur}
                                        isDisabled={this.state.isLoadingGroups}
                                    />
                                    <small className="error">{this.state.errors.groupID}</small>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="imageFile" className="text-right middle">Select Image</label>
                                </div>
                                <div className="large-9 small-9 cell ">
                                    <input name="imageFile" type="file" onChange={this.handleFilePickerChange} onBlur={this.handleBlur} />
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="imageFile" className="text-right middle">* Current Image</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.imageFile && "error")} style={{marginBottom: 20}}>
                                    {this.state.imagePickerURL &&
                                    <Image src={this.state.imagePickerURL} width={200} height={120} border={true} />
                                    }
                                    {!this.state.imagePickerURL && this.state.item.imageURL &&
                                    <Image src={this.state.item.imageURL} width={200} height={120} border={true} />
                                    }
                                    {!this.state.imagePickerURL && !this.state.item.imageURL &&
                                    <p>No image selected</p>
                                    }
                                    <small className="error">{this.state.errors.imageFile}</small>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset">
                                    <legend>Units</legend>
                                    {this.state.item.langID === null &&
                                    <React.Fragment>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="unitID" className="text-right middle">Unit</label>
                                            </div>
                                            <div className={"small-9 cell " + (this.state.errors.unitID && "error")}>
                                                <ReactSelect
                                                    name="unitID"
                                                    className="react-select"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.unitID}
                                                    options={this.state.unitOptions}
                                                    onBlur={this.handleBlur}
                                                />
                                                <small className="error">{this.state.errors.unitID}</small>
                                            </div>
                                        </div>
                                        {this.state.item.unitID && this.state.item.unitID !== null &&
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="maxUnitValue" className="text-right middle ">Maximum allowed value</label>
                                            </div>
                                            <div className={"small-9 cell " + (this.state.errors.maxUnitValue && "error")}>
                                                <input
                                                    type="number"
                                                    step="1"
                                                    pattern="\d+"
                                                    name="maxUnitValue"
                                                    placeholder="Users won't be able to submit habit units greater than the value set in here"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.maxUnitValue}
                                                    onBlur={this.handleBlur}
                                                />
                                                <small className="error">{this.state.errors.maxUnitValue}</small>
                                            </div>
                                        </div>
                                        }
                                    </React.Fragment>
                                    }
                                    {this.state.item.unitID === UnitType.TIMES &&
                                    <React.Fragment>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="unitDisplayName" className="text-right middle ">Unit Display Name</label>
                                            </div>
                                            <div className={"small-9 cell " + (this.state.errors.unitDisplayName && "error")}>
                                                <input
                                                    type="text"
                                                    name="unitDisplayName"
                                                    placeholder="Display unit name can be used instead of the main unit name"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.unitDisplayName}
                                                    onBlur={this.handleBlur}
                                                />
                                                <small className="error">{this.state.errors.unitDisplayName}</small>
                                            </div>
                                        </div>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="unitDisplayNamePlural" className="text-right middle ">Unit Display Name Plural</label>
                                            </div>
                                            <div className={"small-9 cell " + (this.state.errors.unitDisplayName && "error")}>
                                                <input
                                                    type="text"
                                                    name="unitDisplayNamePlural"
                                                    placeholder="Please enter a plural variant of the unit display name if the above is set"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.unitDisplayNamePlural}
                                                    onBlur={this.handleBlur}
                                                />
                                                <small className="error">{this.state.errors.unitDisplayNamePlural}</small>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    }
                                    {this.state.item.langID === null && this.state.item.unitID && this.state.item.unitID !== null &&
                                    <React.Fragment>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="secondaryUnitID" className="text-right middle">Secondary Unit</label>
                                            </div>
                                            <div className={"small-9 cell " + (this.state.errors.unitID && "error")}>
                                                <ReactSelect
                                                    name="secondaryUnitID"
                                                    className="react-select"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.secondaryUnitID}
                                                    options={this.state.secondaryUnitOptions}
                                                    onBlur={this.handleBlur}
                                                />
                                                <small className="error">{this.state.errors.secondaryUnitID}</small>
                                            </div>
                                        </div>
                                        {this.state.item.secondaryUnitID && this.state.item.secondaryUnitID !== null &&
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="secondaryUnitRatio" className="text-right middle ">Secondary Unit Ratio</label>
                                            </div>
                                            <div className={"small-9 cell " + (this.state.errors.secondaryUnitRatio && "error")}>
                                                <input
                                                    type="number"
                                                    step="1"
                                                    pattern="\d+"
                                                    name="secondaryUnitRatio"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.secondaryUnitRatio}
                                                    onBlur={this.handleBlur}
                                                    placeholder="Main unit to secondary unit ratio 1/X eg. Ratio 5 means 1 main unit equals 5 secondary units"
                                                />
                                                <small className="error">{this.state.errors.secondaryUnitRatio}</small>
                                            </div>
                                        </div>
                                        }
                                    </React.Fragment>
                                    }
                                </fieldset>
                            </div>
                        </div>
                        {this.state.item.langID === null && this.state.item.unitID &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset">
                                    <legend>Mini Habit</legend>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="isMiniHabit" className="text-right middle">Is Mini Habit?</label>
                                        </div>
                                        <div className="large-4 small-9 cell">
                                            <label className="chkbx-container">
                                                <input className="chkbx" type="checkbox" name="isMiniHabit" onChange={this.handleChange}
                                                       checked={this.state.item.miniHabitTarget !== null && this.state.item.miniHabitTarget !== undefined}/>
                                                <span className="chkmk"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {this.state.item.miniHabitTarget !== null && this.state.item.miniHabitTarget !== undefined &&
                                    <React.Fragment>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="actionTitle" className="text-right middle ">* Mini Habit Target</label>
                                            </div>
                                            <div className={"small-9 cell " + (this.state.errors.miniHabitTarget && "error")}>
                                                <input type="number" name="miniHabitTarget" step="1" pattern="\d+" onChange={this.handleChange} value={this.state.item.miniHabitTarget} onBlur={this.handleBlur} />
                                                <small className="error">{this.state.errors.miniHabitTarget}</small>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    }
                                </fieldset>
                            </div>
                        </div>
                        }
                        {this.context.permissions.habit.action &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset">
                                    <legend>Action Button</legend>
                                    {this.state.item.langID === null &&
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="action" className="text-right middle">Add Action</label>
                                        </div>
                                        <div className="large-4 small-9 cell">
                                            <label className="chkbx-container">
                                                <input className="chkbx" type="checkbox" name="action" onChange={this.handleChange}
                                                       checked={this.state.item.action || 0 === 1}/>
                                                <span className="chkmk"></span>
                                            </label>
                                        </div>
                                    </div>
                                    }
                                    {this.state.item.action === 1 &&
                                    <React.Fragment>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="actionTitle" className="text-right middle ">* Button title</label>
                                            </div>
                                            <div className={"small-9 cell " + (this.state.errors.actionTitle && "error")}>
                                                <input type="text" name="actionTitle" onChange={this.handleChange} value={this.state.item.actionTitle} onBlur={this.handleBlur} />
                                                <small className="error">{this.state.errors.actionTitle}</small>
                                            </div>
                                        </div>
                                        {this.state.item.langID === null &&
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="service" className="text-right middle">* Service</label>
                                            </div>
                                            <div className={"small-9 cell " + (this.state.errors.serviceID && "error")}>
                                                <div className="input-group">
                                                    <span className="input-group-label" style={{fontSize: 10}}>{this.state.item.serviceTypeName}</span>
                                                    <input className="input-group-field" name="serviceID" type="text" value={this.state.item.serviceTitle || "Not selected" } disabled/>
                                                    <div className="input-group-button">
                                                        <button type="button" className="button" onClick={this.showServiceModal}>Browse</button>
                                                    </div>
                                                </div>
                                                <small className="error">{this.state.errors.serviceID}</small>
                                            </div>
                                        </div>
                                        }
                                    </React.Fragment>
                                    }
                                </fieldset>
                            </div>
                        </div>
                        }
                        {this.state.item.langID === null && this.context.permissions.habit.allOrgs &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset">
                                    <legend>Visibility</legend>

                                    <BlockUi tag="div" blocking={this.state.isLoadingVisibility || this.state.isLoadingOrganisations}>
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="organisations" className="text-right middle">Organisations</label>
                                            </div>
                                            <div className="large-9 small-9 cell">
                                                <ReactSelect
                                                    name="organisations"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.organisations}
                                                    options={this.state.organisationOptions}
                                                    isMulti={true}
                                                    placeholder="None"
                                                    className="react-select"
                                                />
                                            </div>
                                        </div>
                                    </BlockUi>
                                </fieldset>
                            </div>
                        </div>
                        }
                        {this.state.item.langID === null &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <fieldset className="fieldset">
                                    <legend>Translations</legend>
                                    <div className="grid-x grid-padding-x">
                                        <div className="cell small-12">
                                            <span>Add translation for</span>
                                            <ReactSelect
                                                name="selectedLanguage"
                                                className="react-select translation-selector"
                                                onChange={this.handleChange}
                                                value={this.state.selectedLanguage}
                                                options={this.state.languageOptions}
                                                onBlur={this.handleBlur}
                                            />
                                            <Button type="button" className="translation-button" color={Colors.PRIMARY} isDisabled={!this.state.selectedLanguage} onClick={() => this.handleAddTranslation(this.state.selectedLanguage)}>Add</Button>
                                        </div>
                                    </div>
                                    <div className="grid-x grid-padding-x">
                                        <div className="cell small-12">
                                            <ReactTable
                                                columns={[
                                                    {
                                                        Header: 'Language',
                                                        accessor: 'langName'
                                                    },
                                                    {
                                                        Header: 'Actions',
                                                        Cell: row => (
                                                            <ButtonGroup size={Sizes.TINY}>
                                                                {this.context.permissions.habit.edit &&
                                                                <Button type="button" color={Colors.PRIMARY} onClick={() => this.handleEditTranslation(row.original)}><FontAwesomeIcon icon="edit" /></Button>
                                                                }
                                                                {this.context.permissions.habit.delete &&
                                                                <Button type="button" color={Colors.ALERT} onClick={() => this.showDeleteTranslation(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                                                                }
                                                            </ButtonGroup>
                                                        ),
                                                        maxWidth: 100,
                                                        show: this.context.permissions.habit.delete || this.context.permissions.habit.edit
                                                    }
                                                ]}
                                                data={this.state.item.translations}
                                                className='-striped'
                                                showPagination={false}
                                                noDataText={"No translations"}
                                                pageSize={this.state.item.translations ? this.state.item.translations.length : 1}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        }
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    {this.state.item.langID === null ?
                                        <React.Fragment>
                                            <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                                Cancel
                                            </button>
                                            <button className="button success" type="submit" disabled={!this.state.validated || this.state.isLoadingTranslations}>
                                                Submit
                                            </button>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <button className="button secondary" type="button" onClick={() => this.props.onCompleted(this.state.item)}>
                                                Update
                                            </button>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="HabitInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}

export default HabitForm;
