import React from 'react'
import Axios from "axios";
import {NotificationManager} from "react-notifications";
import ReactTable from "react-table";
import Utils from 'utils/Utils'
import { API } from 'api/APIClient'
import moment from "moment";

export default class MoodTotalsTable extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            data: [],
            selectedItem: null,
            isLoadingData: false
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startDate !== this.props.startDate ||
            prevProps.endDate !== this.props.endDate ||
            prevProps.filters !== this.props.filters) {
            this.fetchData();
        }
    }

    fetchData = () => {

        if (!this.state.isLoadingData) {

            this.setState({
                isLoadingData: true
            }, () => {

                Axios
                    .get(API.report.getUserEmotionTotals, {
                        params: {
                            filters: this.props.filters,
                            startDate: this.props.startDate.format("YYYY-MM-DD"),
                            endDate: this.props.endDate.format("YYYY-MM-DD"),
                            tz: moment().local().format("Z")
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            let data = res.data.data;

                            this.setState({
                                isLoadingData: false,
                                data: data.emotions
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingData: false
                            })
                        }
                    });

            })

        }

    }

    handleItemClick = (item) => {
        let value = this.state.selectedItem ? (this.state.selectedItem.id === item.id ? null : item) : item;
        this.setState({
            selectedItem: value
        }, () => {
            if (typeof this.props.onItemSelected === 'function') {
                this.props.onItemSelected(value)
            }
        })
    }

    render() {

        const columns = [
            {
                Header: 'ID',
                accessor: 'id',
                width: 50
            },
            {
                Header: 'Name',
                accessor: 'name',
                minWidth: 150
            },
            {
                Header: 'Selected Period Units',
                accessor: 'periodUnits',
                className: "text-center highlighted-cell"
            },
            {
                Header: 'Today Units',
                accessor: 'todayUnits',
                className: "text-center"
            },
            {
                Header: 'Total Units',
                accessor: 'units',
                className: "text-center"
            }
        ];

        const selectedID = this.state.selectedItem ? this.state.selectedItem.id : null;

        return (
            <React.Fragment>
                <div className="grid-x grid-padding-x">
                    <div className="small-12 cell">
                        <ReactTable
                            columns={columns}
                            data={this.state.data}
                            defaultPageSize={10}
                            loading={this.state.isLoadingData}
                            multisort={true}
                            className='-striped'
                            getTrProps={(table, row) => ({
                                style: {cursor: "pointer"},
                                className: selectedID === Utils.getNested(row, "original", "id") ? "selectedRow" : null,
                                onClick: () => {
                                    this.handleItemClick(row.original);
                                }
                            })}
                        />
                    </div>
                </div>
            </React.Fragment>

        )
    }
}