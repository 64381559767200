import TableContainer from "../common/TableContainer";
import React, {useEffect, useState} from "react";
import DateHelper from "../../utils/DateHelper";
import ReactTable from "react-table";
import Axios from "axios";
import {API} from "../../api/APIClient";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import {PermissionUtil} from "../../utils/PermissionUtil";

export const PluginChatFlowListSelector = (props) => {

    const {callback} = props;

    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [itemCount, setItemCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchChatFlowsFromNetwork();
    }, []);

    useEffect(() => {
        fetchChatFlowsFromNetwork();
    }, [page, pageSize, sorted])

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function rowWasSelected(row) {
        if (row) {
            handleCallback("select", row);
        }
    }

    function fetchChatFlowsFromNetwork() {
        if (isLoading) return;
        setIsLoading(true);

        const data = {
            fetchData : true
        };

        Axios.post(API.plugin.getPluginChatFlowsDT, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setItems(resp.data.rows);
                    setItemCount(resp.data.recordsTotal);
                } else {
                    console.log(API.formatError(resp));
                }
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    }

    // RENDER

    const buttons = [];
    let filters = [];

    let columns = [
        {
            Header: 'ID',
            accessor: 'id',
            width: 60,
        },
        {
            Header: 'Name',
            accessor: 'name',
            minWidth: 200
        },
        {
            Header: '# Chat Flows',
            accessor: 'data',
            width: 150,
            Cell : (row) => {
                let dataParse = JSON.parse(row.value);
                if (dataParse && Array.isArray(dataParse)) {
                    return dataParse.length;
                }

                return 0;
            }
        },
        {
            Header: 'Created At',
            accessor: 'createdAt',
            width: 180,
            Cell: row => {
                return DateHelper.convertToLocalDateTime(row.value);
            }
        },
        {
            Header: 'Actions',
            Cell: row => (
                <ButtonGroup>
                    <Button color={Colors.PRIMARY} onClick={() => { rowWasSelected(row.original) }}>Select</Button>
                </ButtonGroup>
            ),
            maxWidth: 100,
            sortable: false,
            show: PermissionUtil.delete("plugin") || PermissionUtil.edit("plugin")
        }
    ]

    let table = (
        <ReactTable
            columns={columns}
            data={items}
            page={page}
            pages={Math.ceil(itemCount / pageSize)}
            loading={isLoading}
            multisort={true}
            manual
            className={"-striped"}
            onFetchData={(state) => {
                setPageSize(state.pageSize);
                setSorted(state.sorted);
                setPage(state.page);
            }} />
    );

    return (
        <>
            <div className={"modal-head"}>
                <div className={"modal-head"}>
                    <h3>Chat Flows</h3>
                </div>
            </div>

            <div className={"modal-body"}>
                <div className="grid-x grid-padding-x">
                    <div className="small-12 cell">

                        <TableContainer
                            title="Plugin Chat Flows"
                            buttons={buttons}
                            filters={filters}
                            table={table}
                        />

                    </div>
                </div>
            </div>

        </>
    )

}