import React from 'react'
import EditorHelper from 'components/contentEditor/EditorHelper'
import Utils from 'utils/Utils'
import Image from 'components/common/Image'
import rightArrow from 'assets/img/right_arrow.png'

export default class TableItem extends React.Component {

    render() {

        const {item, defaults, style, innerRef, ...props} = this.props;

        const title = Utils.value(Utils.getNested(this.props.item, "data", "title"), "");
        const subtitle = Utils.value(Utils.getNested(this.props.item, "data", "subtitle"), "");

        return (
            <div
                ref={innerRef}
                style={Object.assign(style, {
                    paddingTop: EditorHelper.getMargin(item, defaults, "top") * this.props.scale,
                    paddingBottom: EditorHelper.getMargin(item, defaults, "bottom") * this.props.scale
                })}

                {...props}
            >
                <div
                    style={{
                        paddingLeft: EditorHelper.getMargin(item, defaults, "left") * this.props.scale,
                        paddingRight: EditorHelper.getMargin(item, defaults, "right") * this.props.scale,
                        display: "block"
                    }}
                >
                    <div
                        style={{
                            minHeight: 80 * this.props.scale,
                            display: "flex",
                            alignItems: "center",
                            paddingTop: 15 * this.props.scale,
                            paddingBottom: 15 * this.props.scale
                        }}
                    >

                        <div style={{ flexGrow: "1", marginRight: 5 * this.props.scale}}>
                            <div
                                style={{
                                    fontFamily: "Barlow Condensed, sans-serif",
                                    fontSize: 20 * this.props.scale,
                                    fontWeight: "600",
                                    lineHeight: "1em",
                                    color: "#575757"
                                }}
                            >
                                {title}
                            </div>

                            {subtitle &&
                            <div
                                style={{
                                    fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                                    fontSize: 13 * this.props.scale,
                                    color: "#575757",
                                    lineHeight: "1.3em",
                                    marginTop: 2
                                }}
                            >
                                {subtitle}
                            </div>
                            }

                        </div>

                        <div>
                            <Image
                                src={rightArrow}
                                width={20 * this.props.scale}
                                height={20 * this.props.scale}
                                fit="contain"
                            />
                        </div>

                    </div>
                </div>
                <div style={{
                    backgroundColor: "#c1c1c1",
                    height: 1,
                    marginLeft: EditorHelper.getMargin(item, defaults, "left") * this.props.scale
                }}
                />

            </div>
        )
    }

}