import 'react-dates/initialize';
import AppComponent from "../common/AppComponent"
import React from "react"
import ReportDateRange from 'components/reports/ReportDateRange'
import moment from 'moment'
import ReactSelect from "../common/ReactSelect";
import Axios from "axios";
import {API} from "../../api/APIClient";
import {NotificationManager} from "react-notifications";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DATE_PRESETS = [
    {
        text: "Last 7 days",
        start: moment().subtract(6, "days"),
        end: moment()
    },
    {
        text: "Last 30 days",
        start: moment().subtract(30, "days"),
        end: moment()
    },
    {
        text: "Last 90 days",
        start: moment().subtract(90, "days"),
        end: moment()
    }
]

export default class ReportFilters extends AppComponent {


    constructor(props, context) {
        super(props);

        this.state = {
            startDate: props.startDate || DATE_PRESETS[1].start,
            endDate: props.endDate || DATE_PRESETS[1].end,
            filters: {
                organisations: context.permissions.reports.allOrgs ? [] : [context.organisation.id],
                roles: [],
                campuses: [],
                courses: [],
                studyYears: [],
                departments: [],
                studentCategories: [],
                degreeTypes: []
            },
            organisationOptions: [],
            roleOptions: [],
            campusOptions: [],
            studyYearOptions: [],
            courseOptions: [],
            studentCategoryOptions: [],
            degreeTypesOptions: [],
            departmentOptions: [],
            additionalFiltersDisabled: context.permissions.reports.allOrgs,
            isLoadingRoleOptions: false,
            isLoadingCampuses: false,
            isLoadingRoles: false,
            isLoadingStudyYears: false,
            isLoadingCourses: false,
            isLoadingStudentCategories: false,
            isLoadingDegreeTypes: false,
        }

        if (typeof this.props.onChange === 'function') {
            this.props.onChange(this.state.startDate, this.state.endDate, this.state.filters)
        }
    }

    componentDidMount() {
        this.getRoleOptions();
        if (this.context.permissions.reports.allOrgs) {
            this.getOrganisationOptions();
        }
        else {
            this.getCampusOptions();
            this.getStudyYearOptions();
            this.getCourseOptions();
            this.getStudentCategoryOptions();
            this.getDegreeOptions();
            this.getDepartmentOptions();
        }
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    getRoleOptions = () => {
        if (!this.state.isLoadingRoles) {
            this.setState({
                isLoadingRoles: true
            }, () => {

                Axios
                    .get(API.appUser.getRoleOptions)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingRoles: false,
                                roleOptions: res.data.data.roleOptions.map((role) => { return {value: role.id.toString(), label: role.name} })
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingRoles: false
                            })
                        }
                    });
            })
        }
    }

    getCampusOptions = () => {

        if (!this.state.isLoadingCampuses) {

            this.setState({
                isLoadingCampuses: true
            }, () => {

                Axios
                    .get(API.campus.getCampuses, {
                        params: {
                            organisationID: this.state.filters.organisations[0]
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingCampuses: false,
                                campusOptions: res.data.data.campusOptions.map((campus) => { return {value: campus.id.toString(), label: campus.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingCampuses: false
                            })
                        }
                    });

            })

        }


    }

    getStudyYearOptions = () => {

        if (!this.state.isLoadingStudyYears) {

            this.setState({
                isLoadingStudyYears: true
            }, () => {

                Axios
                    .get(API.appUser.getStudyYearOptions)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingStudyYears: false,
                                studyYearOptions: res.data.data.studyYearOptions.map((studyYear) => { return {value: studyYear.value.toString(), label: studyYear.name} })
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingStudyYears: false
                            })
                        }
                    });

            })

        }


    }


    getCourseOptions = () => {

        if (!this.state.isLoadingCourses) {

            this.setState({
                isLoadingCourses: true
            }, () => {

                Axios
                    .get(API.course.getCourses, {
                        params: {
                            organisationID: this.state.filters.organisations[0]
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingCourses: false,
                                courseOptions: res.data.data.courses.map((item) => { return {value: item.id.toString(), label: item.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingCourses: false
                            })
                        }
                    });

            })

        }

    }


    getDepartmentOptions = () => {

        if (!this.state.isLoadingDepartments) {

            this.setState({
                isLoadingDepartments: true
            }, () => {

                Axios
                    .get(API.department.getDepartments, {
                        params: {
                            organisationID: this.state.filters.organisations[0]
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingDepartments: false,
                                departmentOptions: res.data.data.departments.map((item) => { return {value: item.id.toString(), label: item.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingDepartments: false
                            })
                        }
                    });
            })
        }
    }


    getStudentCategoryOptions = () => {
        if (!this.state.isLoadingStudentCategories) {

            this.setState({
                isLoadingStudentCategories: true
            }, () => {

                Axios
                    .get(API.studentCategory.getCategories, {
                        params: {
                            organisationID: this.state.filters.organisations[0]
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingStudentCategories: false,
                                studentCategoryOptions: res.data.data.studentCategories.map((item) => { return {value: item.id.toString(), label: item.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingStudentCategories: false
                            })
                        }
                    });

            })

        }
    }

    getDegreeOptions = () => {
        if (!this.state.isLoadingDegreeTypes) {

            this.setState({
                isLoadingDegreeTypes: true
            }, () => {

                Axios
                    .get(API.degreeType.getTypes, {
                        params: {
                            organisationID: this.state.filters.organisations[0]
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingDegreeTypes: false,
                                degreeTypeOptions: res.data.data.degreeTypes.map((item) => { return {value: item.id.toString(), label: item.name} }).sort((a, b) => a.label.localeCompare(b.label))
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingDegreeTypes: false
                            })
                        }
                    });

            })

        }
    }

    handleDatesChange = (start, end) => {
        this.setState({
            startDate: start,
            endDate: end
        })
    }

    handleChange = (event) => {

        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let newState = {};

        let filters = Object.assign({}, this.state.filters);

        if ((name === "organisations" && this.state.organisationOptions === value.length) ||
            (name === "roles" && this.state.roleOptions.length === value.length) ||
            (name === "studyYears" && this.state.studyYearOptions.length === value.length) ||
            (name === "campuses" && this.state.campusOptions.length === value.length) ||
            (name === "courses" && this.state.courseOptions.length === value.length) ||
            (name === "studentCategories" && this.state.studentCategoryOptions.length === value.length) ||
            (name === "degreeTypes" && this.state.degreeTypeOptions.length === value.length) ||
            (name === "departments" && this.state.departmentOptions.length === value.length)) {
            value = [];
        }

        if (name === "organisations") {

            newState.additionalFiltersDisabled = value.length !== 1;

            filters.campuses = [];
            filters.studyYears = [];
            filters.courses = [];
            filters.studentCategories = [];
            filters.degreeTypes = [];
            filters.departments = [];

            newState.campusOptions = [];
            newState.studyYearOptions = [];
            newState.courseOptions = [];
            newState.studentCategoryOptions = [];
            newState.degreeTypeOptions = [];
            newState.departmentOptions = [];
        }

        filters[name] = value;

        newState.filters = filters;

        this.setState(newState, () => {
            if (name === "organisations" && value.length === 1) {
                this.getCampusOptions();
                this.getStudyYearOptions();
                this.getCourseOptions();
                this.getStudentCategoryOptions();
                this.getDegreeOptions();
                this.getDepartmentOptions();
            }
        });
    }

    updateData = () => {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(this.state.startDate, this.state.endDate, this.state.filters)
        }
    }

    clearFilters = () => {
        let newState = {};
        newState.additionalFiltersDisabled = true;

        let filters = Object.assign({}, this.state.filters);

        filters.organisations = [];
        filters.roles = [];
        filters.campuses = [];
        filters.studyYears = [];
        filters.courses = [];
        filters.studentCategories = [];
        filters.degreeTypes = [];
        filters.departments = [];

        newState.campusOptions = [];
        newState.studyYearOptions = [];
        newState.courseOptions = [];
        newState.studentCategoryOptions = [];
        newState.degreeTypeOptions = [];
        newState.departmentOptions = [];
        newState.filters = filters;

        this.setState(newState, () => {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(this.state.startDate, this.state.endDate, this.state.filters)
            }
        });
    }

    render() {

        let selectedRange = "Custom";
        for (var i=0; i < DATE_PRESETS.length; i++) {
            let item = DATE_PRESETS[i];
            if (this.state.startDate && this.state.endDate && this.state.startDate.isSame(item.start) && this.state.endDate.isSame(item.end)) {
                selectedRange = item.text;
                break;
            }
        }

        return (
            <React.Fragment>
                <div className="grid-x grid-padding-x" style={{marginBottom: 10}}>
                    <div className="cell small-12">
                        <span style={{display: "block"}}>Date Range: {selectedRange}</span>
                        <ReportDateRange
                            datePresets={DATE_PRESETS}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={(start, end) => this.handleDatesChange(start, end)}
                        />
                    </div>
                </div>
                <div className="grid-x grid-padding-x">
                    {this.context.permissions.reports.allOrgs &&
                    <div className="cell small-12 medium-6 large-3">
                        <span>Organisations</span>
                        <ReactSelect
                            name="organisations"
                            onChange={this.handleChange}
                            value={this.state.filters.organisations}
                            options={this.state.organisationOptions}
                            isMulti={true}
                            placeholder="All"
                            className="react-select"
                            isDisabled={this.state.isLoadingOrganisations}
                        />
                    </div>
                    }
                    <div className="cell small-12 medium-6 large-3">
                        <span>User Role</span>
                        <ReactSelect
                            name="roles"
                            onChange={this.handleChange}
                            value={this.state.filters.roles}
                            options={this.state.roleOptions}
                            isMulti={true}
                            placeholder="All"
                            className="react-select"
                            isDisabled={this.state.isLoadingRoleOptions}
                        />
                    </div>
                    <div className="cell small-12 medium-6 large-3">
                        <span>Campus</span>
                        <ReactSelect
                            name="campuses"
                            onChange={this.handleChange}
                            value={this.state.filters.campuses}
                            options={this.state.campusOptions}
                            isMulti={true}
                            placeholder="All"
                            className="react-select"
                            isDisabled={this.state.isLoadingCampuses || this.state.additionalFiltersDisabled}
                        />
                    </div>
                    <div className="cell small-12 medium-6 large-3">
                        <span>Study Year</span>
                        <ReactSelect
                            name="studyYears"
                            onChange={this.handleChange}
                            value={this.state.filters.studyYears}
                            options={this.state.studyYearOptions}
                            isMulti={true}
                            placeholder="All"
                            className="react-select"
                            isDisabled={this.state.isLoadingStudyYears || this.state.additionalFiltersDisabled}
                        />
                    </div>
                    <div className="cell small-12 medium-6 large-3">
                        <span>Study Department</span>
                        <ReactSelect
                            name="courses"
                            onChange={this.handleChange}
                            value={this.state.filters.courses}
                            options={this.state.courseOptions}
                            isMulti={true}
                            placeholder="All"
                            className="react-select"
                            isDisabled={this.state.isLoadingCourses || this.state.additionalFiltersDisabled}
                        />
                    </div>
                    <div className="cell small-12 medium-6 large-3">
                        <span>Staff Department</span>
                        <ReactSelect
                            name="departments"
                            onChange={this.handleChange}
                            value={this.state.filters.departments}
                            options={this.state.departmentOptions}
                            isMulti={true}
                            placeholder="All"
                            className="react-select"
                            isDisabled={this.state.isLoadingDepartments || this.state.additionalFiltersDisabled}
                        />
                    </div>
                    <div className="cell small-12 medium-6 large-3">
                        <span>Student Category</span>
                        <ReactSelect
                            name="studentCategories"
                            onChange={this.handleChange}
                            value={this.state.filters.studentCategories}
                            options={this.state.studentCategoryOptions}
                            isMulti={true}
                            placeholder="All"
                            className="react-select"
                            isDisabled={this.state.isLoadingStudentCategories || this.state.additionalFiltersDisabled}
                        />
                    </div>
                    <div className="cell small-12 medium-6 large-3">
                        <span>Degree Type</span>
                        <ReactSelect
                            name="degreeTypes"
                            onChange={this.handleChange}
                            value={this.state.filters.degreeTypes}
                            options={this.state.degreeTypeOptions}
                            isMulti={true}
                            placeholder="All"
                            className="react-select"
                            isDisabled={this.state.isLoadingDegreeTypes || this.state.additionalFiltersDisabled}
                        />
                    </div>
                </div>
                <div className="grid-x grid-padding-x">
                    <div className="cell small-12">
                        <ButtonGroup >
                            <Button color={Colors.ALERT} onClick={this.clearFilters}>Clear Filters</Button>
                            <Button color={Colors.PRIMARY} onClick={this.updateData}>Update Data</Button>
                        </ButtonGroup>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}