import React from 'react'
import ReactSelect from "../common/ReactSelect";
import {SketchPicker} from "react-color";
import Utils from 'utils/Utils'


export default class LabelEditor extends React.Component {

    state = {
        showColorPicker: false
    }

    handleChange = (event) => {

        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = Object.assign({}, this.props.item);

        if (name === "margin-left" || name === "margin-right" || name === "margin-top" || name === "margin-bottom") {
            let type = name.replace("margin-", "");
            let style = Object.assign({}, this.props.item.style);
            let margins = Object.assign({}, style.margins);

            if (value !== null && value.toString().length > 0) {
                margins[type] = parseInt(value);
            }
            else {
                delete margins[type];
            }

            if (Object.keys(margins).length > 0) {
                style.margins = margins;
            }
            else {
                delete style.margins;
            }

            if (Object.keys(style).length > 0) {
                item.style = style;
            }
            else {
                delete item.style;
            }
        }
        else if (name === "font-type" || name === "font-size") {
            let attribute = name.replace("font-", "");
            let style = Object.assign({}, this.props.item.style);
            let font = Object.assign({}, style.font);

            if (attribute === "size") {
                value = parseInt(value);
            }

            if (value !== null && value.toString().length > 0) {
                font[attribute] = value;
            }
            else {
                delete font[attribute];
            }

            if (Object.keys(font).length > 0) {
                style.font = font;
            }
            else {
                delete style.font;
            }

            if (Object.keys(style).length > 0) {
                item.style = style;
            }
            else {
                delete item.style;
            }
        }
        else if (name === "text") {
            let data = Object.assign({}, item.data);
            data.text = value;
            item.data = data;
        }

        if (this.props.onChange instanceof Function) {
            this.props.onChange(item);
        }
    }

    handleColorChange = (color) => {
        let item = Object.assign({}, this.props.item);
        let style = Object.assign({}, item.style);
        let font = Object.assign({}, style.font);

        if (color) {
            font.color = color.hex;
            style.font = font;
        }
        else {
            delete font.color;
            if (Object.keys(font).length === 0) {
                delete style.font;
            }
        }

        if (Object.keys(style).length > 0) {
            item.style = style;
        }
        else {
            delete item.style;
        }

        if (this.props.onChange instanceof Function) {
            this.props.onChange(item);
        }
    }

    handleDelete = () => {
        if (this.props.onDelete instanceof Function) {
            this.props.onDelete(this.props.item);
        }
    }

    render() {

        let leftMargin = Utils.getNested(this.props.item, "style", "margins", "left");
        let rightMargin = Utils.getNested(this.props.item, "style", "margins", "right");
        let topMargin = Utils.getNested(this.props.item, "style", "margins", "top");
        let bottomMargin = Utils.getNested(this.props.item, "style", "margins", "bottom");

        let fontType = Utils.getNested(this.props.item, "style", "font", "type");
        let fontSize = Utils.getNested(this.props.item, "style", "font", "size");
        let fontColor = Utils.getNested(this.props.item, "style", "font", "color");

        let text = Utils.value(Utils.getNested(this.props.item, "data", "text"), "");

        return (
            <div className="grid-x grid-padding-x">
                <div className="cell small-9">
                    <h3>Label</h3>
                </div>
                <div className="cell small-3 text-right">
                    <button className="button alert small" onClick={this.handleDelete}>Delete</button>
                </div>
                <div className="cell small-12 medium-12 large-8">
                    <div className="grid-x grid-padding-x">
                        <div className="cell small-12">
                            <fieldset className="fieldset">
                                <legend>Properties</legend>
                                <div className="grid-x grid-padding-x">
                                    <div className="cell small-12">
                                        <span>Text</span>
                                        <textarea rows="6" type="text" name="text" onChange={this.handleChange} value={text} />
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div className="cell small-12 medium-12 large-4">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell small-setts-box">
                            <fieldset className="fieldset">
                                <legend>Margins</legend>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-6 cell">
                                        <span>Left</span>
                                        <input name="margin-left" type="number" placeholder="inherited" min={0} value={leftMargin} onChange={this.handleChange} />
                                    </div>
                                    <div className="small-6 cell">
                                        <span>Top</span>
                                        <input name="margin-top" type="number" placeholder="inherited" min={0} value={topMargin} onChange={this.handleChange} />
                                    </div>
                                    <div className="small-6 cell">
                                        <span>Right</span>
                                        <input name="margin-right" type="number" placeholder="inherited" min={0} value={rightMargin} onChange={this.handleChange} />
                                    </div>
                                    <div className="small-6 cell">
                                        <span>Bottom</span>
                                        <input name="margin-bottom" type="number" placeholder="inherited" min={0} value={bottomMargin} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell small-setts-box">
                            <fieldset className="fieldset">
                                <legend>Font</legend>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell">
                                        <span>Type</span>
                                        <ReactSelect
                                            classNamePrefix="editor"
                                            name="font-type"
                                            className="react-select"
                                            onChange={this.handleChange}
                                            value={fontType}
                                            options={this.props.fontTypeOptions}
                                            placeholder="inherited"
                                        />
                                    </div>
                                    <div className="small-6 cell">
                                        <span>Font Size</span>
                                        <input name="font-size" type="number" placeholder="inherited" min={0} value={fontSize} onChange={this.handleChange} />
                                    </div>
                                    <div className="small-6 cell">
                                        <span>Colour</span>
                                        <div className="input-group">
                                            <input className="input-group-field" type="text" placeholder="inherited" value={fontColor} disabled/>
                                            <div className="input-group-button color-picker">
                                                <div style={{position: "relative"}}>
                                                    <div className="swatch" onClick={ () => this.setState({showColorPicker: !this.state.showColorPicker}) }>
                                                        <div className="color" style={{backgroundColor: fontColor || "transparent"}}/>
                                                    </div>
                                                    { this.state.showColorPicker ?
                                                        <div className="popover" style={{right: 0}}>
                                                            <div className="cover" onClick={ () => this.setState({showColorPicker: false})} />
                                                            <SketchPicker color={ fontColor } onChange={ this.handleColorChange } />
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}