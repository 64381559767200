import React from 'react'
import ReactSelect from "../common/ReactSelect";
import {SketchPicker} from "react-color";
import Utils from 'utils/Utils'
import Image from "../common/Image";


export default class ImageEditor extends React.Component {

    contentModeOptions = [
        {value: "fill", label: "Scale Fill"},
        {value: "contain", label: "Aspect Fit"},
        {value: "cover", label: "Aspect Fill"}
    ]

    constructor(props) {
        super(props);
    }

    handleChange = (event) => {

        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = Object.assign({}, this.props.item);

        if (name === "margin-left" || name === "margin-right" || name === "margin-top" || name === "margin-bottom") {
            let type = name.replace("margin-", "");
            let style = Object.assign({}, this.props.item.style);
            let margins = Object.assign({}, style.margins);

            if (value !== null && value.toString().length > 0) {
                margins[type] = parseInt(value);
            }
            else {
                delete margins[type];
            }

            if (Object.keys(margins).length > 0) {
                style.margins = margins;
            }
            else {
                delete style.margins;
            }

            if (Object.keys(style).length > 0) {
                item.style = style;
            }
            else {
                delete item.style;
            }
        }
        else if (name === "sizing-aspectRatio") {
            let data = Object.assign({}, this.props.item.data);

            if (value === null || value.toString().length === 0) {
                value = 100;
            }

            value = parseInt(value);
            data.aspectRatio = value / 100;
            item.data = data;
        }
        else if (name === "sizing-contentMode") {
            let data = Object.assign({}, this.props.item.data);
            if (value === null || value.toString().length === 0) {
                value = "cover";
            }
            data.contentMode = value;
            item.data = data;
        }
        else if (name === "font-type" || name === "font-size") {
            let attribute = name.replace("font-", "");
            let style = Object.assign({}, this.props.item.style);
            let font = Object.assign({}, style.font);

            if (attribute === "size") {
                value = parseInt(value);
            }

            if (value !== null && value.toString().length > 0) {
                font[attribute] = value;
            }
            else {
                delete font[attribute];
            }

            if (Object.keys(font).length > 0) {
                style.font = font;
            }
            else {
                delete style.font;
            }

            if (Object.keys(style).length > 0) {
                item.style = style;
            }
            else {
                delete item.style;
            }
        }
        else {
            item[name] = value;
        }

        if (this.props.onChange instanceof Function) {
            this.props.onChange(item);
        }
    }

    handleFilePickerChange = (event) => {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        if (!file) {
            return;
        }

        let self = this;

        reader.onloadend = () => {
            const imagePickerFile = file;
            const imagePickerURL = reader.result;

            let item = Object.assign({}, self.props.item);
            let data = Object.assign({}, item.data);
            data.url = imagePickerURL;
            item.data = data;
            item.imagePickerURL = imagePickerURL;
            item.imagePickerFile = imagePickerFile;

            if (self.props.onChange instanceof Function) {
                self.props.onChange(item);
            }
        };

        reader.readAsDataURL(file);
    }

    handleDelete = () => {
        if (this.props.onDelete instanceof Function) {
            this.props.onDelete(this.props.item);
        }
    }

    render() {

        let leftMargin = Utils.getNested(this.props.item, "style", "margins", "left");
        let rightMargin = Utils.getNested(this.props.item, "style", "margins", "right");
        let topMargin = Utils.getNested(this.props.item, "style", "margins", "top");
        let bottomMargin = Utils.getNested(this.props.item, "style", "margins", "bottom");

        let aspectRatio = Utils.getNested(this.props.item, "data", "aspectRatio");
        aspectRatio = aspectRatio * 100;

        let contentMode = Utils.getNested(this.props.item, "data", "contentMode");

        let image = Utils.getNested(this.props.item, "data", "url");
        if (image && image.length === 0) {
            image = undefined;
        }

        return (
            <div className="grid-x grid-padding-x">
                <div className="small-9 cell">
                    <h3>Image</h3>
                </div>
                <div className="cell auto text-right">
                    <button className="button alert small" onClick={this.handleDelete}>Delete</button>
                </div>
                <div className="cell small-12 medium-12 large-8">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell">
                            <fieldset className="fieldset">
                                <legend>Properties</legend>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="imageFile" className="text-right middle">Select Image</label>
                                    </div>
                                    <div className="large-9 small-9 cell ">
                                        <input name="imageFile" type="file" onChange={this.handleFilePickerChange}/>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="imageFile" className="text-right middle">* Current Image</label>
                                    </div>
                                    <div className="large-9 small-9 cell " style={{marginBottom: 20}}>
                                        {this.props.item.imagePickerURL &&
                                        <Image src={this.props.item.imagePickerURL} width={200} height={100} border={true} fit="contain" />
                                        }
                                        {!this.props.item.imagePickerURL && image &&
                                        <Image src={image} width={200} height={100} border={true} fit="contain"/>
                                        }
                                        {!this.props.item.imagePickerURL && !image &&
                                        <p>No image selected</p>
                                        }
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div className="cell small-12 medium-12 large-4">
                    <div className="grid-x grid-padding-x">
                        <div className="small-12 cell small-setts-box">
                            <fieldset className="fieldset">
                                <legend>Margins</legend>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-6 cell">
                                        <span>Left</span>
                                        <input name="margin-left" type="number" placeholder="inherited" min={0} value={leftMargin} onChange={this.handleChange} />
                                    </div>
                                    <div className="small-6 cell">
                                        <span>Top</span>
                                        <input name="margin-top" type="number" placeholder="inherited" min={0} value={topMargin} onChange={this.handleChange} />
                                    </div>
                                    <div className="small-6 cell">
                                        <span>Right</span>
                                        <input name="margin-right" type="number" placeholder="inherited" min={0} value={rightMargin} onChange={this.handleChange} />
                                    </div>
                                    <div className="small-6 cell">
                                        <span>Bottom</span>
                                        <input name="margin-bottom" type="number" placeholder="inherited" min={0} value={bottomMargin} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div className="medium-4 small-12 cell">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell small-setts-box">
                                <fieldset className="fieldset">
                                    <legend>Sizing</legend>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-6 cell">
                                            <span>Height Aspect Ratio</span>
                                            <input name="sizing-aspectRatio" type="number" placeholder="inherited" min={1} value={aspectRatio} onChange={this.handleChange} />
                                        </div>
                                        <div className="small-6 cell">
                                            <span>Content Mode</span>
                                            <ReactSelect
                                                classNamePrefix="editor"
                                                name="sizing-contentMode"
                                                className="react-select"
                                                onChange={this.handleChange}
                                                value={contentMode}
                                                options={this.contentModeOptions}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}