// Mapped to the DB

export const DayTime = {
    MORNING: 1,
    AFTERNOON: 2,
    EVENING: 3,
    ONCE_AT_ANY_TIME: 4,
    getTitle: function(value) {
        switch (value) {
            case DayTime.MORNING:
                return "Morning";
            case DayTime.AFTERNOON:
                return "Afternoon";
            case DayTime.EVENING:
                return "Evening";
            case DayTime.ONCE_AT_ANY_TIME:
                return "Anytime";
        }
    },
    getSelectOptions: function() {
        return [
            {value: DayTime.MORNING, label: this.getTitle(DayTime.MORNING)},
            {value: DayTime.AFTERNOON, label: this.getTitle(DayTime.AFTERNOON)},
            {value: DayTime.EVENING, label: this.getTitle(DayTime.EVENING)},
            {value: DayTime.ONCE_AT_ANY_TIME, label: this.getTitle(DayTime.ONCE_AT_ANY_TIME)}
        ];
    }
};

Object.freeze(DayTime);