import React from 'react'
import Axios from 'axios'
import ReactSelect from 'components/common/ReactSelect'
import { API } from 'api/APIClient'
import AppComponent from 'components/common/AppComponent'
import ModalProgress from 'components/common/ModalProgress'
import moment from 'moment'
import DatePicker from "components/common/DatePicker"
import DateHelper from 'utils/DateHelper'

// This comment is just added in order to try enforce npm to detect a change in this file

export default class AppConfigForm extends AppComponent {

    static defaultProps = {
        item: {}
    }

    constructor(props, context) {
        super(props);

        this.state = {
            item: Object.assign({
                createdAt: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                organisationID: context.organisation.id,
                requireConsent: 0,
                showWellbeingOnboarding: 1,
                showTutorial: 1,
                active: 1
            }, props.item),
            organisationOptions: [
                props.item.organisationID ? {value: props.item.organisationID, label: props.item.organisationName} : {value: context.organisation.id, label: context.organisation.name}
            ],
            validated: false,
            errors: {},
            touched: {},
            isUploading: false,
            roleOptions: [],
            campusOptions: [],
            visibility: [],
        };
    }

    componentDidMount() {
        this.validate();
        if (this.context.permissions.appConfig.allOrgs) {
            this.getOrganisationOptions();
        }
    }

    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id.toString(), label: item.name} })
                    })
                }
            })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {
                Axios
                    .post((this.state.item.id ? API.appConfig.updateAppConfig : API.appConfig.createAppConfig), this.state.item)
                    .then((res) => {
                        if (res.data.success) {
                            this.props.onSubmitted(res.data.data.item);
                        }
                        else if (res.data.error) {
                            let errors = this.state.errors;
                            errors.genericError = res.data.error.desc;
                            this.setState({
                                errors: errors
                            });
                        }

                        this.setState({
                            isUploading: false
                        });
                    })
            })
        }
    }

    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = this.state.item;
        item[name] = value;

        this.setState({
            item: item
        }, () => {
            this.validate()
        });
    }

    validate = () => {

        let validated = true;
        let errors = {};

        const item = this.state.item;
        const touched = this.state.touched;

        if (!item.name) {
            if (touched.name) {
                errors.name = "Campus name is required";
            }
            validated = false;
        }

        if (!item.promotionDate) {
            if (touched.promotionDate) {
                errors.promotionDate = "Promotion date is required"
            }
            validated = false;
        }

        if (!item.supportEmail) {
            if (touched.supportEmail) {
                errors.supportEmail = "Support email is required"
            }
            validated = false;
        }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    render() {
        return (
            <React.Fragment>
                <ModalProgress show={this.state.isUploading} />
                <div className="modal-head">
                    <h3>{this.state.item.id ? "Edit App Config" : "New App Config"}</h3>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="createdAt" className="text-right middle">Created At</label>
                            </div>
                            <div className="large-9 small-9 cell">
                                <input name="createdAt" type="text" value={moment(DateHelper.convertToLocalDateTime(this.state.item.createdAt), "YYYY-MM-DD HH:mm:ss").format("LLLL")} disabled={true} />
                            </div>
                        </div>
                        {this.context.permissions.appConfig.allOrgs && !this.props.item.id ?
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="organisationID" className="text-right middle">* Organisation</label>
                                </div>
                                <div className={"small-9 cell " + (this.state.errors.organisationID && "error")}>
                                    <ReactSelect
                                        name="organisationID"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.item.organisationID}
                                        options={this.state.organisationOptions}
                                        onBlur={this.handleBlur}
                                    />
                                    <small className="error">{this.state.errors.organisationID}</small>
                                </div>
                            </div>
                            :
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="organisationName" className="text-right middle ">* Organisation</label>
                                </div>
                                <div className="small-9 cell">
                                    <input type="text" name="organisationName" value={this.state.item.organisationName} disabled/>
                                </div>
                            </div>
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="name" className="text-right middle ">* Config Name</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.name && "error")}>
                                <input type="text" name="name" onChange={this.handleChange} value={this.state.item.name} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.name}</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="supportEmail" className="text-right middle ">* Support Email</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.supportEmail && "error")}>
                                <input type="email" name="supportEmail" onChange={this.handleChange} value={this.state.item.supportEmail} onBlur={this.handleBlur} />
                                <small className="error">{this.state.errors.supportEmail}</small>
                                <small className="field-info">Support email address that is available to users in the app</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="startDate" className="text-right middle">* Promotion Date</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.promotionDate && "error")}>
                                <DatePicker
                                    name="promotionDate"
                                    value={this.state.item.promotionDate}
                                    onChange={this.handleChange}
                                    options={{
                                        dateFormat: "m-d",
                                        altFormat: "F J",
                                        altInput: true,
                                        static: true
                                    }}
                                />
                                <small className="error">{this.state.errors.promotionDate}</small>
                                <small className="field-info">Promotion date is used to determine users current year of study. It is only used for "Student" and "Both Student and Staff" user types.</small>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="showWellbeingOnboarding" className="text-right middle">Show Wellbeing Onboarding</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.showWellbeingOnboarding && "error")}>
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="showWellbeingOnboarding" onChange={this.handleChange} checked={this.state.item.showWellbeingOnboarding === 1} onBlur={this.handleBlur} />
                                    <span className="chkmk"></span>
                                    <small className="error">{this.state.errors.showWellbeingOnboarding}</small>
                                </label>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="showTutorial" className="text-right middle">Show Tutorial</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.showTutorial && "error")}>
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="showTutorial" onChange={this.handleChange} checked={this.state.item.showTutorial === 1} onBlur={this.handleBlur} />
                                    <span className="chkmk"></span>
                                    <small className="error">{this.state.errors.showTutorial}</small>
                                </label>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="requireConsent" className="text-right middle">Show Data Sharing Screen</label>
                            </div>
                            <div className={"small-9 cell " + (this.state.errors.requireConsent && "error")}>
                                <label className="chkbx-container">
                                    <input className="chkbx" type="checkbox" name="requireConsent" onChange={this.handleChange} checked={this.state.item.requireConsent === 1} onBlur={this.handleBlur} />
                                    <span className="chkmk"></span>
                                    <small className="error">{this.state.errors.requireConsent}</small>
                                </label>
                                <small className="field-info">Determines if the data sharing consent screen should be displayed during the onboarding. It will only affect "Student" and "Both Student and Staff" users.</small>
                            </div>
                        </div>
                        <div className="form-error-msg grid-x grid-padding-x" style={{display: this.state.errors.genericError ? "block" : "none"}}>
                            <div className="cell small-12">
                                <span>{this.state.errors.genericError}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}
