import React, {useEffect, useRef, useState} from "react";
import ReactTable from "react-table";
import Image from "../common/Image";
import DateHelper from "../../utils/DateHelper";
import {Button, ButtonGroup, Colors, Sizes, Switch} from "react-foundation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableContainer from "../common/TableContainer";
import Axios from "axios";
import {API} from "../../api/APIClient";
import {ContextUtil} from "../../utils/ContextUtil";
import {PermissionUtil} from "../../utils/PermissionUtil";
import Modal from "react-responsive-modal";
import {PluginForm} from "./PluginForm";
import StandardModal from "../common/StandardModal";
import {Link} from "react-router-dom";
import {Toast} from "../../utils/ToastUtil";

export const PluginTable = (props) => {

    const MODAL_MODE_FORM = 1;
    const MODAL_MODE_DELETE = 2;

    const {isSelector} = props;

    const [items, setItems] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([{id : "id", "desc" : true}]);
    const [totalPages, setTotalPages] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const [organisationID, setOrganisationID] = useState();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState(-1);
    const [modalPlugin, setModalPlugin] = useState(null);

    const searchTimeout = useRef();

    useEffect(() => {
        fetchPluginConfigsFromNetwork();

        if (PermissionUtil.allOrgs("plugin")) {
            // TODO Get organisations
        }
    }, []);

    useEffect(() => {
        fetchPluginConfigsFromNetwork();
    }, [page, pageSize]);

    function showPluginModal(plugin) {
        setModalMode(MODAL_MODE_FORM);
        setModalPlugin(plugin);
        setModalOpen(true);
    }

    function pluginModalDidDismiss() {
        setModalOpen(false);
        fetchPluginConfigsFromNetwork();
    }

    function promptForDeletion(plugin) {
        setModalMode(MODAL_MODE_DELETE);
        setModalPlugin(plugin);
        setModalOpen(true);
    }

    function formDidCallback(action, data) {
        if (action === "submit") {
            if (data) {
                // TODO
            }

            setModalOpen(false);
        } else if (action === "cancel") {
            setModalOpen(false);
        }
    }

    function searchTextDidChange(newValue) {
        setSearchKey(newValue);

        searchTimeout.current = setTimeout(() => {
            fetchPluginConfigsFromNetwork();
        }, 1000);
    }

    function fetchPluginConfigsFromNetwork() {
        if (isLoading) return;
        setIsLoading(true);

        Axios.get(API.plugin.getPluginConfigsDT)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setItems(resp.data.rows);
                    setTotalPages(resp.data.recordsTotal);
                } else {
                    console.log(resp);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    function deletePluginConfigOverNetwork() {
        if (isDeleting) return;
        setIsDeleting(true);

        const data = {
            id : modalPlugin ? modalPlugin.pluginId : -1
        }

        Axios.post(API.plugin.deletePlugin, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    fetchPluginConfigsFromNetwork();
                    setModalOpen(false);
                } else {
                    console.log(API.formatError(resp));
                }
                setIsDeleting(false);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // RENDER

    const buttons = [];
    if (PermissionUtil.allOrgs("plugin")) {
        buttons.push(
            <Link
                to={{
                    pathname: "/plugin/chatFlows",
                    state: {}
                }}
            >
                <button type="button" className="button small">Manage Chat Flows</button>
            </Link>
        )
    }

    if (PermissionUtil.create("plugin")) {
        buttons.push(
            <Link to={{
                pathname: "/plugin/support",
                state: {}
            }}>
                <button type="button" className="button small">Manage Support</button>
            </Link>
        )

        buttons.push(
            <button type="button" className="button small" onClick={() => showPluginModal()}>Add Configuration</button>
        )
    }

    let columns = [
        {
            Header: 'ID',
            accessor: 'pluginId',
            width: 60
        },
        {
            Header: 'Name',
            accessor: 'name',
            minWidth: 200
        },
        {
            Header: 'Created By',
            accessor: 'organisationName',
            width: 150
        },
        {
            Header: 'Created At',
            accessor: 'createdAt',
            width: 180,
            Cell: row => {
                return DateHelper.convertToLocalDateTime(row.value);
            }
        },
        // {
        //     Header: "Visible",
        //     accessor: 'visible',
        //     width: 90,
        //     Cell: row => {
        //         if (PermissionUtil.edit("plugin")) {
        //             return (<Switch size={Sizes.TINY} onChange={(event) => this.handleHabitVisibility(row.original, event)} input={{checked: row.original.visible}} />);
        //         }
        //         else {
        //             return row.original.visible === 1 ? "Yes" : "No";
        //         }
        //     },
        //     className: "text-center",
        //     show: !isSelector && organisationID !== null
        // },
        {
            Header: 'Actions',
            Cell: row => (
                <ButtonGroup size={Sizes.TINY}>
                    {PermissionUtil.edit("plugin") && (ContextUtil.getOrganisation().id === row.original.organisationID || PermissionUtil.allOrgs("plugin")) &&
                        <Button color={Colors.PRIMARY} onClick={() => { console.log(row); showPluginModal(row.original) }}><FontAwesomeIcon icon="edit" /></Button>
                    }
                    {PermissionUtil.delete("plugin") && (ContextUtil.getOrganisation().id === row.original.organisationID || PermissionUtil.allOrgs("plugin")) &&
                        <Button color={Colors.ALERT} onClick={() => promptForDeletion(row.original)}><FontAwesomeIcon icon="trash" /></Button>
                    }
                </ButtonGroup>
            ),
            maxWidth: 100,
            sortable: false,
            show: PermissionUtil.delete("plugin") || PermissionUtil.edit("plugin")
        }
    ];

    const table = (
        <ReactTable
            columns={columns}
            data={items}
            page={page}
            pages={Math.ceil(totalPages / pageSize)}
            pageSize={pageSize}
            loading={isLoading}
            multisort={true}
            manual
            className='-striped'
            onFetchData={(state) => {
                setPageSize(state.pageSize);
                setSorted(state.sorted);
                setPage(state.page);
            }}
        />
    );

    const filters = [];

    let modalContent = [];
    if (modalOpen) {
        if (modalMode === MODAL_MODE_FORM) {
            modalContent = (
                <PluginForm
                    pluginId={modalPlugin ? modalPlugin.pluginId : -1}
                    callback={formDidCallback}
                />
            );
        } else if (modalMode === MODAL_MODE_DELETE) {
            modalContent = (
                <StandardModal
                    title={"Delete Configuration"}
                    content={(
                        <>
                            <p>Are you sure you want to delete the configuration <strong>{modalPlugin ? modalPlugin.name : "X"}</strong>? This action cannot be reverted.</p>
                            <p>Please note, any integrations using this configuration will cease functioning correctly when removed.</p>
                        </>
                    )}
                    buttons={(
                        <>
                            <button className={"button secondary"} type={"button"} onClick={() => setModalOpen(false)}>
                                Cancel
                            </button>

                            <button className={"button alert"} type={"button"} onClick={() => deletePluginConfigOverNetwork()}>
                                Delete
                            </button>
                        </>
                    )} />
            )
        }
    }

    return (
        <>
            <TableContainer
                title="Plugins"
                buttons={buttons}
                filters={filters}
                table={table}
            />

            <Modal
                classNames={{modal: "modal-medium"}}
                open={modalOpen}
                modalId="PluginModal"
                onClose={pluginModalDidDismiss}
                center
            >
                {modalContent}
            </Modal>
        </>
    )

}