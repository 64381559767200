import React from 'react'
import AppComponent from 'components/common/AppComponent'
import {Button, ButtonGroup, Colors} from "react-foundation"
import ReactSelect from "components/common/ReactSelect"
import Axios from "axios";
import {API} from "api/APIClient"
import BlockUI from 'react-block-ui'
import { NotificationManager } from "react-notifications"
import ServicesSelector from "components/services/ServicesSelector";
import Modal from "react-responsive-modal";
import { Redirect } from 'react-router-dom'
import {ServiceType} from "../../../constants/ServiceType";

export default class WellbeingScoreSetup extends AppComponent {

    constructor(props, context) {
        super(props);

        this.state = {
            item: {
                organisationID: context.organisation.id,
                serviceID: null,
                serviceTitle: null
            },
            organisationOptions: [
                {value: context.organisation.id, label: context.organisation.name}
            ],
            redirect: null,
            isLoadingHabits: false,
            isLoadingPodcasts: false,
            isLoadingPlan: false,
            isUploading: false,
            isLoadingSOSPage: false,
            errors: {},
            modal: {
                isOpen: false,
                content: null,
                isUploading: false,
                error: null
            }
        };
    }

    componentDidMount() {
        if (this.context.permissions.wellbeingScore.allOrgs) {
            this.getOrganisationOptions();
        }
        this.getSOSPage();
        this.validate();
    }

    getSOSPage = () => {
        if (!this.isLoadingSOSPage) {

            this.setState({
                isLoadingSOSPage: true
            }, () => {

                Axios
                    .get(API.organisation.getSOSPage, {
                        params: {
                            organisationID: this.state.item.organisationID
                        }
                    })
                    .then((res) => {
                        if (res.data.success) {

                            let item = Object.assign({}, this.state.item);

                            if (res.data.data.service) {
                                item.serviceID = res.data.data.service.id;
                                item.serviceTitle = res.data.data.service.title;
                            }
                            else {
                                item.serviceID = null;
                                item.serviceTitle = null;
                            }



                            this.setState({
                                isLoadingSOSPage: false,
                                item: item
                            }, () => {
                                this.validate();
                            })
                        }
                        else {
                            this.setState({
                                isLoadingSOSPage: false
                            }, () => {
                                this.validate();
                            })
                        }
                    })

            })

        }
    }


    getOrganisationOptions = () => {
        Axios
            .get(API.organisation.getOrganisations, {
                props: {
                    appUsersOnly: 1
                }
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        organisationOptions: res.data.data.organisations.map((item) => { return {value: item.id, label: item.name} })
                    })
                }
            })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        let item = this.state.item;
        item[name] = value;

        let change = {
            item: item
        };

        if (name === "organisationID") {
            change.item = {
                organisationID: value,
                serviceID: null,
                serviceTitle: null
            };
        }

        this.setState(change, () => {
            this.validate();
            if (name === "organisationID") {
                this.getSOSPage();
            }
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (! this.state.isUploading) {
            this.setState({
                isUploading: true
            }, () => {
                Axios
                    .post((API.organisation.updateSOSPage), this.state.item)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isUploading: false,
                                initialItem: Object.assign({}, this.state.item)
                            }, () => {
                                NotificationManager.success("Saved successfully");
                                this.validate();
                            })
                        }
                        else {
                            this.setState({
                                isUploading: false
                            }, () => {
                                NotificationManager.error(res.data.error.desc);
                            })
                        }
                    })
            })
        }
    }

    validate = () => {

        let validated = true;


        this.setState({
            validated: validated
        });
    }

    showServiceModal = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.item.serviceID) {
            selected.push({id: this.state.item.serviceID})
        }

        const content = (
            <ServicesSelector
                organisationID={this.state.item.organisationID}
                serviceTypes={[ServiceType.PAGE]}
                isSingleSelection
                selected={selected}
                onSubmitted={(service) => {

                    let item = Object.assign({}, this.state.item);
                    item.serviceID = service.id;
                    item.serviceTitle = service.title;

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null,
                    size: "modal-large"
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    render() {

        if (this.state.redirect) {
            return (<Redirect to="/organisation" />);
        }

        return (
            <BlockUI tag="div" blocking={this.isUploading}>
                <div className="gird-x grid-padding-x" style={{marginBottom: 100}}>
                    <div className="cell small-12" style={{margin: "20px 0"}}>
                        <div className="grid-x grid-padding-x">
                            {this.context.permissions.wellbeingScore.allOrgs &&
                            <div className="small-12 medium-6 large-3 cell ">
                                <span>Organisation</span>
                                <ReactSelect
                                    name="organisationID"
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.item.organisationID}
                                    options={this.state.organisationOptions}
                                    isDisabled={this.state.isLoadingHabits}
                                />
                            </div>
                            }
                        </div>
                        <div className="small-12">
                            <fieldset className="fieldset">
                                <legend>Wellbeing Score</legend>
                                <BlockUI tag="div" blocking={this.state.isLoadingSOSPage}>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="service" className="text-right middle">Low score help's page</label>
                                        </div>
                                        <div className={"small-9 cell " + (this.state.errors.serviceID && "error")}>
                                            <div className="input-group">
                                                <input className="input-group-field" name="serviceID" type="text" value={this.state.item.serviceTitle || "Not selected" } disabled/>
                                                {this.context.permissions.wellbeingScore.edit &&
                                                <div className="input-group-button">
                                                    <button type="button" className="button" onClick={this.showServiceModal}>Browse</button>
                                                </div>
                                                }
                                            </div>
                                            <small className="error">{this.state.errors.serviceID}</small>
                                            <small className="field-info">Selected page will be displayed on the Wellbeing Score page in case if user's latest score was Low or Very Low.</small>
                                        </div>
                                    </div>
                                </BlockUI>
                            </fieldset>
                        </div>
                    </div>
                </div>

                { this.context.permissions.wellbeingScore.edit &&
                <div className="fix-save">
                    <ButtonGroup>
                        <Button className="edit-button" onClick={() => this.setState({redirect: true})}>Cancel</Button>
                        <Button className="edit-button" color={Colors.SUCCESS} disabled={!this.state.validated} onClick={this.handleSubmit} >Save</Button>
                    </ButtonGroup>
                </div>
                }

                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="OnboadingInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </BlockUI>
        )
    }

}


