import Axios from "axios";
import {API} from "../api/APIClient";

export const TranslationUtil = {
    fetchChatLanguages : (callback) => {
        Axios.get(API.chat.getChatLanguages)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (callback) {
                        callback("success", resp.data.languages);
                    }
                } else {
                    if (callback) {
                        callback("error", API.formatError(r));
                    }
                }
            })
            .catch((e) => {
                console.log(e);
                if (callback) {
                    callback("error", e);
                }
            });
    },
    fetchChatLanguageOptions : (callback) => {
        TranslationUtil.fetchChatLanguages((action, data) => {
            if (action === "success") {
                const mapped = data.map((lang) => {
                    return {
                        label : lang.name,
                        value: lang.code
                    }
                });
                callback("success", mapped);
            } else {
                callback(action, data);
            }
        });
    }
}