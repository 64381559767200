import React from 'react'
import Axios from 'axios'
import moment from 'moment'
import { API } from 'api/APIClient'
import Image from 'components/common/Image'
import ReactSelect from 'components/common/ReactSelect'
import AppComponent from 'components/common/AppComponent'
import AxiosHelper from 'utils/AxiosHelper'
import ModalProgress from 'components/common/ModalProgress'
import FileInput from 'components/common/FileInput'
import { NotificationManager } from 'react-notifications'
import BlockUI from 'react-block-ui'
import ServicesSelector from "../services/ServicesSelector";
import Modal from "react-responsive-modal";
import DatePicker from 'components/common/DatePicker'
import PostHelp from 'components/posts/PostHelp'
import DateHelper from 'utils/DateHelper'
import TemplateFile from "../../assets/files/podcast_asset_template.psd";
import UUID from "../../utils/UUID";
import StandardModal from "../common/StandardModal";
import {Button, ButtonGroup, Colors, Sizes} from "react-foundation";
import ReactTable from "react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { SketchPicker } from 'react-color';

class ButtonColors extends AppComponent {

    static defaultProps = {
        onSubmitted: () => void 0,
    }

    constructor(props, context) {
        super(props);

        this.state = {
            buttons: [],
            isLoadingButtons: false
        };
    }

    componentDidMount() {
        this.getColorOptions()
    }

    getColorOptions = () => {
        if (!this.state.isLoadingButtons) {
            this.setState({
                isLoadingButtons: true
            }, () => {

                Axios
                    .get(API.post.getActionButtonColors)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingButtons: false,
                                buttons: res.data.data.colors
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingButtons: false
                            })
                        }
                    });
            })
        }
    }

    handlePresetClick = (colors) => {
        this.props.onSubmitted(colors);
    }

    render() {

        const styles = {
            standardButton: {
                minWidth: "150px",
                backgroundColor: this.props.defaultButtonBGColor,
                color: this.props.defaultButtonTextColor,
                height: "40px",
                textAlign: "center",
                lineHeight: "40px",
                fontWeight: "900",
                borderRadius: "25px",
                display: "inline-block",
                paddingLeft: "20px",
                paddingRight: "20px",
                border: "1px solid #000000",
                cursor: "pointer"
            },
            buttonPreview: {
                minWidth: "150px",
                height: "40px",
                textAlign: "center",
                lineHeight: "40px",
                fontWeight: "900",
                borderRadius: "25px",
                display: "inline-block",
                paddingLeft: "20px",
                paddingRight: "20px",
                margin: "5px",
                cursor: "pointer"
            }
        }

        return (
            <React.Fragment>
                <ModalProgress show={this.state.isLoadingButtons} showProgress={false} title="Loading"/>
                <div className="modal-head">
                    Select Colour Presets
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <h5>Standard Button Colour</h5>
                                <div style={styles.standardButton} onClick={() => { this.handlePresetClick({textColor: this.props.defaultButtonTextColor, bgColor: this.props.defaultButtonBGColor}) }}>Button title</div>
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <h5>Accessible Colour Combinations</h5>
                                {this.state.buttons.map((item) => {
                                    return (<div style={Object.assign({backgroundColor: item.bgColor, color: item.textColor},styles.buttonPreview)} onClick={() => { this.handlePresetClick(item) } }>Button title</div>)
                                })}
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default ButtonColors;
